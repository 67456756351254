import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useChangePasswordMutation } from '../../api/services/usersApi'
import Spinner from '../Spinner'
import LoginPicture from '../../img/image 11.png'
import loginIcon from '../../img/Group 1210 (1).png'

function ChangePassword() {
  const navigate = useNavigate()
  const [formData, setFormData] = useState({
    password: '',
    confirm_password: '',
  })
  const [updateUser, { isLoading }] = useChangePasswordMutation()
  const handleNavigate = () => {
    navigate('/login')
  }
  const handleHomepage = () => {
    navigate('/')
  }
  const urlParams = new URLSearchParams(window.location.search)
  const param1Value = urlParams.get('q')

  const handleUpdateUser = async () => {
    try {
      if (formData.password !== formData.confirm_password) {
        toast.error('Nova lozinka i potvrda nove lozinke se ne podudaraju')
        return
      }
      await updateUser({
        ...formData,
        body: formData,
        verification_code: param1Value,
      })

      toast.success('Vaš profil je uspješno ažuriran')
      setFormData({
        password: '',
        confirm_password: '',
      })
      navigate('/login')
    } catch (error) {
      console.error('Greška prilikom ažuriranja korisnika:', error)
      toast.error('Došlo je do greške prilikom ažuriranja korisnika')
    }
  }

  return (
    <div className="flex w-full h-full overflow-y-hidden">
      <div className="hidden w-[40%] h-screen bg-white lg:flex justify-end md:justify-start items-center rounded-md">
        <div>
          <img
            src={LoginPicture}
            alt="bannerImg"
            // style={{ maxHeight: '960px', width: '100%', height: 'auto' }}
          />
        </div>
      </div>
      {/* Drugi dio */}
      <div className=" md:px-14 md:py-10 bg-white w-full lg:w-[60%] rounded-md flex lg:justify-end justify-center items-center md:block h-full">
        <div className="flex flex-col gap-6 w-full mr-4 md:mr-0 justify-center items-center mt-44 md:mt-24 monitor:mt-36">
          <div className="flex font-bold text-black text-lg">
            <div className="ml-2 monitor:mt-5 hover:cursor-pointer">
              <img
                src={loginIcon}
                alt="logo"
                className=""
                onClick={handleHomepage}
              />
            </div>
          </div>
          {isLoading ? (
            <Spinner />
          ) : (
            <div className="flex flex-col font-medium text-base gap-5 font-luxorlight">
              <div className="flex flex-col ">
                <label htmlFor="email">Nova lozinka</label>

                <input
                  type="password"
                  // placeholder="Nova lozinka"
                  value={formData.password || ''}
                  onChange={(e) =>
                    setFormData({ ...formData, password: e.target.value })
                  }
                  className="border border-[#C2C2C2] h-[50px] w-[310px] md:w-full monitor:w-full px-2 outline-[#DCA950]"
                />
              </div>
              <div className="flex flex-col w-full">
                <label htmlFor="password">Potvrdite novu lozinku</label>
                <input
                  value={formData.confirm_password || ''}
                  type="password"
                  // placeholder="Potvrdite novu lozinku"
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      confirm_password: e.target.value,
                    })
                  }
                  className="border border-[#C2C2C2] h-[50px] md:w-[550px] monitor:w-[660px] px-2 outline-[#DCA950]"
                />
              </div>
              <div className="flex flex-row gap-8 mt-4 w-full">
                <button
                  type="submit"
                  className=" bg-black h-[50px] text-white font-medium p-2 w-1/2"
                  onClick={handleNavigate}
                >
                  {'Odustani'}
                </button>
                <button
                  type="submit"
                  className="bg-[#DCA950] h-[50px] text-white font-medium p-2 w-1/2"
                  onClick={handleUpdateUser}
                >
                  {'Potvrdi'}
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default ChangePassword
