import { api } from '../api'

// GET api/v1/scents/get-scents/page/page_size

export const scentsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getAllScents: builder.query({
      query: ({ page, page_size, query }) =>
        `api/v1/scents/get-scents/${page}/${page_size}/${
          query ? `?${query}` : ''
        }`,
      providesTags: ['Scent'],
    }),
    getAllScent: builder.query({
      query: () => `api/v1/scents/get-scent-non-paginated`,
      providesTags: ['Scent'],
    }),
    getOneScent: builder.query({
      query: ({ scent_id }) => `api/v1/scents/get-scent/${scent_id}`,
      providesTags: ['Scent'],
    }),
    addScent: builder.mutation({
      query: (body) => ({
        url: 'api/v1/scents',
        body,
        method: 'POST',
      }),
      invalidatesTags: ['Scent'],
    }),
    updateScent: builder.mutation({
      query: (body) => ({
        url: 'api/v1/scents',
        body,
        method: 'PUT',
      }),
      invalidatesTags: ['Scent'],
    }),
    deleteScent: builder.mutation({
      query: ({ scent_id }) => ({
        url: `api/v1/scents/${scent_id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Scent'],
    }),
  }),
})
export const {
  useAddScentMutation,
  useDeleteScentMutation,
  useGetAllScentsQuery,
  useGetOneScentQuery,
  useUpdateScentMutation,
  useGetAllScentQuery,
} = scentsApi
