import React from 'react'
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFViewer,
  Image,
  Font,
} from '@react-pdf/renderer'
import { RxCross2 } from 'react-icons/rx'
import { format } from 'date-fns'
import IMAGES from '../../img/images'

Font.register({
  family: 'Open Sans',
  fonts: [
    {
      src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf',
    },
    {
      src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-700.ttf',
      fontWeight: 700,
    },
  ],
})

const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: '#ffffff',
  },
  section: {
    marginRight: 8,
    marginLeft: 8,
    // marginTop: 12,
    padding: 8,
    flexGrow: 1,
  },
  coupon: {
    textAlign: 'center',

    marginTop: '31px',
    padding: 10,
    height: '6cm',
    // border: '1px solid #ccc',
  },
  title: {
    color: 'black',
    fontWeight: 'extrabold',
    // fontFamily: '',
    // fontStyle:,
    position: 'absolute',
    right: '87px',
    top: '15px',
    fontSize: 24,
    marginBottom: 5,
  },
  code: {
    fontSize: 12,
    position: 'absolute',
    bottom: '30px',
    right: '75px',
  },
  footer: {
    marginTop: 10,
    fontSize: 10,
    position: 'absolute',
    bottom: '8px',
    right: '55px',
    color: 'white',
  },
  link: {
    // textAlign: center,
    position: 'absolute',
    bottom: '10px',
    left: '172px',
    color: 'violet',
    fontSize: 16,
  },
  image: {
    // backgroundImage: 'url("src/img/kupondesigned.png")',
    // backgroundSize: 'cover',
    // backgroundRepeat: 'no-repeat',
    height: '6cm',
    position: 'absolute',
    zIndex: '-10',
    marginLeft: '20px',
    maxHeight: '5.93cm',
    // padding: 10,
    // backgroundColor: 'black',
  },
  subtitle: {
    position: 'absolute',
    top: '45px',
    right: '87px',
    fontSize: 12,
  },
  heading: {
    position: 'absolute',
    bottom: '28px',
    left: '70px',
    color: 'white',
    fontSize: 18,
  },
})

const printCoupons = ({ allCoupons, setShowPrint }) => {
  return (
    <>
      <div className="fixed inset-0 flex items-center justify-center z-50">
        <div className="fixed inset-0 bg-black opacity-50"></div>
        <div className="flex flex-col rounded-lg p-10  bg-white z-10 space-y-7 ">
          <div className="flex flex-row justify-between">
            <h1 className="text-xl font-semibold">Printanje kupona</h1>
            <RxCross2
              className="w-6 h-6 cursor-pointer"
              onClick={() => {
                setShowPrint(false)
              }}
            />
          </div>
          <PDFViewer width="1000px" height="600px">
            <Document>
              <Page size="A4" style={styles.page}>
                <View style={styles.section}>
                  {allCoupons?.map((coupon) => (
                    <View style={styles.coupon}>
                      <Image
                        src={IMAGES.backgroundCoupon || ''}
                        style={styles.image}
                      />
                      <Text style={styles.title}>
                        {Math.floor(coupon?.percentage)}%
                      </Text>
                      <Text style={styles.subtitle}>POPUST</Text>
                      <Text style={styles.code}>{coupon?.content}</Text>
                      <Text style={styles.footer}>
                        Kupon važi do{' '}
                        {format(new Date(coupon?.to), 'dd.MM.yyyy')}
                      </Text>
                      <Text style={styles.heading}>
                        Iskoristite kupovinom ONLINE na:
                      </Text>
                      <Text style={styles.link}>mad.ba</Text>
                    </View>
                  ))}
                </View>
              </Page>
            </Document>
          </PDFViewer>
        </div>
      </div>
    </>
  )
}

export default printCoupons
