import { api } from '../api'

export const imageApi = api.injectEndpoints({
  endpoints: (builder) => ({
    updateImagePosition: builder.mutation({
      query: (body) => ({
        url: `api/v1/image-positions/`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['imagePosition'],
    }),
    getAllImagePositions: builder.query({
      query: () => `api/v1/image-positions/get-all-images/`,
      providesTags: ['imagePosition'],
    }),
  }),
})

export const { useUpdateImagePositionMutation, useGetAllImagePositionsQuery } =
  imageApi
