import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  volume_id: '',
}

const perfumeSlice = createSlice({
  name: 'perfume',
  initialState,
  reducers: {
    setVolumeId: (state, action) => {
      state.volume_id = action.payload
    },
  },
})

export const { setVolumeId } = perfumeSlice.actions
export default perfumeSlice.reducer
