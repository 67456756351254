import React from 'react'
import loginIcon from '../../img/Group 1210 (1).png'
import RegisterForm from './RegisterForm'
import RegisterPicture from '../../img/Rectangle 2238.png'
import { useNavigate } from 'react-router'
import { useGetAllImagePositionsQuery } from '../../api/services/imagePosition'

const Register = () => {
  const navigate = useNavigate()
  const handleHomepage = () => {
    navigate('/')
  }
  const { data: imagePositions } = useGetAllImagePositionsQuery()

  return (
    <div className="flex w-full h-full">
      <div className="hidden md:w-[40%] h-screen bg-white lg:flex justify-end md:justify-start items-center rounded-md">
        <div className="w-full h-full">
          <img
            src={
              Array.isArray(imagePositions) && imagePositions.length > 0
                ? imagePositions[7].isDefault
                  ? RegisterPicture
                  : imagePositions[7].image
                : ''
            }
            alt="bannerImg"
            className="w-full h-screen"
          />
        </div>
      </div>
      {/* Drugi dio */}
      <div className="pb-4 md:px-14 md:py-10 pt-12 bg-white w-full md:w-[60%] rounded-md flex lg:justify-evenly justify-center md:block h-full">
        <div className="flex flex-col mt-6 h-full">
          <div className="flex font-bold text-black text-lg justify-center mb-8">
            <div className="ml-2 monitor:mt-5 hover:cursor-pointer w-full h-full">
              <img
                src={loginIcon}
                alt="logo"
                className=""
                onClick={handleHomepage}
              />
            </div>
          </div>
          <RegisterForm />
        </div>
      </div>
    </div>
  )
}

export default Register
