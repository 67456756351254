import React from 'react'
import { useMemo, useEffect, useState } from 'react'

import { FaUser, FaFileDownload, FaFilePdf, FaClock } from 'react-icons/fa'
import { AiFillEdit, AiOutlineSelect } from 'react-icons/ai'
import { MdWorkspacesFilled } from 'react-icons/md'
import { FiActivity } from 'react-icons/fi'
import {
  BsFillTrashFill,
  BsPlusSquareFill,
  BsBarChartSteps,
  BsGearFill,
} from 'react-icons/bs'
import { IoLogoWebComponent } from 'react-icons/io5'

import {
  useFilters,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
  useExpanded,
} from 'react-table'

import DatePicker, { registerLocale } from 'react-datepicker'
import bs from 'date-fns/locale/bs'
import { useAsyncDebounce } from 'react-table'
import SubRow from './SubRow'
import PrefetchPagination from './PrefetchPagination'
import Spinner from './Spinner'
import ColumnFilterPagination from './ColumnFilterPagination'
import { useSelector } from 'react-redux'
import { filterByFirstCharacter } from './FilterByFirstCharacter'
import CustomPrefetchPagination from '../pages/CustomPrefetchPagination'

registerLocale('bs', bs)

const CustomSubrowTable = ({
  columns,
  data,
  isLoading,
  isFetching,
  handleAddArticle,
  handleEdit,
  handleDelete,
  handleAddUser,
  handleComponent,
  handleDownload,
  handleActivate,
  handleObradiZahtjev,
  handlePlaceOfExpense,
  handleShowLogs,
  handleAddWorkTime,
  handleSelect,
  isDeleting,
  noProdDelete,
  type,
  currentDate,
  setCurrentDate,
  startDate,
  setDateRange,
  endDate,
  currentYear,
  setCurrentYear,
  actions,
  tooltipAddArticle,
  tooltipEditText,
  tooltipDeleteText,
  tooltipComponent,
  tooltipAddText,
  tooltipPOXText,
  tooltipLogText,
  tooltipSelectText,
  navbar = true,
  pagination = true,
  wfull = 'w-full',
  filtering,
  filterRows = false,
  setFilteredRows,
  CustomSubComponent,
  expandedRow,
  handleRowClick,
  handleChangePhases,
  handleDownloadPdf,
  tooltipPhaseText,
  tooltipDownload,
  tooltipDownloadPdf,
  tooltipAddWorkTime,
  // Pagination
  controlledPageCount,
  setPage,
  pageManual,
  setPageAmount,
  count,
  filterInputs,
  setFilterInputs,

  // dynamic columns
  api = '',
  apiType = '',
  dynamicColumns,
}) => {
  const [openColumnSelection, setOpenColumnSelection] = useState(false)

  //Actions buttons
  const tableHooks = (hooks) => {
    hooks.visibleColumns.push((columns) => [
      ...columns,
      {
        id: 'action',
        Header: 'Akcije',
        Cell: ({ row }) => (
          <div className="flex items-center justify-center space-x-1">
            {handleEdit && (
              <div className="tooltip tooltip-left" data-tip={tooltipEditText}>
                <button
                  className="btn btn-xs"
                  onClick={(e) => {
                    handleEdit(row)
                    e.stopPropagation()
                  }}
                  disabled={row.original?.order_status === 'ZATVOREN'}
                >
                  <AiFillEdit />
                </button>
              </div>
            )}
            {handlePlaceOfExpense && (
              <div className="tooltip tooltip-left" data-tip={tooltipPOXText}>
                <button
                  className="btn btn-xs"
                  onClick={(e) => {
                    handlePlaceOfExpense(row)
                    e.stopPropagation()
                  }}
                  //  disabled={row.original?.order_status !== 'U PRIPREMI'}
                >
                  <MdWorkspacesFilled />
                </button>
              </div>
            )}
            {handleDelete && (
              <div
                className="tooltip tooltip-left"
                data-tip={tooltipDeleteText}
              >
                <button
                  className="btn btn-xs"
                  onClick={(e) => {
                    handleDelete(row)
                    e.stopPropagation()
                  }}
                  disabled={
                    !noProdDelete
                      ? isDeleting ||
                        row.values.status === 'ANNULLED' ||
                        row.original?.order_status !== 'U PRIPREMI'
                      : isDeleting
                  }
                >
                  <BsFillTrashFill />
                </button>
              </div>
            )}
            {handleAddUser && (
              <div className="tooltip tooltip-left" data-tip={tooltipAddText}>
                <button
                  className="btn btn-xs"
                  onClick={(e) => {
                    handleAddUser(row)
                    e.stopPropagation()
                  }}
                >
                  <FaUser />
                </button>
              </div>
            )}{' '}
            {handleDownloadPdf && (
              <div
                className="tooltip tooltip-left"
                data-tip={tooltipDownloadPdf}
              >
                <button
                  className="btn btn-xs"
                  onClick={(e) => {
                    handleDownloadPdf(row)
                    e.stopPropagation()
                  }}
                >
                  <FaFilePdf />
                </button>
              </div>
            )}
            {handleChangePhases && (
              <div className="tooltip tooltip-left" data-tip={tooltipPhaseText}>
                <button
                  className="btn btn-xs"
                  onClick={(e) => {
                    handleChangePhases(row)
                    e.stopPropagation()
                  }}
                >
                  <BsBarChartSteps />
                </button>
              </div>
            )}
            {handleDownload && (
              <div className="tooltip tooltip-left" data-tip={tooltipDownload}>
                <button
                  className="btn btn-xs"
                  onClick={(e) => {
                    handleDownload(row)
                    e.stopPropagation()
                  }}
                  //  disabled={row.original?.status === 'RESERVED' || row.original?.primary_status === 'RESERVED'}
                >
                  <FaFileDownload />
                </button>
              </div>
            )}
            {handleActivate && (
              <button
                className="btn btn-xs"
                onClick={(e) => {
                  handleActivate(row)
                  e.stopPropagation()
                }}
              >
                <AiOutlineSelect />
              </button>
            )}
            {handleAddWorkTime && (
              <div
                className="tooltip tooltip-left"
                data-tip={tooltipAddWorkTime}
              >
                <button
                  className="btn btn-xs"
                  onClick={(e) => {
                    handleAddWorkTime(row)
                    e.stopPropagation()
                  }}
                >
                  <FaClock />
                </button>
              </div>
            )}
            {handleComponent && (
              <div className="tooltip tooltip-left" data-tip={tooltipComponent}>
                <button
                  className="btn btn-xs"
                  onClick={(e) => {
                    handleComponent(row)
                    e.stopPropagation()
                  }}
                >
                  <IoLogoWebComponent />
                </button>
              </div>
            )}
            {handleObradiZahtjev && (
              <button
                className="btn btn-xs"
                onClick={(e) => {
                  handleObradiZahtjev(row)
                  e.stopPropagation()
                }}
                disabled={row.values.status === 'ANNULLED'}
              >
                Obradi
              </button>
            )}
            {handleSelect && (
              <div
                className="tooltip tooltip-left"
                data-tip={tooltipSelectText}
              >
                <button
                  className="btn btn-xs"
                  onClick={() => handleSelect(row)}
                >
                  <AiOutlineSelect />
                </button>
              </div>
            )}
            {handleShowLogs && (
              <div className="tooltip tooltip-left" data-tip={tooltipLogText}>
                <button
                  className="btn btn-xs"
                  onClick={(e) => {
                    handleShowLogs(row)
                    e.stopPropagation()
                  }}
                  disabled={row.original?.order_status !== 'U IZRADI'}
                >
                  <FiActivity />
                </button>
              </div>
            )}
            {handleAddArticle && (
              <div
                className="tooltip tooltip-left"
                data-tip={tooltipAddArticle}
              >
                <button
                  className="btn btn-xs"
                  onClick={(e) => {
                    handleAddArticle(row)
                    e.stopPropagation()
                  }}
                  disabled={row.original?.order_status === 'ZATVOREN'}
                >
                  <BsPlusSquareFill />
                </button>
              </div>
            )}
          </div>
        ),
      },
    ])
  }

  //FILTERS
  const handleFilterChange = useAsyncDebounce((value, columnId) => {
    setPage(1)
    setFilterInputs((prev) => {
      const newValue = value.trim() || ' '
      return { ...prev, [columnId]: newValue }
    })
  }, 50)

  const defaultColumn = useMemo(
    () => ({
      Filter: ({ column }) => (
        <ColumnFilterPagination
          column={column}
          handleFilterChange={handleFilterChange}
          count={count}
        />
      ),
    }),
    [filterInputs, handleFilterChange, count],
  )

  const now = new Date()

  const { user } = useSelector((state) => state.auth)
  //   const { data: columnData } = useGetFilterTableQuery(
  //     { user_id: user?.users_id, api, type: apiType },
  //     {
  //       skip: !user?.users_id || !api,
  //     },
  //   )

  //   const hiddenColumns = useMemo(
  //     () => (columnData?.columns ? JSON.parse(columnData?.columns) : []),
  //     [columnData],
  //   )

  const cols = useMemo(
    () => (Array.isArray(columns) ? [...columns] : []),
    [columns],
  )

  const tableInstance = useTable(
    {
      columns: cols,
      data,
      manualPagination: true,
      manualFilters: true,
      pageCount: controlledPageCount,
      defaultColumn,
      filterTypes: {
        filterByFirstCharacter: filterByFirstCharacter,
      },
      initialState: { pageSize: 25 },
      filterable: true,
    },
    useFilters,
    useGlobalFilter,
    actions && tableHooks,
    useSortBy,
    useExpanded,
    usePagination,
  )
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    pageOptions,
    page,
    state: { pageSize },
    setPageSize,
    pageCount,
    filteredRows,
  } = tableInstance

  useEffect(() => {
    if (filterRows && setFilteredRows) {
      setFilteredRows(filteredRows)
    }
  }, [filterRows, filteredRows])

  return (
    <>
      {dynamicColumns ? (
        <div className="max-w-md">
          <button
            className="btn btn-bloc"
            onClick={() => setOpenColumnSelection(true)}
          >
            <BsGearFill className="h-6 w-6" />
          </button>
        </div>
      ) : null}
      {navbar && (
        <div className="flex items-end justify-between font-luxorlight">
          {/* <GlobalFilter
            preGlobalFilteredRows={preGlobalFilteredRows}
            setGlobalFilter={setGlobalFilter}
            globalFilter={globalFilter}
          /> */}
          {type === 'year' && (
            <div className="flex items-center mt-3 mb-3 space-x-3">
              <p className="w-full">Godina:</p>
              <DatePicker
                showYearPicker
                dateFormat="yyyy"
                selected={currentYear}
                onChange={(date) => setCurrentYear(date)}
                className="border-2 border-solid rounded-lg outline-none input input-sm border-slate-500"
                locale="bs"
                onFocus={(e) => {
                  e.preventDefault()
                }}
              />
            </div>
          )}
          {type === 'yearMonth' && (
            <div className="flex items-center mt-3 mb-3 space-x-3">
              <p className="w-full">Period:</p>
              <DatePicker
                showMonthYearPicker
                dateFormat="MM.yyyy"
                selected={currentDate}
                onChange={(date) => setCurrentDate(date)}
                className="border-2 border-solid rounded-lg outline-none input input-sm border-slate-500"
                locale="bs"
                onFocus={(e) => {
                  e.preventDefault()
                }}
              />
            </div>
          )}
          {type === 'dayRange' && (
            <div className="flex items-center mt-3 mb-3 space-x-3">
              <p className="w-full">Period:</p>
              <DatePicker
                selectsRange={true}
                startDate={startDate}
                onChange={(date) => setDateRange(date)}
                endDate={endDate}
                dateFormat="dd.MM.yyyy"
                // isClearable={true}
                className="border-2 border-solid rounded-lg outline-none input input-sm border-slate-500"
                locale="bs"
                onFocus={(e) => {
                  e.preventDefault()
                }}
              />
            </div>
          )}
          {pagination && (
            <CustomPrefetchPagination
              pageSize={pageSize}
              setPageSize={setPageSize}
              pageOptions={pageOptions}
              pageCount={pageCount}
              setPage={setPage}
              setPageAmount={setPageAmount}
              pageManual={pageManual}
            />
          )}
        </div>
      )}
      {/* {isLoading && <Spinner />} */}
      <div className="overflow-auto block max-w-full shadow-lg shadow-slate-400 font-luxor">
        <table
          {...getTableProps()}
          className={`text-base border border-main-400  ${wfull}`}
        >
          <thead className="p-2">
            {headerGroups.map((headerGroup) => (
              // eslint-disable-next-line react/jsx-key
              <tr
                {...headerGroup.getHeaderGroupProps()}
                className="  bg-main-400 text-main-300 "
              >
                {headerGroup.headers.map((column) => (
                  // eslint-disable-next-line react/jsx-key
                  <th
                    {...column.getHeaderProps({
                      style: {
                        minWidth: column.minWidth,
                        width: column.width,
                        maxWidth: column.maxWidth,
                        textAlign: 'center',
                      },
                    })}
                    className="p-2 border  border-t-main-400  border-b-main-400  border-l-main-300  text-sm font-semibold border-r-main-300 first-of-type:border-l-main-400 last-of-type:border-r-main-400 w-full"
                  >
                    <div>
                      <span {...column.getSortByToggleProps()}>
                        {column.render('Header')}
                        {column.isSorted
                          ? column.isSortedDesc
                            ? ' ▼'
                            : ' ▲'
                          : ''}
                      </span>
                    </div>

                    {filtering && (
                      <div className="text-sky-900">
                        {column.canFilter ? column.render('Filter') : null}
                      </div>
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody {...getTableBodyProps()}>
            {page.map((row, idx) => {
              prepareRow(row)
              var dateObj = new Date(row.original.planned_delivery_date)
              const tolerance = new Date()
              tolerance.setDate(dateObj.getDate() - +row.original.tolerance)

              tolerance.setHours(0)
              tolerance.setMinutes(0)
              tolerance.setSeconds(0)
              return (
                <React.Fragment key={idx}>
                  <tr
                    {...row.getRowProps()}
                    className={`bg-white hover:bg-slate-200 border-collapse border border-b-main-700 text-sm text-center cursor-pointer ${
                      isFetching ? 'bg-gray-200 text-gray-400' : ''
                    } `}
                    onClick={handleRowClick ? () => handleRowClick(row) : null}
                  >
                    {row.cells.map((cell) => {
                      return (
                        // eslint-disable-next-line react/jsx-key
                        <td
                          {...cell.getCellProps({
                            style: {
                              minWidth: cell.column.minWidth,
                              width: cell.column.width,
                            },
                          })}
                          className={
                            row.original.order_status === 'ZATVOREN'
                              ? `p-1 border bg-white border-l-white border-r-white border-b-main-700 text-sm text-center`
                              : now <= dateObj
                              ? `p-1 border ${
                                  row.original.is_updated === 'Y'
                                    ? 'bg-blue-400'
                                    : now >= tolerance
                                    ? 'bg-orange-300'
                                    : 'bg-white'
                                } border-l-white border-r-white border-b-main-700 text-sm text-center`
                              : `p-1 border ${
                                  row.original.is_updated === 'Y'
                                    ? 'bg-blue-400'
                                    : now >= tolerance
                                    ? 'bg-orange-300'
                                    : 'bg-white'
                                } border-l-white border-r-white border-b-main-700 text-sm text-center`
                          }
                        >
                          {cell.render('Cell')}
                        </td>
                      )
                    })}
                  </tr>
                  {expandedRow === row.id ? (
                    <tr className="h-full">
                      <td colSpan={row.cells.length} className="h-full">
                        <SubRow id={row.original.order_id} />
                      </td>
                    </tr>
                  ) : null}
                </React.Fragment>
              )
            })}
          </tbody>
        </table>
      </div>
      {isLoading && (
        <div className="flex justify-center items-center mt-5">
          <Spinner />
        </div>
      )}
    </>
  )
}

export default CustomSubrowTable
