import React from 'react'
import teta from '../../img/Group 1232 (1).png'
import PerfumeTypeTitle from './PerfumeTypeTitle'
import { useGetSuggestedArticlesQuery } from '../../api/services/articleApi'
import { useNavigate } from 'react-router-dom'
import ArticleCard from './ArticleCard'

function NicheCollection({ imagePositions }) {
  const navigate = useNavigate()
  const { data: suggestedArticles } = useGetSuggestedArticlesQuery('NICHE')
  return (
    <>
      <div className="flex md:flex-row flex-col monitor:h-[1320px] md:h-[870px] mb-20 border border-t-2 monitor:mt-36">
        <div className="md:w-[50%] w-full overflow-hidden relative">
          <img
            className=" w-full object-cover object-bottom"
            src={
              Array.isArray(imagePositions) && imagePositions.length > 0
                ? imagePositions[3].isDefault
                  ? teta
                  : imagePositions[3].image
                : ''
            }
            alt=""
          />
          <div className="flex flex-col gap-2 absolute monitor:bottom-[145px] monitor:left-[140px] md:bottom-[80px] md:left-[100px] bottom-10 left-10">
            <PerfumeTypeTitle text={'Niche'} fontsize={20} />
            <p className="monitor:text-4xl md:text-3xl font-luxor  text-white">
              Preporučeno iz Niche kolekcije
            </p>
            <p className="font-luxorthin monitor:text-xl md:text-xl text-[#C2C2C2]  monitor:w-[554px] md:w-[250px] w-[200px]">
              There are many variations of passages of Lorem Ipsum available.
            </p>
            <div className="flex items-center gap-2 md:gap-6 mt-7">
              <button
                onClick={() => navigate('/niche')}
                className="bg-[#DCA950] text-[0.7rem] md:w-[185px] w-[130px] text-white  md:text-lg xl:text-xl py-1 px-1 md:px-3 md:py-1 xl:px-5 xl:py-3  flex items-center justify-center gap-2 md:gap-5 font-luxorthin "
              >
                Pogledaj sve{' '}
                <svg
                  height="11"
                  width="6"
                  fill="none"
                  viewBox="0 0 6 11"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0.447959 9.65454L4.94971 5.68225L0.447959 1.2877"
                    stroke="white"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
        <div className="md:w-[60%] w-full monitor:pl-24 md:pl-12 monitor:pr-[140px] md:pr-[100px] px-[35px] ">
          <div className="grid grid-cols-2 md:gap-x-5 md:gap-y-[420px] gap-y-[270px] monitor:gap-y-[300px] grid-rows-3 overflow-y-scroll monitor:h-[1320px] md:h-[870px] h-[550px] pt-20 overscroll-auto  scrollbar-hide snap-mandatory snap-x">
            {suggestedArticles?.slice(0, 6).map((item, index) => {
              return (
                <div className="snap-always snap-center ">
                  <ArticleCard
                    item={item}
                    index={index}
                    parfume_type={'Niche'}
                  />
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </>
  )
}

export default NicheCollection
