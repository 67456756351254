import React from 'react'
import { useNavigate } from 'react-router-dom'

const Background = ({ img }) => {
  const navigate = useNavigate()
  return (
    <div className="relative">
      <img src={img} alt="" className="w-full h-20 md:h-full" />
      <div className="absolute top-4 md:top-0 left-2 md:left-0 md:right-0 sm:bottom-0 flex flex-col justify-center items-start text-white px-12 md:px-8 lg:px-24 pt-1 md:pt-3">
        <div className="w-full flex justify-center flex-col">
          <p className="text-xs md:text-2xl lg:text-3xl xl:text-4xl font-bold text-left flex justify-center">
            Ostvari 10% popusta na prvu kupovinu
          </p>
          <div className="flex justify-center">
            <button
              className="border border-white text-xs md:text-base py-0.5 sm:py-2 px-2 sm:px-6 md:px-10 rounded-lg transition duration-300 hover:bg-white hover:text-black mt-2 sm:mt-5"
              onClick={() => navigate('/login')}
            >
              Registruj se
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Background
