import { api } from '../api'

// GET api/v1/volumes/get-volumes/page/page_size

export const volumeApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getVolumes: builder.query({
      query: ({ page, page_size, query }) => ({
        url: `api/v1/volumes/get-volumes/${page}/${page_size}/${
          query ? `?${query}` : ''
        }`,
      }),
      providesTags: ['Volumes'],
    }),
    getOneVolume: builder.query({
      query: (volume_id) => `api/v1/volumes/get-volume/${volume_id}`,
      providesTags: ['Volumes'],
    }),
    getVolumesByArticleGroup: builder.query({
      query: ({ article_group_id }) =>
        `api/v1/volumes/get-volumes-by-article-group/${article_group_id}`,
      providesTags: ['Volumes'],
    }),
    getAllVolumes: builder.query({
      query: () => ({
        url: `/api/v1/volumes/get-all-volumes`,
      }),
      providesTags: ['Volumes'],
    }),
    addVolume: builder.mutation({
      query: (body) => ({
        url: `api/v1/volumes`,
        body,
        method: 'POST',
      }),
      invalidatesTags: ['Volumes'],
    }),
    updateVolume: builder.mutation({
      query: (body) => ({
        url: `api/v1/volumes`,
        body,
        method: 'PUT',
      }),
      invalidatesTags: ['Volumes'],
    }),
    deleteVolume: builder.mutation({
      query: (volume_id) => ({
        url: `api/v1/volumes/${volume_id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Volumes'],
    }),
    getVolumesByGroup: builder.query({
      query: ({ article_group_id }) => ({
        url: `api/v1/volumes/get-volumes-by-article-group/${article_group_id}`,
      }),
      providesTags: ['Volumes'],
    }),
  }),
})

export const {
  useGetVolumesQuery,
  useGetOneVolumeQuery,
  useGetVolumesByArticleGroupQuery,
  useAddVolumeMutation,
  useDeleteVolumeMutation,
  useUpdateVolumeMutation,
  useGetVolumesByGroupQuery,
  useGetAllVolumesQuery,
} = volumeApi

// GET api/v1/volumes/get-volumes/article_group_id
// GET api/v1/perfume-categories
// GET api/v1/volumes
