import React, { useEffect, useState } from 'react'
import { AiOutlineClose } from 'react-icons/ai'
import Select from 'react-select'
import { toast } from 'react-toastify'
import { useUpdateDiscountMutation } from '../../api/services/articleApi'

function UpdateDiscount({ onClose, data }) {
  const [selectedStatus, setSelectedStatus] = useState(data?.status)
  const [statusUpdate, setStatusUpdate] = useState({
    article_group_id: data?.article_group_id,
    discount: data?.discount,
    status: data?.status,
  })
  const [updateStatus] = useUpdateDiscountMutation()

  useEffect(() => {
    if (data) {
      setStatusUpdate({
        article_group_id: data?.article_group_id,
        discount: data?.discount,
        status: data?.status,
      })
      setSelectedStatus(data?.status)
    }
  }, [data])

  const handleSubmit = (e) => {
    e.preventDefault()
    updateStatus(statusUpdate)
      .unwrap()
      .then((res) => {
        toast.success(res.message)
        onClose()
      })
      .catch((err) => toast.error(err.data.message))
  }

  const statusOptions = [
    { label: 'Active', value: 'active' },
    { label: 'Unactive', value: 'unactive' },
  ]

  return (
    <div>
      <div className="fixed inset-0 flex items-center justify-center z-50">
        {/* Background overlay */}
        <div className="fixed inset-0 bg-black opacity-50"></div>

        {/* Modal */}
        <div className="flex flex-col rounded-lg m-10 w-2/3 md:w-1/3 bg-white z-10 space-y-3 py-4 px-4 gap-3">
          <button className="flex justify-end p-2" onClick={onClose}>
            <AiOutlineClose />
          </button>
          <h2 className="flex justify-center font-bold ">Izmijena statusa</h2>
          <form
            action=""
            className="flex flex-col gap-6"
            onSubmit={handleSubmit}
          >
            <Select
              options={statusOptions}
              onChange={(selectedOption) => {
                setSelectedStatus(selectedOption.value)
                setStatusUpdate({
                  ...statusUpdate,
                  status: selectedOption.value,
                })
              }}
              value={statusOptions.find(
                (option) => option.value === selectedStatus,
              )}
            />
            <div className="flex justify-center">
              <button
                className="flex justify-center bg-blue-400 rounded-md text-lg text-white h-8 items-center px-2 text-center w-5/6"
                type="submit"
              >
                Izmijeni
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default UpdateDiscount
