import React from 'react'
import { FaTrash } from 'react-icons/fa'
import { AiFillEdit } from 'react-icons/ai'
import { useNavigate } from 'react-router-dom'

function Actions({ articleId, setOpenDelete, selectedArticle }) {
  const navigate = useNavigate()
  const handleUpdate = () => {
    navigate(`/admin/update_article/${articleId}`)
  }

  const handleDelete = () => {
    setOpenDelete(true)
  }

  return (
    <>
      <div className="">
        <div className="flex flex-col font-medium rounded-lg  bg-blue-500 text-white top-40 justify-start items-start w-36 h-28 p-2 gap-2">
          <p>Akcije</p>
          <div className="flex flex-col gap-1 w-full">
            <button
              className="flex items-center rounded hover:bg-white hover:text-black p-1"
              onClick={handleUpdate}
            >
              <AiFillEdit />
              Uredi
            </button>

            <button
              className="flex items-center rounded hover:bg-white hover:text-black p-1"
              onClick={handleDelete}
            >
              {' '}
              <FaTrash />
              Izbriši
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

export default Actions
