import React, { memo, useEffect, useMemo } from 'react'
import { HiOutlineAdjustments } from 'react-icons/hi'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import ProductCard from '../components/selective/ProductCard'
import FilterOptions from '../components/selective/FilterOptions'
import { useDispatch, useSelector } from 'react-redux'
import {
  useGetArticleByGroupMutation,
  useGetArticleByGroupQuery,
} from '../api/services/articleApi'
import { useGetAllArticleGroupsQuery } from '../api/services/articleGroup'
import FilterModal from '../components/selective/FilterModal'
import { AiOutlineClose } from 'react-icons/ai'
import {
  reset,
  resetCategories,
  resetGender,
  resetVolume,
  toggleGender,
} from '../api/features/filterSlice'

import Pagination from '../components/Pagination'
import { useLocation, useSearchParams } from 'react-router-dom'
import { useGetAllPerfumeTypesQuery } from '../api/services/perfumeTypeApi'
import ArticleCard from '../components/homePage/ArticleCard'
import { MdOutlineKeyboardArrowUp } from 'react-icons/md'
import { MdOutlineKeyboardArrowDown } from 'react-icons/md'
import banner from '../img/Group 1233.png'
import Spinner from '../components/Spinner'
import { useGetAllImagePositionsQuery } from '../api/services/imagePosition'
import ArticleCardSkeleton from '../components/homePage/ArticleCardSkeleton'

const Selective = () => {
  const user = useSelector((state) => state.auth.user)
  const { parfume_type, gender_id } = useParams()
  let [searchParams, setSearchParams] = useSearchParams()

  const { data: allGroups } = useGetAllArticleGroupsQuery()
  const { data: perfumeType } = useGetAllPerfumeTypesQuery()
  const { data: imagePositions } = useGetAllImagePositionsQuery()
  const [currentPage, setCurrentPage] = useState(1)
  const [sortOrder, setSortOrder] = useState('')
  const dispatch = useDispatch()

  let location = useLocation()

  useEffect(() => {
    setCurrentPage(1)
  }, [location])

  const selectedCategories = searchParams
    .getAll('categories')
    .map((cat) => decodeURIComponent(cat))

  const selectedVolume = searchParams
    .getAll('volumes')
    .map((vol) => decodeURIComponent(vol))

  const selectedGender = searchParams
    .getAll('genders')
    .map((gen) => decodeURIComponent(gen))

  const [isFilterOpen, setIsFilterOpen] = useState(false)
  const [openFilterModal, setOpenFilterModal] = useState(false)

  const handleFilterClick = () => {
    setIsFilterOpen(!isFilterOpen)
  }

  const currentGroup = allGroups?.find((item) => {
    return (
      parfume_type ===
      item.article_group_name?.toLowerCase()?.replace(/\s+/g, '-')
    )
  })

  const firstQuery = useMemo(() => {
    const firstCategories = selectedCategories.reduce((prev, curr) => {
      return prev + `&categories=${curr}`
    }, '')
    const firstVolumes = selectedVolume.reduce((prev, curr) => {
      return prev + `&volumes=${curr}`
    }, '')
    const firstGenders = selectedGender.reduce((prev, curr) => {
      return prev + `&types=${curr}`
    }, '')
    return firstCategories + firstVolumes + firstGenders
  })
  const { data: paginatedArticles, isLoading } = useGetArticleByGroupQuery({
    article_group_id: currentGroup?.article_group_id,
    user_id: user?.user_id,
    page: currentPage,
    page_size: 12,
    sort_by: sortOrder,
    query: firstQuery,
  })

  const handleFilterOpen = () => {
    setOpenFilterModal(true)
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [currentPage])

  const handleResetAllFilters = () => {
    dispatch(reset())
  }
  const handleResetGender = (id) => {
    dispatch(resetGender(id))
  }
  const handleResetVolume = (item) => {
    dispatch(resetVolume(item))
  }
  const handleResetCategories = (id) => {
    dispatch(resetCategories(id))
  }

  useEffect(() => {
    setIsFilterOpen(false)
  }, [parfume_type])

  const numbersArray = Array.from(
    { length: paginatedArticles?.total_pages },
    (_, index) => index + 1,
  )

  const handleSortByPrice = () => {
    const newSortOrder = sortOrder === 'ASC' ? 'DESC' : 'ASC'
    setSortOrder(newSortOrder)
  }

  return (
    <>
      <div className="w-full md:h-[270px] h-fit overflow-hidden relative">
        <img
          className=" w-full object-cover object-bottom"
          src={
            Array.isArray(imagePositions) && imagePositions.length > 0
              ? imagePositions[6].isDefault
                ? banner
                : imagePositions[6].image
              : ''
          }
          alt=""
        />
        <p className="capitalize font-ivy md:text-[63px] text-3xl absolute md:top-20 top-7 monitor:left-[140px] md:left-[100px] left-[35px] text-white">
          {currentGroup?.article_group_name.toLowerCase()}
        </p>
      </div>
      <div className="monitor:pr-[140px] md:pr-[100px] pr-[35px] md:mt-20 mt-10 font-normal md:font-medium">
        <div className="flex justify-between">
          <div className="monitor:pl-[140px] md:pl-[100px] pl-[35px]">
            <div
              className="bg-black w-[40px] h-[40px] flex justify-center items-center cursor-pointer"
              onClick={handleFilterClick}
            >
              <svg
                height="20"
                width="23"
                fill="none"
                viewBox="0 0 23 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M21.5 1H1.5L9.5 10.46V17L13.5 19V10.46L21.5 1Z"
                  stroke="white"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1.5"
                />
              </svg>
            </div>
          </div>
          <div className="flex gap-6">
            <div
              onClick={handleSortByPrice}
              className="border border-solid-1  p-2 font-luxor text-lg flex flex-row items-center justify-center gap-1 cursor-pointer"
            >
              Sortiranje po cijeni{' '}
              {sortOrder === 'ASC' ? (
                <MdOutlineKeyboardArrowDown />
              ) : (
                <MdOutlineKeyboardArrowUp />
              )}
            </div>
          </div>
        </div>
        <div
          className={`grid grid-cols-2 md:grid-cols-4 justify-items-center  mt-10 md:gap-x-2 gap-y-5   ${
            isFilterOpen
              ? 'md:pl-6 monitor:pl-10 pl-6  '
              : 'monitor:pl-[140px] md:pl-[100px] pl-[35px]'
          }`}
        >
          {isFilterOpen && (
            <div
              //
              className={`transition-transform max-w-[510px]  overflow-hidden  ${
                isFilterOpen
                  ? 'transform translate-x-0 duration-500 monitor:pl-[120px] md:pl-[80px] pl-[15px] bg-[#FEFBF6]    '
                  : 'max-h-0 transform translate-x-full duration-500'
              } row-span-4 col-span-1`}
            >
              <FilterOptions
                article_group_id={currentGroup?.article_group_id}
              />
            </div>
          )}
          {isLoading
            ? Array.from({ length: 12 }, (_, index) => (
                <ArticleCardSkeleton key={index} />
              ))
            : paginatedArticles?.articles?.map((item) => {
                return (
                  <ArticleCard
                    item={item}
                    parfume_type={item?.article_group?.article_group_name}
                  />
                  // <ArticleCardSkeleton />
                )
              })}
        </div>
      </div>
      <div className="w-fit mx-auto">
        <Pagination
          numbersArray={numbersArray}
          setCurrentPage={setCurrentPage}
          paginatedArticles={paginatedArticles}
          currentPage={currentPage}
        />
      </div>
      {openFilterModal && (
        <FilterModal
          setOpenFilterModal={setOpenFilterModal}
          openFilterModal={openFilterModal}
          article_group_id={currentGroup?.article_group_id}
        />
      )}
    </>
  )
}

export default memo(Selective)
