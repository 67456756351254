import React from 'react'

function ColumnFilters({ column }) {
  const { filterValue, setFilter } = column

  return (
    <input
      value={filterValue || ''}
      onChange={(e) => setFilter(e.target.value)}
      type="text "
      className="text-black my-1 w-5/6"
    />
  )
}

export default ColumnFilters
