import React, { useEffect, useState } from 'react'
import { useUpdateScentMutation } from '../../api/services/scentsApi'
import { AiOutlineClose } from 'react-icons/ai'
import { toast } from 'react-toastify'

function UpdateScent({ scent, setOpenEdit }) {
  const [updateScent] = useUpdateScentMutation()
  const [scentData, setScentData] = useState({
    scent_name: '',
  })
  useEffect(() => {
    if (scent) {
      setScentData({
        scent_name: scent.scent_name || '',
        scent_id: scent.scent_id || '',
      })
    }
  }, [scent])

  const handleInputScentName = async () => {
    try {
      // Send the scent data to the API
      const response = await updateScent(scentData)

      if (response.data) {
        toast.success('Nota uspiješno ažurirana!')
        // Clear the input field
        setScentData({ scent_id: scent.scent_id, scent_name: '' })
        setOpenEdit(false)
      } else {
        console.error('Error updating scent:', response.error)
      }
    } catch (error) {
      console.error('Network error:', error)
    }
  }
  const onClose = () => {
    setOpenEdit(false)
  }
  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      {/* Background overlay */}
      <div className="fixed inset-0 bg-black opacity-50"></div>

      {/* Modal */}
      <div className="flex flex-col rounded-lg m-10 w-2/3 md:w-1/3 bg-white z-10 space-y-3 py-4">
        <button className="flex justify-end p-2" onClick={onClose}>
          <AiOutlineClose />
        </button>
        <label htmlFor="" className="mx-3 font-bold">
          Naziv mirisa
        </label>
        <input
          type="text"
          value={scentData.scent_name}
          onChange={(e) =>
            setScentData({ ...scentData, scent_name: e.target.value })
          }
          className="mx-3 border-blue-400 border-2 rounded h-12 px-2"
        />
        <button
          className="mx-3 rounded border-black hover: bg-blue-400 h-8 text-white font-bold"
          onClick={handleInputScentName}
        >
          Ažuriraj
        </button>
      </div>
    </div>
  )
}

export default UpdateScent
