import React from 'react'
import img from '../img/iconnobg.png'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useGetAllArticleGroupsQuery } from '../api/services/articleGroup'
import { IoLogoInstagram } from 'react-icons/io'
import { FaFacebookSquare } from 'react-icons/fa'

const Footer = () => {
  const { data: articleGroups } = useGetAllArticleGroupsQuery()
  const location = useLocation()
  const navigate = useNavigate()

  const handleScrollToTop = () => {
    navigate('/')
    window.scrollTo({
      top: 0,
      behavior: 'smooth', // Smooth scrolling
    })
  }

  return (
    <>
      <div className="h-[621px] flex md:flex-row flex-col mt-[150px]">
        <div className="bg-[#DCA950] md:h-[50%] h-full md:pl-[70px] md:px-0 px-[35px] py-12 md:hidden">
          <p
            className={`text-[20px] text-white uppercase font-luxorthin leading-7`}
          >
            KONTAKT
          </p>
          <p className="text-[45px] font-luxor text-white leading-10">
            Imate pitanje za nas?
          </p>
          <p className="text-xl font-luxorthin text-white pt-3">
            Ukoliko imate bilo kakvih pitanja za nas, stojimo Vam na
            raspolaganju.
          </p>
          <div className="flex flex-row  gap-0 w-full max-h-[73px] relative pt-6">
            <div
              onClick={() => {
                navigate('/kontakt')
              }}
              className="justify-center w-48 py-[22px] text-white text-lg text-center font-luxorlight whitespace-nowrap bg-black h-[70px] max-md:px-5 cursor-pointer"
            >
              Kontaktirajte nas
            </div>
          </div>
        </div>
        <div className="bg-black monitor:pl-[140px] md:pl-[100px] px-[35px] md:px-0 py-[90px] w-full md:w-[30%] text-white text-center md:text-left">
          <div
            className="flex md:inline-block justify-center"
            onClick={handleScrollToTop}
          >
            <img src={img} alt="icon" className="cursor-pointer" />
          </div>
          <p className="monitor:text-[50px] md:text-[36px] text-[50px] font-ivy mt-[120px]">
            PROBAJ ME
          </p>
          <p className="text-[15px] font-luxorlight text-[#707070] monitor:w-[398px] md:w-[250px]">
            There are many variations of passages of Lorem Ipsum available, but
            the majority have suffered alteration in some form, by injected
            humour, or randomised words. <br />
            <br />
            There are many variations of passages of Lorem Ipsum available for
            next chapter of everything you need.
          </p>
        </div>
        <div className="flex flex-col md:w-[70%] w-full">
          <div className="bg-[#DCA950] h-[50%] pl-[70px] py-12 hidden md:inline-block">
            <p
              className={`text-[20px] text-white uppercase font-luxorthin leading-7`}
            >
              KONTAKT
            </p>
            <p className="text-[45px] font-luxor text-white leading-10">
              Imate pitanje za nas?
            </p>
            <p className="text-xl font-luxorthin text-white pt-3">
              Ukoliko imate bilo kakvih pitanja za nas, stojimo Vam na
              raspolaganju.
            </p>
            <div className="flex flex-row gap-0 w-[690px] max-h-[73px] relative pt-6">
              <div
                onClick={() => {
                  navigate('/kontakt')
                }}
                className="justify-center w-48 py-[22px] text-white text-lg text-center font-luxorlight whitespace-nowrap bg-black h-[70px] max-md:px-5 cursor-pointer"
              >
                Kontaktirajte nas
              </div>
            </div>
          </div>
          <div className="bg-[#FBFAF3] md:h-[40%] h-full pt-[41px] md:pl-[70px] px-[35px] md:px-0 text-center md:text-left">
            <div className="flex md:flex-row flex-col gap-20">
              <div>
                <div>
                  <p className="font-luxor text-[25px] ">Kategorije</p>
                  <div className="bg-[#DCA950] md:max-w-[78px] max-w-full min-h-[1px] mb-5"></div>
                  <div className="grid grid-cols-2 gap-x-2 gap-y-[1px] grid-rows-4 overflow-hidden ">
                    {articleGroups?.map((item) => {
                      const type = item.article_group_name
                        .toLowerCase()
                        .replace(/\s+/g, '-')

                      const decodedPathname = decodeURIComponent(
                        location.pathname,
                      )
                      return (
                        <Link
                          key={Math.random()}
                          to={`/${type}`}
                          className={`flex items-center justify-start pb-0.5 `}
                        >
                          <span className="capitalize font-luxorthin text-[18px] leading-6">
                            {item.article_group_name.toLowerCase()}
                          </span>
                        </Link>
                      )
                    })}
                  </div>
                </div>
              </div>
              <div>
                <p className="font-luxor text-[25px] ">Informacije</p>
                <div className="bg-[#DCA950] md:max-w-[78px] max-w-full min-h-[1px] mb-5"></div>
                <div>
                  <p
                    className=" font-luxorthin text-[18px] cursor-pointer"
                    onClick={() => navigate('/dostava')}
                  >
                    Dostava
                  </p>
                  <p
                    className=" font-luxorthin text-[18px] cursor-pointer"
                    onClick={() => navigate('/politikaprivatnosti')}
                  >
                    Politika privatnosti
                  </p>
                  <p
                    className=" font-luxorthin text-[18px] cursor-pointer"
                    onClick={() => navigate('/uslovikoristenja')}
                  >
                    Uslovi korištenja
                  </p>
                  <p
                    className=" font-luxorthin text-[18px] cursor-pointer"
                    onClick={() => navigate('/onama')}
                  >
                    O nama
                  </p>
                </div>
              </div>
              <div className="monitor:pl-80   ">
                <p className="font-luxor text-[25px] ">Kontakt informacije</p>
                <div className="bg-[#DCA950] md:max-w-[78px] max-w-full min-h-[1px] mb-5"></div>

                <div>
                  <p className=" font-luxorthin text-[18px]">
                    Slobodno nas kontaktirajte:{' '}
                  </p>
                  <p className=" font-luxorthin text-[18px]">062-589/4135</p>
                  <p className=" font-luxorthin text-[18px]">mad@ba.com</p>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-[#FBFAF3] h-[15%]">
            <div className="bg-[#33333359]  ml-[70px] monitor:mr-[140px] md:mr-[100px] mr-[35px] min-h-[0.1px] my-1.5 "></div>
            <div className="flex flex-row justify-between font-luxorthin text-[#33333359] text-[18px] items-center pl-[70px] monitor:pr-[140px] md:pr-[100px] pr-[35px]">
              <div>&copy; {new Date().getFullYear()} Mad.ba</div>
              <div className="flex flex-row gap-3 items-center">
                <div class="w-12 h-12 rounded-full bg-[#DCA950] flex items-center justify-center cursor-pointer">
                  <a
                    href="https://www.instagram.com/madparfumeur_bih/"
                    target="_blank"
                  >
                    <IoLogoInstagram className="text-white w-8 h-8" />
                  </a>
                </div>
                <div class="w-12 h-12 rounded-full bg-[#DCA950] flex items-center justify-center cursor-pointer">
                  <a
                    href="https://www.facebook.com/p/MAD-Parfumeur-Bosna-i-Hercegovina-100076059167857/"
                    target="_blank"
                  >
                    <FaFacebookSquare className="text-white w-7 h-7" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Footer
