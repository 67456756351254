import React from 'react'
import banner from '../../img/Group 1233.png'
import PerfumeTypeTitle from './PerfumeTypeTitle'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'

function SmallBanner({ imagePositions }) {
  const user = useSelector((state) => state.auth.user)
  const navigate = useNavigate()
  return (
    <div className="w-full md:h-[417px] h-[202px] relative">
      <div>
        <img
          className="w-full md:h-[417px] h-[202px]"
          src={
            Array.isArray(imagePositions) && imagePositions.length > 0
              ? imagePositions[4].isDefault
                ? banner
                : imagePositions[4].image
              : ''
          }
          alt=""
        />
      </div>
      <div className="absolute monitor:left-[140px] md:left-[100px] left-10 bottom-16 text-center md:text-left">
        <PerfumeTypeTitle text={'Specijalna ponuda'} fontsize={20} />
        <div className="md:text-[63px] text-[32px] flex flex-row gap-5 text-white">
          <p className="font-ivy">Ostvari</p>
          <p className="font-ivybold">10% popusta</p>
        </div>
        <p className="font-ivy text-white md:text-[63px] text-[32px] md:leading-[4rem]">
          na prvu kupovinu
        </p>
      </div>
      {!user && (
        <div className="flex items-center gap-2 md:gap-6 absolute md:bottom-16 bottom-5 md:right-[200px] monitor:right-[240px] translate-x-1/2 right-1/2  ">
          <button
            onClick={() => navigate('/login/register')}
            className="bg-[#DCA950] text-[0.7rem] md:w-[185px] w-[130px] text-white  md:text-lg xl:text-xl py-1 px-1 md:px-3 md:py-1 xl:px-5 xl:py-3  flex items-center justify-center gap-2 md:gap-5 font-luxorthin "
          >
            Registruj se{' '}
            <svg
              height="11"
              width="6"
              fill="none"
              viewBox="0 0 6 11"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.447959 9.65454L4.94971 5.68225L0.447959 1.2877"
                stroke="white"
              />
            </svg>
          </button>
        </div>
      )}
    </div>
  )
}

export default SmallBanner
