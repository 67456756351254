import React from 'react'
import { AiOutlineCheck } from 'react-icons/ai'
import { useNavigate } from 'react-router-dom'
function CheckoutComplete() {
  const navigate = useNavigate()
  const handleNavigate = () => {
    navigate('/')
  }
  return (
    <div className="flex flex-col justify-center items-center">
      <div className="flex justify-center items-center mb-8 mt-12">
        <div className="flex bg-[#FFDDA0] rounded-full justify-center items-center w-48 h-48">
          <div className=" items-center flex w-44 h-44   rounded-full justify-center">
            <div className="rounded-full bg-[#DCA950] w-40 h-40 text-white p-5 flex justify-center items-center">
              <AiOutlineCheck size={160} />
            </div>
          </div>
        </div>
      </div>
      <label htmlFor="" className="font-bold text-[45px] font-luxor">
        Čestitamo!
      </label>
      <label htmlFor="" className="font-bold text-[45px] font-luxor">
        Uspješno ste poručili artikle!
      </label>
      <label
        htmlFor=""
        className="mx-6 text-2xl text-[#C2C2C2] text-center mt-6 font-luxorthin"
      >
        Detalji narudžbe su poslani na Email adresu koju ste naveli...
      </label>
      <button
        className="w-52 bg-[#DCA950] mx-auto font-luxorlight my-auto text-white p-5 text-xl mt-10"
        onClick={handleNavigate}
      >
        Nastavi kupovinu
      </button>
    </div>
  )
}

export default CheckoutComplete
