const TableActionButton = ({ active, label, disabled, Icon, onClick }) => {
  return (
    <button
      className={`${
        active ? 'bg-blue-500  text-black' : 'text-white'
      } group flex w-full items-center hover:bg-white  hover:text-black rounded-md px-2 py-2 overflow-visible text-sm z-[99999]  disabled:cursor-not-allowed disabled:opacity-30`}
      onClick={onClick}
      disabled={disabled}
    >
      {Icon && <Icon className="mr-2" aria-hidden="true" />}
      {label}
    </button>
  )
}

export default TableActionButton
