import React from 'react'
import { Line } from 'react-chartjs-2'

const DailyChart = ({ dataDaily, optionsDaily }) => {
  return (
    <div className="w-full h-full overflow-hidden">
      <Line data={dataDaily} options={optionsDaily} />
    </div>
  )
}

export default DailyChart
