import React, { useEffect, useMemo } from 'react'
import { useState } from 'react'

import {
  useGetAllFavoriteArticlesQuery,
  useRemoveOneFavoriteArticlesMutation,
  useRemoveAllFavoriteArticlesMutation,
} from '../api/services/articleApi'
import { useSelector } from 'react-redux'
import { FaHeart, FaRegHeart } from 'react-icons/fa'
import { toast } from 'react-toastify'
import {
  useAddArticleToCartMutation,
  useGetBagArticlesQuery,
} from '../api/services/bagArticleApi'
import ArticleCard from '../components/homePage/ArticleCard'
import { useGetAllArticleGroupsQuery } from '../api/services/articleGroup'
import banner from '../img/Group 1233.png'
import { useGetAllImagePositionsQuery } from '../api/services/imagePosition'

const Favorites = () => {
  const user = useSelector((state) => state.auth.user)
  const bag = useSelector((state) => state.auth.bag)
  const cart = useSelector((state) => state.cart.cart)

  const [selectedId, setSelectedId] = useState()

  const { data: allFavoriteArticles } = useGetAllFavoriteArticlesQuery(
    user?.user_id,
  )

  const { data: articleGroups } = useGetAllArticleGroupsQuery()
  const { data: bagArticles } = useGetBagArticlesQuery(bag?.bag_id)
  const { data: imagePositions } = useGetAllImagePositionsQuery()

  const [removeAllFavoriteArticles] = useRemoveAllFavoriteArticlesMutation()

  const handleRemoveAllFavs = () => {
    removeAllFavoriteArticles({ user_id: user?.user_id })
    toast.info('Obrisani svi favoriti!')
  }

  const columns = useMemo(() => [
    {
      Header: '',
      accessor: 'item.article_images',
      Cell: ({ row }) => {
        return (
          <img
            alt=""
            src={row?.original?.article_images[0]?.url}
            className="bg-black w-10 h-10 md:w-32 md:h-32"
          />
        )
      },
    },
    {
      Header: 'Naziv',
      accessor: 'article_name',
    },
    {
      Header: 'Ml',
      accessor: 'volume.total',
    },
    {
      Header: 'Cijena',
      accessor: 'price',
    },
  ])
  const data = useMemo(
    () => (Array.isArray(allFavoriteArticles) ? allFavoriteArticles : []),
    [allFavoriteArticles],
  )

  const [addToCart] = useAddArticleToCartMutation()

  const handleMoveAllToCart = () => {
    data.map((fav) => {
      const articles = {
        bag_id: bag?.bag_id,
        article_id: fav.article_id,
        article_name: fav.article_name,
        amount: 1,
        price: fav.price,
        volume: fav.volume.total,
      }

      const is_in_cart = user
        ? bagArticles?.find((item) => item?.article_id === fav.article_id)
        : cart?.find((item) => item?.article_id === fav.article_id)

      if (is_in_cart) {
        toast.error('Artikal se već nalazi u korpi!')
        return
      }

      addToCart(articles)
      toast.success('Dodano u korpu!')
    })
  }

  const tableHooks = (hooks) => {
    hooks.visibleColumns.push((columns) => [
      ...columns,
      {
        id: 'action',
        accessor: 'action',
        Header: '',
        Cell: ({ row }) => {
          const [isHeartClicked, setIsHeartClicked] = useState(false)

          useEffect(() => {
            if (
              row.original?.favoritedByUsers?.length &&
              row.original.favoritedByUsers[0]?.user_id === user?.user_id
            ) {
              setIsHeartClicked(true)
            }
          }, [data])

          const [removeFavoriteArticles] =
            useRemoveOneFavoriteArticlesMutation()

          const handleHeartClick = () => {
            if (isHeartClicked) {
              setIsHeartClicked(!isHeartClicked)
              setTimeout(() => {
                const articles = {
                  article_id: row?.original?.article_id,
                  user_id: user?.user_id,
                }
                removeFavoriteArticles(articles)
                toast.info('Obrisano iz favorita!')
              }, 500)
            }
          }

          const handleButtonClick = () => {
            const articles = {
              bag_id: bag?.bag_id,
              article_id: row?.original?.article_id,
              article_name: row?.original?.article_name,
              amount: 1,
              price: row?.original?.price,
              volume: row?.original?.volume.total,
            }

            const is_in_cart = user
              ? bagArticles?.find(
                  (item) => item?.article_id === row?.original?.article_id,
                )
              : cart?.find(
                  (item) => item?.article_id === row?.original?.article_id,
                )

            if (is_in_cart) {
              toast.error('Artikal se već nalazi u korpi!')
              return
            }

            addToCart(articles)
            toast.success('Dodano u korpu!')
          }

          return (
            <div className="flex flex-row md:flex-col md:justify-end md:items-end gap-2 md:gap-6 w-full">
              <div className="flex justify-end">
                <button onClick={handleHeartClick}>
                  {isHeartClicked ? (
                    <FaHeart className="w-4 h-4 md:w-6 md:h-6 text-red-500" />
                  ) : (
                    <FaRegHeart className="w-4 h-4 md:w-6 md:h-6" />
                  )}
                </button>
              </div>
              <div className="flex md:justify-end md:items-center md:gap-6">
                <div className="flex md:items-end md:justify-end scale-75 md:scale-100">
                  <button
                    onClick={handleButtonClick}
                    className="border border-black bg-black text-white font-normal text-xs md:text-sm md:py-2 px-1 md:px-10 py-1"
                  >
                    DODAJ U KORPU
                  </button>
                </div>
              </div>
            </div>
          )
        },
      },
    ])
  }

  return (
    <>
      {allFavoriteArticles?.length ? (
        <div className="relative">
          <div className="w-full md:h-[270px] overflow-hidden relative">
            <img
              className=" w-full object-cover object-bottom"
              src={
                Array.isArray(imagePositions) && imagePositions.length > 0
                  ? imagePositions[6].isDefault
                    ? banner
                    : imagePositions[6].image
                  : ''
              }
              alt=""
            />
            <p className="capitalize font-ivy md:text-[63px] text-3xl absolute md:top-20 top-5 monitor:left-[140px] md:left-[100px] left-[35px] text-white">
              Omiljeni artikli
            </p>
          </div>
          <div className="monitor:pl-[140px] md:pl-[100px] pl-[35px] font-luxor md:text-[45px] text-3xl md:mt-[57px] mt-4">
            Lista omiljenih artikala
          </div>
          <div
            className={`grid grid-cols-2 lg:grid-cols-4 justify-items-center mt-10 gap-x-5 gap-y-5 md:gap-y-0  monitor:mx-[140px] md:mx-[100px] mx-[35px]
              border-b pb-[90px]
          `}
          >
            {data.map((item) => {
              const articleGroupName = articleGroups?.find(
                (gr) => gr?.article_group_id === item?.article_group_id,
              )
              return (
                <ArticleCard
                  item={item}
                  parfume_type={articleGroupName?.article_group_name}
                />
              )
            })}
          </div>

          <div className="md:justify-center items-center  md:mt-[54px] mt-[15px]  flex flex-col md:flex-row md:gap-10 gap-5">
            <button
              className="md:w-[393px] w-[260px] h-[55px] flex flex-row gap-3 justify-center items-center  bg-[#DCA950] text-white font-luxorthin md:text-xl text-md "
              onClick={handleMoveAllToCart}
            >
              <span>Dodajte sve u korpu</span>
              <svg
                height="11"
                width="6"
                fill="none"
                viewBox="0 0 6 11"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.447959 9.65454L4.94971 5.68225L0.447959 1.2877"
                  stroke="white"
                />
              </svg>
            </button>

            <button
              className="md:w-[393px] w-[260px] h-[55px] flex flex-row gap-3 justify-center items-center  bg-black text-white font-luxorthin md:text-xl text-md "
              onClick={handleRemoveAllFavs}
            >
              <span>Uklonite sve iz omiljenih artikala</span>
              <svg
                height="11"
                width="6"
                fill="none"
                viewBox="0 0 6 11"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.447959 9.65454L4.94971 5.68225L0.447959 1.2877"
                  stroke="white"
                />
              </svg>
            </button>
          </div>
        </div>
      ) : (
        <div className="flex items-center justify-center p-14 font-luxorthin text-xl mt-12">
          <p>Nema sadržaja u favoritima!</p>
        </div>
      )}
    </>
  )
}

export default Favorites
