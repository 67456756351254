import { api } from '../api'

export const usersApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getAllUsers: builder.query({
      query: ({ page, page_size, query }) => ({
        url: `api/v1/users/get-users/${page}/${page_size}/${
          query ? `?${query}` : ''
        }`,
      }),
      providesTags: ['User'],
    }),
    editUser: builder.mutation({
      query: (body) => ({
        url: `api/v1/users`,
        body,
        method: 'PUT',
      }),
      invalidatesTags: ['User'],
    }),
    getOneUser: builder.query({
      query: (user_id) => ({
        url: `api/v1/users/get-user/${user_id}`,
      }),
      providesTags: ['User'],
    }),
    deleteUser: builder.mutation({
      query: (user_id) => ({
        url: `api/v1/users/${user_id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['User'],
    }),
    getUserStatus: builder.query({
      query: () => ({
        url: `api/v1/user-statuses`,
      }),
      providesTags: ['User'],
    }),
    adminCreate: builder.mutation({
      query: (body) => ({
        url: `api/v1/users/for-admin`,
        body,
        method: 'POST',
      }),
      invalidatesTags: ['User'],
    }),
    passwordChangeUser: builder.mutation({
      query: (body) => ({
        url: 'api/v1/users/change-password',
        body,
        method: 'PATCH',
      }),
      invalidatesTags: ['User'],
    }),
    updateUserStatus: builder.query({
      query: ({ verification_code }) => ({
        url: `api/v1/users/updating-user-status/${verification_code}`,
        // headers: {
        //   Authorization: `Bearer ${token}`,
        //   // 'Content-Type': 'application/json'
        // },
      }),
      providesTags: ['User'],
    }),
    forgottenPassword: builder.query({
      query: ({ email }) => ({
        url: `api/v1/users/forgotten-pass-email/${email}`,
        method: 'GET',
      }),
      providesTags: ['User'],
    }),
    changePassword: builder.mutation({
      query: ({ verification_code, body }) => ({
        url: `api/v1/users/change-password/${verification_code}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['User'],
    }),
    createEmailVerification: builder.mutation({
      query: ({ user_id }) => ({
        url: `api/v1/users/send-mail/${user_id}`,
        method: 'POST',
      }),
      invalidatesTags: ['User'],
    }),
    getAllEmails: builder.query({
      query: () => ({
        url: 'api/v1/users/get-all-verified-emails',
      }),
      providesTags: ['User'],
    }),
    sendNewsletterMail: builder.mutation({
      query: (body) => ({
        url: 'api/v1/users/newsletter',
        method: 'POST',
        body,
      }),
    }),
    sendContactUsMail: builder.mutation({
      query: (body) => ({
        url: 'api/v1/users/info-mail',
        method: 'POST',
        body,
      }),
    }),
  }),
})
export const {
  useGetAllUsersQuery,
  useEditUserMutation,
  useGetOneUserQuery,
  useDeleteUserMutation,
  useGetUserStatusQuery,
  useAdminCreateMutation,
  usePasswordChangeUserMutation,
  useUpdateUserStatusQuery,
  useForgottenPasswordQuery,
  useLazyForgottenPasswordQuery,
  useChangePasswordMutation,
  useGetAllEmailsQuery,
  useCreateEmailVerificationMutation,
  useSendNewsletterMailMutation,
  useSendContactUsMailMutation,
} = usersApi

// POST api/v1/users/for-admin
// GET api/v1/user-statuses
// DELETE api/v1/users/user_id
// GET api/v1/users/get-user/user_id
// query: () => "api/v1/users/get-users",
// PUT api/v1/users { name, email, username, address, country, zip_code, phone_number, password, user_id }
