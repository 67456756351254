import React from 'react'
import { useState, useMemo } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { HiDotsVertical } from 'react-icons/hi'
import {
  useDeleteOneArticleMutation,
  useEditSuggestedArticleMutation,
  useGetAllArticlesQuery,
} from '../../api/services/articleApi'
import { AiOutlinePlusCircle } from 'react-icons/ai'
import Actions from '../../components/ArticleCrud/ActionArticle'
import Select from 'react-select'
import 'react-datepicker/dist/react-datepicker.css'
import TableWrapper from '../../components/TableWrapper'
import { toast } from 'react-toastify'
import GlobalDelete from '../../components/GlobalDelete'
import MainPaginatedTable from '../../components/MainPaginatedTable'
import SelectColumnFilterPaginated from '../../components/SelectColumnFilterPaginated '
import { useAsyncDebounce } from 'react-table'
import { FiEdit } from 'react-icons/fi'
import { FaTrash } from 'react-icons/fa'

function ArticleCrud() {
  const navigate = useNavigate()

  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(25)

  const [filterInputs, setFilterInputs] = useState({})

  const query = useMemo(() => {
    let queryString = ''
    for (const [key, value] of Object.entries(filterInputs)) {
      if (value.trim() !== '') {
        queryString += `${key}=${encodeURIComponent(value)}&`
      }
    }

    return queryString
  }, [filterInputs])

  const { data: articles, isFetching } = useGetAllArticlesQuery({
    page: page,
    page_size: pageSize,
    query,
  })

  const [editSuggested] = useEditSuggestedArticleMutation()

  const [deleteArticleMutation] = useDeleteOneArticleMutation()
  const [expandedRow, setExpandedRow] = useState(null)
  const [selectedArticle, setSelectedArticle] = useState('')
  const [openDelete, setOpenDelete] = useState(false)
  const [selectedArticleOptions, setSelectedArticleOptions] = useState({})

  const handleRowClick = (row) => {
    if (expandedRow === row.id) {
      setExpandedRow(null)
    } else {
      setExpandedRow(row.id)
      setSelectedArticle(row.original)
    }
  }
  const handleDelete = async () => {
    try {
      const response = await deleteArticleMutation({
        article_id: selectedArticle.article_id,
      })

      if (response.data) {
        toast.success('Artikal je uspiješno izbrisan')
        setOpenDelete(false)
      } else {
        console.error('Error deleting scent:', response.error)
      }
    } catch (error) {
      console.error('Network error:', error)
    }
  }
  const handleCancel = () => {
    setOpenDelete(false)
  }

  const handleSelectChange = (row, option) => {
    setSelectedArticleOptions({
      ...selectedArticleOptions,
      [row.id]: option,
    })

    editSuggested({
      ...row.original,
      suggested: option.value,
    })
      .then((response) => {
        toast.success('Artikal je uspešno ažuriran:', response)
      })
      .catch((error) => {
        toast.error('Greška prilikom ažuriranja artikla:', error)
      })
  }
  const handleFilterChange = useAsyncDebounce((value, columnId) => {
    setPage(1)
    setFilterInputs((prev) => {
      const newValue = value.trim() || ' '
      return { ...prev, [columnId]: newValue }
    })
  }, 50)

  const columns = useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'article_id',
      },
      {
        Header: 'Naziv artikla',
        accessor: 'article_name',
        Cell: ({ row }) => {
          return (
            <div className="flex">
              <img
                src={row.original?.article_images[0]?.url}
                alt="article img"
                className="w-10 h-10 flex items-center justify-center"
              />
              <Link
                to={`/article/${row.original?.article_id}`}
                className="flex items-center justify-center"
              >
                {row.original.article_name}
              </Link>
            </div>
          )
        },
      },
      {
        Header: 'Kategorija',
        accessor: 'article_group.article_group_name',
        Filter: ({ column }) => (
          <SelectColumnFilterPaginated
            column={column}
            handleFilterChange={handleFilterChange}
            advancedOptions
            providedOptions={[
              { label: 'SELECTIVE', value: 'SELECTIVE' },
              { label: 'NICHE', value: 'NICHE' },
              { label: 'KOLONJSKE VODE', value: 'KOLONJSKE VODE' },
              { label: 'PARFEMI ZA KOSU', value: 'PARFEMI ZA KOSU' },
              { label: 'SVIJEĆE', value: 'SVIJEĆE' },
              { label: 'AMBIJENTALNI MIRISI', value: 'AMBIJENTALNI MIRISI' },
              { label: 'MIRISI ZA AUTO', value: 'MIRISI ZA AUTO' },
              { label: 'MIRISI ZA TIJELO', value: 'MIRISI ZA TIJELO' },
            ]}
          />
        ),
      },
      {
        Header: 'Tip',
        accessor: 'perfume_type.perfume_type_name',
        Filter: ({ column }) => (
          <SelectColumnFilterPaginated
            column={column}
            handleFilterChange={handleFilterChange}
            advancedOptions
            providedOptions={[
              { label: 'MUŠKI', value: 'MUŠKI' },
              { label: 'ŽENSKI', value: 'ŽENSKI' },
              { label: 'UNISEX', value: 'UNISEX' },
            ]}
          />
        ),
      },
      {
        Header: 'Cijena',
        accessor: 'price',
      },
      {
        Header: 'Zaliha',
        accessor: 0,
      },
      {
        Header: 'Prodano',
        accessor: 'total_bought_amount',
      },
      {
        Header: 'Prihod',
        accessor: 'total_bought_value',
      },
      {
        id: 'preporuceno',
        Header: 'Preporučeno',
        accessor: 0,
        Cell: ({ row }) => {
          const suggested = {
            label: row.original.suggested === 'Y' ? 'Da' : 'Ne',
            value: row.original.suggested,
          }

          return (
            <div className="flex items-center justify-center cursor-pointer relative">
              <Select
                value={suggested}
                onChange={(option) => handleSelectChange(row, option)}
                options={[
                  { value: 'Y', label: 'Da' },
                  { value: 'N', label: 'Ne' },
                ]}
              />
            </div>
          )
        },
      },
    ],
    [expandedRow],
  )
  const data = useMemo(
    () => (Array.isArray(articles?.articles) ? articles?.articles : []),
    [articles?.articles],
  )

  const handleDodajArtikal = () => {
    navigate('/admin/add_article')
  }

  const tableHooksArray = [
    {
      label: 'Uredi',
      Icon: FiEdit,
      onClick: (row) => {
        navigate(`/admin/update_article/${row?.original?.article_id}`)
      },
      disabled: false,
    },
    {
      label: 'Izbriši',
      Icon: FaTrash,
      onClick: (row) => {
        setOpenDelete(true)
        setSelectedArticle(row.original)
      },
      disabled: false,
    },
  ]

  return (
    <>
      <div className="flex flex-col mx-4 my-5">
        <div className="flex flex-row justify-end my-6 items-center rounded mx-4">
          <button
            className="bg-blue-500 rounded-md text-sm text-white h-8 flex items-center px-2"
            onClick={handleDodajArtikal}
          >
            <AiOutlinePlusCircle className="flex items-center" />
            Dodaj artikal
          </button>
        </div>
        <TableWrapper>
          <MainPaginatedTable
            data={data}
            columns={columns}
            actions={true}
            filtering
            // pagination
            pagination
            controlledPageCount={articles?.total_pages || 0}
            setPage={setPage}
            setPageAmount={setPageSize}
            count={articles?.total_count}
            pageManual={page}
            isFetching={isFetching}
            filterInputs={filterInputs}
            setFilterInputs={setFilterInputs}
            hooksArray={tableHooksArray}
          />
        </TableWrapper>
      </div>
      {openDelete && (
        <GlobalDelete
          articleId={selectedArticle?.article_id}
          setOpenDelete={setOpenDelete}
          handleDelete={handleDelete}
          handleCancel={handleCancel}
        />
      )}
    </>
  )
}

export default ArticleCrud
