import React, { useMemo, useState } from 'react'
import { AiOutlinePlusCircle } from 'react-icons/ai'
import { IoPrintOutline } from 'react-icons/io5'
import AddScentModal from '../../components/scentCrud/addScentModal'
import TableWrapper from '../../components/TableWrapper'
import MainPaginatedTable from '../../components/MainPaginatedTable'
import {
  useDeleteCouponMutation,
  useGetAllCouponsQuery,
  useLazyGetAllCouponsToPrintQuery,
} from '../../api/services/couponApi'
import { useRowSelect } from 'react-table'
import { toast } from 'react-toastify'
import { FaTrash } from 'react-icons/fa'
import { BiDetail } from 'react-icons/bi'
import GlobalDelete from '../../components/GlobalDelete'
import AddCouponModal from '../../components/Coupons/addCouponModal'
import { format } from 'date-fns'
import PrintCoupons from '../../components/Coupons/printCoupons'
import DetailsCouponModal from '../../components/Coupons/detailsCouponModal'

function Coupon() {
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(25)
  const [openDelete, setOpenDelete] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [selectedRow, setSelectedRow] = useState()

  const [brojLot, setBrojLot] = useState()
  const [showPrint, setShowPrint] = useState(false)
  const [showDetails, setShowDetails] = useState(false)

  const [deleteCouponMutation] = useDeleteCouponMutation()

  const { data: allCoupons, isFetching } = useGetAllCouponsQuery({
    page: page,
    page_size: pageSize,
  })

  const [getAllCouponsToPrint, { data: kuponi }] =
    useLazyGetAllCouponsToPrintQuery()

  const handleCancel = () => {
    setOpenDelete(false)
  }

  const handleDelete = async () => {
    try {
      const response = await deleteCouponMutation({
        voucher_id: selectedRow.voucher_id,
      })

      if (response.data) {
        toast.success('Kupon je uspijesno izbrisan')
        setOpenDelete(false)
      } else {
        toast.error('Error deleting scent:', response.error)
      }
    } catch (error) {
      toast.error('Network error:', error)
    }
  }

  const columns = useMemo(() => [
    {
      Header: 'ID',
      accessor: 'voucher_id',
    },
    {
      Header: 'Naziv kupona',
      accessor: 'code',
    },
    {
      Header: 'LOT',
      accessor: 'voucher_batch_key',
    },
    {
      Header: 'Popust',
      accessor: 'percentage',
    },
    {
      Header: 'Šifra kupona',
      accessor: 'content',
    },
    {
      Header: 'Od',
      accessor: 'from',
      Cell: ({ row }) => {
        const date = new Date(row.original.from)
        return format(date, 'dd.MM.yyyy')
      },
    },
    {
      Header: 'Do',
      accessor: 'to',
      Cell: ({ row }) => {
        const date = new Date(row.original.to)
        return format(date, 'dd.MM.yyyy')
      },
    },
    {
      Header: 'Iskorišten',
      accessor: 'coupon_used',
    },
  ])

  const data = useMemo(
    () => (Array.isArray(allCoupons?.find) ? allCoupons?.find : []),
    [allCoupons?.find],
  )

  const tableHooksArray = [
    {
      label: 'Izbriši',
      Icon: FaTrash,
      onClick: (row) => {
        setOpenDelete(true)
        setSelectedRow(row.original)
      },
      disabled: false,
    },
    {
      label: 'Detalji',
      Icon: BiDetail,
      onClick: (row) => {
        setShowDetails(true)
        setSelectedRow(row.original)
      },
      disabled: false,
    },
  ]

  const handleCloseModal = () => {
    setOpenModal(false)
  }

  return (
    <>
      <div className="px-4">
        <div className="bg-white border rounded-lg p-2 flex justify-between items-center">
          <div
            className="p-2 bg-blue-500 border rounded-lg text-white flex items-center justify-between gap-3 cursor-pointer"
            onClick={setOpenModal}
          >
            <AiOutlinePlusCircle />
            Kreiraj kupon
          </div>
          <div className=" flex flex-row items-center">
            <div>
              <label className="font-semibold">Broj LOT-a:</label>
              <input
                className="mx-3 border-blue-400 border-2 rounded h-8 px-2 w-24 "
                type="number"
                onChange={(e) => {
                  setBrojLot(e.target.value)
                }}
              />
            </div>
            <div
              className="p-2 bg-blue-500 border rounded-lg text-white flex items-center justify-between gap-3 cursor-pointer"
              onClick={async () => {
                const response = await getAllCouponsToPrint({
                  voucher_batch_key: brojLot,
                }).unwrap()

                setShowPrint(true)
              }}
            >
              <IoPrintOutline />
              Printaj kupone
            </div>
          </div>
        </div>
        <div className="pt-10">
          <TableWrapper>
            <MainPaginatedTable
              data={data}
              columns={columns}
              actions={true}
              pagination
              controlledPageCount={allCoupons?.total_pages || 0}
              setPage={setPage}
              setPageAmount={setPageSize}
              count={allCoupons?.total_count}
              pageManual={page}
              isFetching={isFetching}
              hooksArray={tableHooksArray}
            />
          </TableWrapper>
          {openDelete && (
            <GlobalDelete
              articleId={selectedRow?.voucher_id}
              setOpenDelete={setOpenDelete}
              handleDelete={handleDelete}
              handleCancel={handleCancel}
            />
          )}
          {openModal && <AddCouponModal onClose={handleCloseModal} />}
          {showPrint && (
            <PrintCoupons allCoupons={kuponi} setShowPrint={setShowPrint} />
          )}
          {showDetails && (
            <DetailsCouponModal
              coupon={selectedRow.content}
              setShowDetails={setShowDetails}
            />
          )}
        </div>
      </div>
    </>
  )
}

export default Coupon
