import MapComp from '../components/MapComp'
import React, { useState } from 'react'
import { FaMapMarkerAlt } from 'react-icons/fa'
import ContactFormInput from '../components/ContactFormInput'

function Kontakt() {
  const [selectedPopup, setSelectedPopup] = useState([])
  const [selectedPopupMobile, setSelectedPopupMobile] = useState([])

  return (
    <div>
      <div className="hidden md:block ">
        <MapComp
          className="hidden md:inline-block"
          setSelectedPopup={setSelectedPopup}
          selectedPopup={selectedPopup}
        />
      </div>
      <div></div>
      <div className="flex md:flex-row flex-col justify-between monitor:px-[140px] h-full md:gap-0 gap-10 md:px-[100px] px-[35px]   font-luxorlight mt-10">
        <div className="md:border-r border-b md:border-b-0 w-full md:w-[33.3%]">
          <p className="text-4xl mb-5">Informacije</p>
          <div className="mb-10">
            <p className="border-b w-[30%] text-2xl">Telefon</p>
            <p className="text-xl">062-589/4135</p>
          </div>

          <p className="md:border-b md:w-[30%] text-2xl">Radno vrijeme</p>
          <div className="text-lg ">
            <p className="text-lg font-semibold">Podrška korisnicima</p>
            <p>PON-PET</p>
            <p>7:00h-15:00h</p>
          </div>
          <div className="text-lg mb-2">
            <p className="text-lg font-semibold">Poslovnice</p>
            <p>PON-NED</p>
            <p>9:00h-21:00h</p>
          </div>
        </div>
        <div className="md:border-r border-b md:border-b-0 w-full md:w-[33.3%]  md:ml-2 md:pl-5">
          <p className="text-4xl mb-5">Poslovnice</p>
          <div className="md:flex hidden flex-col gap-3 pb-5 md:pb-0">
            {selectedPopup?.slice(0, 7).map((element) => {
              const key = Object.entries(element)

              const onClickShowMarker = () => {
                key[0][1].openPopup()
              }
              return (
                <button
                  className="font-luxorlight text-xl cursor-pointer flex flex-row gap-5 w-fit"
                  onClick={onClickShowMarker}
                >
                  <FaMapMarkerAlt />
                  {key[0][0]}
                </button>
              )
            })}
          </div>
          <div className="flex md:hidden flex-col gap-3 pb-5 md:pb-0">
            {selectedPopupMobile?.slice(0, 7).map((element) => {
              const key = Object.entries(element)

              const onClickShowMarker = () => {
                key[0][1].openPopup()
              }
              return (
                <button
                  className="font-luxorlight text-xl cursor-pointer flex flex-row gap-5 w-fit"
                  onClick={onClickShowMarker}
                >
                  <FaMapMarkerAlt />
                  {key[0][0]}
                </button>
              )
            })}
          </div>
        </div>
        <div className="md:w-[33.3%] md:border-none  w-full md:ml-2 md:pl-5">
          <p className="text-4xl mb-5">Kontaktirajte nas</p>
          <ContactFormInput />
        </div>
      </div>
      <div className="block md:hidden pt-10">
        <p className="font-luxorthin text-2xl mb-5 px-[35px]">
          Mapa sa svim MAD Parfumeur poslovnicama
        </p>
        <MapComp
          className="hidden md:inline-block"
          setSelectedPopup={setSelectedPopupMobile}
          selectedPopup={selectedPopupMobile}
        />
      </div>
    </div>
  )
}

export default Kontakt
