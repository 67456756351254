import React, { useState } from 'react'
import ReactDatePicker from 'react-datepicker'
// import { useAddScentMutation } from '../../api/services/scentsApi'
import { AiOutlineClose } from 'react-icons/ai'
import { toast } from 'react-toastify'
import { useCreateAutoCouponMutation } from '../../api/services/couponApi'
import Spinner from '../Spinner'

function AddCouponModal({ onClose }) {
  const [addAutoCouponMutation, { isLoading }] = useCreateAutoCouponMutation()

  // STATE ZA SUBMIT
  const [formData, setFormData] = useState({
    percentage: '',
    from: new Date(),
    to: new Date(),
    code: '',
    number_of_created_vouchers: '',
  })

  const handleInputCoupon = async () => {
    try {
      const response = await addAutoCouponMutation({
        percentage: formData.percentage,
        from: formData.from.toISOString().substring(0, 10),
        to: formData.to.toISOString().substring(0, 10),
        code: formData.code,
        number_of_created_vouchers: formData.number_of_created_vouchers,
      })
      if (response.data) {
        toast.success('Kupon uspiješno dodan!')
        setFormData({
          percentage: '',
          from: new Date(),
          to: new Date(),
          code: '',
          number_of_created_vouchers: '',
        })
        onClose()
      } else {
        toast.error('Error adding scent:', response.error)
      }
    } catch (error) {
      console.error('Network error:', error)
    }
  }

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      {/* Background overlay */}
      <div className="fixed inset-0 bg-black opacity-50"></div>

      {/* Modal */}
      <div className="flex flex-col rounded-lg m-10 w-96 md:w-[650px] md:h-[400px] bg-white z-10 space-y-7 py-4 px-4">
        <div className="flex justify-between p-2">
          <h1 className="font-bold">Kreiranje kupona</h1>
          <button onClick={onClose}>
            <AiOutlineClose />
          </button>
        </div>
        {isLoading ? (
          <div className="mx-auto mt-10">
            <Spinner />
          </div>
        ) : (
          <>
            <div className="flex flex-row justify-between mb-20 ">
              <div className="flex flex-col">
                <label htmlFor="" className="mx-3 font-bold">
                  Naziv kupona:
                </label>
                <input
                  type="text"
                  // value={amount.amount}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      code: e.target.value,
                    })
                  }
                  className="mx-3 border-blue-400 border-2 rounded h-8 px-2 w-36 md:w-48"
                />
              </div>
              <div className="flex flex-col">
                <label htmlFor="" className="mx-3 font-bold">
                  Količina:
                </label>
                <input
                  type="number"
                  // value={content.content}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      number_of_created_vouchers: e.target.value,
                    })
                  }
                  className="mx-3 border-blue-400 border-2 rounded h-8 px-2 w-36 md:w-48"
                />
              </div>
            </div>
            <div className="flex flex-row justify-between">
              <div className="flex flex-col mx-3 font-bold">
                <label>Od:</label>
                <ReactDatePicker
                  className="border-2 text-center rounded bg-white border-blue-400 text-black py-1 shadow-lg w-36 md:w-48 "
                  selected={formData.from}
                  maxDate={formData.to}
                  dateFormat="dd.MM.yyyy"
                  onChange={(date) => {
                    setFormData({
                      ...formData,
                      from: date,
                    })
                  }}
                />
              </div>
              <div className="flex flex-col mx-3 font-bold">
                <label>Do:</label>
                <ReactDatePicker
                  className="border-2 text-center rounded bg-white border-blue-400  text-black py-1 shadow-lg w-36 md:w-48 "
                  selected={formData.to}
                  minDate={formData.from}
                  dateFormat="dd.MM.yyyy"
                  onChange={(date) => {
                    setFormData({
                      ...formData,
                      to: date,
                    })
                  }}
                />
              </div>
            </div>
            <div className="flex flex-col items-center">
              <label htmlFor="" className="mx-3 font-bold">
                Popust(%):
              </label>
              <input
                type="text"
                // value={amount.amount}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    percentage: e.target.value,
                  })
                }
                className="mx-3 border-blue-400 border-2 rounded h-8 px-2 w-48"
              />
            </div>
            {/* </div> */}
            <button
              className="mx-3 rounded border-black hover: bg-blue-400 h-8 text-white font-bold"
              onClick={handleInputCoupon}
            >
              Dodaj
            </button>
          </>
        )}
      </div>
    </div>
  )
}

export default AddCouponModal
