import React from 'react'
import banner from '../../img/Group 1234 (3).png'

function LargeBanner({ imagePositions }) {
  return (
    <>
      <div className="w-full monitor:h-[594px] md:h-[494px]  relative monitor:my-[100px] md:my-[70px] ">
        <div>
          <img
            className="w-full  monitor:h-[594px] md:h-[494px] "
            src={
              Array.isArray(imagePositions) && imagePositions.length > 0
                ? imagePositions[5].isDefault
                  ? banner
                  : imagePositions[5].image
                : ''
            }
            alt=""
          />
        </div>
        <div className="absolute monitor:top-2/3 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
          <p className="font-ivy md:text-[45px] text-[14px] text-center text-white">
            Zakoračite u čaroban svijet mirisa uz MAD parfeme, kreiranje
            najposebnijim spojem nota.
          </p>
          <p className="font-ivybold md:text-[45px] text-xs text-center text-white md:mt-7 mt-2 ">
            Otkrijte svoj zapanjujući miris!{' '}
          </p>
        </div>
      </div>
    </>
  )
}

export default LargeBanner
