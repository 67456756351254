import { api } from '../api'

// GET api/v1/orders/get-orders/page/page_size

export const orderApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getAllOrders: builder.query({
      query: ({ page, page_size, query }) =>
        `api/v1/orders/get-orders/${page}/${page_size}/${
          query ? `?${query}` : ''
        }`,
      providesTags: ['Orders'],
    }),
    getOneOrder: builder.query({
      query: (order_id) => `api/v1/orders/get-orders/${order_id}`,
      providesTags: ['Orders'],
    }),
    deleteOrder: builder.mutation({
      query: (order_id) => ({
        url: `api/v1/orders/${order_id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Orders'],
    }),
    addOrder: builder.mutation({
      query: (body) => ({
        url: `api/v1/orders`,
        body,
        method: 'POST',
      }),
      invalidatesTags: ['Orders'],
    }),
    updateOrder: builder.mutation({
      query: (body) => ({
        url: `api/v1/orders`,
        body,
        method: 'PUT',
      }),
      invalidatesTags: ['Orders', 'User', 'Articles'],
    }),
    getLastOrders: builder.query({
      query: (user_id) => ({
        url: `api/v1/orders/last-order/${user_id}`,
      }),
      providesTags: ['Orders'],
    }),
    getUserOrders: builder.query({
      query: (user_id) => ({
        url: `api/v1/orders/by-user/${user_id}/ACCOUNT`,
      }),
      providesTags: ['Orders'],
    }),
    getLastFour: builder.query({
      query: () => 'api/v1/orders/last-four',
      providesTags: ['Orders'],
    }),
    getOrderDate: builder.query({
      query: ({ from, to }) => `api/v1/orders/get-daily-orders/${from}/${to}`,
      providesTags: ['Orders'],
    }),
    getOrdersMainChart: builder.query({
      query: ({ from, to }) => `api/v1/orders/get-orders-monthly/${from}/${to}`,
      provideTags: ['Orders'],
    }),
    getPaginatedUserOrders: builder.query({
      query: ({ page, page_size, user_id }) => ({
        url: `api/v1/orders/by-user-paginated/${user_id}/ACCOUNT/${page}/${page_size}`,
      }),
      providesTags: ['Orders'],
    }),
    postSendMail: builder.mutation({
      query: (body) => ({
        url: `api/v1/orders/sending-mail`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Orders'],
    }),
    getOrderByCoupon: builder.query({
      query: ({ coupon }) => ({
        url: `api/v1/orders/get-orders-by-coupon/${coupon}`,
      }),
      providesTags: ['Orders'],
    }),
  }),
})

// GET api/v1/orders/by-user/user_id
// DELETE api/v1/orders/order_id
// GET api/v1/orders/last-order/user_id
// api/v1/order-articles/multiple
// GET api/v1/orders/get-orders
export const {
  useAddOrderMutation,
  useDeleteOrderMutation,
  useGetAllOrdersQuery,
  useGetOneOrderQuery,
  useGetLastOrdersQuery,
  useUpdateOrderMutation,
  useGetUserOrdersQuery,
  useGetLastFourQuery,
  useGetOrderDateQuery,
  useGetOrdersMainChartQuery,
  usePostSendMailMutation,
  useGetPaginatedUserOrdersQuery,
  useGetOrderByCouponQuery,
} = orderApi
