import { api } from '../api'

export const perfumeTypeApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getAllPerfumeTypes: builder.query({
      query: () => 'api/v1/perfume-types',
    }),
  }),
})

export const { useGetAllPerfumeTypesQuery } = perfumeTypeApi
