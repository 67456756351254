import React, { useEffect, useMemo, useState } from 'react'
import Modal from '../Modal'
import { MdImageSearch } from 'react-icons/md'
import { FiUpload } from 'react-icons/fi'
import MainTable from '../MainTable'
import {
  useGetAllImagePositionsQuery,
  useUpdateImagePositionMutation,
} from '../../api/services/imagePosition'
import { toast } from 'react-toastify'

function ImageConfig() {
  const { data: imagePositions } = useGetAllImagePositionsQuery()
  const [show1, setShow1] = useState(false)
  const [selectedImage, setSelectedImage] = useState('')

  const [updateImagePosition] = useUpdateImagePositionMutation()
  const data = useMemo(
    () => (Array.isArray(imagePositions) ? imagePositions : []),
    [imagePositions],
  )

  const columns = useMemo(
    () => [
      {
        Header: 'Preview',
        accessor: 'preview1',
        Cell: ({ row }) => {
          return (
            <button
              onClick={() => {
                setSelectedImage(row.original.preview)
                setShow1(true)
              }}
            >
              <MdImageSearch />
            </button>
          )
        },
      },
      {
        Header: 'Pozicija',
        accessor: 'name',
      },
      {
        Header: 'Dimenzije',
        accessor: 'dimensions',
      },
      {
        Header: 'Default',
        accessor: 'isDefault',
        Cell: ({ row }) => {
          return (
            <div className="justify-center items-center flex mx-2">
              <label className="relative inline-flex items-center cursor-pointer">
                <input
                  type="checkbox"
                  className="sr-only peer"
                  checked={row.original.isDefault === true}
                  onChange={(e) => {
                    const formData = new FormData()
                    for (const key in row.original) {
                      if (Object.hasOwnProperty.call(row.original, key)) {
                        if (key === 'isDefault') {
                          const value = !row.original[key]
                          formData.append(key, value)
                        } else {
                          const value = row.original[key]
                          formData.append(key, value)
                        }
                      }
                    }

                    updateImagePosition(formData)
                  }}
                />
                <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-700 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
              </label>
            </div>
          )
        },
      },
      {
        Header: 'Upload',
        accessor: 'uploads',
        Cell: ({ row }) => {
          return (
            <div className="flex relative items-center justify-center">
              <input
                type="file"
                id="file-input"
                accept="image/*"
                className="opacity-0 cursor-pointer absolute inset-0"
                alt="Image input"
                onChange={async (event) => {
                  try {
                    const formData = new FormData()
                    formData.append('file', event.target.files[0])
                    formData.append(
                      'image_position_id',
                      row.original.image_position_id,
                    )
                    formData.append('isDefault', row.original.isDefault)

                    await updateImagePosition(formData)

                    toast.success('Slika uspješno ažurirana!')
                  } catch (error) {
                    toast.error('Došlo je do greške prilikom ažuriranja slike')
                  }
                }}
              />
              <label
                htmlFor="file-input"
                className="block text-center mt-2 cursor-pointer "
              >
                <FiUpload />
              </label>
            </div>
          )
        },
      },
    ],
    [data],
  )

  return (
    <>
      <div className="bg-white rounded p-2">
        <MainTable columns={columns} data={data} />
      </div>

      <Modal showModal={show1} setShowModal={setShow1} title="Pozicija slike 1">
        <img className="w-full" src={selectedImage} alt="" />
      </Modal>
    </>
  )
}

export default ImageConfig
