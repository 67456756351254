import { api } from '../api'

export const articleGroup = api.injectEndpoints({
  endpoints: (builder) => ({
    getAllArticleGroups: builder.query({
      query: () => 'api/v1/article-groups/get-article-groups',
    }),
    getOneArticleGroup: builder.query({
      query: (article_group_id) =>
        `api/v1/article-groups/get-article-group/${article_group_id}`,
      providesTags: ['ArticleGroups'],
    }),
    addArticleGroup: builder.mutation({
      query: (body) => ({
        url: `api/v1/articles/`,
        body,
        method: 'POST',
      }),
      invalidatesTags: ['ArticleGroups'],
    }),
    updateArticleGroup: builder.mutation({
      query: (body) => ({
        url: `api/v1/article-groups/`,
        body,
        method: 'PUT',
      }),
      invalidatesTags: ['ArticleGroups'],
    }),
    deleteArticleGroup: builder.mutation({
      query: () => ({
        url: `api/v1/article-groups/article_group_id`,
        method: 'DELETE',
      }),
      invalidatesTags: ['ArticleGroups'],
    }),
  }),
})

export const {
  useGetAllArticleGroupsQuery,
  useAddArticleGroupMutation,
  useDeleteArticleGroupMutation,
  useGetOneArticleGroupQuery,
} = articleGroup
