import React, { useEffect, useState } from 'react'
import {
  useGetShippingCostQuery,
  useUpdateShippingCostMutation,
} from '../../api/services/configuratorsApi'
import { toast } from 'react-toastify'

function BirthdayConfig() {
  const [updateShippingCost] = useUpdateShippingCostMutation()
  const { data: birthdayConfig } = useGetShippingCostQuery()
  const [formData, setFormData] = useState({
    is_enabled: '',
    birthday_discount: '',
  })

  useEffect(() => {
    if (birthdayConfig) {
      setFormData((prevData) => ({
        ...prevData,
        is_enabled: birthdayConfig.is_enabled || '',
        birthday_discount: birthdayConfig.birthday_discount || '',
      }))
    }
  }, [birthdayConfig])

  const handleUpdateBirthdayDiscount = async () => {
    try {
      const response = await updateShippingCost({
        ...formData,
        is_enabled: formData?.is_enabled,
        birthday_discount: formData?.birthday_discount,
      })

      if (response.error) {
        toast.error('An error occurred while updating birthday discount.')
      } else {
        toast.success('Cijena poštarine je ažurirana')
      }
    } catch (error) {
      toast.error('An unexpected error occurred. Please try again later.')
    }
  }

  return (
    <>
      <label htmlFor="" className="my-4 font-bold">
        Konfiguracija rođendanskih kupona
      </label>
      <article className="flex flex-col bg-white px-4 rounded space-y-2 py-2">
        <label htmlFor="" className="text-gray-400">
          Slanje kupona mailom
        </label>
        <div className="items-center flex mx-2">
          <label className="relative inline-flex items-center cursor-pointer">
            <input
              type="checkbox"
              className="sr-only peer"
              checked={formData.is_enabled === 'Y'}
              value={formData.is_enabled}
              onChange={(e) => {
                setFormData({
                  ...formData,
                  is_enabled: e.target.checked ? 'Y' : 'N',
                })
              }}
            />
            <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-700 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
          </label>
        </div>
        <label htmlFor="" className="text-gray-400">
          Procenat popusta
        </label>
        <input
          type="text"
          className="border rounded h-8 px-2"
          name="birthday_discount"
          value={formData.birthday_discount || ''}
          onChange={(e) =>
            setFormData({ ...formData, birthday_discount: e.target.value })
          }
        />
      </article>
      <button
        className="bg-gray-700 text-white h-8 m-4"
        onClick={handleUpdateBirthdayDiscount}
      >
        Izmijeni
      </button>
    </>
  )
}

export default BirthdayConfig
