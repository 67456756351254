import React, { useMemo } from 'react'
import { AiOutlineCloseCircle } from 'react-icons/ai'
import MainTable from '../MainTable'
import TableWrapper from '../TableWrapper'
import { useGetOrderByCouponQuery } from '../../api/services/ordersApi'
import { format } from 'date-fns'

const DetailsCouponModal = ({ coupon, setShowDetails }) => {
  const { data: orders } = useGetOrderByCouponQuery({ coupon: coupon })

  const onClose = () => {
    setShowDetails(false)
  }

  const columns = useMemo(
    () => [
      {
        Header: 'ID Narudžbe',
        accessor: 'order_id',
      },
      {
        Header: 'Ime',
        accessor: 'first_name',
      },
      {
        Header: 'Prezime',
        accessor: 'last_name',
      },
      {
        Header: 'Adresa',
        accessor: 'address',
        Cell: ({ row }) => {
          return (
            <span>
              {row?.original?.additional_address?.length < 1
                ? row?.original?.address
                : `${row?.original?.address}, ${row?.original?.additional_address}`}
            </span>
          )
        },
      },

      {
        Header: 'Grad',
        accessor: 'city',
      },
      {
        Header: 'Poštanski Broj',
        accessor: 'zip_code',
      },
      {
        Header: 'Broj Telefona',
        accessor: 'phone_number',
      },
      {
        Header: 'Napomena',
        accessor: 'note',
        Cell: ({ row }) => {
          return (
            <span>
              {row?.original?.note?.length < 1 ? '---' : row?.original?.note}
            </span>
          )
        },
      },
      {
        Header: 'Kupon',
        accessor: 'coupon',
        Cell: ({ row }) => {
          return (
            <span>
              {row?.original?.coupon?.length < 1
                ? '---'
                : row?.original?.coupon}
            </span>
          )
        },
      },
      {
        Header: 'Datum',
        accessor: '',
        Cell: ({ row }) => {
          const dateObject = new Date(row?.original?.updated_at)
          return format(dateObject, 'dd.MM.yyyy')
        },
      },
    ],
    [],
  )
  const data = useMemo(
    () => (orders && Object.keys(orders).length ? [orders] : []),
    [orders],
  )

  return (
    <>
      <div className="fixed inset-0 bg-black opacity-50"></div>
      <div className="fixed inset-0 flex items-center justify-center z-50 p-10 md:p-0">
        {/* Background overlay */}

        {/* Modal */}
        <div className="flex flex-col gap-2 rounded-lg bg-white z-10 w-full h-1/2 md:w-2/3 md:h-2/3 border border-black p-2">
          <div className="flex justify-between border-b-2 border-black">
            <h1>Detalji</h1>
            <span className="cursor-pointer" onClick={onClose}>
              {<AiOutlineCloseCircle />}
            </span>
          </div>
          <TableWrapper>
            <MainTable columns={columns} data={data} />
          </TableWrapper>
        </div>
      </div>
    </>
  )
}

export default DetailsCouponModal
