import { AiOutlineClose, AiOutlineCloseCircle } from 'react-icons/ai'
import { useGetPerfumeCategoriesQuery } from '../../api/services/perfumeApi'
import { useGetAllPerfumeTypesQuery } from '../../api/services/perfumeTypeApi'
import { useEffect, useState } from 'react'
import { useGetAllVolumesQuery } from '../../api/services/volumesApi'
import { useDispatch, useSelector } from 'react-redux'
import {
  reset,
  toggleCategory,
  toggleGender,
  toggleGroup,
  toggleVolume,
} from '../../api/features/filterSlice'
import { useGetAllArticleGroupsQuery } from '../../api/services/articleGroup'

const FilterModalAll = ({ setOpenFilterModal }) => {
  const [menuOpen, setMenuOpen] = useState(false)
  const closeModal = () => {
    setMenuOpen(false)
    setTimeout(() => {
      setOpenFilterModal(false)
    }, 300)
  }
  useEffect(() => {
    setMenuOpen(true)
  }, [])

  const { data: perfumeCategories } = useGetPerfumeCategoriesQuery()
  const { data: perfumeType } = useGetAllPerfumeTypesQuery()
  const { data: volumes } = useGetAllVolumesQuery()
  const { data: articleGroups } = useGetAllArticleGroupsQuery()

  const selectedCategories = useSelector(
    (state) => state.filter.selectedCategories,
  )
  const selectedVolume = useSelector((state) => state.filter.selectedVolume)
  const selectedGender = useSelector((state) => state.filter.selectedGender)
  const selectedGroup = useSelector((state) => state.filter.selectedGroup)
  const dispatch = useDispatch()

  const handleCategoryChange = (category) => {
    dispatch(toggleCategory(category))
  }
  const handleVolumeChange = (volume) => {
    dispatch(toggleVolume(volume))
  }
  const handleGenderChange = (gender) => {
    dispatch(toggleGender(gender))
  }
  const handleGroupChange = (group) => {
    dispatch(toggleGroup(group))
  }

  return (
    <div className="fixed inset-0 flex items-start justify-start z-10 bg-black bg-opacity-50 overflow-hidden">
      <div
        className={`flex  flex-col  gap-6 bg-white w-2/3 h-full p-4 transition-transform ${
          menuOpen ? 'transform translate-x-0' : 'transform -translate-x-full'
        }`}
      >
        <div onClick={closeModal} className="w-full flex justify-between">
          <p>Filter</p>
          <span className="flex items-center">
            <AiOutlineCloseCircle className="w-6 h-6" />
          </span>
        </div>
        <div className="flex flex-col justify-between mt-1">
          <div className="flex flex-col items-start gap-3 justify-between w-full text-base"></div>
        </div>
        <div className="flex flex-col mt-3 gap-8 text-gray-500 overflow-y-auto max-h-fit">
          <div className="flex flex-col gap-2">
            <h2>Tip mirisa:</h2>
            {perfumeCategories?.map((category, index) => {
              const uniqueId = Math.random()
              return (
                <div className="flex items-center gap-2" key={index}>
                  <input
                    type="checkbox"
                    value={category.perfume_category_id}
                    checked={selectedCategories?.find(
                      (item) =>
                        item?.perfume_category_id ===
                        category?.perfume_category_id,
                    )}
                    onChange={() => handleCategoryChange(category)}
                    id={uniqueId}
                  />
                  <label htmlFor={uniqueId}>
                    {category.perfume_category_name}
                  </label>
                </div>
              )
            })}
          </div>
          <div className="flex flex-col gap-2">
            <h2>ML:</h2>
            {volumes
              ?.map((volume) => ({
                total: volume.total,
                volume_id: volume.volume_id,
              }))
              .sort((a, b) => a.total - b.total)
              .map((volume, index) => {
                const uniqueId = Math.random()
                return (
                  <div className="flex gap-2" key={index}>
                    <input
                      type="checkbox"
                      value={volume.volume_id}
                      checked={selectedVolume?.find(
                        (item) => item?.volume_id === volume?.volume_id,
                      )}
                      onChange={() => handleVolumeChange(volume)}
                      id={uniqueId}
                    />
                    <label htmlFor={uniqueId}>{volume.total}</label>
                  </div>
                )
              })}
          </div>
          <div className="flex flex-col gap-2">
            <h2>Pol:</h2>
            {perfumeType?.map((gender, index) => {
              const uniqueId = Math.random()
              return (
                <div className="flex items-center gap-2" key={index}>
                  <input
                    type="checkbox"
                    value={gender.perfume_type_id}
                    checked={selectedGender?.find(
                      (item) =>
                        item?.perfume_type_id === gender?.perfume_type_id,
                    )}
                    onChange={() => handleGenderChange(gender)}
                    id={uniqueId}
                  />
                  <label htmlFor={uniqueId}>{gender?.perfume_type_name}</label>
                </div>
              )
            })}
          </div>
          <div className="flex flex-col gap-2">
            <h2>Kategorije:</h2>
            {articleGroups?.map((group, index) => {
              const uniqueId = Math.random()
              return (
                <div className="flex gap-2" key={index}>
                  <input
                    type="checkbox"
                    value={group?.article_group_id}
                    checked={selectedGroup?.find(
                      (item) =>
                        item?.article_group_id === group?.article_group_id,
                    )}
                    onChange={() => handleGroupChange(group)}
                    id={uniqueId}
                  />
                  <label htmlFor={uniqueId}>{group?.article_group_name}</label>
                </div>
              )
            })}
          </div>
        </div>
        <div
          onClick={() => dispatch(reset())}
          className="bg-gray-300 p-2 w-full flex items-center justify-between"
        >
          Resetuj filtere{' '}
          <span>
            <AiOutlineClose />
          </span>
        </div>
      </div>
    </div>
  )
}

export default FilterModalAll
