import React, { useEffect } from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import { useState } from 'react'
import { useGetBagArticlesQuery } from '../../api/services/bagArticleApi'
import { useSelector } from 'react-redux'
import { useGetShippingCostQuery } from '../../api/services/configuratorsApi'

function Racun({ couponAmount, isCoupon, user }) {
  const [totalPrice, setTotalPrice] = useState(0)
  const [cartTotalPrice, setCartTotalPrice] = useState(0)
  const [totalShipping, setTotalShipping] = useState(0)
  const [totalDiscount, setTotalDiscount] = useState(0)
  const bag = useSelector((state) => state.auth.bag)
  const { data: bagArticles } = useGetBagArticlesQuery(bag?.bag_id)
  const { data: shippingCost } = useGetShippingCostQuery()
  const cartOrderArticles = JSON.parse(localStorage.getItem('cart')) || []

  //border color na selectu

  useEffect(() => {
    const total = bagArticles?.reduce((prev, current) => {
      if (current?.article?.discount && current?.article?.discount > 0)
        return prev + current.article.discount_price * current?.amount
      else return prev + current.price * current?.amount
    }, 0)
    const totalDiscount = bagArticles?.reduce((prev, current) => {
      if (current?.article?.discount && current?.article?.discount > 0)
        return prev + current.article.discount * current?.amount
      // Corrected calculation
      else return prev // No discount applied for this article
    }, 0)
    setTotalDiscount(totalDiscount)
    setTotalPrice(total)

    if (
      total >= shippingCost?.free_shipping ||
      totalWithCoupon >= shippingCost?.free_shipping
    ) {
      setTotalShipping(0)
    } else {
      setTotalShipping(shippingCost?.shipping_cost)
    }
  }, [bagArticles])

  useEffect(() => {
    const total = cartOrderArticles?.reduce((prev, current) => {
      if (current?.discount && current?.discount > 0)
        return prev + current.discount_price * current?.amount
      else return prev + current.price * current?.amount
    }, 0)

    setCartTotalPrice(total)
  }, [cartOrderArticles])
  const totalWithCoupon = cartTotalPrice - (cartTotalPrice * couponAmount) / 100

  const total =
    bagArticles?.length > 0 && user
      ? totalPrice
      : isCoupon
      ? totalWithCoupon
      : cartTotalPrice

  return (
    <>
      <div className="flex flex-col md:flex-1 pt-5 w-full lg:w-[40%] h-full bg-[#FEFBF6]">
        {/* Vaša narudžba */}
        <div className="flex flex-col gap-2 py-9 h-fit lg:h-[1400px] px-10">
          <h1 className="font-bold mb-6 text-2xl">Korpa</h1>
          <div className="flex md:flex-row w-full justify-between border-b">
            <label htmlFor="proizvodi" className="text-md">
              Proizvodi
            </label>
            <label htmlFor="total" className="text-md">
              {' '}
              Ukupno
            </label>
          </div>
          {bagArticles?.length > 0 && user
            ? bagArticles.map((item) => (
                <div
                  key={Math.random()}
                  className="flex justify-between py-2 border-gray-300 font-medium"
                >
                  <div className="flex flex-col">
                    <p>{`${item?.article_name} - ${item.volume}ml`}</p>
                    <div className="flex flex-row">
                      <p className="font-bold mr-3">{`${item?.price} KM`}</p>
                      <p>{` x ${item.amount}`}</p>
                    </div>

                    <p className="text-[#C2C2C2] text-sm">
                      {'U cijenu je uključen PDV (17%)'}
                    </p>
                  </div>

                  {item?.article?.discount && item?.article?.discount > 0 ? (
                    <div className="flex items-center">
                      <p>
                        {(
                          item?.article?.discount_price * item?.amount
                        )?.toFixed(2)}
                      </p>
                    </div>
                  ) : (
                    <div className="flex items-center">
                      <p>{(item?.price * item?.amount)?.toFixed(2)}</p>
                    </div>
                  )}
                </div>
              ))
            : cartOrderArticles.map((item) => (
                <div
                  key={Math.random()}
                  className="flex justify-between border-b py-1 border-gray-300 font-medium"
                >
                  <div className="flex flex-col">
                    <p>{`${item?.article_name} - ${item.volume}ml`}</p>
                    <div className="flex flex-row">
                      <p className="font-bold mr-3">{`${item?.price} KM`}</p>
                      <p>{` x ${item.amount}`}</p>
                    </div>

                    <p className="text-[#C2C2C2] text-sm">
                      {'U cijenu je uključen PDV (17%)'}
                    </p>
                  </div>
                  <p>
                    {isCoupon === true ? (
                      (
                        (item?.price - (item?.price * couponAmount) / 100) *
                        item?.amount
                      )?.toFixed(2)
                    ) : item?.discount && item.discount > 0 ? (
                      <p>
                        {(item?.discount_price * item?.amount)?.toFixed(2)}{' '}
                      </p>
                    ) : (
                      (item?.price * item?.amount)?.toFixed(2)
                    )}
                  </p>
                </div>
              ))}
          {/* Troskovi dostave */}
          <div className="flex flex-col w-full justify-between my-2 border-y border-[#9C9C9C] py-4">
            <div className="flex justify-between w-full">
              <h2 className="text-md text-[#9C9C9C]">Proizvodi</h2>
              <div className="flex flex-row items-center">
                <label htmlFor="" className="  mr-1">
                  {parseFloat(total).toFixed(2)}
                </label>
                <p className="text-sm ">KM</p>
              </div>
            </div>
            {/* <div className="flex justify-between w-full">
              <h2 className="text-md text-[#9C9C9C]">Popust</h2>
              <div className="flex flex-row items-center">
                <label htmlFor="" className=" mr-1">
                  {parseFloat(totalDiscount).toFixed(2)}
                </label>
                <p className="text-sm">KM</p>
              </div>
            </div> */}
            <div className="flex justify-between w-full">
              <h2 className="text-md text-[#9C9C9C]">Dostava</h2>
              <div className="flex flex-row items-center">
                <label htmlFor="" className="mr-1">
                  {total >= shippingCost?.free_shipping ||
                  totalWithCoupon >= shippingCost?.free_shipping
                    ? 'Besplatna'
                    : parseFloat(shippingCost?.shipping_cost).toFixed(2)}
                </label>

                <p className="text-sm">
                  {total >= shippingCost?.free_shipping ||
                  totalWithCoupon >= shippingCost?.free_shipping
                    ? ''
                    : 'KM'}
                </p>
              </div>
            </div>
          </div>
          {/* Naplata total */}
          <div className="flex flex-row w-full justify-between my-2 pb-2">
            <h2 className="font-bold text-md">Ukupno za naplatu</h2>
            <div className="">
              <label htmlFor="totalPrice" className="text-md font-bold">
                {isCoupon === true &&
                totalWithCoupon >= shippingCost?.free_shipping
                  ? totalWithCoupon.toFixed(2)
                  : isCoupon === true
                  ? (
                      totalWithCoupon + parseFloat(shippingCost?.shipping_cost)
                    ).toFixed(2)
                  : total >= shippingCost?.free_shipping
                  ? parseFloat(total).toFixed(2)
                  : (
                      parseFloat(total) +
                      parseFloat(shippingCost?.shipping_cost)
                    ).toFixed(2)}{' '}
                KM
              </label>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Racun
