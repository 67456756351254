import React, { useEffect, useMemo } from 'react'
import { useState } from 'react'
import Select from 'react-select'
import { useGetShippingCostQuery } from '../../api/services/configuratorsApi'
import {
  useAddOrderMutation,
  usePostSendMailMutation,
} from '../../api/services/ordersApi'
import { toast } from 'react-toastify'
import {
  useDeleteAllBagArticlesMutation,
  useGetBagArticlesQuery,
  useUpdateBagArticleByVoucherMutation,
} from '../../api/services/bagArticleApi'
import { useDispatch, useSelector } from 'react-redux'
import { useAddOrderArticlesMutation } from '../../api/services/orderArticlesApi'
import { useNavigate } from 'react-router'
import { removeCart } from '../../api/features/cartSlice'
import { RadioGroup } from '@headlessui/react'
import {
  useGetAllCouponsToUseQuery,
  useUpdateUsedCouponMutation,
} from '../../api/services/couponApi'

function OsnovneInformacije({
  setFormData,
  formData,
  couponAmount,
  isCoupon,
  setIsCoupon,
  setCouponAmount,
  user,
}) {
  const [totalShipping, setTotalShipping] = useState(0)
  const [totalPrice, setTotalPrice] = useState(0)
  const [cartTotalPrice, setCartTotalPrice] = useState(0)
  const [totalDiscount, setTotalDiscount] = useState(0)
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false)

  //   Dodatna adresa
  const bag = useSelector((state) => state.auth.bag)
  const { data: bagArticles } = useGetBagArticlesQuery(bag?.bag_id)
  const { data: shippingCost } = useGetShippingCostQuery()
  const { data: allCoupons } = useGetAllCouponsToUseQuery()
  const cartOrderArticles = JSON.parse(localStorage.getItem('cart')) || []

  const [addOrder, { isLoading }] = useAddOrderMutation()
  const [addOrderArticles] = useAddOrderArticlesMutation()
  const [sendMail] = usePostSendMailMutation()
  const [deleteAllBagArticles] = useDeleteAllBagArticlesMutation()
  const [updateBagArticleByVoucher] = useUpdateBagArticleByVoucherMutation()
  const [updateUsedCouponMutation] = useUpdateUsedCouponMutation()

  const dispatch = useDispatch()

  // const [errors, setErrors] = useState({})
  const navigate = useNavigate()

  const isInvalid = (fieldName) => {
    //if valid and empty
    return !formData[fieldName] && fieldName !== 'additional_address'
  }

  useEffect(() => {
    const total = bagArticles?.reduce((prev, current) => {
      if (current?.article?.discount && current?.article?.discount > 0)
        return prev + current.article.discount_price * current?.amount
      else return prev + current.price * current?.amount
    }, 0)
    const totalDiscount = bagArticles?.reduce((prev, current) => {
      if (current?.article?.discount && current?.article?.discount > 0)
        return prev + current.article.discount * current?.amount
      else return prev // No discount applied for this article
    }, 0)
    setTotalDiscount(totalDiscount)
    setTotalPrice(total)
    if (
      total >= shippingCost?.free_shipping ||
      totalWithCoupon >= shippingCost?.free_shipping
    ) {
      setTotalShipping(0)
    } else {
      setTotalShipping(shippingCost?.shipping_cost)
    }
  }, [bagArticles])

  useEffect(() => {
    const total = cartOrderArticles?.reduce((prev, current) => {
      if (current?.discount && current?.discount > 0)
        return prev + current.discount_price * current?.amount
      else return prev + current.price * current?.amount
    }, 0)

    setCartTotalPrice(total)
  }, [cartOrderArticles])

  const data = useMemo(
    () => (Array.isArray(allCoupons) ? allCoupons : []),
    [allCoupons],
  )

  const handleCouponSubmit = ({ data }) => {
    const realCoupon = data?.find(
      (item) => formData.coupon.trim() === item.content,
    )
    if (!realCoupon || realCoupon.coupon_used === 'Y') {
      toast.error('Nevažeći kupon!')
      setIsCoupon(false)
    } else if (bagArticles.length > 0) {
      toast.success('Kupon iskorišten!')
      updateBagArticleByVoucher({
        bag_id: bag?.bag_id,
        query: `?coupon=${parseInt(realCoupon?.percentage)}`,
      })
      updateUsedCouponMutation({ coupon_id: realCoupon?.voucher_id })
    } else {
      setIsCoupon(true)
      setCouponAmount(parseInt(realCoupon?.percentage))
    }
  }

  const handleChange = (e) => {
    const { name, value } = e.target
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }))
  }

  const total = bagArticles?.length > 0 ? totalPrice : cartTotalPrice
  const totalWithCoupon = cartTotalPrice - (cartTotalPrice * couponAmount) / 100

  const handleFinishCheckout = () => {
    if (
      !formData.first_name ||
      !formData.last_name ||
      !formData.email ||
      !formData.address ||
      !formData?.country ||
      !formData.zip_code ||
      !formData.phone_number ||
      !formData.city ||
      !formData.method_of_payment_id ||
      !isCheckboxChecked
    ) {
      return toast.error('Popunite sva polja!')
    }

    addOrder({
      ...formData,
      shipping_cost: totalShipping,
    })
      .unwrap()
      .then((res) => {
        const articles =
          bagArticles?.length > 0
            ? bagArticles?.map((item) => {
                return {
                  ...item,

                  coupon: res?.order?.coupon,
                  price:
                    item?.article?.discount > 0
                      ? item?.article?.discount_price
                      : item?.price,
                  order_id: res?.order?.order_id,
                  volume_id: item?.article?.volume_id,
                }
              })
            : cartOrderArticles?.map((item) => {
                return {
                  ...item,
                  price:
                    item?.discount > 0
                      ? item?.discount
                      : item?.price - (item?.price * couponAmount) / 100,
                  order_id: res?.order?.order_id,
                  volume_id: item?.volume_id,
                }
              })

        addOrderArticles({
          order_id: res?.order?.order_id,
          order_articles: articles,
        })
          .unwrap()
          .then(() => {
            sendMail({
              order_id: res?.order?.order_id,
            })
          })

        deleteAllBagArticles(bag?.bag_id)
        dispatch(removeCart())
        toast.success('Narudžba uspješno kreirana!')
      })
    navigate('/checkout_complete')
  }
  const options = [
    { value: 'Bosna i Hercegovina', label: 'Bosna i Hercegovina' },
  ]
  return (
    <div className="flex flex-col pt-5 w-full lg:w-[70%] px-16 h-full">
      <div className="pt-8 pb-14 border-b-2">
        {/* DETALJI DOSTAVE */}
        <div className="flex flex-col gap-3">
          <h1 className="font-bold text-2xl mb-6">Osnovni podatci</h1>
          {/* IME PREZIME */}
          {/* First row */}
          <div className="flex lg:flex-row flex-col w-full gap-4">
            <div className="flex flex-col wfull lg:w-1/2">
              <label htmlFor="" className="text-gray-500">
                Ime
              </label>
              <div className="flex flex-col">
                <input
                  id="first_name"
                  type="text"
                  className={`border mb-3 h-14 pl-3 outline-[#DCA950] border-[#9C9C9C] ${
                    isInvalid('first_name') && 'border-[#DCA950] border-2'
                  }`}
                  required
                  onChange={handleChange}
                  name="first_name"
                  value={formData?.first_name || ''}
                />
              </div>
            </div>
            <div className="flex flex-col w-full lg:w-1/2">
              <label htmlFor="" className="text-gray-500">
                Prezime
              </label>
              <input
                id="last_name"
                type="text"
                className={`border mb-3 h-14 pl-3 outline-[#DCA950] border-[#9C9C9C] ${
                  isInvalid('last_name') && 'border-[#DCA950] border-2'
                }`}
                required
                onChange={handleChange}
                name="last_name"
                value={formData?.last_name || ''}
              />
            </div>
          </div>
          {/* Second row */}
          <div className="flex lg:flex-row flex-col w-full gap-4">
            <div className="flex flex-col wfull lg:w-1/2">
              <label htmlFor="" className="text-gray-500">
                Email
              </label>
              <input
                type="email"
                id="email"
                required={true}
                pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}"
                className={`border mb-3 h-14 pl-3 outline-[#DCA950] border-[#9C9C9C] ${
                  isInvalid('email') && 'border-[#DCA950] border-2'
                }`}
                onChange={handleChange}
                name="email"
                value={formData?.email || ''}
              />
            </div>
            <div className="flex flex-col w-full lg:w-1/2">
              <div className="flex items-center">
                <label htmlFor="" className="text-gray-500 mr-3">
                  Broj telefona
                </label>
              </div>
              <input
                id="phone_number"
                type="number"
                className={`border mb-3 h-14 pl-3 outline-[#DCA950] border-[#9C9C9C] ${
                  isInvalid('phone_number') && 'border-[#DCA950] border-2'
                }`}
                required
                title="Unesite svoj broj telefona"
                onChange={handleChange}
                name="phone_number"
                value={formData?.phone_number || ''}
              />
            </div>
          </div>
          <div className="flex lg:flex-row flex-col w-full gap-4">
            <div className="flex flex-col w-full lg:w-1/2">
              <label htmlFor="" className="text-gray-500">
                Adresa (ulica i broj)
              </label>
              <input
                type="text"
                id="address"
                className={`border mb-3 h-14 pl-3 outline-[#DCA950] border-[#9C9C9C] ${
                  isInvalid('address') && 'border-[#DCA950] border-2'
                }`}
                required
                onChange={handleChange}
                name="address"
                value={formData?.address || ''}
                disabled={formData?.additional_address?.length > 0}
              />
            </div>
            <div className="flex flex-col w-full lg:w-1/2">
              <label htmlFor="" className="text-gray-500">
                Grad
              </label>
              <input
                type="text"
                id="city"
                className={`border mb-3 h-14 pl-3 outline-[#DCA950] border-[#9C9C9C] ${
                  isInvalid('city') && 'border-[#DCA950] border-2'
                }`}
                required
                onChange={handleChange}
                name="city"
                value={formData?.city || ''}
              />
            </div>
          </div>
          {/* 3rd row */}
          <div className="flex lg:flex-row flex-col w-full gap-4">
            <div className="flex flex-col w-full lg:w-1/2">
              <label htmlFor="" className="text-gray-500">
                Poštanski broj
              </label>
              <input
                type="number"
                id="zip_code"
                className={`border mb-3 h-14 pl-3 outline-[#DCA950] border-[#9C9C9C] ${
                  isInvalid('zip_code') && 'border-[#DCA950] border-2'
                }`}
                required
                onChange={handleChange}
                name="zip_code"
                value={formData?.zip_code || ''}
              />
            </div>
            <div className="flex flex-col w-full lg:w-1/2">
              <label htmlFor="" className="text-gray-500">
                Država
              </label>
              <Select
                options={options}
                name="country"
                id="country"
                placeholder="Izaberi državu"
                styles={{
                  control: (provided, state) => ({
                    ...provided,
                    border: state.isFocused
                      ? '1px solid #DCA950'
                      : '1px solid #555555',
                    height: '56px',
                    display: 'flex',
                    alignItems: 'center',
                    paddingLeft: '0.75rem',
                    outline: '#DCA950',
                  }),
                  singleValue: (provided) => ({
                    ...provided,
                    color: '#555555',
                    fontSize: '15px',
                  }),
                  placeholder: (provided) => ({
                    ...provided,
                    color: '#9C9C9C',
                    fontSize: '15px',
                  }),
                  dropdownIndicator: (provided) => ({
                    ...provided,
                    color: '#DCA950',
                  }),
                  menu: (provided) => ({
                    ...provided,
                    zIndex: 9999,
                  }),
                }}
                required
                value={options.find(
                  (option) => option.value === formData?.country,
                )}
                onChange={(selectedOption) => {
                  setFormData({ ...formData, country: selectedOption.value })
                }}
              />
            </div>
          </div>
        </div>
        {/* Kontakt informacije */}
      </div>
      {/* Nacin plaćanja */}
      <div className="py-9 pb-14 border-b-2">
        <div className="flex flex-col gap-3">
          <h1 className="font-bold text-2xl">Način dostave</h1>
          <div
            className={`flex flex-row border items-center h-12 px-3 w-full ${
              formData.method_of_payment_id === 2 ? 'border-[#DCA950]' : ''
            }`}
          >
            <label className="flex gap-2 w-[85%] h-full items-center">
              <RadioGroup>
                <RadioGroup.Option checked={true}>
                  {() => (
                    <div
                      className={`text-[#DCA950] accent-[#DCA950] bg-[#DCA950] rounded-full p-2 border border-[]`}
                    >
                      <input type="radio" className="sr-only" checked={true} />
                    </div>
                  )}
                </RadioGroup.Option>
              </RadioGroup>
              Brza pošta
            </label>
            <label
              htmlFor=""
              className={`flex border-l w-[15%] items-center justify-center h-full ${
                formData.method_of_payment_id === 2
                  ? 'border-l-[#DCA950] border-l'
                  : ''
              }`}
            >
              {total >= shippingCost?.free_shipping ||
              totalWithCoupon >= shippingCost?.free_shipping
                ? 'Besplatna'
                : parseFloat(shippingCost?.shipping_cost).toFixed(2) + ' KM'}
            </label>
          </div>
        </div>
      </div>
      <div className="py-9 border-b-2">
        <div className="flex flex-col gap-3">
          <h1 className="font-bold text-2xl">Način plaćanja</h1>
          <div
            className={`flex flex-row border items-center h-12 px-3 w-full ${
              formData.method_of_payment_id === 2 ? 'border-[#DCA950]' : ''
            }`}
          >
            <label className="flex gap-2 w-[85%] h-full items-center">
              <RadioGroup
                value={formData.method_of_payment_id}
                onChange={(value) =>
                  setFormData((prev) => ({
                    ...prev,
                    method_of_payment_id: value,
                  }))
                }
              >
                <RadioGroup.Option value={2}>
                  {({ checked }) => (
                    <div
                      className={`text-[#DCA950] accent-[#DCA950] ${
                        checked ? 'bg-[#DCA950] text-white' : ''
                      } rounded-full p-2 border-2 border-[#c7c7c7c7]`}
                    >
                      <input type="radio" className="sr-only" />
                    </div>
                  )}
                </RadioGroup.Option>
              </RadioGroup>
              Plaćanje pouzećem
            </label>
          </div>
        </div>
      </div>
      <div className="flex flex-col py-8 gap-4">
        <h1 className="font-bold text-2xl">Napomena</h1>
        <textarea
          name="note"
          onChange={handleChange}
          value={formData?.note || ''}
          id="note"
          className="border mb-1 pt-1 h-24 pl-3 resize-none border-[#9C9C9C] outline-[#DCA950]"
          required
          title="Niste unijeli napomenu"
        />
        <h1 className="font-bold text-2xl">Kupon</h1>
        <div className="flex">
          <input
            name="coupon"
            type="text"
            id="coupon"
            className="border-y border-l h-14 pl-3 outline-[#DCA950] border-[#9C9C9C] w-fit"
            value={formData.coupon}
            onChange={(e) => {
              setFormData((formData) => ({
                ...formData,
                coupon: e.target.value,
              }))
            }}
            required
            title="Niste unijeli kupon"
          />

          <button
            disabled={
              (Array.isArray(bagArticles) &&
                bagArticles.length > 0 &&
                user &&
                (bagArticles.some((item) => item.article.discount > 0) ||
                  bagArticles[0]?.coupon_used === true)) ||
              (Array.isArray(cartOrderArticles) &&
              cartOrderArticles.length > 0 &&
              isCoupon === true
                ? true
                : false)
            }
            className="border-y border-r h-14 outline-[#DCA950] bg-[#DCA950] text-white items-center w-28 border-[#DCA950]"
            onClick={() => {
              handleCouponSubmit({ data })
            }}
          >
            Potvrdi
          </button>
        </div>

        <div className="flex flex-row mb-10 px-1 py-3">
          <input
            type="checkbox"
            id="checkbox_id"
            className="mr-2 accent-[#DCA950] text-white w-6 h-6"
            onChange={() => setIsCheckboxChecked(!isCheckboxChecked)}
          />
          <label htmlFor="checkbox_id" className="text-md text-[#9C9C9C]">
            Slažem se s uvjetima korištenja i odredbama
          </label>
        </div>
        <div className="flex justify-center my-2">
          <button
            id="checkoutFinish"
            className={`bg-black text-white w-full h-12${
              !isCheckboxChecked
                ? 'opacity-50 cursor-not-allowed h-12 bg-gray-600'
                : ''
            }`}
            onClick={handleFinishCheckout}
            disabled={!isCheckboxChecked || isLoading}
          >
            {'Završi kupovinu >'}
          </button>
        </div>
      </div>
    </div>
  )
}

export default OsnovneInformacije
