import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useGetSuggestedArticlesQuery } from '../../api/services/articleApi'

const Recommended = () => {
  const navigate = useNavigate()

  const { data: suggestedArticles } = useGetSuggestedArticlesQuery('NICHE')

  const handleClick = () => {
    navigate('/niche')
  }

  return (
    <div className="px-2 md:px-14 bg-slate-50">
      <p className="text-center font-semibold md:font-bold text-2xl py-3">
        Preporučeno
      </p>
      <div className="grid grid-cols-2 gap-4 lg:flex md:w-full md:h-full items-center justify-center">
        {suggestedArticles?.map((item, index) => {
          return (
            <div
              key={index}
              className="flex flex-col w-full h-full md:py-10 transition-transform transform hover:scale-105 duration-500"
            >
              <Link to={`/article/${item.article_id}`}>
                <img
                  src={item?.article_images[0]?.url || ''}
                  alt=""
                  className="w-full h-full  border object-cover rounded-xl"
                />
              </Link>
              <div className="text-sm md:text-base font-medium md:font-semibold py-3 text-center">
                <p>{item?.article_name}</p>
                {/* <p className="bg-orange-100 rounded-xl text-orange-600 inline-block px-2">
                //   BAM: {item?.price}
                </p> */}
                {item?.discount && item?.discount > 0 ? (
                  <div>
                    <p className="text-[10px] md:text-[13px] text-gray-400 line-through ">
                      {item.price} KM
                    </p>
                    <p className="bg-red-100 rounded-xl text-[15px] text-red-600 inline-block px-2">
                      {item.discount_price} KM
                    </p>
                  </div>
                ) : (
                  <p className="bg-blue-100 rounded-xl text-blue-600 inline-block px-2">
                    {item?.price} KM
                  </p>
                )}
              </div>
            </div>
          )
        })}
      </div>
      <div className="flex items-center justify-center p-2 mt-4 md:mt-0 md:p-8 pb-3">
        <button
          onClick={handleClick}
          className="border border-black text-xs md:text-base px-2 py-1 md:px-4 md:py-2 rounded-lg transition duration-300 ease-in-out hover:bg-gray-200  font-semibold text-gray-500"
        >
          Pogledaj sve proizvode
        </button>
      </div>
    </div>
  )
}

export default Recommended
