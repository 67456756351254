import { api } from '../api'

export const shippingApi = api.injectEndpoints({
  endpoints: (builder) => ({
    updateShippingCost: builder.mutation({
      query: (body) => ({
        url: 'api/v1/configurators/',
        body,
        method: 'PUT',
      }),
      invalidatesTags: ['ShippingCost'],
    }),
    // updateFreeShipping: builder.mutation({
    //   query: (body) => ({
    //     url: 'api/v1/configurators',
    //     body,
    //     method: 'PUT',
    //   }),
    //   invalidatesTags: ['ShippingCost'],
    // }),
    // getFreeShipping: builder.query({
    //   query: () => ({
    //     url: `api/v1/configurators`,
    //   }),
    //   providesTags: ['ShippingCost'],
    // }),
    getShippingCost: builder.query({
      query: () => ({
        url: `api/v1/configurators`,
      }),
      providesTags: ['ShippingCost'],
    }),
    updatePopupImage: builder.mutation({
      query: (body) => ({
        url: 'api/v1/configurators',
        body,
        method: 'PUT',
      }),
    }),
  }),
})

export const {
  useUpdateShippingCostMutation,
  useGetShippingCostQuery,
  useUpdatePopupImageMutation,
  // useGetFreeShippingQuery,
  // useUpdateFreeShippingMutation,
} = shippingApi
