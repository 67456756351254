import { createSlice } from '@reduxjs/toolkit'

// Pokušajte da uzmete vrednosti iz local storage-a
const token = localStorage.getItem('token')
const user = localStorage.getItem('user')
const bag = localStorage.getItem('bag')

const initialState = {
  isAuthenticated: token !== null, // Proverava da li postoji token
  user: JSON.parse(user) || null, // Pretvara JSON string u objekat
  bag: JSON.parse(bag) || null, // Pretvara JSON string u objekat
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    signIn: (state, action) => {
      state.isAuthenticated = true
      state.user = action.payload
    },
    logout: (state) => {
      state.isAuthenticated = false
      state.user = null

      // Dodajte ovde logiku za brisanje iz local storage-a
      localStorage.removeItem('token')
      localStorage.removeItem('user')
      localStorage.removeItem('bag')
    },
    setBag: (state, action) => {
      state.bag = action.payload
    },
  },
})

export const { signIn, logout, setBag } = authSlice.actions
export default authSlice.reducer
