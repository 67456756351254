import React, { useState } from 'react'
import { FaTrash } from 'react-icons/fa'
import { FiEdit } from 'react-icons/fi'

const VolumesActionModal = ({ setOpenEdit, setOpenDelete }) => {
  return (
    <>
      <div>
        {/* Background overlay */}
        {/* <div className="fixed inset-0 bg-black opacity-50"></div> */}

        {/* Modal */}
        <div className="flex flex-col font-medium rounded-lg bg-blue-500 text-white justify-start items-start w-36 h-28 p-2 gap-2">
          <p>Akcije</p>
          <div className="flex flex-col gap-1 w-full">
            <button
              className="flex items-center gap-4 hover:bg-white hover:text-black p-1 rounded"
              onClick={() => setOpenEdit(true)}
            >
              <FiEdit /> Uredi
            </button>

            <button
              className="flex items-center gap-4 hover:bg-white hover:text-black p-1 rounded"
              onClick={() => setOpenDelete(true)}
            >
              {' '}
              <FaTrash />
              Izbriši
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

export default VolumesActionModal
