import React, { useMemo, useState } from 'react'
import { AiFillStar, AiOutlineStar } from 'react-icons/ai'
import { toast } from 'react-toastify'
import { formatDistanceToNow } from 'date-fns'
import { hr } from 'date-fns/locale'
import { BsChatSquareText } from 'react-icons/bs'
import { useReviewPaginatedArticlesQuery } from '../../api/services/articleApi'
import useScrollQuery from '../../hooks/useScrollQuery'
import { useSelector } from 'react-redux'

const ReviewsOld = ({ data, user_id, setRatingMess }) => {
  const user = useSelector((state) => state.auth.user)
  const article_id = data?.article_id

  const [page, setPage] = useState(1)
  const { data: reviews } = useReviewPaginatedArticlesQuery({
    article_id: article_id,
    page: page,
    page_size: 5,
  })

  const { data: allReviews } = useScrollQuery(reviews, page)

  const reviewCount = data?.reviewedByUsers?.reduce((total) => {
    return total + 1
  }, 0)

  const user_ids_from_data =
    data?.reviewedByUsers?.map((item) => item?.user_id) || []
  const isUserMatch = user_ids_from_data?.includes(user_id)
  return (
    <div className="border bg-gray-50 border-b border-gray-300 pb-4 rounded-lg p-4 mt-2">
      <h2 className="py-5 text-sm font-medium">RECENZIJE</h2>
      <div className="flex flex-col md:flex-row md:items-center md:justify-between pb-4">
        <div className="flex items-center">
          {Array.from({ length: 5 })?.map((_, index) => (
            <span key={index}>
              <AiFillStar fill="#FFD700" className="w-4 h-4 md:w-5 md:h-5" />
            </span>
          ))}
          <p className="px-2">{reviewCount} recenzcije</p>
        </div>
        {user ? (
          <span
            className="flex items-center gap-2 cursor-pointer"
            onClick={() =>
              isUserMatch
                ? toast.error('Vec ste dodali recenziju!')
                : setRatingMess(true)
            }
          >
            <BsChatSquareText />
            Napiši recenziju
          </span>
        ) : null}
      </div>
      <div className="flex flex-col gap-4 pt-4 header max-h-80 overflow-y-auto">
        {Object.values(allReviews)?.map((item) => {
          return item?.articles[0]?.reviewedByUsers?.map((item) => {
            const numberOfStars = item?.reviews?.review
            const stars = Array.from({ length: 5 }).map((_, index) => (
              <span key={index}>
                {index < numberOfStars ? (
                  <AiFillStar fill="#FFD700" />
                ) : (
                  <AiOutlineStar />
                )}
              </span>
            ))

            return (
              <>
                <div className="flex flex-col text-sm md:text-base">
                  <div className="flex justify-between">
                    <span>
                      {item?.first_name} {item?.last_name}
                    </span>
                    <span className="flex items-center gap-2">
                      {item && item?.updated_at ? (
                        <span>
                          {formatDistanceToNow(
                            new Date(item?.reviews?.updated_at),
                            {
                              addSuffix: true,
                              locale: hr,
                            },
                          )}
                        </span>
                      ) : (
                        ''
                      )}

                      <span className="flex">{stars}</span>
                    </span>
                  </div>
                  <span className="px-2 md:px-6 text-xs md:text-sm">
                    {item?.reviews?.comment}
                  </span>
                </div>
              </>
            )
          })
        })}
        <div>
          <button
            onClick={() => setPage((prev) => prev + 1)}
            className="cursor-pointer disabled:cursor-not-allowed"
            disabled={page === reviews?.total_pages}
          >
            {page === reviews?.total_pages || reviews?.total_count === 0
              ? ''
              : 'Prikazi vise'}
          </button>
        </div>
      </div>
    </div>
  )
}

export default ReviewsOld
