import * as L from 'leaflet'
import 'leaflet/dist/leaflet.css'
import { useEffect, useMemo, useRef } from 'react'
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet'
import iconcustom from '../img/location-pin.png'

function ClientVisitMap({ setSelectedPopup, selectedPopup }) {
  const markerRef = useRef()
  const markerRefs = useRef([])
  markerRefs.current = []

  const markeri = [
    {
      i: 1,
      cords: [44.5323186, 18.651181],
      name: 'Tuzla',
      mall: 'Bingo City Center',
      url: `https://www.google.com/maps/dir//44.5323186,18.651181`,
    },
    {
      i: 2,
      cords: [44.525256, 18.6279961],
      name: 'Tuzla 2',
      mall: 'TC Belamionix',
      url: `https://www.google.com/maps/dir//44.525256,18.6279961`,
    },
    {
      i: 3,
      cords: [43.8562513, 18.4062084],
      name: 'Sarajevo',
      mall: 'Alta Shopping Center',
      url: `https://www.google.com/maps/dir//43.9002924,18.3941637`,
    },
    {
      i: 4,
      cords: [44.6854222, 18.3085725],
      name: 'Gračanica',
      mall: 'TC Etna',
      url: `https://www.google.com/maps/dir//44.6854222,18.3085725`,
    },
    {
      i: 5,
      cords: [44.2037965, 17.9121505],
      name: 'Zenica',
      mall: 'TC Džananović',
      url: `https://www.google.com/maps/dir//44.2037965,17.9121505`,
    },
    {
      i: 6,
      cords: [44.6761915, 18.0153877],
      name: 'Tešanj',
      mall: 'Mega Mall',
      url: `https://www.google.com/maps/dir//44.6761915,18.0153877`,
    },
    {
      i: 7,
      cords: [43.3084197, 17.8254834],
      name: 'Mostar',
      mall: 'Plaza Shopping Centar',
      url: `https://www.google.com/maps/dir//43.3063044,17.828444`,
    },
  ]

  const position1 = [43.8389, 18.2647]
  const mapStyle = {
    zIndex: 0,
  }

  var myIcon = L.icon({
    iconUrl: iconcustom,
    iconSize: [30, 30],
    // iconAnchor: [22, 94],
    // popupAnchor: [-3, -76],
    // shadowUrl: 'my-icon-shadow.png',
    // shadowSize: [68, 95],
    // shadowAnchor: [22, 94]
  })

  const areEqual = useMemo(() => {
    return Object.entries(markerRef?.current || {})?.length === markeri.length
  }, markerRef.current)

  useEffect(() => {
    setSelectedPopup(markerRefs.current)
  }, [])
  return (
    <div className="px-0.5">
      <MapContainer
        center={position1}
        zoom={8}
        className="md:h-[600px] monitor:h-[600px]  h-[600px]"
      >
        {/* <TileLayer
          attribution=' <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://tiles.stadiamaps.com/tiles/alidade_smooth/{z}/{x}/{y}.png"
        /> */}
        <TileLayer
          attribution=' <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {markeri?.map((mark, index) => {
          return (
            <Marker
              ref={(el) => {
                if (el && !markerRefs.current.includes(el)) {
                  markerRefs.current.push({
                    [`${mark.name}, ${mark.mall}`]: el,
                  })
                }
                // console.log(markerRefs.current)
              }}
              // ref={(element) => {
              //   // if (markerRef.current) {

              //   const newCurrent = {
              //     ...markerRef.current,
              //     [`${mark.name}, ${mark.mall}`]: element,
              //   }
              //   console.log('nc', newCurrent)
              //   markerRef.current = newCurrent
              //   // console.log('mr', markerRef.current)

              //   // setRefs((prev) => [...prev, element])
              //   // }
              // }}
              key={index}
              className="rounded-full"
              icon={myIcon}
              position={mark?.cords}
            >
              <Popup>
                <p className="text-xl font-luxor">GRAD: {mark?.name}</p>
                <p className="text-lg font-luxorthin">{mark?.mall}</p>
                <p className="text-lg font-luxorthin">
                  Radno vrijeme: 9:00 - 21:00
                </p>
                <a href={mark?.url} target="_blank">
                  <div className="bg-black text-white cursor-pointer p-4">
                    Prikaži na Google Maps
                  </div>
                </a>
              </Popup>
            </Marker>
          )
        })}
      </MapContainer>

      {markerRefs.current.map((element) => {
        // const onClickShowMarker = () => {
        //   element[1].openPopup()
        // }
        // return (
        //   <button className="font-luxor text-xl" onClick={onClickShowMarker}>
        //     {element[0]}
        //   </button>
        // )
      })}
    </div>
  )
}

export default ClientVisitMap
