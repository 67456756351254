import React, { useEffect, useState } from 'react'
import { AiOutlineClose } from 'react-icons/ai'
import {
  useGetOneVolumeQuery,
  useUpdateVolumeMutation,
} from '../../api/services/volumesApi'
import Select from 'react-select'
import { useGetAllArticleGroupsQuery } from '../../api/services/articleGroup'
import { toast } from 'react-toastify'

const EditVolumeModal = ({ volume_id, setOpenEdit }) => {
  const { data: articleGropus } = useGetAllArticleGroupsQuery()
  const [updateVolume] = useUpdateVolumeMutation()
  const { data: oneVolume } = useGetOneVolumeQuery(volume_id)

  const [total, setTotal] = useState('')
  const [selectedOptions, setSelectedOptions] = useState([])

  const handleChange = (selected) => {
    setSelectedOptions(selected)
  }

  useEffect(() => {
    if (oneVolume) {
      setTotal(oneVolume.total)
      setSelectedOptions(
        oneVolume?.article_groups?.map((item) => ({
          label: item.article_group_name,
          value: item.article_group_id,
        })),
      )
    }
  }, [oneVolume])

  const groupsOptions = articleGropus?.map((group) => {
    return {
      value: group.article_group_id,
      label:
        group.article_group_name.charAt(0).toUpperCase() +
        group.article_group_name.slice(1).toLowerCase(),
    }
  })

  const handleUpdate = () => {
    const volumeData = {
      total: total,
      article_groups: selectedOptions?.map((item) => item?.value),
      volume_id: volume_id,
    }

    updateVolume(volumeData)
    toast.success('Uspješna izmjena!')
    setOpenEdit(false)
  }

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      {/* Background overlay */}
      <div className="fixed inset-0 bg-black opacity-50"></div>

      {/* Modal */}
      <div className="flex flex-col rounded-lg m-10 w-2/3 md:w-1/3 bg-white z-10 space-y-3 py-4">
        <button
          className="flex justify-end p-2"
          onClick={() => setOpenEdit(false)}
        >
          <AiOutlineClose />
        </button>
        <div className="flex start px-4">
          <Select
            isMulti
            name="groups"
            options={groupsOptions}
            className="basic-multi-select"
            classNamePrefix="select"
            placeholder="Odaberi Grupu"
            value={selectedOptions}
            onChange={handleChange}
          />
        </div>
        <div className="flex start px-4"></div>
        <div className="flex flex-col gap-2">
          <h2 className="flex justify-start font-medium text-lg px-4">
            Količina
          </h2>
          <input
            type="number"
            className="mx-3 border-blue-400 border-2 rounded h-12 px-2"
            onChange={(e) => setTotal(e.target.value)}
            value={total || ''}
          />
        </div>

        <button
          className="mx-3 rounded border-black hover: bg-blue-400 h-8 text-white font-bold"
          onClick={handleUpdate}
        >
          Uredi
        </button>
      </div>
    </div>
  )
}

export default EditVolumeModal
