import { useSelector } from 'react-redux'
import { Navigate, Outlet } from 'react-router-dom'

const ProtectedAdmin = () => {
  const user = useSelector((state) => state.auth.user)

  return user?.is_admin !== 'Y' ? <Navigate to="/" /> : <Outlet />
}

export default ProtectedAdmin
