import { useSelector } from 'react-redux'
import { Navigate, Outlet } from 'react-router-dom'

const ProtectedCustomer = () => {
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated)

  return isAuthenticated === false ? <Navigate to="/" /> : <Outlet />
}

export default ProtectedCustomer
