import React, { useEffect, useRef, useState, memo } from 'react'
import {
  useGetArticleByGroupMutation,
  useGetArticleByGroupQuery,
} from '../../api/services/articleApi'
import { useSelector } from 'react-redux'
import { AiOutlineLeft, AiOutlineRight } from 'react-icons/ai'
import ProductCard from '../selective/ProductCard'
import ArticleCard from '../homePage/ArticleCard'
import { useGetAllArticleGroupsQuery } from '../../api/services/articleGroup'
import PerfumeTypeTitle from '../homePage/PerfumeTypeTitle'

const SimilarProducts = ({ data, article_group_id, article_id }) => {
  const user = useSelector((state) => state.auth.user)
  const [currentPage, setCurrentPage] = useState(1)

  const { data: similarProducts } = useGetArticleByGroupQuery({
    article_group_id: article_group_id,
    user_id: user?.user_id,
    sort_by: '',
    page: currentPage,
    page_size: 10,
  })
  const uniqueArticleGroup = article_group_id
  const { data: articleGroups } = useGetAllArticleGroupsQuery()
  const articleGroupName = articleGroups?.find(
    (item) => item?.article_group_id === data?.article_group_id,
  )

  const containerRef = useRef(null)

  function smoothHorizontalScroll(container, targetScrollLeft, duration) {
    const startScrollLeft = container.scrollLeft
    const startTime = performance.now()

    function step(currentTime) {
      const elapsedTime = currentTime - startTime

      if (elapsedTime < duration) {
        const progress = elapsedTime / duration
        const newScrollLeft =
          startScrollLeft + (targetScrollLeft - startScrollLeft) * progress
        container.scrollLeft = newScrollLeft
        requestAnimationFrame(step)
      } else {
        container.scrollLeft = targetScrollLeft
      }
    }

    requestAnimationFrame(step)
  }
  const container = containerRef.current
  const scrollAmount = 200

  function handleScroll(direction) {
    const currentScrollLeft = container.scrollLeft
    const targetScrollLeft =
      direction === 'left'
        ? Math.max(currentScrollLeft - scrollAmount, 0)
        : currentScrollLeft + scrollAmount

    smoothHorizontalScroll(container, targetScrollLeft, 500)
  }

  const filteredSimilarProducts = similarProducts?.articles.filter(
    (product) => product.article_id !== article_id,
  )

  return (
    <>
      <div
        className="monitor:px-[140px] md:px-[100px] px-[35px]
      "
      >
        <PerfumeTypeTitle
          text={articleGroupName?.article_group_name}
          fontsize={20}
        />
        <div className="flex flex-row gap-3">
          <p className="font-luxor md:text-[45px] text-3xl leading-10">
            Slični artikli
          </p>
        </div>
        <p className="text-[#C2C2C2] md:text-xl text-lg font-luxorthin">
          Lorem Ipsum is simply dummy text of the printing industry and pricing.
        </p>
      </div>
      <div
        className="md:flex md:flex-row md:gap-5 grid grid-cols-2 gap-x-5 gap-y-[30px] monitor:px-[140px] md:px-[100px] px-[35px] pt-10 w-full overflow-hidden "
        ref={containerRef}
      >
        {filteredSimilarProducts?.slice(0, 4).map((item) => {
          return (
            <ArticleCard
              item={item}
              parfume_type={articleGroupName?.article_group_name}
            />
          )
        })}
      </div>
    </>
  )
}

export default memo(SimilarProducts)
