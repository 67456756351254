import React from 'react'
import PerfumeTypeTitle from './PerfumeTypeTitle'
import ArticleCard from './ArticleCard'
import { useGetSuggestedArticlesQuery } from '../../api/services/articleApi'

function SelectiveSection() {
  const { data: allSuggested } = useGetSuggestedArticlesQuery('Selective')
  return (
    <>
      <div
        className="monitor:px-[140px] md:px-[100px] px-[35px] py-10 md:py-0
      "
      >
        <div className="hidden md:inline-block">
          <PerfumeTypeTitle text={'Za nju i njega'} fontsize={20} />
        </div>
        <div className=" md:hidden">
          <PerfumeTypeTitle text={'Za nju i njega'} fontsize={12} />
        </div>
        <div className="flex flex-row gap-3">
          <p className="font-luxor md:text-[45px] text-2xl leading-10">
            Odabrana Selective kolekcija
          </p>
          {/* <p
            className="hover:underline text-[#DCA950] text-xl font-luxorlight cursor-pointer pt-3.5"
            onClick={() => {
              navigate('/ambijentalni-mirisi')
            }}
          >
            Prikaži sve
          </p> */}
        </div>
        <p className="text-[#C2C2C2] md:text-xl text-sm font-luxorthin">
          Lorem Ipsum is simply dummy text of the printing industry and pricing.
        </p>
      </div>
      <div className="md:flex md:flex-row md:justify-between monitor:px-[140px] grid grid-cols-2 gap-x-5 gap-y-[30px] md:px-[100px] px-[35px] md:pt-10 pt-2 w-full overflow-hidden">
        {allSuggested?.slice(0, 4).map((item, index) => (
          <ArticleCard item={item} index={index} parfume_type={'Selective'} />
        ))}
      </div>
    </>
  )
}

export default SelectiveSection
