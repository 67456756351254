import React, { useState } from 'react'
import { AiOutlineClose } from 'react-icons/ai'
import {
  useAddVolumeMutation,
  useGetVolumesByArticleGroupQuery,
} from '../../api/services/volumesApi'
import { useGetAllArticleGroupsQuery } from '../../api/services/articleGroup'
import Select from 'react-select'
import { toast } from 'react-toastify'

const AddVolumeModal = ({ setAddVolume }) => {
  const [createVolume] = useAddVolumeMutation()

  const [total, setTotal] = useState('')
  const [selectedOptions, setSelectedOptions] = useState([])

  const { data: articleGropus } = useGetAllArticleGroupsQuery()

  const groupsOptions = articleGropus?.map((group) => {
    return {
      value: group.article_group_id,
      label:
        group.article_group_name.charAt(0).toUpperCase() +
        group.article_group_name.slice(1).toLowerCase(),
    }
  })

  //   const { data: volumesByGroup } = useGetVolumesByArticleGroupQuery()

  const handleCreate = () => {
    const volumeData = {
      total: total,
      article_groups: selectedOptions?.map((item) => item?.value),
    }
    createVolume(volumeData)
    toast.success('Uspješno dodano!')
    setAddVolume(false)
  }

  const handleChange = (selected) => {
    setSelectedOptions(selected)
  }

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      {/* Background overlay */}
      <div className="fixed inset-0 bg-black opacity-50"></div>

      {/* Modal */}
      <div className="flex flex-col rounded-lg m-10 w-2/3 md:w-1/3 bg-white z-10 space-y-3 py-4">
        <button
          className="flex justify-end p-2"
          onClick={() => setAddVolume(false)}
        >
          <AiOutlineClose />
        </button>
        <div className="flex start px-4">
          <Select
            defaultValue={groupsOptions}
            isMulti
            name="groups"
            options={groupsOptions}
            className="basic-multi-select"
            classNamePrefix="select"
            placeholder="Odaberi Grupu"
            value={selectedOptions}
            onChange={handleChange}
          />
        </div>
        <div className="flex flex-col gap-2">
          <h2 className="flex justify-start font-medium text-lg px-4">
            Količina
          </h2>
          <input
            type="number"
            className="mx-3 border-blue-400 border-2 rounded h-12 px-2"
            onChange={(e) => setTotal(e.target.value)}
            value={total || ''}
          />
        </div>

        <button
          className="mx-3 rounded border-black hover: bg-blue-400 h-8 text-white font-bold"
          onClick={handleCreate}
        >
          Dodaj
        </button>
      </div>
    </div>
  )
}

export default AddVolumeModal
