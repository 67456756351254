import React, { useMemo, useState } from 'react'
import { AiFillStar, AiOutlineStar } from 'react-icons/ai'
import { toast } from 'react-toastify'
import { formatDistanceToNow } from 'date-fns'
import { hr } from 'date-fns/locale'
import { BsChatSquareText } from 'react-icons/bs'
import { useReviewPaginatedArticlesQuery } from '../../api/services/articleApi'
import useScrollQuery from '../../hooks/useScrollQuery'
import { useSelector } from 'react-redux'
import CreateReview from './CreateReview'

const Reviews = ({ data, user_id, setRatingMess, rating, setRating }) => {
  const user = useSelector((state) => state.auth.user)
  const article_id = data?.article_id
  const [currIndex, setCurrIndex] = useState(0)

  const [page, setPage] = useState(1)
  const { data: reviews } = useReviewPaginatedArticlesQuery({
    article_id: article_id,
    page: page,
    page_size: 20,
  })

  const { data: allReviews } = useScrollQuery(reviews, page)

  const reviewCount = data?.reviewedByUsers?.reduce((total) => {
    return total + 1
  }, 0)

  const user_ids_from_data =
    data?.reviewedByUsers?.map((item) => item?.user_id) || []
  const isUserMatch = user_ids_from_data?.includes(user_id)

  const handleIncreaseCurr = () => {
    if (currIndex === reviews?.total_count - 1) {
      setCurrIndex(0)
    } else {
      const newIndex = currIndex + 1
      setCurrIndex(newIndex)
    }
  }
  return (
    <>
      <div className="w-full h-[77px] flex flex-row justify-start items-center p-6 bg-[#FEFBF6]  mb-[35px]">
        <span className="font-luxor text-2xl">RECENZIJE ({reviewCount})</span>
      </div>
      <div className=" border  mt-2">
        <div className="flex flex-col   md:items-center md:justify-between pb-4">
          <div className="flex flex-row justify-between gap-4 pl-6 border-b w-full h-[130px] max-h-80 ">
            {Object.values(allReviews)?.map((item) => {
              const currentReview = [
                item?.articles[0]?.reviewedByUsers[currIndex],
              ]

              return currentReview.map((item) => {
                const numberOfStars = item?.reviews?.review

                const stars = Array.from({ length: 5 })?.map((_, index) => (
                  <div className="flex items-center justify-center">
                    <span key={index}>
                      {index < numberOfStars ? (
                        <AiFillStar
                          fill="#DCA950"
                          className="w-3 h-3 md:w-7 md:h-7"
                        />
                      ) : !numberOfStars ? (
                        ''
                      ) : (
                        <AiOutlineStar
                          fill="#DCA950"
                          className="w-3 h-3 md:w-7 md:h-7"
                        />
                      )}
                    </span>
                  </div>
                ))

                return (
                  <>
                    <div className="flex flex-col text-sm md:text-base font-luxorthin">
                      <div className="flex justify-start gap-2">
                        <span className="flex justify-start items-center gap-2 ">
                          <div className="flex flex-col md:items-center items-start justify-start">
                            {!numberOfStars && (
                              <div className="md:text-2xl text-xl font-luxorthin text-[#C2C2C2] mx-auto pt-4">
                                Trenutno nema recenzija za ovaj proizvod...
                              </div>
                            )}
                            <span className="flex pt-4">{stars}</span>
                            {item && item?.updated_at ? (
                              <span className="md:text-sm text-xs text-[#C2C2C2]">
                                {formatDistanceToNow(
                                  new Date(item?.reviews?.updated_at),
                                  {
                                    addSuffix: true,
                                    locale: hr,
                                  },
                                )}
                              </span>
                            ) : (
                              ''
                            )}
                          </div>
                          <p className="font-luxorthin md:text-[24px] text-md md:ml-2 ">
                            {numberOfStars?.toFixed(1)}
                          </p>
                          <span className="md:text-[24px] text-lg">
                            {item?.first_name} {item?.last_name}
                          </span>
                        </span>
                      </div>
                      <span className="mt-2 md:pl-1 max-w-[95%] md:text-[20px] text-lg text-[#C2C2C2] overflow-y-scroll scrollbar-hide">
                        {item?.reviews?.comment}
                      </span>
                    </div>
                  </>
                )
              })
            })}
            <div>
              <button
                onClick={handleIncreaseCurr}
                className="cursor-pointer bg-[#9C9C9C] h-full w-[53px] flex justify-center items-center"
              >
                <svg
                  height="16"
                  width="10"
                  fill="none"
                  viewBox="0 0 6 11"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0.447959 9.65454L4.94971 5.68225L0.447959 1.2877"
                    stroke="white"
                  />
                </svg>
              </button>
            </div>
          </div>
          {!user ? (
            <div className="font-luxorthin monitor:text-xl md:text-lg text-xs px-6 md:px-0 text-[#C2C2C2] max-h-6 mt-4">
              Prijavite se na vaš korisnički nalog da biste napisali recenziju!
            </div>
          ) : isUserMatch ? (
            <div className="font-luxorthin text-xl text-[#C2C2C2] pl-6 md:pl-0 max-h-6 mt-4">
              Već ste dodali recenziju!
            </div>
          ) : (
            <CreateReview
              setRatingMess={setRatingMess}
              isUserMatch={isUserMatch}
              rating={rating}
              article_id={data?.article_id}
              setRating={setRating}
            />
          )}
        </div>
      </div>
    </>
  )
}

export default Reviews
