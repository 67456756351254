import React from 'react'

function GlobalDelete({ handleDelete, handleCancel }) {
  return (
    <div className="fixed inset-0 flex justify-center items-center bg-black/60 z-10 p-10 md:p-0">
      <div className="text-xl border border-gray-500 max-w-xl mx-auto bg-white rounded-xl relative">
        <h2 className="border-b-2 border-gray-400 shadow rounded p-2">
          Obriši ?
        </h2>

        <div className="flex justify-center px-16 py-4">
          <p className="text-gray-600 py-6">
            Da li ste sigurni da želite obrisati?
          </p>
        </div>
        <div className="flex justify-center gap-4 p-4 bg-gray-200 rounded-b-xl text-lg">
          <button
            className="border-black px-3 py-1 rounded bg-blue-500 text-white hover:bg-blue-600 hover:text-white"
            onClick={handleCancel}
          >
            Ne
          </button>
          <button
            className="border-black px-2 py-1 rounded bg-blue-500 text-white hover:bg-blue-600 hover:text-white"
            onClick={handleDelete}
          >
            Obriši
          </button>
        </div>
      </div>
    </div>
  )
}

export default GlobalDelete
