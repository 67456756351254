import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import Select from 'react-select'
import {
  useEditUserMutation,
  useGetOneUserQuery,
} from '../../api/services/usersApi'
import { countries } from '../../data/countries'
import { toast } from 'react-toastify'

function UserProfile() {
  const user = useSelector((state) => state.auth.user)
  const { data: UserData } = useGetOneUserQuery(user?.user_id)
  const [updateUser] = useEditUserMutation()

  const handleUpdate = (e) => {
    e.preventDefault()

    updateUser({
      ...formData,
      user_id: UserData?.user_id,
    })
      .then((res) => {
        toast.success(res?.data?.message)
      })
      .catch((error) => {
        toast.error('Greška pri ažuriranju korisnika:', error)
      })
  }
  const [formData, setFormData] = useState({
    first_name: user?.first_name || '',
    last_name: user?.last_name || '',
    email: user?.email || '',
    address: user?.address || '',
    country: user?.country || '',
    zip_code: user?.zip_code || '',
    city: user?.city || '',
    phone_number: user?.phone_number || '',
  })

  const countryOptions = countries.map((item) => ({
    value: item.name,
    label: item.name,
  }))

  useEffect(() => {
    if (UserData) {
      setFormData({
        first_name: UserData.first_name || '',
        last_name: UserData.last_name || '',
        email: UserData.email || '',
        address: UserData.address || '',
        country: UserData.country || '',
        zip_code: UserData.zip_code || '',
        city: UserData.city || '',
        phone_number: UserData?.phone_number || '',
      })
    }
  }, [UserData])

  const handleImageChange = (e) => {
    const file = e.target.files[0]
    if (file) {
      const FD = new FormData()

      for (const key in UserData) {
        if (UserData.hasOwnProperty(key)) {
          FD.append(key, UserData[key])
        }
      }

      FD.append('image', file)

      updateUser(FD)
    }
  }

  const handleImageDelete = () => {
    updateUser({
      ...formData,
      user_id: UserData?.user_id,
      profile_image: ' ',
    })
  }

  return (
    <div className="p-8 lg:p-8 flex flex-col gap-8 items-center w-full ">
      <div className="border border-gray-100 w-full flex flex-col py-2">
        <div className="flex flex-col gap-5 md:gap-0 bg-white shadow-2xl p-10 w-full flex-wrap ">
          <div className="flex text-[30px] font-luxorlight pb-3">
            <h1>Korisnička slika</h1>
          </div>
          <div className="flex flex-col lg:flex-row border border-[#555555] p-2 gap-5 items-center">
            <div className="flex w-28 h-28">
              <img
                className="border  w-[112px] h-[112px]"
                alt=""
                src={UserData?.profile_image || ''}
              />
            </div>
            <div className="flex flex-col gap-1 w-[200px]">
              <div className="flex gap-2 font-luxor text-[25px]">
                <span>{UserData?.first_name}</span>
                <span>{UserData?.last_name}</span>
              </div>{' '}
              <div className="font-luxorlight text-[15px] text-[#9C9C9C]">
                <p>{UserData?.email}</p>
                <p>{UserData?.phone_number}</p>
              </div>
              <div className="font-luxorlight text-[15px]">
                <button onClick={handleImageDelete} className="text-red-500">
                  Izbriši sliku
                </button>
              </div>
            </div>
            <div className="flex flex-row-reverse w lg:w-[65%] monitor:w-[75%] text-white items-end font-luxorlight text-[18px] pb-4">
              <div className="mx-2">
                <label
                  htmlFor="file-upload"
                  className="bg-[#DCA950] hover:cursor-pointer h-[50px] w-[240px] py-3 px-6"
                >
                  <input
                    id="file-upload"
                    type="file"
                    accept="image/*"
                    className="hidden hover"
                    onChange={handleImageChange}
                  />
                  Postavi sliku profila
                </label>
              </div>
            </div>
          </div>
        </div>
        <form onSubmit={handleUpdate} className="flex w-full">
          <div className="flex flex-col gap-10 bg-white shadow-2xl border-gray-300 px-10 w-full">
            <div className="flex">
              <p className="text-[30px] font-luxorlight">
                Promjeni korisničke informacije
              </p>
            </div>
            <div className="lg:border border-[#555555] mb-10 font-luxorlight">
              <div className="grid lg:grid-cols-4 grid-cols-1 flex-wrap mx-2 gap-5 my-4">
                <div className="flex flex-col gap-2">
                  <label
                    htmlFor="first_name"
                    className="text-[#9C9C9C] text-[15px]"
                  >
                    Ime
                  </label>
                  <input
                    id="first_name"
                    type="text"
                    name="first_name"
                    className="border border-[#555555] h-12 items-center px-3 outline-[#DCA950]"
                    value={formData.first_name}
                    onChange={(e) =>
                      setFormData({ ...formData, first_name: e.target.value })
                    }
                  />
                </div>
                <div className="flex flex-col gap-2">
                  <label
                    htmlFor="first_name"
                    className="text-[#9C9C9C] text-[15px]"
                  >
                    Prezime
                  </label>
                  <input
                    id="last_name"
                    type="text"
                    name="last_name"
                    className="border border-[#555555] h-12 items-center px-3 outline-[#DCA950]"
                    value={formData.last_name}
                    onChange={(e) =>
                      setFormData({ ...formData, last_name: e.target.value })
                    }
                  />
                </div>
                <div className="flex flex-col gap-2">
                  <label
                    htmlFor="phone_number"
                    className="text-[#9C9C9C] text-[15px]"
                  >
                    Broj telefona
                  </label>
                  <input
                    id="phone_number"
                    type="text"
                    name="phone_number"
                    className="border border-[#555555] h-12 items-center px-3 outline-[#DCA950]"
                    value={formData?.phone_number || ''}
                    onChange={(e) =>
                      setFormData({ ...formData, phone_number: e.target.value })
                    }
                  />
                </div>
                <div className="flex flex-col gap-2">
                  <label htmlFor="email" className="text-[#9C9C9C] text-[15px]">
                    Email adresa
                  </label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    className="border border-[#555555] h-12 items-center px-3 outline-[#DCA950]"
                    value={formData?.email || ''}
                    onChange={(e) =>
                      setFormData({ ...formData, email: e.target.value })
                    }
                  />
                </div>
              </div>
              <div className="grid lg:grid-cols-4 grid-cols-1 flex-wrap mx-2 gap-5 mt-3">
                <div className="flex flex-col gap-2">
                  <label
                    htmlFor="zip_code"
                    className="text-[#9C9C9C] text-[15px]"
                  >
                    Poštanski broj / ZIP
                  </label>
                  <input
                    id="zip_code"
                    type="text"
                    name="zip_code"
                    className="border border-[#555555] h-12 items-center px-3 outline-[#DCA950]"
                    value={formData?.zip_code || ''}
                    onChange={(e) =>
                      setFormData({ ...formData, zip_code: e.target.value })
                    }
                  />
                </div>
                <div className="flex flex-col gap-2">
                  <label
                    htmlFor="address"
                    className="text-[#9C9C9C] text-[15px]"
                  >
                    Adresa
                  </label>
                  <input
                    type="text"
                    className="border border-[#555555] h-12 items-center px-3 outline-[#DCA950]"
                    value={formData?.address || ''}
                    onChange={(e) =>
                      setFormData({ ...formData, address: e.target.value })
                    }
                  />
                </div>
                <div className="flex flex-col gap-2">
                  <label htmlFor="city" className="text-[#9C9C9C] text-[15px]">
                    Grad
                  </label>
                  <input
                    type="city"
                    id="city"
                    name="city"
                    className="border border-[#555555] h-12 items-center px-3 outline-[#DCA950]"
                    value={formData?.city || ''}
                    onChange={(e) =>
                      setFormData({ ...formData, city: e.target.value })
                    }
                  />
                </div>

                <div className="flex flex-col gap-2">
                  <label
                    htmlFor="country"
                    className="text-[#9C9C9C] text-[15px]"
                  >
                    Drzava
                  </label>
                  <Select
                    options={countryOptions}
                    name="country"
                    id="country"
                    required
                    placeholder="Država"
                    styles={{
                      control: (provided, state) => ({
                        ...provided,
                        border: state.isFocused
                          ? '1px solid #DCA950'
                          : '1px solid #555555',
                        height: '3rem',
                        display: 'flex',
                        alignItems: 'center',
                        paddingLeft: '0.75rem',
                        outline: '#DCA950',
                      }),
                      singleValue: (provided) => ({
                        ...provided,
                        color: '#555555',
                        fontSize: '15px',
                      }),
                      placeholder: (provided) => ({
                        ...provided,
                        color: '#9C9C9C',
                        fontSize: '15px',
                      }),
                      dropdownIndicator: (provided) => ({
                        ...provided,
                        color: '#DCA950',
                      }),
                      menu: (provided) => ({
                        ...provided,
                        zIndex: 9999,
                      }),
                    }}
                    value={countryOptions?.find(
                      (option) => option.value === formData?.country,
                    )}
                    onChange={(selectedOption) => {
                      setFormData({
                        ...formData,
                        country: selectedOption.value,
                      })
                    }}
                  />
                </div>
              </div>

              <div className="flex lg:justify-end justify-center py-3 px-3 mt-6 ">
                <button
                  className="bg-[#DCA950] text-white p-2 font-semibold h-[45px] w-52"
                  type="submit"
                >
                  Sačuvaj izmjene
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

export default UserProfile
