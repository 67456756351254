import React from 'react'
import { useGetAllUsersQuery } from '../../api/services/usersApi'
import { BsSend } from 'react-icons/bs'

const Messages = () => {
  const { data: allUsers } = useGetAllUsersQuery()
  return (
    <div>
      <div className="px-8">
        <div className="bg-blue-600 p-4">
          <div className="">Poruke</div>
          <div className="flex w-full gap-4">
            <div className="bg-black border border-white rounded-lg w-1/3 p-4 flex flex-col gap-2">
              <div className="border text-white px-3">Search</div>
              {allUsers?.map((user) => {
                return (
                  <div className="px-3 text-white border border-white">
                    <div className="flex justify-between">
                      <p>
                        {user.first_name} {user.last_name}
                      </p>
                      <p>datum</p>
                    </div>
                    <p>poruka</p>
                  </div>
                )
              })}
            </div>

            <div className="bg-purple-500 border rounded-lg w-2/3 p-4 flex flex-col justify-between">
              <div className="border rounded-md bg-gray-100">Ime i prezime</div>
              <div className="flex border bg-gray-100 rounded-md py-1">
                <input
                  type="text"
                  placeholder="Kucaj ovdje"
                  className="w-full bg-gray-100 border"
                />
                <button className="bg-blue-400 px-4 border rounded-lg flex justify-center items-center gap-1">
                  Pošalji
                  <BsSend className="w-3 h-3" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Messages
