import React, { useEffect, useState } from 'react'
import { AiOutlineUser, AiOutlineMenuUnfold } from 'react-icons/ai'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import AdminResponsive from './AdminResponsive'
import { BiChevronDown } from 'react-icons/bi'

function AdminHeader() {
  const user = useSelector((state) => state.auth.user)
  const navigate = useNavigate()
  const [openModal, setOpenModal] = useState(false)

  const handleClick = () => {
    setOpenModal(!openModal)
  }

  const handleAdmin = () => {
    navigate('/admin')
  }
  const handleUser = () => {
    navigate('/korisnik')
  }

  const [openMenu, setOpenMenu] = useState(false)

  const handleMenuClick = () => {
    setOpenMenu(true)
  }

  // Prevent scrolling when the modal is open
  useEffect(() => {
    if (openMenu) {
      // Disable scrolling
      document.body.style.overflow = 'hidden'
    } else {
      // Re-enable scrolling
      document.body.style.overflow = 'auto'
    }
  }, [openMenu])
  return (
    <>
      <div className="flex gap-2 m-4 relative">
        <div className="flex bg-white p-1 rounded gap-2 lg:hidden">
          <button
            className="flex bg-white p-1 rounded w-10 items-center justify-center lg:hidden "
            onClick={handleMenuClick}
          >
            <AiOutlineMenuUnfold className="h-6 w-6" />
          </button>
        </div>

        <div
          onClick={handleClick}
          className="ml-auto flex bg-gray-100 p-1 rounded gap-6"
        >
          <div
            className="flex bg-white p-1 rounded cursor-pointer gap-4"
            onClick={handleClick}
          >
            <AiOutlineUser size={20} className="self-center" />
            <div className="flex flex-col ml-2">
              <label htmlFor="" className="text-sm">
                <span>
                  {user?.last_name} {user?.first_name}{' '}
                </span>
              </label>
              <label htmlFor="flex" className="text-sm text-gray-400">
                {user?.is_admin === 'Y' ? 'Admin' : 'Korisnik'}
              </label>
            </div>
            <span className="flex items-center">{<BiChevronDown />}</span>
          </div>
          {openModal && (
            <div className="bg-gray-100 border border-blue-500 rounded-md flex flex-col absolute top-16 right-4 p-2 gap-2 z-10">
              <div
                className="cursor-pointer hover:text-red-500"
                onClick={handleAdmin}
              >
                Admin Panel
              </div>
              <div
                className="cursor-pointer hover:text-red-500"
                onClick={handleUser}
              >
                User Panel
              </div>
            </div>
          )}
        </div>
      </div>
      {openMenu && <AdminResponsive setOpenMenu={setOpenMenu} />}
    </>
  )
}

export default AdminHeader
