import React, { useEffect, useMemo, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Payment from '../components/korpa/Payment'
import {
  useDeleteOneBagArticleMutation,
  useGetBagArticlesQuery,
  useUpdateBagArticleMutation,
} from '../api/services/bagArticleApi'
import { updateCart } from '../api/features/cartSlice'
import { toast } from 'react-toastify'
import CartArticle from '../components/Cart/CartArticle'
import DeleteModal from '../components/DeleteModal'

function Korpa() {
  const bag = useSelector((state) => state.auth.bag)
  const cart = useSelector((state) => state.cart.cart)
  const user = useSelector((state) => state.auth.user)
  const [deleteModal, setDeleteModal] = useState(false)
  const [article, setArticle] = useState({})
  const [currentPrice, setCurrentPrice] = useState(0)
  const [cartTotalPrice, setCartTotalPrice] = useState(0)
  const [pingAnimation, setPingAnimation] = useState(false)

  const { data: bagArticles } = useGetBagArticlesQuery(bag?.bag_id)

  const dispatch = useDispatch()

  const [deleteArticle] = useDeleteOneBagArticleMutation(
    article?.bag_article_id,
  )
  const [updateBagArticle] = useUpdateBagArticleMutation()

  function handleCancel() {
    setDeleteModal((prev) => !prev)
  }

  useEffect(() => {
    const total = cart?.reduce((prev, current) => {
      if (current?.discount && current?.discount > 0)
        return prev + current.discount_price * current?.amount
      else return prev + current.price * current?.amount
    }, 0)
    setCartTotalPrice(total)
  }, [cart])

  useEffect(() => {
    const total = bagArticles?.reduce((prev, current) => {
      if (current?.article?.discount && current?.article?.discount > 0)
        return prev + current.article.discount_price * current?.amount
      else return prev + current.price * current?.amount
    }, 0)

    setCurrentPrice(total)
  }, [bagArticles])

  const total = bagArticles?.length > 0 && user ? currentPrice : cartTotalPrice

  const data = useMemo(() => {
    if (Array.isArray(bagArticles) && bagArticles?.length > 0 && user) {
      return bagArticles
    } else {
      return cart
    }
  }, [bagArticles, cart])

  const handleDelete = (article_id) => {
    if (bag && user) {
      const foundItem = data?.find((item) => item.article_id === article_id)
      const articles = {
        bag_article_id: foundItem?.bag_article_id,
      }

      if (foundItem) {
        deleteArticle(articles)
      }
    } else {
      const foundItem = data?.find((item) => item.article_id === article_id)
      const updatedCartData = cart.filter(
        (article) => article.article_id !== foundItem?.article_id,
      )
      localStorage.setItem('cart', JSON.stringify(updatedCartData))
      dispatch(updateCart(updatedCartData))
    }

    setDeleteModal(false)
    toast.info('Obrisano iz korpe!')
  }
  const decreaseCount = (article_id) => {
    if (bag && user) {
      const foundItem = data.find((item) => item.article_id === article_id)
      if (foundItem) {
        updateBagArticle({
          article_name: foundItem.article_name,
          amount: foundItem.amount - 1,
          price: foundItem.price,
          bag_id: bag.bag_id,
          bag_article_id: foundItem.bag_article_id,
        })
      }
      setPingAnimation(true)
      setTimeout(() => setPingAnimation(false), 500)
    } else {
      const foundItem = data?.find((item) => item.article_id === article_id)

      const updatedData = cart?.map((item) => {
        if (item?.article_id === foundItem?.article_id) {
          const newAmount = foundItem?.amount - 1 >= 1 ? item?.amount - 1 : 1

          return {
            ...item,
            amount: newAmount,
          }
        }
        return item
      })
      setPingAnimation(true)
      setTimeout(() => setPingAnimation(false), 500)
      localStorage.setItem('cart', JSON.stringify(updatedData))
      dispatch(updateCart(updatedData))
    }
  }
  const increaseCount = (article_id) => {
    if (bag && user) {
      const foundItem = data?.find((item) => item.article_id === article_id)

      if (foundItem) {
        updateBagArticle({
          article_name: foundItem.article_name,
          amount: foundItem.amount + 1,
          price: foundItem.price,
          bag_id: bag.bag_id,
          bag_article_id: foundItem.bag_article_id,
        })
      }
      setPingAnimation(true)
      setTimeout(() => setPingAnimation(false), 500)
    } else {
      const foundItem = data?.find((item) => item.article_id === article_id)

      const updatedData = cart?.map((item) => {
        if (item?.article_id === foundItem?.article_id) {
          const newAmount = foundItem?.amount + 1 >= 0 ? item?.amount + 1 : 0

          return {
            ...item,
            amount: newAmount,
          }
        }
        return item
      })
      setPingAnimation(true)
      setTimeout(() => setPingAnimation(false), 500)
      localStorage.setItem('cart', JSON.stringify(updatedData))
      dispatch(updateCart(updatedData))
    }
  }

  return (
    <>
      {(bagArticles?.length && user) || cart.length ? (
        <div className="flex flex-col lg:flex-row justify-between items-start px-4 font-luxor w-full gap-4">
          <CartArticle
            data={data}
            increaseCount={increaseCount}
            decreaseCount={decreaseCount}
            handleDelete={handleDelete}
            setDeleteModal={setDeleteModal}
            handleOpen={handleCancel}
            bagArticles={bagArticles}
            setPingAnimation={setPingAnimation}
            pingAnimation={pingAnimation}
          />
          <Payment total={total} user={user} />
        </div>
      ) : (
        <div className="flex items-center justify-center p-14 font-luxor">
          <p>Korpa je prazna!</p>
        </div>
      )}
      {deleteModal && (
        <DeleteModal
          setOpenDelete={setDeleteModal}
          article={article}
          bag={bag}
          handleDelete={handleDelete}
          handleCancel={handleCancel}
        />
      )}
    </>
  )
}
export default Korpa
