import React from 'react'
import TableActionButton from './TableActionBtn'

const ActionList = ({ actions, row }) => {
  return (
    <div>
      {actions?.map((item) => {
        return (
          <TableActionButton
            key={Math.random()}
            Icon={item.Icon}
            label={item.label}
            onClick={() => item.onClick(row)}
            disabled={
              typeof item.disabled === 'boolean'
                ? item.disabled
                : () => item.disabled(row)
            }
          />
        )
      })}
    </div>
  )
}

export default ActionList
