import React, { useState, useEffect } from 'react'
import Select from 'react-select'
import CreatableSelect from 'react-select/creatable'
import { BsSend } from 'react-icons/bs'
import { GrAttachment } from 'react-icons/gr'
import { useSelector } from 'react-redux'
import {
  useGetOneUserQuery,
  useEditUserMutation,
  useGetAllEmailsQuery,
  useSendNewsletterMailMutation,
} from '../../api/services/usersApi'
import { countries } from '../../data/countries'
import { toast } from 'react-toastify'
import CijenaPostarine from '../../components/adminSettings/CijenaPostarine'
import PopupDiscount from '../../components/adminSettings/PopupDiscount'
import BirthdayConfig from '../../components/adminSettings/BirthdayConfig'
import ImageConfig from '../../components/adminSettings/ImageConfig'

function Settings() {
  const user = useSelector((state) => state.auth.user)

  const [updateUser] = useEditUserMutation()
  const [sendNewsletter] = useSendNewsletterMailMutation()

  const [selectedValue, setSelectedValue] = useState('')

  const [selectedEmails, setSelectedEmails] = useState([])
  const [selectedFile, setSelectedFile] = useState(null)

  const { data: UserData } = useGetOneUserQuery(user?.user_id)
  const { data: EmailsData } = useGetAllEmailsQuery()

  const emailOptions = EmailsData?.verifiedEmails?.map((email) => ({
    label: `${email.first_name} ${email.last_name} (${email.email})`,
    value: email.email,
  }))

  const email_receivers = [
    { value: 'svi', label: 'Svi' },
    { value: 'jedan', label: 'Jedan' },
  ]

  const [formData, setFormData] = useState({
    username: '',
    first_name: '',
    last_name: '',
    email: '',
    address: '',
    country: '',
    zip_code: '',
    phone_number: '',
    old_password: '',
    password: '',
    city: '',
  })

  const [emailData, setEmailData] = useState({
    heading: '',
    content: '',
  })

  useEffect(() => {
    if (UserData) {
      setFormData({
        username: UserData.username || '',
        first_name: UserData.first_name || '',
        last_name: UserData.last_name || '',
        email: UserData.email || '',
        address: UserData.address || '',
        country: UserData.country || '',
        zip_code: UserData.zip_code || '',
        phone_number: UserData.phone_number || '',
        city: UserData.city || '',
      })
    }
  }, [UserData])

  const handleSelect = (selectedOption) => {
    setSelectedValue(selectedOption)
  }

  const handleEmailPush = (selectedEmails) => {
    setSelectedEmails(selectedEmails)
  }
  // const handleFileSelect = (e) => {
  //   setSelectedFile(e.target.files[0])
  // }

  const handleSendMail = () => {
    if (selectedValue.value === 'svi') {
      sendNewsletter({
        email: emailOptions,
        heading: emailData.heading,
        content: emailData.content,
        file: selectedFile,
      })
        .unwrap()
        .then(() => {
          setSelectedEmails([])
          setEmailData({
            heading: '',
            content: '',
          })
          setSelectedFile(null)
        })
    } else {
      sendNewsletter({
        email: selectedEmails,
        heading: emailData.heading,
        content: emailData.content,
        file: selectedFile,
      })
        .unwrap()
        .then(() => {
          setSelectedEmails([])
          setEmailData({
            heading: '',
            content: '',
          })
          setSelectedFile(null)
        })
    }
  }

  return (
    <>
      <div className="mx-8 my-4 relative">
        <div className="flex flex-col md:flex-row  ">
          <div className="flex flex-col w-full md:w-2/4 md:mx-4 h-full">
            <CijenaPostarine />
            <BirthdayConfig />
            <ImageConfig />
          </div>

          <div className="flex flex-col md:w-2/4   ">
            <PopupDiscount />

            <label htmlFor="" className="my-4 font-bold">
              Email obavještenja
            </label>
            <article className="flex flex-col bg-white px-4 rounded space-y-3 py-4 ">
              <div className="flex flex-col space-y-3">
                <label htmlFor="">Primalac</label>
                <Select
                  defaultValue={email_receivers}
                  options={email_receivers}
                  value={selectedValue}
                  onChange={handleSelect}
                />
                {selectedValue.value === 'jedan' ? (
                  <CreatableSelect
                    isMulti
                    name="colors"
                    options={emailOptions}
                    onChange={handleEmailPush}
                    value={selectedEmails.value}
                    className="basic-multi-select"
                    classNamePrefix="select"
                  />
                ) : (
                  ''
                )}

                <label htmlFor="">Naslov</label>
                <input
                  type="text"
                  placeholder="Naslov e-maila"
                  className="border rounded h-8 px-2"
                  onChange={(e) => {
                    setEmailData({ ...emailData, heading: e.target.value })
                  }}
                />
                <label htmlFor="">Poruka</label>
                <div className="flex flex-col border rounded">
                  <textarea
                    name="content"
                    id=""
                    cols="6"
                    rows="6"
                    className=" rounded resize-none p-2 "
                    placeholder="Email poruka:"
                    value={emailData.content}
                    onChange={(e) => {
                      setEmailData({ ...emailData, content: e.target.value })
                    }}
                  ></textarea>
                  <div className="flex justify-end space-x-2 m-2">
                    {/* <div className="flex items-center justify-center ">
                      <label
                        htmlFor="fileInput"
                        className="items-center justify-center hover:cursor-pointer"
                      >
                        <GrAttachment />
                      </label>
                      <input
                        type="file"
                        id="fileInput"
                        style={{ display: 'none' }}
                        onChange={handleFileSelect}
                      />
                    </div> */}
                    <button
                      onClick={handleSendMail}
                      disabled={
                        !selectedValue.value ||
                        !emailData.content ||
                        !emailData.heading
                      }
                      className="border bg-blue-500 rounded text-white text-sm py-1 px-2 flex items-center text-"
                    >
                      Pošalji <BsSend />
                    </button>
                  </div>
                  {selectedFile && (
                    <div>Prikačena datoteka: {selectedFile.name}</div>
                  )}
                </div>
              </div>
            </article>
          </div>
        </div>
      </div>
    </>
  )
}

export default Settings
