import React, { useMemo } from 'react'
import { AiFillCloseCircle } from 'react-icons/ai'
import { format } from 'date-fns'
import MainTable from '../MainTable'
import TableWrapper from '../TableWrapper'
import 'react-datepicker/dist/react-datepicker.css'
import { useGetTotalSoldArticlesQuery } from '../../api/services/articleApi'

function ExpandedTotalSales({ onClose, endDate, startDate }) {
  const { data: TotalSoldArticles } = useGetTotalSoldArticlesQuery({
    from: startDate.toISOString().substring(0, 10),
    to: endDate.toISOString().substring(0, 10),
  })
  const data = useMemo(
    () =>
      Array.isArray(TotalSoldArticles?.articles)
        ? TotalSoldArticles?.articles
        : [],
    [TotalSoldArticles],
  )

  const columns = useMemo(
    () => [
      {
        Header: 'Ime artikla',
        accessor: 'article_name',
      },

      {
        Header: 'Količina',
        accessor: 'total_bought',
      },
      {
        Header: 'ML',
        accessor: 'volume',
      },
      {
        Header: 'Cijena(komad)',
        accessor: 'article_price',
      },
    ],
    [],
  )
  return (
    <>
      {/* Transparent overlay */}
      <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
        <div className="flex flex-col rounded-xl mx-4 sm:mx-10 w-full sm:w-2/3 lg:w-2/3 lg:h-fit h-2/4 bg-white z-10 relative">
          <button className="absolute top-2 right-2 p-2" onClick={onClose}>
            <AiFillCloseCircle size={25} />
          </button>

          {/* Close button (top right) */}
          <div className="flex flex-col px-8 py-4">
            <section className="flex flex-col justify-center items-center py-4 gap-2">
              <h1>Ispis prodatih artikala za dan</h1>
              <TableWrapper>
                <MainTable columns={columns} data={data} />
              </TableWrapper>
            </section>
          </div>
        </div>
      </div>
    </>
  )
}

export default ExpandedTotalSales
