import { api } from '../api'

export const articleImagesApi = api.injectEndpoints({
  endpoints: (builder) => ({
    addNewImage: builder.mutation({
      query: (body) => ({
        url: `api/v1/article-images`,
        body,
        method: 'POST',
      }),
      invalidatesTags: ['Articles'],
    }),
    deleteImage: builder.mutation({
      query: (article_image_id) => ({
        url: `api/v1/article-images/${article_image_id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Articles'],
    }),
  }),
})

export const { useAddNewImageMutation, useDeleteImageMutation } =
  articleImagesApi
