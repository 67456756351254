import React, { useEffect, useState } from 'react'
import img from '../img/icon.jpg'
import { FaBars } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import { useLocation, useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { useGetAllArticleGroupsQuery } from '../api/services/articleGroup'
import {
  useGetAllFavoriteArticlesQuery,
  useLazySearchArticlesQuery,
} from '../api/services/articleApi'
import { useGetBagArticlesQuery } from '../api/services/bagArticleApi'
import ResponsiveMenu from './homePage/ResponsiveMenu'
import { debounce } from 'lodash'
import { reset } from '../api/features/filterSlice'
import '../styles/custom-scrollbar.css'

const Header = () => {
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated)
  const user = useSelector((state) => state.auth.user)
  const bag = useSelector((state) => state.auth.bag)
  const cart = useSelector((state) => state.cart.cart)

  const { data: allFavoriteArticles } = useGetAllFavoriteArticlesQuery(
    user?.user_id,
  )
  const { data: bagArticles } = useGetBagArticlesQuery(bag?.bag_id)

  const location = useLocation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { data: articleGropus } = useGetAllArticleGroupsQuery()
  const [fetch, { data: searchArticles }] = useLazySearchArticlesQuery()

  const handleCartClick = () => {
    navigate('/korpa')
  }
  const handleHeartClick = () => {
    navigate('/favorites')
  }

  const handleIconClick = () => {
    navigate('/')
  }

  const handleUserClick = () => {
    if (user?.is_admin === 'Y') {
      navigate('/admin')
    } else if (user?.is_admin === 'N') {
      navigate('/korisnik')
    } else {
      navigate('/login')
    }
  }

  const favoriteArticlesCount = allFavoriteArticles?.reduce((total) => {
    return total + 1
  }, 0)
  const bagCount = bagArticles?.reduce((total) => {
    return total + 1
  }, 0)

  const cartCount = cart?.reduce((total) => {
    return total + 1
  }, 0)

  const [openMenu, setOpenMenu] = useState(false)
  const [showResults, setShowResults] = useState(false)

  const handleMenuClick = () => {
    setOpenMenu(true)
  }

  // Prevent scrolling when the modal is open
  useEffect(() => {
    if (openMenu) {
      // Disable scrolling
      document.body.style.overflow = 'hidden'
    } else {
      // Re-enable scrolling
      document.body.style.overflow = 'auto'
    }
  }, [openMenu])

  // const handleChange = debounce((e) => {
  //   const value = e.target.value
  //   if (value) fetch(value)
  //   setShowResults(true)
  // }, 200)

  const handleChange = debounce((e) => {
    const value = e.target.value
    if (value?.length < 1) {
      setShowResults(false)
      return
    }
    fetch(value)
    setShowResults(true)
  }, 200)

  return (
    <>
      <div className="relative md:flex justify-between flex-col px-0 lg:px-0 w-full">
        <div className="flex justify-end md:justify-between items-center min-h-[100px] py-1 px-[37px] md:px-[97px] lg:py-1 monitor:px-[137px]">
          <div className="flex items-center justify-center sm:hidden md:flex lg:hidden md:py-2">
            {/* <div className="text-left px-1 flex items-center rounded-lg"> */}
            <span className="md:pl-4">
              <FaBars
                className="flex items-center justify-center md:w-6 md:h-6"
                onClick={handleMenuClick}
                size={25}
              />
            </span>
            {/* </div> */}
          </div>
          {/*PRVI DIV*/}
          <div className=" w-20 h-20 lg:w-28 lg:h-28 absolute lg:static top-1/2 left-[37px] transform  -translate-y-1/2 lg:translate-x-0 lg:translate-y-0">
            <div
              onClick={handleIconClick}
              className="cursor-pointer w-full h-full"
            >
              <img src={img} alt="icon" className="w-full h-full " />
            </div>
          </div>{' '}
          {/*ikona*/}
          <div className=" hidden md:flex gap-0 text-lg font-light max-md:flex-wrap font-luxorlight">
            <div className="flex flex-auto gap-5 justify-between pr-20 pl-4 border border-solid w-[631px] h-[60px] border-stone-300 text-neutral-400 max-md:flex-wrap max-md:pr-5 max-md:max-w-full">
              <div className=" pt-[21px]">
                <svg
                  height="17"
                  width="17"
                  fill="none"
                  viewBox="0 0 17 17"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11.861 10.2473H11.1279L10.868 9.99675C11.7774 8.93886 12.3249 7.56545 12.3249 6.07141C12.3249 2.73997 9.62453 0.0395508 6.29309 0.0395508C2.96165 0.0395508 0.26123 2.73997 0.26123 6.07141C0.26123 9.40285 2.96165 12.1033 6.29309 12.1033C7.78713 12.1033 9.16054 11.5558 10.2184 10.6463L10.469 10.9062V11.6393L15.1089 16.2699L16.4916 14.8872L11.861 10.2473ZM6.29309 10.2473C3.98242 10.2473 2.11719 8.38207 2.11719 6.07141C2.11719 3.76074 3.98242 1.89551 6.29309 1.89551C8.60375 1.89551 10.469 3.76074 10.469 6.07141C10.469 8.38207 8.60375 10.2473 6.29309 10.2473Z"
                    fill="#9C9C9C"
                  />
                </svg>
              </div>
              <input
                type="text"
                placeholder="Pretraživanje"
                className="flex-1 border-none p-1 rounded outline-none "
                onChange={handleChange}
              />
              {/* <button className="text-black">
                <BiSearch className="w-5 h-5" />
              </button> */}
            </div>
            {showResults && (
              <div className=" hidden md:inline-block absolute w-[631px] top-20 bg-white border  p-4 overflow-y-scroll scroller max-h-64 z-50 ">
                {/* <button className="absolute top-0 right-0" onClick={closeModal}>
                  <AiOutlineCloseCircle className="w-5 h-5" />
                </button> */}
                <ul
                  className="flex flex-col"
                  onClick={() => setShowResults(false)}
                >
                  {searchArticles?.map((article) => {
                    return (
                      <li className="hover:bg-gray-100 " key={article.id}>
                        <Link
                          to={`/article/${article?.article_id}`}
                          className="flex gap-4 items-center h-10"
                        >
                          <img
                            src={article?.article_images[0]?.url}
                            alt="#"
                            className="w-10 h-10"
                          />
                          {/* Dodaj Link komponentu koja vodi na odabrani artikal */}
                          <div className="flex justify-between p-1 w-full">
                            <p className="truncate max-w-[300px] ...">
                              {article?.article_name}
                            </p>
                            <p>{article?.price} KM</p>
                          </div>
                        </Link>
                      </li>
                    )
                  })}
                </ul>
              </div>
            )}
            <div className="justify-center w-28 py-4 md:inline-block hidden text-white text-center whitespace-nowrap bg-[#DCA950] h-[60px] max-md:px-5">
              Traži
            </div>
          </div>
          {/*Search bar*/}
          <div className="hidden md:flex gap-4 md:gap-9">
            {/* <p className="hidden md:flex gap-2">
              {' '}
              <span>{user?.first_name}</span>
              <span>{user?.last_name}</span>
            </p> */}
            {/* <div className="cursor-pointer">
              <BsPerson
                className="w-5 h-5 md:w-6 md:h-6"
                onClick={handleUserClick}
              />
            </div> */}

            {isAuthenticated && (
              <div
                onClick={handleHeartClick}
                className="cursor-pointer relative"
              >
                {/* <AiOutlineHeart className="w-5 h-5 md:w-6 md:h-6" /> */}
                <svg
                  height="30"
                  width="28"
                  fill="none"
                  viewBox="0 0 30 28"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M26.2072 4.47168C23.4796 1.74284 19.056 1.74284 16.3285 4.47168L14.9825 5.81763L13.6366 4.47168C10.9087 1.74375 6.4858 1.74375 3.75786 4.47168C1.02993 7.19962 1.02993 11.6225 3.75786 14.3504L5.10381 15.6964L14.9825 25.5751L24.8613 15.6963L26.2072 14.3504C28.9361 11.6228 28.936 7.19924 26.2072 4.47168Z"
                    stroke="#12131C"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="3"
                  />
                </svg>
                {favoriteArticlesCount ? (
                  <span className="absolute top-0 right-0 transform translate-x-1/2 -translate-y-1/2 bg-[#FF7777] text-white font-karla text-xs rounded-full w-4 h-4 flex items-center justify-center">
                    {favoriteArticlesCount}
                  </span>
                ) : null}
              </div>
            )}

            <div className="cursor-pointer" onClick={handleUserClick}>
              {/* <PiUserCircle className="w-5 h-5 md:w-7 md:h-7" /> */}
              <svg
                height="30"
                width="30"
                fill="none"
                viewBox="0 0 36 36"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M18.2379 3.5083C10.2384 3.5083 3.74609 10.0006 3.74609 18.0001C3.74609 25.9996 10.2384 32.492 18.2379 32.492C26.2374 32.492 32.7298 25.9996 32.7298 18.0001C32.7298 10.0006 26.2374 3.5083 18.2379 3.5083ZM11.0935 27.101C11.7166 25.7967 15.5135 24.5215 18.2379 24.5215C20.9624 24.5215 24.7738 25.7967 25.3824 27.101C23.4115 28.6661 20.9334 29.5936 18.2379 29.5936C15.5425 29.5936 13.0643 28.6661 11.0935 27.101ZM27.4547 24.9997C25.3824 22.4781 20.3537 21.6231 18.2379 21.6231C16.1221 21.6231 11.0935 22.4781 9.02112 24.9997C7.54296 23.0578 6.64446 20.6377 6.64446 18.0001C6.64446 11.6092 11.847 6.40667 18.2379 6.40667C24.6288 6.40667 29.8314 11.6092 29.8314 18.0001C29.8314 20.6377 28.9329 23.0578 27.4547 24.9997ZM18.2379 9.30504C15.4265 9.30504 13.1658 11.5658 13.1658 14.3772C13.1658 17.1886 15.4265 19.4493 18.2379 19.4493C21.0493 19.4493 23.3101 17.1886 23.3101 14.3772C23.3101 11.5658 21.0493 9.30504 18.2379 9.30504ZM18.2379 16.551C17.0351 16.551 16.0642 15.58 16.0642 14.3772C16.0642 13.1744 17.0351 12.2034 18.2379 12.2034C19.4408 12.2034 20.4117 13.1744 20.4117 14.3772C20.4117 15.58 19.4408 16.551 18.2379 16.551Z"
                  fill="black"
                />
              </svg>
            </div>

            <div onClick={handleCartClick} className="cursor-pointer relative">
              {/* <AiOutlineShoppingCart className="w-5 h-5 md:w-6 md:h-6" /> */}
              <svg
                height="30"
                width="30"
                fill="none"
                viewBox="0 0 33 34"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M21.6354 18.3605C22.6442 18.3605 23.5318 17.809 23.9891 16.9752L28.8042 8.24621C29.3018 7.35853 28.6562 6.25564 27.634 6.25564H7.7283L6.46401 3.56567H2.06592V6.25564H4.75588L9.59782 16.4641L7.78209 19.7458C6.80026 21.5481 8.09144 23.7404 10.1358 23.7404H26.2756V21.0504H10.1358L11.6153 18.3605H21.6354ZM9.00603 8.9456H25.3476L21.6354 15.6705H12.1936L9.00603 8.9456ZM10.1358 25.0854C8.65633 25.0854 7.4593 26.2959 7.4593 27.7754C7.4593 29.2548 8.65633 30.4653 10.1358 30.4653C11.6153 30.4653 12.8258 29.2548 12.8258 27.7754C12.8258 26.2959 11.6153 25.0854 10.1358 25.0854ZM23.5856 25.0854C22.1062 25.0854 20.9091 26.2959 20.9091 27.7754C20.9091 29.2548 22.1062 30.4653 23.5856 30.4653C25.0651 30.4653 26.2756 29.2548 26.2756 27.7754C26.2756 26.2959 25.0651 25.0854 23.5856 25.0854Z"
                  fill="#12131C"
                />
                {/* <circle cx="29.20238" cy="4.54125" fill="#FF7777" r="3.7976" /> */}
              </svg>

              {bagArticles?.length > 0 && user ? (
                <span className=" absolute font-karla top-0 right-0 transform translate-x-1/2 -translate-y-1/2 bg-[#FF7777] text-white text-xs rounded-full w-4 h-4 flex items-center justify-center">
                  {bagCount}
                </span>
              ) : (
                cart?.length > 0 && (
                  <span className="absolute font-karla top-0 right-0 transform translate-x-1/2 -translate-y-1/2 bg-[#FF7777] text-white text-xs rounded-full w-4 h-4 flex items-center justify-center">
                    {cartCount}
                  </span>
                )
              )}
            </div>
          </div>{' '}
          {/*3 ikonice  */}
        </div>
        <div className="flex flex-col md:flex-row md:text-sm monitor:text-xl justify-between mt-1 md:gap-1 font-luxorthin bg-[#f3f3f3] md:px-[100px] monitor:px-[140px] md:max-h[40px] monitor:max-h-[60px] ">
          {/* Drugi DIV */}

          <div className="lg:flex justify-between w-full text-base hidden ">
            <Link
              to={'/'}
              className={`flex items-center justify-center border-b-2 border-white md:text-md monitor:text-[20px] py-1 ${
                location.pathname === '/' ? 'active:border-[#DCA950]' : ''
              }`}
              style={{
                color: location.pathname === '/' ? '#DCA950' : '#5B5B5B',
                borderBottom: location.pathname === '/' ? 'none' : 'none',
              }}
            >
              Početna
            </Link>
            <Link
              onClick={() => dispatch(reset())}
              to={'/shop'}
              className={`flex items-center justify-center border-b-2 border-white   md:text-md monitor:text-[20px] py-1 ${
                location.pathname === '/shop' ? 'active:border-[#DCA950]' : ''
              }`}
              style={{
                color: location.pathname === '/shop' ? '#DCA950' : '#5B5B5B',
                borderBottom: location.pathname === '/shop' ? 'none' : 'none',
              }}
            >
              Svi artikli
            </Link>
            {articleGropus?.map((item) => {
              const type = item?.article_group_name
                .toLowerCase()
                .replace(/\s+/g, '-')

              const decodedPathname = decodeURIComponent(location.pathname)
              return (
                <div key={Math.random()} onClick={() => dispatch(reset())}>
                  <Link
                    key={Math.random()}
                    to={`/${type}`}
                    className={`flex items-center justify-center border-b-2 border-white md:text-md monitor:text-[20px]  py-4 ${
                      decodedPathname?.substring(1) === type
                        ? 'active:border-[#DCA950]'
                        : ''
                    }`}
                    style={{
                      color:
                        decodedPathname.substring(1) === type
                          ? '#DCA950'
                          : '#5B5B5B',
                      borderBottom:
                        decodedPathname.substring(1) === type ? 'none' : 'none',
                    }}
                  >
                    <span className="capitalize">
                      {item?.article_group_name?.toLowerCase()}
                    </span>
                  </Link>
                </div>
              )
            })}
            <Link
              to={'/kontakt'}
              className={`flex items-center justify-center bg-black md:text-md monitor:text-[20px]  py-1 w-[138px] ${
                location.pathname === '/kontakt'
                  ? 'active:border-[#DCA950]'
                  : ''
              }`}
              style={{
                color: location.pathname === '/kontakt' ? 'white' : 'white',
                // borderBottom:
                //   location.pathname === '/shop' ? '2px solid #DCA950' : 'none',
              }}
            >
              Kontakt
            </Link>
          </div>
        </div>
      </div>
      {openMenu && (
        <ResponsiveMenu
          setOpenMenu={setOpenMenu}
          articleGropus={articleGropus}
        />
      )}
    </>
  )
}

export default Header

// const options =
//   articleGropus?.map((item) => ({
//     value: item?.article_group_name?.toLowerCase(), // Zamijenite sa odgovarajućim svojstvom iz dobijenih podataka
//     label: item?.article_group_name?.toLowerCase(), // Zamijenite sa odgovarajućim svojstvom iz dobijenih podataka
//   })) || []

// options.unshift({ value: 'pocetna', label: 'Početna' })

// const handleSelectChange = (option) => {
//   if (option.value === 'pocetna') {
//     navigate('/')
//   } else {
//     navigate(option?.label?.toLowerCase()?.replace(/\s+/g, '-'))
//   }
// }
// const customStyles = {
//   control: (provided, state) => ({
//     ...provided,
//     border: 'none',
//     boxShadow: 'none',
//     background: '#f0f0f0',
//   }),
//   menu: (provided, state) => ({
//     ...provided,
//     width: '300px',
//     maxHeight: 'none',
//   }),
// }
