import { useMemo } from 'react'

const PrefetchPagination = ({
  pageSize,
  setPageSize,
  pageOptions,
  pageCount,
  setPage,
  setPageAmount,
  pageManual,
}) => {
  const arrayInSelect = useMemo(
    () => [...Array(pageOptions.length).keys()].map((i) => i + 1),
    [pageOptions],
  )

  return (
    <div className="flex flex-col lg:flex-row items-center justify-between bg-blue-500 p-2 mb-2 mt-2 flex-wrap w-full gap-2">
      <button
        className="bg-white px-14 py-2 rounded  disabled:cursor-not-allowed"
        onClick={() => {
          setPage(1)
        }}
        disabled={pageManual === 1}
      >
        {'<<'}
      </button>
      <button
        className="text-black bg-white rounded px-4 py-2 disabled:bg-main-700/30 disabled:cursor-not-allowed hover:bg-main-700/95"
        onClick={() => {
          setPage((prev) => prev - 1)
        }}
        disabled={pageManual === 1}
      >
        Prethodno
      </button>
      <select
        value={pageManual}
        onChange={(e) => {
          setPage(+e.target.value)
        }}
        className="bg-white px-6 py-2 rounded "
      >
        {arrayInSelect.map((el) => (
          <option key={el} value={el}>
            {el}
          </option>
        ))}
      </select>
      <div className="text-white font-semibold">
        {pageManual} / {pageOptions.length}
      </div>
      <select
        className="bg-white px-6 py-2 rounded "
        value={pageSize}
        onChange={(e) => {
          setPageSize(Number(e.target.value))
          setPageAmount(Number(e.target.value))
          setPage(1)
        }}
      >
        {[10, 25, 50, 100, 250, 500, 1000].map((pageSize) => (
          <option key={pageSize} value={pageSize}>
            Prikaži {pageSize}
          </option>
        ))}
      </select>
      <button
        className="text-black bg-white rounded px-4 py-2 disabled:bg-main-700/30 disabled:cursor-not-allowed hover:bg-main-700/95"
        onClick={() => setPage((prev) => prev + 1)}
        disabled={pageManual === pageOptions.length || pageOptions.length === 0}
      >
        Sljedeće
      </button>
      <button
        className="bg-white px-14 py-2 rounded  disabled:cursor-not-allowed"
        onClick={() => {
          setPage(pageCount)
        }}
        disabled={pageManual === pageOptions.length || pageOptions.length === 0}
      >
        {'>>'}
      </button>
    </div>
  )
}

export default PrefetchPagination
