import { useState } from 'react'
import Modal from '../Modal'
import { useReviewArticlesMutation } from '../../api/services/articleApi'
import { useSelector } from 'react-redux'
import { AiFillStar, AiOutlineStar } from 'react-icons/ai'
import { toast } from 'react-toastify'

const MessageModal = ({ isOpen, setIsOpen, rating, article_id, setRating }) => {
  const [addReview] = useReviewArticlesMutation()
  const user = useSelector((state) => state.auth.user)

  const [message, setMessage] = useState('')

  const handleRatingChange = async () => {
    try {
      const stars = {
        article_id: article_id,
        user_id: user?.user_id,
        review: rating,
        comment: message || '',
      }
      await addReview(stars)
      toast.success('Uspješno dodana recenzija!')
    } catch (error) {
      toast.error('Došlo je do greške prilikom dodavanja recenzije')
    }
  }

  return (
    <Modal showModal={isOpen} setShowModal={setIsOpen} title="Dodaj recenziju">
      <div className="text-xl  max-w-xl mx-auto  rounded-xl relative bg-slate-200">
        <div className="flex items-center justify-center ">
          {Array.from({ length: 5 })?.map((_, index) => {
            return (
              <span
                key={index}
                className="cursor-pointer"
                onClick={(e) => {
                  e.preventDefault()
                  setRating(index + 1)
                }}
              >
                {index < rating ? (
                  <AiFillStar
                    fill="#FFD700"
                    className="w-6 h-6 md:w-5 md:h-5"
                  />
                ) : (
                  <AiOutlineStar
                    fill="#FFD700"
                    className="w-6 h-6 md:w-5 md:h-5"
                  />
                )}
              </span>
            )
          })}
        </div>
        <div className="flex justify-center w-full py-4 px-2">
          <input
            className="border border-black w-full rounded-lg p-2"
            name="message"
            onChange={(e) => setMessage(e.target.value)}
            value={message || ''}
          />
        </div>
        <div className="flex justify-center gap-4 p-4  rounded-b-xl text-lg">
          <button
            className=" px-2 py-1 rounded bg-blue-500 text-white hover:bg-blue-600 hover:text-white"
            onClick={() => {
              handleRatingChange()
              setIsOpen(false)
            }}
          >
            Ne{' '}
          </button>
          <button
            className=" px-2 py-1 rounded bg-blue-500 text-white hover:bg-blue-600 hover:text-white"
            onClick={() => {
              handleRatingChange()
              setIsOpen(false)
            }}
          >
            Dodaj{' '}
          </button>
        </div>
      </div>
    </Modal>
  )
}
export default MessageModal
