import { api } from './api'
import authReducer from './features/authSlice'
import cartReducer from './features/cartSlice'
import filterReducer from './features/filterSlice'
import perfumeReducer from './features/perfumeSlice'
import { configureStore } from '@reduxjs/toolkit'

export const store = configureStore({
  reducer: {
    auth: authReducer,
    cart: cartReducer,
    filter: filterReducer,
    perfume: perfumeReducer,
    [api.reducerPath]: api.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: { warnAfter: 128 },
      serializableCheck: false,
    }).concat([api?.middleware]),
  // middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(baseApi.middleware),
})
