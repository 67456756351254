import { api } from '../api'

export const perfumeApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getPerfumeCategories: builder.query({
      query: () => ({
        url: `api/v1/perfume-categories`,
      }),
      invalidatesTags: ['Perfume'],
    }),
  }),
})

export const { useGetPerfumeCategoriesQuery } = perfumeApi

// GET api/v1/perfume-categories
