import { createSlice } from '@reduxjs/toolkit'

const cart = localStorage.getItem('cart')

const initialState = {
  cart: JSON.parse(cart) || [], // Pretvara JSON string u objekat
}

const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    updateCart: (state, action) => {
      state.cart = action.payload
    },
    removeCart: (state) => {
      state.cart = []
      localStorage.removeItem('cart')
    },
  },
})

export const { updateCart, removeCart } = cartSlice.actions
export default cartSlice.reducer
