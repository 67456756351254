import React, { useMemo, useState } from 'react'
import { AiOutlineCloseCircle } from 'react-icons/ai'
import MainTable from '../MainTable'
import TableWrapper from '../TableWrapper'
import { useGetOrderArticlesQuery } from '../../api/services/orderArticlesApi'
import EditAction from './EditAction'

const OrderArticles = ({ selectedOrder, setEditModal }) => {
  const { data: articles } = useGetOrderArticlesQuery(selectedOrder?.order_id, {
    skip: !selectedOrder.order_id,
  })
  const [update, setUpdate] = useState(false)

  const onClose = () => {
    setEditModal(false)
  }

  const columns = useMemo(
    () => [
      {
        Header: 'Ime artikla',
        accessor: 'article_name',
      },

      {
        Header: 'Količina',
        accessor: 'amount',
      },
      {
        Header: 'ML',
        accessor: 'volume',
      },
      {
        Header: 'Cijena(komad)',
        accessor: 'price',
      },
      {
        Header: 'Ukupno',
        Cell: ({ row }) => {
          const totalPrice = row?.original?.price * row?.original?.amount

          return <span>{parseFloat(totalPrice).toFixed(2) + ' KM'}</span>
        },
      },
    ],
    [],
  )
  const data = useMemo(
    () => (Array.isArray(articles) ? articles : []),
    [articles],
  )

  return (
    <>
      <div className="fixed inset-0 bg-black opacity-50"></div>
      <div className="fixed inset-0 flex items-center justify-center z-50 p-10 md:p-0">
        {/* Background overlay */}

        {/* Modal */}
        <div className="flex flex-col gap-2 rounded-lg bg-white z-10 w-full h-1/2 md:w-1/2 md:h-2/3 border border-black p-2">
          <div className="flex justify-between border-b-2 border-black">
            <h1>Pregled narudžbe</h1>
            <span className="cursor-pointer" onClick={onClose}>
              {<AiOutlineCloseCircle />}
            </span>
          </div>
          <TableWrapper>
            <MainTable columns={columns} data={data} />
          </TableWrapper>
        </div>
      </div>
      {update && <EditAction setUpdate={setUpdate} />}
    </>
  )
}

export default OrderArticles
