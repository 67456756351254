import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useLazyForgottenPasswordQuery } from '../../api/services/usersApi'
import Spinner from '../Spinner'
import LoginPicture from '../../img/image 11.png'
import loginIcon from '../../img/Group 1210 (1).png'

function ForgotPasswordEmail() {
  const navigate = useNavigate()
  const [email, setEmail] = useState()
  const [checkUserExist, { isLoading }] = useLazyForgottenPasswordQuery()
  const handleNavigate = () => {
    navigate('/login')
  }
  const handleHomepage = () => {
    navigate('/')
  }
  const handleChange = (e) => {
    setEmail(e.target.value)
  }
  const handleConfirm = async () => {
    try {
      const userExists = await checkUserExist({ email: email })

      if (userExists) {
        alert('Check your email.')
      } else {
        alert('User does not exist')
      }
    } catch (error) {
      console.error('Error checking user existence:', error)
      alert('An error occurred. Please try again later.')
    }
  }
  return (
    <>
      <div className="flex w-full h-full overflow-y-hidden">
        <div className="hidden w-[40%] h-screen bg-white lg:flex justify-end md:justify-start items-center rounded-md">
          <div>
            <img src={LoginPicture} alt="bannerImg" />
          </div>
        </div>
        {/* Drugi dio */}
        <div className=" md:px-14 md:py-10 bg-white w-full lg:w-[60%] rounded-md flex justify-center lg:justify-end md:block h-full">
          <div className="flex flex-col gap-6 w-1/2 md:w-full  md:mr-0 justify-center items-center mt-48 monitor:mt-32">
            <div className="flex font-bold text-black text-lg">
              <div
                className="ml-2 monitor:mt-5 hover:cursor-pointer"
                onClick={handleHomepage}
              >
                <img src={loginIcon} alt="logo" className="" />
              </div>
            </div>
            {isLoading ? (
              <Spinner />
            ) : (
              <div className="flex flex-col font-medium text-base gap-5 font-luxorlight">
                <div className="flex flex-col ">
                  <label htmlFor="email">E-Mail adresa</label>
                  <input
                    type="email"
                    name="email"
                    className="border border-[#C2C2C2] h-[50px] w-[320px] md:w-[550px] monitor:w-[620px] px-2 outline-[#DCA950]"
                    placeholder="korisnik@email.com"
                    onChange={handleChange}
                  />
                </div>
                <div className="flex justify-between gap-12">
                  <button
                    className="bg-black h-[50px] text-white font-medium p-2 w-1/2"
                    onClick={handleNavigate}
                  >
                    Odustani
                  </button>
                  <button
                    className="bg-[#DCA950] h-[50px] text-white font-medium p-2 w-1/2 "
                    onClick={handleConfirm}
                  >
                    Potvrdi
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default ForgotPasswordEmail
