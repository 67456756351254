import React from 'react'
import { useGetBagArticlesQuery } from '../../api/services/bagArticleApi'
import { useSelector } from 'react-redux'
import PerfumeTypeTitle from '../homePage/PerfumeTypeTitle'
import { useGetAllArticleGroupsQuery } from '../../api/services/articleGroup'
import { useGetAllPerfumeTypesQuery } from '../../api/services/perfumeTypeApi'
import { Link, useNavigate } from 'react-router-dom'

function SmallCart({ isCartDisplayed, setIsCartDisplayed, addedArticle }) {
  const navigate = useNavigate()
  const bag = useSelector((state) => state.auth.bag)
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated)
  const cart = useSelector((state) => state.cart.cart)
  const { data: bagArticles } = useGetBagArticlesQuery(bag?.bag_id)

  let articleAmount

  if (isAuthenticated) {
    articleAmount = bagArticles?.find(
      (item) => item?.article_id === addedArticle?.article_id,
    )
  } else {
    articleAmount = cart?.find(
      (item) => item?.article_id === addedArticle?.article_id,
    )
  }

  const bagCount = bagArticles?.reduce((total) => {
    return total + 1
  }, 0)

  const cartCount = cart?.reduce((total) => {
    return total + 1
  }, 0)

  const { data: gen } = useGetAllPerfumeTypesQuery()
  const { data: articleGroups } = useGetAllArticleGroupsQuery()

  const articleGender = gen?.find(
    (item) => item?.perfume_type_id === addedArticle?.perfume_type_id,
  )

  const articleGroupName = articleGroups?.find(
    (item) => item?.article_group_id === addedArticle?.article_group_id,
  )

  setTimeout(() => {
    {
      isCartDisplayed && setIsCartDisplayed(false)
    }
  }, 3500)
  return (
    <div
      className={`fixed top-0 right-0 bottom-0 monitor:w-[955px] md:w-[555px] w-[100%]  transition-transform ${
        isCartDisplayed
          ? 'transform translate-x-0 duration-500'
          : 'transform translate-x-full duration-500'
      }`}
    >
      <div className="flex flex-col monitor:px-20 md:px-10 md:py-6 monitor:py-16 bg-white monitor:max-w-[955px] md:max-w-[555px] max-w-[100%]  max-md:px-5 drop-shadow-2xl">
        <div className="flex gap-5 justify-between max-md:flex-wrap max-md:mr-1.5 max-md:max-w-full mt-5 md:mt-0">
          <div className="flex flex-col">
            <div className="md:text-[40px] text-2xl font-luxor text-black leading">
              Korpa
            </div>
            <div className=" text-xl font-luxorthin text-[#9C9C9C]">
              Broj proizvoda u korpi: {/* <span className="text-[#DCA950]"> */}
              {bagArticles?.length > 0 ? (
                <span className="text-[#DCA950] ">{bagCount}</span>
              ) : (
                cart?.length > 0 && (
                  <span className="text-[#DCA950]">{cartCount}</span>
                )
              )}
            </div>
          </div>
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/d24097c24e91a48097210e5d9df5a42090d00835626b9f5e2b87905aa800caf1?apiKey=0c40c41b63a74856ae4d4007c98537de&"
            className="shrink-0 self-start aspect-square w-[47px] cursor-pointer "
            onClick={() => setIsCartDisplayed(false)}
          />
        </div>
        <div className="monitor:mt-12 md:mt-10 max-md:mt-10 max-md:mr-1.5 max-md:max-w-full">
          <div className="flex gap-5 max-md:flex-col max-md:gap-0">
            <div className="flex flex-col md:w-[250px] md:h-[230px] max-md:ml-0 max-md:w-full hidden md:inline-block ">
              <img
                loading="lazy"
                alt="slika"
                srcSet={addedArticle?.article_images[0].url}
                className="grow w-full aspect-[0.78] max-md:mt-10"
              />
            </div>
            <div className="flex flex-col ml-5 w-[63%] max-md:ml-0 max-md:w-full">
              <div className="flex md:flex-col flex-row gap-5 md:gap-0 grow mt-1 font-light  max-md:max-w-full pr-[70px]">
                <div>
                  <PerfumeTypeTitle
                    text={articleGroupName?.article_group_name}
                    fontsize={14}
                  />
                  <div className="md:mt-2 font-luxor monitor:text-4xl md:text-xl text-xl font-semibold  text-black max-md:max-w-full max-w-[400px]  overflow-hidden">
                    {addedArticle?.article_name}
                  </div>
                  <div className="md:mt-3 text-2xl font-luxor md:text-[25px] text-xl text-zinc-900 max-md:max-w-full">
                    {parseFloat(addedArticle?.discount) > 0
                      ? addedArticle?.discount_price
                      : addedArticle?.price}{' '}
                    KM
                  </div>
                  <div className="mt-0 monitor:text-sm text-xs text-stone-300 font-luxorlight max-md:max-w-full leading-3">
                    U cijenu uključen PDV (17%)
                  </div>
                </div>
                <div className="border-l md:border-none pl-5 md:pl-0">
                  <div className="text-xl font-luxor md:mt-4 ">
                    Količina: {articleAmount?.amount}
                  </div>
                  <div className="flex gap-5 justify-start mt-2 text-base text-black max-md:flex-wrap max-md:max-w-full">
                    <div className="flex flex-col font-luxorlight monitor:text-xl md:text-lg text-[#9C9C9C]">
                      <div>
                        Kategorija:{' '}
                        <span className="text-black">
                          {articleGroupName?.article_group_name}
                        </span>
                      </div>
                      <div>
                        ML:{' '}
                        <span className="text-black">
                          {addedArticle?.volume?.total}
                        </span>
                      </div>
                      <div className="">
                        Pol:
                        <span className="text-black">
                          {' '}
                          {articleGender?.perfume_type_name}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="hidden monitor:inline-block monitor:flex monitor:justify-center monitor:items-center px-16 py-5 mt-6 text-xl text-white cursor-pointer bg-[#DCA950] "
                  onClick={() => navigate('/korpa')}
                >
                  <div className=" flex gap-4">
                    <div className="flex flex-row justify-center items-center gap-4 font-luxorlight ">
                      Prikaži korpu{' '}
                      <svg
                        height="11"
                        width="6"
                        fill="none"
                        viewBox="0 0 6 11"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M0.447959 9.65454L4.94971 5.68225L0.447959 1.2877"
                          stroke="white"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className=" monitor:hidden flex justify-center items-center px-16 py-5 mb-10 mt-12 text-xl text-white cursor-pointer bg-[#DCA950] "
          onClick={() => navigate('/korpa')}
        >
          <div className=" flex gap-4">
            <div className="flex flex-row justify-center items-center gap-4 font-luxorlight ">
              Prikaži korpu{' '}
              <svg
                height="11"
                width="6"
                fill="none"
                viewBox="0 0 6 11"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.447959 9.65454L4.94971 5.68225L0.447959 1.2877"
                  stroke="white"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SmallCart
