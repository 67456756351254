import React from 'react'

function PerfumeTypeTitle({ text, fontsize }) {
  return (
    <div>
      <p
        className={`text-[${fontsize}px] text-[#dca950c9] uppercase font-luxorthin`}
      >
        {text}
      </p>
    </div>
  )
}

export default PerfumeTypeTitle
