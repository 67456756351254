import React, { useState } from 'react'
import { AiOutlineClose } from 'react-icons/ai'
import { useDispatch, useSelector } from 'react-redux'
import { useDeleteUserMutation } from '../../api/services/usersApi'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import { logout } from '../../api/features/authSlice'

function UserDelete({ onClose }) {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [passwordInput, setPasswordInput] = useState('')
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false)
  const UserData = useSelector((state) => state.auth.user)
  const [deleteUser] = useDeleteUserMutation()

  const handleDelete = async () => {
    try {
      if (isCheckboxChecked) {
        await deleteUser(UserData?.user_id).unwrap()

        onClose()
        toast.success('Vaš nalog je uspješno izbrisan.')
        dispatch(logout())
        navigate('/login')
      } else {
        // Show an error message if the conditions are not met
        toast.error('Unesite ispravnu lozinku i potvrdite checkbox.')
      }
    } catch (error) {
      toast.error(error.data.message)
    }
  }

  return (
    <div className="fixed inset-0 flex items-center justify-center z-30 font-luxorlight">
      {/* Background overlay */}
      <div className="fixed inset-0 bg-black opacity-50 z-1"></div>

      <div className="flex flex-col bg-white justify-center z-40 w-4/5  md:w-2/5 p-4 md:p-8">
        <div className="flex flex-col">
          <button
            className="flex justify-end pt-2 hover:text-blue-700 "
            onClick={() => {
              onClose()
            }}
          >
            <AiOutlineClose />
          </button>
          <h1 className="font-bold text-2xl my-4">Brisanje naloga</h1>
          <p className="text-md">
            Nakon potvrde ovog obrasca imate 14 dana da se ponovno prijavite na
            nalog da biste ga vratili a potom se trajno briše.
          </p>
          <p className="flex flex-col my-4 text-md">
            Za brisanje vašeg naloga, unesite vašu lozinku.
          </p>
        </div>
        <div>
          <h2>Lozinka</h2>
          <input
            type="password"
            className="border rounded h-8 px-2 bg-gray-100"
            value={passwordInput}
            onChange={(e) => setPasswordInput(e.target.value)}
          />
        </div>
        <div className="flex items-center my-4">
          <input
            id="delete-checkbox"
            type="checkbox"
            value=""
            checked={isCheckboxChecked}
            onChange={() => setIsCheckboxChecked(!isCheckboxChecked)}
            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
          />
          <label
            htmlFor="delete-checkbox"
            className="ml-2 text-sm text-gray-900 dark:text-gray-300"
          >
            Potvrđujem da razumijem da će svi podaci mog naloga biti izbrisani i
            želim da nastavim.
          </label>
        </div>
        <div className="flex w-full gap-4 font-bold py-3 justify-center items-center my-4">
          <button
            className={`bg-[#DCA950] h-10 text-white w-full ${
              !isCheckboxChecked ? 'opacity-50 cursor-not-allowed' : ''
            }`}
            onClick={handleDelete}
            disabled={!isCheckboxChecked}
          >
            Izbriši nalog
          </button>
        </div>
      </div>
    </div>
  )
}

export default UserDelete
