import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

// Define an API slice
export const api = createApi({
  tagTypes: [
    'Articles',
    'FavoriteArticles',
    'Scent',
    'BagArticle',
    'Notes',
    'Orders',
    'OrderArticles',
    'ArticleGroups',
    'auth',
    'OrderStatus',
    'ShippingCost',
    'User',
    'Perfume',
    'Volumes',
    'Coupon',
    'Discount',
    'Email',
    'imagePosition',
  ],
  baseQuery: fetchBaseQuery({ baseUrl: `${process.env.REACT_APP_URL}/` }), // Set your API base URL here
  endpoints: (builder) => ({}),
})
