import React, { useEffect } from 'react'
import { FiPlus } from 'react-icons/fi'
import { AiFillCloseCircle, AiOutlineQuestionCircle } from 'react-icons/ai'
import { BiImageAdd } from 'react-icons/bi'
import Select from 'react-select'
import { toast } from 'react-toastify'
import {
  useGetOneArticleQuery,
  useUpdateArticleMutation,
} from '../../api/services/articleApi'
import { useGetAllArticleGroupsQuery } from '../../api/services/articleGroup'
import { useGetAllPerfumeTypesQuery } from '../../api/services/perfumeTypeApi'
import { useState } from 'react'
import { useGetAllScentQuery } from '../../api/services/scentsApi'
import { useAddScentToNoteMutation } from '../../api/services/notesApi'
import { useParams, useNavigate } from 'react-router-dom'
import { useAddNewImageMutation } from '../../api/services/articleImagesApi'
import { useGetPerfumeCategoriesQuery } from '../../api/services/perfumeApi'
import { useGetVolumesByArticleGroupQuery } from '../../api/services/volumesApi'
import SliderImage from '../SliderImage'

function UpdateArticle() {
  const [gornjaNota, setGornjaNota] = useState([])
  const [srednjaNota, setSrednjaNota] = useState([])
  const [donjaNota, setDonjaNota] = useState([])
  const [gornjaNotaId, setGornjaNotaId] = useState(0)
  const [srednjaNotaId, setSrednjaNotaId] = useState(0)
  const [donjaNotaId, setDonjaNotaId] = useState(0)
  const [selectedImages, setSelectedImages] = useState([])
  const [imagePreviews, setImagePreviews] = useState([])
  const navigate = useNavigate()

  const { article_id } = useParams()
  const { data: articleData } = useGetOneArticleQuery({ article_id })
  const { data: perfumeTypes } = useGetAllPerfumeTypesQuery()
  const { data: articleGroup } = useGetAllArticleGroupsQuery()
  const { data: scents } = useGetAllScentQuery()
  const { data: perfumeCategory } = useGetPerfumeCategoriesQuery()
  const { data: volume } = useGetVolumesByArticleGroupQuery(
    {
      article_group_id: articleData?.article_group_id,
    },
    {
      skip: !articleData?.article_group_id,
    },
  )

  const [formData, setFormData] = useState({
    article_name: '',
    article_code: '',
    volume: '',
    article_group_id: '',
    perfume_type_id: '',
    perfume_category_id: '',
    notes: [],
    price: '',
    discount: '',
    is_manual: 'N',
  })

  useEffect(() => {
    if (articleData) {
      setFormData({
        article_id: articleData.article_id,
        article_name: articleData.article_name || '',
        article_code: articleData.article_code || '',
        volume: articleData.volume.total || '',
        article_group_id: articleData.article_group_id || '',
        perfume_type_id: articleData?.perfume_type.perfume_type_id || '',
        notes: articleData.notes || [],
        image: articleData.image || '',
        price: articleData.price || '',
        perfume_category_id: articleData.perfume_category_id || '',
        volume_id: articleData.volume_id || '',
        discount: articleData.discount || '',
        is_manual: articleData.is_manual || '',
      })
      const gornjaScents = articleData?.notes[0]?.scents
      const srednjaScents = articleData?.notes[1]?.scents
      const donjaScents = articleData?.notes[2]?.scents
      setGornjaNotaId(articleData?.notes[0]?.note_id)
      setSrednjaNotaId(articleData?.notes[1]?.note_id)
      setDonjaNotaId(articleData?.notes[2]?.note_id)

      setGornjaNota(
        gornjaScents?.map((scent) => ({
          label: scent.scent_name,
          value: scent.scent_id,
        })),
      )
      setSrednjaNota(
        srednjaScents?.map((scent) => ({
          label: scent.scent_name,
          value: scent.scent_id,
        })),
      )
      setDonjaNota(
        donjaScents?.map((scent) => ({
          label: scent.scent_name,
          value: scent.scent_id,
        })),
      )
    }
  }, [articleData])
  const [updateArticle] = useUpdateArticleMutation()
  const [addScentToNote] = useAddScentToNoteMutation()
  const [addArticleImage] = useAddNewImageMutation()

  const scentOptions = scents?.map((scents) => ({
    label: scents.scent_name,
    value: scents.scent_id,
  }))
  const articleGroupOptions = articleGroup?.map((articleGroup) => ({
    label: articleGroup?.article_group_name,
    value: articleGroup?.article_group_id,
  }))
  const perfumeTypeOptions = perfumeTypes?.map((perfumeType) => ({
    label: perfumeType.perfume_type_name,
    value: perfumeType.perfume_type_id,
  }))
  const perfumeCategoryOptions = perfumeCategory?.map((perfumeCategory) => ({
    label: perfumeCategory.perfume_category_name,
    value: perfumeCategory.perfume_category_id,
  }))
  const volumeOptions = volume?.map((volume) => ({
    label: volume.total,
    value: volume.volume_id,
  }))

  const handleGornjaChange = (gornjaNota) => {
    setGornjaNota(gornjaNota)
  }
  const handleSrednjaChange = (srednjaNota) => {
    setSrednjaNota(srednjaNota)
  }
  const handleDonjaChange = (donjaNota) => {
    setDonjaNota(donjaNota)
  }

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files)
    const newSelectedImages = [...selectedImages, ...files]

    setSelectedImages(newSelectedImages)

    const previews = newSelectedImages.map((file) => URL.createObjectURL(file))
    setImagePreviews(previews)
  }

  //REMOVE image
  const handleImageRemove = (indexToRemove) => {
    const updatedSelectedImages = selectedImages.filter(
      (_, index) => index !== indexToRemove,
    )
    setSelectedImages(updatedSelectedImages)

    const updatedPreviews = updatedSelectedImages.map((file) =>
      URL.createObjectURL(file),
    )
    setImagePreviews(updatedPreviews)
  }

  const handleUpdateArticle = async () => {
    try {
      const AllNotes = gornjaNota?.concat(srednjaNota?.concat(donjaNota))
      // Call the mutation with the form data
      const FD = new FormData()

      selectedImages.forEach((image) => {
        FD.append('image', image)
      })
      FD.append('article_id', formData?.article_id)
      if (selectedImages.length) await addArticleImage(FD)

      await updateArticle({ ...formData, notes: AllNotes }).unwrap()

      toast.success('Artikal je uspješno ažuriran')
      // Clear the form data

      setImagePreviews([])
      clearFileInput()
      setSelectedImages([])
    } catch (error) {
      toast.error('Došlo je do greške prilikom ažuriranja artikla')
    }
  }

  const handleAddScentToNote = async () => {
    try {
      const gornjaNoteId = gornjaNotaId
      const srednjaNoteId = srednjaNotaId
      const donjaNoteId = donjaNotaId

      const gornjaScents = gornjaNota?.map((note) => note.value)
      const srednjaScents = srednjaNota?.map((note) => note.value)
      const donjaScents = donjaNota?.map((note) => note.value)

      await addScentToNote({
        note_id: gornjaNoteId,
        scents: gornjaScents,
      })

      await addScentToNote({
        note_id: srednjaNoteId,
        scents: srednjaScents,
      })

      await addScentToNote({
        note_id: donjaNoteId,
        scents: donjaScents,
      })
      toast.success('Note uspijesno promijenjene!')
      navigate('/admin/articles')
    } catch (error) {
      console.error('Error adding scents to notes:', error)
    }
  }

  useEffect(() => {
    return () => {
      imagePreviews.forEach((preview) => URL.revokeObjectURL(preview))
    }
  }, [imagePreviews])

  function clearFileInput() {
    const fileInput = document.getElementById('file-input')
    fileInput.value = ''
  }

  return (
    <div className="mx-4 px-5  p-4 bg-gray-100">
      <div className="flex my-4">
        <button className="shadow-md bg-gray-50 hover:bg-gray-100 w-6 h-6 border border-gray-300 flex items-center justify-center rounded-md">
          <span className="text-m">
            <FiPlus />
          </span>
        </button>
        <label htmlFor="addArticle" className="font-extrabold mx-2">
          DODAJ NOVI ARTIKAL
        </label>
      </div>

      {/* Osnovne informacije tabela */}
      <div className="flex md:flex-row flex-col md:space-x-8 w-full">
        <div className="flex flex-col md:w-2/4">
          <div className="">
            <label htmlFor="basicInfo" className="font-bold ">
              Osnovno
            </label>
            <div className="flex flex-col border rounded-2xl item-center px-7 py-3 my-3 bg-white ">
              <label htmlFor="nazivArtikla" className="text-gray-400 my-2">
                Naziv artikla
              </label>
              <input
                type="text"
                value={formData.article_name}
                onChange={(e) =>
                  setFormData({ ...formData, article_name: e.target.value })
                }
                className="border rounded-md pl-3 h-10"
              />
              <label htmlFor="sifraArtikla" className="text-gray-400 my-2 ">
                Šifra artikla
              </label>
              <input
                type="number"
                className="border rounded-md pl-3 h-10"
                value={formData.article_code}
                onChange={(e) =>
                  setFormData({ ...formData, article_code: e.target.value })
                }
              />
              <label htmlFor="" className="flex text-gray-400 my-2 ">
                ML <AiOutlineQuestionCircle size={18} className="mt-1 ml-2" />
              </label>
              <Select
                name="subcategory"
                options={volumeOptions}
                value={volumeOptions?.find((item) => {
                  return item?.value === formData?.volume_id || null
                })}
                onChange={(option) => {
                  setFormData((prev) => ({
                    ...prev,
                    volume_id: option.value,
                  }))
                }}
              />
            </div>
          </div>

          {/* Kategorija */}
          <div className="">
            <label htmlFor="Categories" className="text-black font-bold">
              Kategorija
            </label>
            <div className="flex flex-col border rounded-2xl item-center px-7 py-3 my-3 gap-y-1 bg-white">
              <label htmlFor="articleCategory" className="text-gray-400">
                Kategorija artikla
              </label>
              <Select
                options={articleGroupOptions}
                value={
                  articleGroupOptions?.find(
                    (item) => item.value === formData.article_group_id,
                  ) || null
                }
                onChange={(option) => {
                  setFormData((prev) => ({
                    ...prev,
                    article_group_id: option.value,
                  }))
                }}
              />
              <label htmlFor="sifraArtikla" className="text-gray-400">
                Podkategorija artikla
              </label>
              <Select
                name="subcategory"
                options={perfumeTypeOptions}
                value={
                  perfumeTypeOptions?.find(
                    (item) => item.value === formData.perfume_type_id,
                  ) || null
                }
                onChange={(option) => {
                  setFormData((prev) => ({
                    ...prev,
                    perfume_type_id: option.value,
                  }))
                }}
                classNamePrefix="select"
              />
              <label htmlFor="" className="text-gray-400">
                Kategorija mirisa parfema
              </label>
              <Select
                name="perfumeCategory"
                options={perfumeCategoryOptions}
                value={
                  perfumeCategoryOptions?.find(
                    (item) => item.value === formData.perfume_category_id,
                  ) || null
                }
                onChange={(option) => {
                  setFormData((prev) => ({
                    ...prev,
                    perfume_category_id: option.value,
                  }))
                }}
                classNamePrefix="select"
              />
            </div>
          </div>

          {/* Stanje artikla ili količina na stanju */}
          <div className="">
            <label htmlFor="inventory" className="text-black font-bold">
              Zalihe
            </label>
            <div className="flex flex-col border rounded-2xl item-center px-7 py-3 my-3 bg-white">
              <label htmlFor="amounth" className="text-gray-400">
                Količina
              </label>
              <input
                type="number"
                className="border rounded-md pl-3 h-10"
                onChange={(e) =>
                  setFormData({ ...formData, stock: e.target.value })
                }
              />
            </div>
          </div>
        </div>

        <div className="flex flex-col md:w-2/4">
          {/* prikaz fotografije */}
          {articleData?.article_images.length ? (
            <div>
              <label htmlFor="inventory" className="text-black font-bold">
                Fotografije artikla
              </label>
              <div className="flex flex-row border rounded-2xl px-7 py-3 my-3 bg-white">
                <SliderImage data={articleData} canDelete />
              </div>
            </div>
          ) : null}
          {/* Dodavanje fotografija */}
          <div>
            <label htmlFor="inventory" className="text-black font-bold">
              Fotografije artikla
            </label>
            <div className="flex flex-row border rounded-2xl  px-7 py-3 my-3 bg-white">
              <div className="relative">
                <input
                  onChange={handleImageChange}
                  type="file"
                  id="file-input"
                  accept="image/*"
                  className="absolute inset-0 opacity-0 cursor-pointer"
                  alt="Image input"
                />
                <div className="border-spacing-4 border-2 border-dashed border-blue-500 rounded-lg p-4">
                  <BiImageAdd className="w-16 h-16 mx-auto text-gray-400" />
                  <label
                    htmlFor="file-input"
                    className="block text-center mt-2"
                  >
                    Klikni za odabir ili prevuci
                  </label>
                </div>
              </div>
              <section className="flex flex-wrap gap-4">
                {imagePreviews.map((preview, index) => (
                  <div
                    key={index}
                    className="border border-gray-300 rounded-lg p-4 h-fit"
                  >
                    <img
                      src={preview}
                      alt={`Preview ${index}`}
                      className="flex max-w-sm max-h-12 mb-2"
                    />
                    <button
                      className="text-blue-500 text-xs rounded-lg hover:text-blue-600 cursor-pointer absolute p-1"
                      onClick={() => handleImageRemove(index)}
                    >
                      <AiFillCloseCircle className="h-5 w-5" />
                    </button>
                  </div>
                ))}
              </section>
            </div>
          </div>

          {/* Cijena artikla */}
          <div className="">
            <label htmlFor="price" className="text-black font-bold">
              Cijena artikla
            </label>
            <div className="border rounded-2xl px-7 py-3 my-3 bg-white">
              <label htmlFor="price" className="text-gray-400 block mb-1">
                Cijena
              </label>
              <div className="flex items-center bg-gray-100 rounded-md border overflow-hidden">
                <div className="bg-gray-100 rounded-md flex items-center px-3 py-1">
                  <h2 className="text-gray-600 text-sm mr-1 font-semibold">
                    KM
                  </h2>
                </div>
                <input
                  type="number"
                  className="pl-3 h-10 flex-grow bg-white rounded-md focus:outline-none "
                  placeholder="Cijena artikla"
                  value={formData.price}
                  onChange={(e) =>
                    setFormData({ ...formData, price: e.target.value })
                  }
                />
              </div>
              <label htmlFor="price" className="text-gray-400 block mb-1">
                Akcijski popust
              </label>
              <div className="items-center flex pb-2">
                <label className="relative inline-flex items-center cursor-pointer">
                  <input
                    type="checkbox"
                    checked={formData.is_manual === 'Y'}
                    onChange={(e) => {
                      const isChecked = e.target.checked
                      setFormData({
                        ...formData,
                        is_manual: isChecked ? 'Y' : 'N',
                        discount:
                          isChecked || formData.is_manual === 'N'
                            ? formData.discount
                            : 0,
                      })
                    }}
                    className="sr-only peer"
                    value={formData.is_manual}
                  />
                  <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                </label>
              </div>
              <div className="flex items-center bg-gray-100 rounded-md border overflow-hidden">
                <div className="bg-gray-100 rounded-md flex items-center px-3 py-1">
                  <h2 className="text-gray-600 text-sm mr-1 font-semibold">
                    %
                  </h2>
                </div>
                <input
                  type="number"
                  className="pl-3 h-10 flex-grow bg-white rounded-md focus:outline-none "
                  placeholder="Procenat popusta"
                  value={formData?.discount}
                  onChange={(e) =>
                    setFormData({ ...formData, discount: e.target.value })
                  }
                  disabled={formData.is_manual === 'N'}
                />
              </div>
            </div>
          </div>

          {/* Buttoni dodaj i odustani */}
          <div className="flex flex-row justify-evenly my-8 space-x-4">
            <button className="border h-10 w-3/6 font-bold bg-gray-300">
              ODUSTANI
            </button>
            <button
              className="border h-10 w-3/6 font-bold bg-slate-800 text-white"
              onClick={handleUpdateArticle}
            >
              AŽURIRAJ ARTIKAL
            </button>
          </div>

          <div className="flex flex-col border rounded-2xl item-center px-7 py-3 my-3 gap-y-1 bg-white">
            <label htmlFor="" className="text-gray-400">
              Gornja nota
            </label>
            <Select
              isMulti
              name="colors"
              options={scentOptions}
              onChange={handleGornjaChange}
              value={gornjaNota}
              className="basic-multi-select"
              classNamePrefix="select"
            />
            <label htmlFor="" className="text-gray-400">
              Srednja nota
            </label>
            <Select
              isMulti
              name="colors"
              options={scentOptions}
              onChange={handleSrednjaChange}
              value={srednjaNota}
              className="basic-multi-select"
              classNamePrefix="select"
            />
            <label htmlFor="" className="text-gray-400">
              Donja nota
            </label>
            <Select
              isMulti
              name="lowNote"
              options={scentOptions}
              onChange={handleDonjaChange}
              value={donjaNota}
              classNamePrefix="select"
            />
            <div>
              <button
                className="border h-10 w-3/6 font-bold bg-slate-800 text-white"
                onClick={handleAddScentToNote}
              >
                Ažuriraj note
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default UpdateArticle
