import React, { useMemo, useState } from 'react'
import MainPaginatedTable from '../MainPaginatedTable'
import { AiOutlineCloseCircle } from 'react-icons/ai'
import TableWrapper from '../TableWrapper'
import { useGetPaginatedUserOrdersQuery } from '../../api/services/ordersApi'
import { format } from 'date-fns'

function UserOrderList({ onClose, user }) {
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(25)
  const [filterInputs, setFilterInputs] = useState({})

  const { data: userOrders } = useGetPaginatedUserOrdersQuery({
    page: page,
    page_size: pageSize,
    user_id: user?.user_id,
  })

  // const handleFilterChange = useAsyncDebounce((value, columnId) => {
  //   setPage(1)
  //   setFilterInputs((prev) => {
  //     const newValue = value.trim() || ' '
  //     return { ...prev, [columnId]: newValue }
  //   })
  // }, 50)

  const columns = useMemo(
    () => [
      {
        Header: 'Broj narudžbe',
        accessor: 'order_number',
      },
      {
        Header: 'Datum',
        accessor: 'created_at',
        Cell: ({ row }) => {
          const dateObject = new Date(row.original.created_at)
          return format(dateObject, 'dd.MM.yyyy')
        },
      },
      {
        Header: 'Cijena',
        accessor: 'order_total',
      },

      {
        Header: 'Status',
        accessor: 'order_status.name',
        Cell: ({ row }) => {
          return (
            <span
              className={`border p-1 capitalize font-medium flex justify-center ${
                row?.original?.order_status?.name === 'NARUČENO'
                  ? 'bg-orange-200 text-orange-500'
                  : ''
              }
                    ${
                      row?.original?.order_status?.name === 'ISPORUČENO'
                        ? 'bg-green-200 text-green-500'
                        : ''
                    }
                    ${
                      row?.original?.order_status?.name === 'OTKAZANO'
                        ? 'bg-red-200 text-red-500'
                        : ''
                    }
                    `}
            >
              {row?.original?.order_status?.name?.toLowerCase()}
            </span>
          )
        },
      },
    ],
    [],
  )
  const userOrder = userOrders?.orders?.map((item) => {
    return item
  })

  const data = useMemo(
    () => (Array.isArray(userOrder) ? userOrder : []),
    [userOrder],
  )
  return (
    <>
      <div className="fixed inset-0 bg-black opacity-50"></div>
      <div className="fixed inset-0 flex items-center justify-center z-50 p-10 md:p-0">
        {/* Background overlay */}

        {/* Modal */}
        <div className="flex flex-col gap-2 rounded-lg bg-white z-10 w-full h-1/2 md:w-2/3 md:h-2/3 border border-black p-2">
          <div className="flex justify-between border-b-2 border-black">
            <h1>Narudzbe korisnika</h1>
            <span className="cursor-pointer" onClick={onClose}>
              {<AiOutlineCloseCircle />}
            </span>
          </div>
          <TableWrapper>
            <MainPaginatedTable
              columns={columns}
              data={data}
              actions={false}
              // pagination
              filtering
              pagination
              controlledPageCount={userOrders?.total_pages || 0}
              setPage={setPage}
              setPageAmount={setPageSize}
              count={userOrders?.total_count}
              pageManual={page}
              filterInputs={filterInputs}
              setFilterInputs={setFilterInputs}
            />
            {/* <MainTable data={data} columns={columns} /> */}
          </TableWrapper>
        </div>
      </div>
    </>
  )
}

export default UserOrderList
