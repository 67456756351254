import { Dialog, Transition } from '@headlessui/react'
import React, { Fragment } from 'react'

const Modal = ({
  children,
  title,
  page,
  showModal,
  setShowModal,
  setShowButton,
  initialFocus,
}) => {
  return (
    <Transition appear show={showModal} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 overflow-y-auto bg-slate-800/50 font-figtree"
        onClose={() => {
          setShowModal(false)
          if (setShowButton) setShowButton(true)
        }}
        initialFocus={initialFocus}
        open={showModal}
      >
        <div className="min-h-screen px-4 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0" />
          </Transition.Child>
          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div
              className={`${
                page === 'addUser'
                  ? 'max-w-2xl h-[550px]'
                  : page === 'payroll'
                  ? 'max-w-7xl'
                  : page === 'cubatore'
                  ? 'max-w-md h-[280px] my-1'
                  : page === 'report'
                  ? 'max-w-6xl max-h-screen my-1'
                  : page === 'companyEdit'
                  ? 'max-w-4xl'
                  : page === 'full'
                  ? 'max-w-full '
                  : page === 'article'
                  ? 'max-w-2xl min-h-[300px]'
                  : page === 'w-xl'
                  ? 'max-w-xl'
                  : 'max-w-md'
              } inline-block w-full p-6 ${
                page === 'full' ? 'my-0' : 'my-8'
              }  overflow-hidden text-left align-middle transition-all transform bg-slate-200 shadow-xl rounded-2xl`}
            >
              {title && (
                <Dialog.Title
                  as="h3"
                  className="flex items-center justify-between pb-2 mb-4 text-lg font-medium leading-6 text-gray-900 border-b border-b-slate-400"
                >
                  {title}
                  <span
                    onClick={() => {
                      setShowModal(false)
                      if (setShowButton) setShowButton(true)
                    }}
                    className="hover:text-red-400 hover:cursor-pointer"
                  >
                    x
                  </span>
                </Dialog.Title>
              )}
              {children}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  )
}

export default Modal
