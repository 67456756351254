import React from 'react'
import { toast } from 'react-toastify'
import Select from 'react-select'
import { countries } from '../../data/countries'
import { useEditUserMutation } from '../../api/services/usersApi'

const UpdateForm = ({ formData, setFormData, setOpenEdit }) => {
  const [editUser] = useEditUserMutation()

  // const [showPassword, setShowPassword] = useState(false)
  // const [showConfirmPassword, setShowConfirmPassword] = useState(false)

  // const toggleShowPassword = () => {
  //   setShowPassword(!showPassword)
  // }

  // setShowConfirmPassword(!showConfirmPassword)

  const handleChange = (e) => {
    const { name, value } = e.target
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }))
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    editUser({
      ...formData,
      // ...(formData?.password ? { password: formData?.password } : {}),
    })
      .unwrap()
      .then((res) => {
        toast.success(res.message)
        setOpenEdit(false)
      })

      .catch((err) => toast.error(err.data.message))
  }

  // const [selectedCountryOption, setSelectedCountryOption] = useState(null)

  const countryOptions = countries.map((item) => ({
    value: item.name,
    label: item.name,
  }))

  return (
    <form
      className="flex flex-col md:max-h-[300px] md:overflow-y-auto gap-5 p-2 w-full overflow-scroll"
      onSubmit={handleSubmit}
    >
      <div className="flex flex-col">
        <label htmlFor="first_name">Ime</label>
        <input
          type="text"
          id="first_name"
          name="first_name"
          className="border  bg-gray-100 rounded-md"
          value={formData.first_name}
          onChange={handleChange}
        />
      </div>
      <div className="flex flex-col">
        <label htmlFor="last_name">Prezime</label>
        <input
          name="last_name"
          value={formData.last_name}
          onChange={handleChange}
          type="text"
          id="last_name"
          className="border  bg-gray-100 rounded-md"
        />
      </div>

      <div className="flex flex-col">
        <label htmlFor="email">Email adresa</label>
        <input
          type="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          id="email"
          className="border  bg-gray-100 rounded-md"
        />
      </div>
      <div className="flex flex-col">
        <label htmlFor="phone_number">Broj telefona</label>
        <input
          type="number"
          id="phone_number"
          name="phone_number"
          value={formData.phone_number}
          onChange={handleChange}
          className="border  bg-gray-100 rounded-md"
        />
      </div>
      <div className="flex flex-col gap-4">
        <div className="flex flex-col">
          <label htmlFor="address">Adresa</label>
          <input
            name="address"
            value={formData.address}
            onChange={handleChange}
            type="text"
            id="address"
            className="border  bg-gray-100 rounded-md"
          />
        </div>

        <div className="flex flex-col">
          <label htmlFor="city">Grad</label>
          <input
            name="city"
            value={formData.city}
            onChange={handleChange}
            type="text"
            id="city"
            className="border bg-gray-100 rounded-md"
          />
        </div>
        <div className="flex flex-col">
          <label htmlFor="zip_code">Poštanski broj</label>
          <input
            name="zip_code"
            value={formData.zip_code}
            onChange={handleChange}
            type="number"
            id="zip_code"
            className="border bg-gray-100 rounded-md"
          />
        </div>
      </div>
      <div className="flex flex-col  font-medium text-base">
        <label htmlFor="country">Država</label>
        <Select
          name="country"
          value={countryOptions.find(
            (option) => option.value === formData.country,
          )}
          onChange={(selectedOption) => {
            // Ažurirajte userData.country sa vrednošću izabrane države
            setFormData({ ...formData, country: selectedOption.value })
          }}
          options={countryOptions}
        />
      </div>
      <div className="font-medium text-base text-start">
        <h1>Admin:</h1>
        <div className="flex gap-5">
          <div className="flex justify-center items-center">
            <label className="flex gap-0.5">
              <input
                type="checkbox"
                name="Y"
                checked={formData.is_admin === 'Y'}
                onChange={() =>
                  setFormData((prev) => ({
                    ...prev,
                    is_admin: 'Y',
                  }))
                }
              />
              Da
            </label>
          </div>
          <label className="flex gap-0.5">
            <input
              type="checkbox"
              name="N"
              checked={formData.is_admin === 'N'}
              onChange={() =>
                setFormData((prev) => ({
                  ...prev,
                  is_admin: 'N',
                }))
              }
            />
            Ne
          </label>
        </div>
      </div>
      <div className="bg-sky-600 border border-blue-700 flex justify-center items-center py-2 rounded-md text-white text-sm font-medium">
        {' '}
        <button type="submit">Izmjeni</button>
      </div>
    </form>
  )
}

export default UpdateForm
