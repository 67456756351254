import React, { useState } from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import OsnovneInformacije from '../components/checkout/osnovneInformacije'
import Racun from '../components/checkout/racun'
import { useSelector } from 'react-redux'

function Checkout() {
  const user = useSelector((state) => state.auth.user)

  const [formData, setFormData] = useState({
    ...(user?.user_id ? { user_id: user?.user_id } : {}),
    first_name: user?.first_name || '',
    last_name: user?.last_name || '',
    zip_code: user?.zip_code || '',
    country: user?.country || '',
    phone_number: user?.phone_number || '',
    email: user?.email || '',
    address: user?.address || '',
    city: user?.city || '',
    shipping_cost: '',
    additional_address: '',
    coupon: '',
    note: '',
  })
  const [totalPrice, setTotalPrice] = useState(0)
  const [isCoupon, setIsCoupon] = useState(false)
  const [couponAmount, setCouponAmount] = useState(0)

  return (
    <div className="flex flex-col w-full lg:flex-row md:pl-14 flex-wrap gap-3 font-luxor h-full">
      {/* LEFT */}
      <OsnovneInformacije
        setFormData={setFormData}
        formData={formData}
        couponAmount={couponAmount}
        isCoupon={isCoupon}
        setIsCoupon={setIsCoupon}
        setCouponAmount={setCouponAmount}
        setTotalPrice={setTotalPrice}
        totalPrice={totalPrice}
        user={user}
      />
      {/* RIGHT SIDE */}
      <Racun
        setFormData={setFormData}
        formData={formData}
        couponAmount={couponAmount}
        user={user}
        isCoupon={isCoupon}
      />
    </div>
  )
}

export default Checkout
