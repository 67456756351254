import React from 'react'
import background from '../../img/image 5.png'
import { useNavigate } from 'react-router-dom'

const HeroSection = ({ imagePositions }) => {
  const navigate = useNavigate()
  return (
    <>
      <div className="w-full max-h-[898px] overflow-hidden flex flex-col md:flex-row">
        <div className="w-full md:w-[50%] md:max-h-[898px] max-h-[389px] py-20 md:py-20 bg-black flex md:justify-start justify-center items-center text-center md:text-left">
          <div className="flex flex-col px-5 md:pl-[100px] font-light text-white max-w-[915px] monitor:pl-[140px]">
            <div className="w-full text-md md:text-xl monitor:text-2xl md:max-w-full font-luxorthin">
              PROBAJ ME
            </div>
            <div className="mt-4 w-full text-2xl md:text-4xl monitor:text-6xl  md:max-w-full flex flex-col">
              <span className="font-ivy">Otkrijte svoju esenciju</span>
              <span className="font-ivybold md:mt-4 mt-0">
                parfemirajte svoj svijet
              </span>
            </div>

            <div className="mt-5 w-full text-md md:text-lg monitor:text-xl leading-7 max-md:max-w-full font-luxorthin text-[#ffffff8c]">
              {' '}
              There are many variations of passages of Lorem Ipsum available,
              but the majority have suffered alteration in some form, by
              injected humour
            </div>
            <div className="flex items-center gap-2 md:gap-6 md:mt-9 mt-4 mx-auto md:mx-0 ">
              <button
                onClick={() => navigate('/shop')}
                className="bg-[#DCA950] text-[0.7rem] w-[130px] md:w-[185px]  md:text-lg xl:text-xl py-2 px-1 md:px-3 md:py-1 xl:px-5 xl:py-3   flex items-center justify-center gap-2 md:gap-5 font-luxorthin "
              >
                Kupite sada{' '}
                <svg
                  height="11"
                  width="6"
                  fill="none"
                  viewBox="0 0 6 11"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0.447959 9.65454L4.94971 5.68225L0.447959 1.2877"
                    stroke="white"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
        <div className=" w-full md:w-[50%]">
          <img
            className="w-full"
            src={
              Array.isArray(imagePositions) && imagePositions.length > 0
                ? imagePositions[0].isDefault
                  ? background
                  : imagePositions[0].image
                : ''
            }
            alt=""
          />
        </div>
      </div>
    </>
  )
}

export default HeroSection
