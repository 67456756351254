import React, { useEffect, useMemo, useState } from 'react'
import { Doughnut, Line } from 'react-chartjs-2'
import DailyChart from '../../components/Dashboard/DailyChart'
import {
  useGetLastFourQuery,
  useGetOrderDateQuery,
  useGetOrdersMainChartQuery,
} from '../../api/services/ordersApi'
import MainTable from '../../components/MainTable'
import { useGetOrderStatusNumberQuery } from '../../api/services/orderStatuses'
import { useNavigate } from 'react-router-dom'
import {
  useGetTopRatedQuery,
  useGetTopSellingQuery,
  useGetTotalSoldArticlesQuery,
} from '../../api/services/articleApi'
import Select from 'react-select'
import TopArticles from '../../components/Dashboard/TopArticles'
import TableWrapper from '../../components/TableWrapper'
import { format } from 'date-fns'
import { Chart } from 'chart.js/auto'
import 'chartjs-adapter-date-fns'
import ReactDatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { IoIosArrowDown } from 'react-icons/io'
import ExpandedTotalSales from '../../components/Dashboard/ExpandedTotalSales'
import Spinner from '../../components/Spinner'

function AdminDashboard() {
  const [selectedOption, setSelectedOption] = useState('top-selling')
  const [selectedData, setSelectedData] = useState([])
  const [fromDate, setFromDate] = useState(new Date())
  const [toDate, setToDate] = useState(new Date())
  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(new Date())
  const [isExpandedChartVisible, setIsExpandedChartVisible] = useState(false)

  const { data: recentOrders } = useGetLastFourQuery()
  const { data: orderStatusNumber } = useGetOrderStatusNumberQuery()
  const { data: topSelling } = useGetTopSellingQuery()
  const { data: topRated } = useGetTopRatedQuery()
  const { data: OrderDate } = useGetOrderDateQuery({
    from: new Date().toISOString().substring(0, 10),
    to: new Date().toISOString().substring(0, 10),
  })

  const { data: MainIncomeChart, isFetching } = useGetOrdersMainChartQuery({
    from: fromDate.toISOString().substring(0, 10),
    to: toDate.toISOString().substring(0, 10),
  })

  const { data: TotalSoldArticles } = useGetTotalSoldArticlesQuery({
    from: startDate.toISOString().substring(0, 10),
    to: endDate.toISOString().substring(0, 10),
  })

  const navigate = useNavigate()

  const options = [
    { value: 'top-selling', label: 'Najprodavanije' },
    { value: 'top-rated', label: 'Najbolje ocijenjeni' },
  ]
  const mainSelectOptions = [
    {
      value: new Date(new Date().setFullYear(new Date().getFullYear() - 1)),
      label: 'Godina',
    },
    {
      value: new Date(new Date().setMonth(new Date().getMonth() - 1)),
      label: 'Mjesec',
    },
  ]
  useEffect(() => {
    if (selectedOption === 'top-selling') {
      setSelectedData(topSelling)
    } else {
      setSelectedData(topRated)
    }
  }, [selectedOption, topSelling, topRated])

  const data1 = {
    labels: ['Naručeno', 'Dostavljeno', 'Otkazano'],
    datasets: [
      {
        data: Object.values(orderStatusNumber || {}), // Example data values
        backgroundColor: ['#ffd322', '#24ccb8', '#ff5967'],
      },
    ],
  }
  const handleShowExpandedChart = () => {
    setIsExpandedChartVisible(true)
  }

  const handleCloseExpandedChart = () => {
    setIsExpandedChartVisible(false)
  }
  const handleSoldArticlesDate = (selectedDate) => {
    setStartDate(selectedDate)
    setEndDate(selectedDate)
  }

  const handleMonthYearChange = (selectedDate) => {
    if (selectedDate) {
      const year = selectedDate.getFullYear()
      const month = selectedDate.getMonth()
      const lastDayOfMonth = new Date(year, month + 1, 0)
      setToDate(lastDayOfMonth)
    }
  }

  const dataDailyOrders = {
    labels: OrderDate?.deliveredOrders?.map((item) => item.updatedAt),
    datasets: [
      {
        label: 'Današnji broj narudžbi',
        data: OrderDate?.deliveredOrders?.map((_, index) => index + 1),
        borderColor: 'rgba(75, 192, 192, 1)', // Line color
        fill: true, // Fill the area under the line
        backgroundColor: 'rgba(75, 192, 192, 0.5)', // Fill color with 50% transparency
        tension: 0.5, // Controls the line curvature (0 for straight lines, 1 for very smooth curves)
        borderWidth: 3, // Line width
        pointRadius: 0, // Default point size
        pointBackgroundColor: 'rgba(75, 192, 192, 1)', // Point color
        pointHoverRadius: 10, // Enlarge point size on hover
        pointHitRadius: 12, // Increase the area that triggers the hover ef
      },
    ],
  }
  const optionsDaily = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        display: true,
        beginAtZero: true,
      },
      x: {
        display: true,
        beginAtZero: true,
        type: 'time',
        time: {
          unit: 'hour',
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
  }
  const dataDailyVisitors = {
    labels: [],
    datasets: [
      {
        label: '',
        data: [2, 4, 3, 5, 7, 1, 5],
        borderColor: '#b589fd', // Line color
        fill: true, // Fill the area under the line
        backgroundColor: 'rgba(181,137,253, 0.5)', // Fill color with 50% transparency
        tension: 0.5, // Controls the line curvature (0 for straight lines, 1 for very smooth curves)
        borderWidth: 3, // Line width
        pointRadius: 0, // Default point size
        pointBackgroundColor: '#b589fd', // Point color
        pointHoverRadius: 10, // Enlarge point size on hover
        pointHitRadius: 12, // Increase the area that triggers the hover ef
      },
    ],
  }

  const dataDailyIncome = {
    labels: OrderDate?.deliveredOrders?.map((item) => item.updatedAt),
    datasets: [
      {
        label: 'Današnji prihod',
        data: OrderDate?.deliveredOrders?.map((item) => +item.orderTotal),
        borderColor: '#fcda5b', // Line color
        fill: true, // Fill the area under the line
        backgroundColor: 'rgba(255,211,34, 0.35)', // Fill color with 50% transparency
        tension: 0.5, // Controls the line curvature (0 for straight lines, 1 for very smooth curves)
        borderWidth: 3, // Line width
        pointRadius: 0, // Default point size
        pointBackgroundColor: '#fcda5b', // Point color
        pointHoverRadius: 10, // Enlarge point size on hover
        pointHitRadius: 12, // Increase the area that triggers the hover ef
      },
    ],
  }
  const updatedArray = MainIncomeChart?.map((obj, index) => {
    const newDate = new Date(fromDate)
    newDate.setMonth(fromDate.getMonth() + index)
    return { ...obj, date: newDate }
  })

  const handleShowOrders = () => {
    navigate('/admin/orders')
  }
  const handleSelectChange = (selectedOption) => {
    setSelectedOption(selectedOption.value)
  }

  const maindata = {
    labels: updatedArray?.map((item) => item.date),
    datasets: [
      {
        label: 'Prihod',
        data: MainIncomeChart?.map((item) => item.value), // Example data for "Prošla godina"
        borderColor: 'rgba(75, 192, 192, 1)',
        tension: 0.3, // Controls the line curvature (0 for straight lines, 1 for very smooth curves)
        fill: true,
        backgroundColor: 'rgba(75, 192, 192, 0.5)',
      },
    ],
  }

  const mainoptions = {
    scales: {
      y: {
        beginAtZero: true,
      },
      x: {
        type: 'time',
        time: {
          unit: 'month',
        },
      },
    },
  }

  const columns = useMemo(
    () => [
      {
        Header: 'Broj Narudzbe',
        accessor: 'order_number',
      },
      {
        Header: 'Ime i Prezime Kupca',
        accessor: 'full_name',
        Cell: ({ row }) => {
          // Prilagodite kako se prikazuju podaci u ćeliji
          return `${row?.original?.first_name} ${row?.original?.last_name}`
        },
      },
      {
        Header: 'Cijena',
        accessor: 'order_total',
      },
      {
        Header: 'Email',
        accessor: 'email',
      },
      {
        Header: 'Status',
        accessor: 'order_status.name',
        Cell: ({ row }) => {
          return (
            <span
              className={`border p-1 capitalize font-medium flex justify-center ${
                row?.original?.order_status?.name === 'NARUČENO'
                  ? 'bg-orange-200 text-orange-500'
                  : ''
              } 
              ${
                row?.original?.order_status?.name === 'ISPORUČENO'
                  ? 'bg-green-200 text-green-500'
                  : ''
              }
              ${
                row?.original?.order_status?.name === 'OTKAZANO'
                  ? 'bg-red-200 text-red-500'
                  : ''
              } 
              `}
            >
              {row?.original?.order_status?.name?.toLowerCase()}
            </span>
          )
        },
      },
    ],
    [],
  )
  const recentOrderList = useMemo(
    () => (Array.isArray(recentOrders) ? recentOrders : []),
    [recentOrders],
  )

  return (
    <div className="flex flex-col p-5 gap-4">
      {/* gornji chartovi */}
      <section className="grid grid-cols-1 lg:grid-cols-3 gap-6 lg:gap-4  w-full overflow-hidden  ">
        <div className="flex flex-col bg-white rounded ">
          <p className="mx-2 my-1 text-gray-500">Današnji prihod</p>
          <h2 className="font-bold text-lg mx-2">
            {OrderDate?.total.toFixed(2)}
          </h2>
          <DailyChart dataDaily={dataDailyIncome} optionsDaily={optionsDaily} />
        </div>
        <div className="grid bg-white rounded ">
          <p className="mx-2 my-1 text-gray-500">Današnje narudžbe</p>
          <h2 className="font-bold text-lg mx-2">{OrderDate?.totalOrders}</h2>
          <DailyChart dataDaily={dataDailyOrders} optionsDaily={optionsDaily} />
        </div>

        <div className="flex flex-col bg-white rounded">
          <div className="flex items-center">
            <p className="mx-2 my-1 text-gray-500">Ukupno prodatih proizvoda</p>
            <button onClick={handleShowExpandedChart}>
              <IoIosArrowDown />
            </button>
          </div>
          <div className="flex items-center justify-center py-2">
            <ReactDatePicker
              selected={startDate}
              onChange={handleSoldArticlesDate}
              className="items-center text-center border"
              dateFormat="dd/MM/yyyy"
            />
          </div>
          <div className="flex justify-center items-center p-8 rounded-full border-b-8">
            <h2 className="font-bold text-2xl ">
              {TotalSoldArticles?.totalBought}
            </h2>
          </div>
        </div>
      </section>

      {/* mid */}
      <section className="flex flex-col lg:flex-row gap-4 w-full">
        <div className="flex flex-col bg-white rounded w-full lg:w-[67%]">
          <div className="flex flex-col w-full px-4 pt-4">
            <p className="font-bold">Prihodi</p>
            <div className="flex flex-wrap justify-evenly items-center ">
              <ReactDatePicker
                selected={fromDate}
                maxDate={toDate}
                showMonthYearPicker
                dateFormat="MM.yyyy"
                className="border text-center rounded bg-blue-400 text-white py-1 shadow-lg "
                onChange={(date) => setFromDate(date)}
              />
              <ReactDatePicker
                selected={toDate}
                minDate={fromDate}
                showMonthYearPicker
                dateFormat="MM.yyyy"
                className="border text-center rounded bg-blue-400 text-white py-1 shadow-lg"
                onChange={handleMonthYearChange}
              />
            </div>
          </div>
          {isFetching ? (
            <Spinner />
          ) : (
            <Line data={maindata} options={mainoptions} />
          )}
        </div>
        <div className="flex flex-col w-full lg:w-[32.8%] bg-white px-4 py-3 gap-4 ">
          <div className="flex justify-between items-center gap-4">
            <h2 className="font-bold text-lg">Top proizvodi</h2>
            <Select
              options={options}
              onChange={handleSelectChange}
              value={
                options.find((item) => item.value === selectedOption) || null
              }
            />
          </div>
          <TopArticles data={selectedData} />
        </div>
      </section>
      {/* bottom */}
      <section className="flex flex-col lg:flex-row  gap-4">
        <div className="flex flex-col bg-white rounded w-full lg:w-[35%] px-4 py-3">
          <h2 className="font-bold text-lg">Status narudžbi</h2>
          <div className="flex items-center justify-center w-full h-[300px]">
            <Doughnut data={data1} />
          </div>
        </div>
        <div className="flex flex-col bg-white rounded w-full lg:w-[65%] gap-8 items-center px-3 py-3">
          <div className="flex justify-between items-center w-full">
            <h2 className="font-bold">Nedavne narudžbe</h2>
            <button className="text-gray-500" onClick={handleShowOrders}>
              Pogledaj sve
            </button>
          </div>
          <TableWrapper className="overflow-x-auto">
            <MainTable columns={columns} data={recentOrderList} />
          </TableWrapper>
        </div>
      </section>
      {isExpandedChartVisible && (
        <ExpandedTotalSales
          onClose={handleCloseExpandedChart}
          startDate={startDate}
          endDate={endDate}
        />
      )}
    </div>
  )
}

export default AdminDashboard
