import React, { useEffect, useState } from 'react'
import {
  useGetShippingCostQuery,
  useUpdateShippingCostMutation,
} from '../../api/services/configuratorsApi'
import { toast } from 'react-toastify'

function CijenaPostarine() {
  const [updateShippingCost] = useUpdateShippingCostMutation()
  const { data: shippingData } = useGetShippingCostQuery()
  const [formData, setFormData] = useState({
    shipping_cost: '',
    free_shipping: '',
  })

  useEffect(() => {
    if (shippingData) {
      setFormData((prevData) => ({
        ...prevData,
        shipping_cost: shippingData.shipping_cost || '',
        free_shipping: shippingData.free_shipping || '',
      }))
    }
  }, [shippingData])
  const handleUpdateShippingCost = () => {
    try {
      const response = updateShippingCost({
        ...formData,
        shipping_cost: formData?.shipping_cost,
        free_shipping: formData?.free_shipping,
      })

      toast.success('Cijena poštarine je ažurirana')
    } catch (error) {
      toast.error('Došlo je do greške prilikom ažuriranja cijene poštarine')
    }
  }

  return (
    <>
      <label htmlFor="" className="my-4 font-bold">
        Unos poštarine
      </label>
      <article className="flex flex-col bg-white px-4 rounded space-y-2 py-2">
        <label htmlFor="" className="text-gray-400">
          Cijena poštarine
        </label>
        <input
          type="text"
          className="border rounded h-8 px-2"
          name="shipping_cost"
          value={formData.shipping_cost || ''}
          onChange={(e) =>
            setFormData({ ...formData, shipping_cost: e.target.value })
          }
        />
        <label htmlFor="" className="text-gray-400">
          Besplatna poštarina za narudžbe preko
        </label>
        <input
          type="text"
          className="border rounded h-8 px-2"
          name="free_shipping"
          value={formData.free_shipping || ''}
          onChange={(e) =>
            setFormData({ ...formData, free_shipping: e.target.value })
          }
        />
      </article>
      <button
        className="bg-gray-700 text-white h-8 m-4"
        onClick={handleUpdateShippingCost}
      >
        Izmijeni
      </button>
    </>
  )
}

export default CijenaPostarine
