import { useState, useEffect } from 'react'

const useScrollQuery = (response, page) => {
  const [data, setData] = useState({})

  useEffect(() => {
    if (response) setData((prev) => ({ ...prev, [`page_${page}`]: response }))
  }, [response, page])

  return { data }
}

export default useScrollQuery
