import React, { useEffect, useState } from 'react'
import { AiOutlineCloseCircle } from 'react-icons/ai'
import { useUpdateOrderMutation } from '../../api/services/ordersApi'
import { toast } from 'react-toastify'
import Spinner from '../Spinner'

const OrderStatusModal = ({ setOrderStatus, orderId, selectedOrder }) => {
  const [updateStatus, { isLoading }] = useUpdateOrderMutation()
  const onClose = () => {
    setOrderStatus(false)
  }

  const [status, setStatus] = useState({
    order_id: orderId,
    order_status_id: '',
    order_status_name: '',
  })

  useEffect(() => {
    if (selectedOrder) {
      setStatus({
        order_id: orderId,
        order_status_id: selectedOrder?.order_status_id,
        order_status_name: selectedOrder?.order_status?.name,
      })
    }
  }, [selectedOrder])

  const handleSubmit = (e) => {
    e.preventDefault()
    updateStatus(status)
      .unwrap()
      .then((res) => {
        toast.success(res.message)
        setOrderStatus(false)
      })

      .catch((err) => toast.error(err.data.message))
  }

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      {/* Background overlay */}
      <div className="fixed inset-0 bg-black opacity-50"></div>

      {/* Modal */}
      <div className="flex flex-col rounded-lg bg-white z-10 justify-start items-start w-52 p-2">
        {isLoading ? (
          <Spinner />
        ) : (
          <>
            <div className="flex justify-between px-2 py-2 w-full border-b-2">
              <p className="text-gray-600 font-medium text-lg">Uredi staus</p>
              <button onClick={onClose}>
                <AiOutlineCloseCircle className="w-5 h-5 text-gray-500" />
              </button>
            </div>

            <div className="font-medium text-base flex flex-col justify-start">
              <div className="flex flex-col gap-3 py-2">
                <div>
                  <label className="flex gap-2">
                    <input
                      type="checkbox"
                      name="status"
                      checked={status.order_status_name === 'NARUČENO'}
                      onChange={() =>
                        setStatus((prev) => ({
                          ...prev,
                          order_status_name: 'NARUČENO',
                          order_status_id: 1,
                        }))
                      }
                    />
                    Naručeno
                  </label>
                </div>
                <label className="flex gap-2">
                  <input
                    type="checkbox"
                    name="status"
                    checked={status.order_status_name === 'ISPORUČENO'}
                    onChange={() =>
                      setStatus((prev) => ({
                        ...prev,
                        order_status_name: 'ISPORUČENO',
                        order_status_id: 2,
                      }))
                    }
                  />
                  Isporučeno
                </label>
                <label className="flex gap-2">
                  <input
                    type="checkbox"
                    name="status"
                    checked={status.order_status_name === 'OTKAZANO'}
                    onChange={() =>
                      setStatus((prev) => ({
                        ...prev,
                        order_status_name: 'OTKAZANO',
                        order_status_id: 3,
                      }))
                    }
                  />
                  Otkazano
                </label>
              </div>
              <button
                className="border border-blue-200 bg-blue-600 text-white rounded-lg text-sm py-1 px-3"
                onClick={handleSubmit}
              >
                Izmjeni
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default OrderStatusModal
