import React, { useState } from 'react'
import { AiOutlineCloseCircle } from 'react-icons/ai'
import { toast } from 'react-toastify'
import { countries } from '../../data/countries'
import Select from 'react-select'
import { useAdminCreateMutation } from '../../api/services/usersApi'
import Spinner from '../Spinner'

const AddCustomerModal = ({ setAddModal }) => {
  const [adminCreate, { isLoading }] = useAdminCreateMutation()

  const onClose = () => {
    setAddModal(false)
  }

  const [userData, setUserData] = useState({
    first_name: '',
    last_name: '',
    email: '',
    username: '',
    address: '',
    country: '',
    zip_code: '',
    phone_number: '',
    password: '',
    gender: '',
    city: '',
    is_admin: '',
  })

  const handleChange = (e) => {
    const { name, value } = e.target
    setUserData((prevData) => ({
      ...prevData,
      [name]: value,
    }))
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    adminCreate(userData)
      .unwrap()
      .then((res) => {
        toast.success(res.message)
        setAddModal(false)
      })

      .catch((err) => toast.error(err.data.message))
  }

  const [selectedCountryOption, setSelectedCountryOption] = useState(null)

  const countryOptions = countries?.map((item) => ({
    value: item.name,
    label: item.name,
  }))

  const handleCountryChange = (selectedOption) => {
    setSelectedCountryOption(selectedOption)
  }

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 p-10">
      {/* Background overlay */}
      <div className="fixed inset-0 bg-black opacity-50"></div>

      {/* Modal */}
      <div className="flex flex-col rounded-lg bg-white z-10 justify-start items-center md:justify-center md:items-center w-full h-full md:h-auto md:w-1/3 overflow-auto">
        <div className="flex justify-between px-2 mt-6 w-full border-b border-gray-400">
          <p className="text-gray-600 font-medium text-lg">Dodaj kupca</p>
          <button onClick={onClose}>
            <AiOutlineCloseCircle className="w-5 h-5 text-gray-500" />
          </button>
        </div>
        {isLoading ? (
          <Spinner />
        ) : (
          <form
            className="flex flex-col h-full md:max-h-[300px] md:overflow-y-auto gap-5 p-2"
            onSubmit={handleSubmit}
          >
            <div className="flex flex-col md:flex-row justify-between gap-5 font-medium text-base">
              <div className="flex flex-col">
                <label htmlFor="first_name">Ime</label>
                <input
                  type="text"
                  id="first_name"
                  name="first_name"
                  className="border  bg-gray-100 rounded-md"
                  value={userData.first_name || ''}
                  onChange={handleChange}
                />
              </div>
              <div className="flex flex-col">
                <label htmlFor="last_name">Prezime</label>
                <input
                  name="last_name"
                  value={userData.last_name || ''}
                  onChange={handleChange}
                  type="text"
                  id="last_name"
                  className="border  bg-gray-100 rounded-md"
                />
              </div>
            </div>
            <div className="flex flex-col md:flex-row justify-between font-medium text-base">
              <div className="flex flex-col">
                <label htmlFor="email">Email adresa</label>
                <input
                  type="email"
                  name="email"
                  value={userData.email || ''}
                  onChange={handleChange}
                  id="email"
                  className="border  bg-gray-100 rounded-md"
                />
              </div>
              <div className="flex flex-col">
                <label htmlFor="phone_number">Broj telefona</label>
                <input
                  type="number"
                  id="phone_number"
                  name="phone_number"
                  value={userData.phone_number || ''}
                  onChange={handleChange}
                  className="border  bg-gray-100 rounded-md"
                />
              </div>
            </div>
            <div className="flex flex-col md-flex-row justify-between font-medium text-base flex-wrap gap-2">
              <div className="flex flex-col">
                <label htmlFor="address">Adresa</label>
                <input
                  name="address"
                  value={userData.address || ''}
                  onChange={handleChange}
                  type="text"
                  id="address"
                  className="border  bg-gray-100 rounded-md"
                />
              </div>
              <div className="flex flex-col">
                <label htmlFor="zip_code">Poštanski broj</label>
                <input
                  name="zip_code"
                  value={userData.zip_code || ''}
                  onChange={handleChange}
                  type="number"
                  id="zip_code"
                  className="border bg-gray-100 rounded-md"
                />
              </div>
              <div className="flex flex-col">
                <label htmlFor="password">Lozinka</label>
                <input
                  name="password"
                  value={userData.password || ''}
                  onChange={handleChange}
                  type="password"
                  id="password"
                  className="border bg-gray-100 rounded-md"
                />
              </div>
            </div>
            <div className="flex flex-col md:flex-row font-medium text-base gap-10">
              <div className="flex flex-col">
                <label htmlFor="city">Grad</label>
                <input
                  id="city"
                  type="text"
                  name="city"
                  className="border bg-gray-100 rounded-md"
                  value={userData.city || ''}
                  onChange={handleChange}
                />
              </div>
              <Select
                placeholder="Država"
                name="country"
                value={countryOptions.find(
                  (option) => option.value === userData.country,
                )}
                onChange={(selectedOption) => {
                  setUserData({ ...userData, country: selectedOption.value })
                }}
                options={countryOptions}
                className="flex items-center justify-center py-4 w-full"
              />
            </div>
            <div className="font-medium text-base">
              <h1>Admin?</h1>
              <div className="flex flex-col md:flex-row  gap-2 md:gap-5">
                <div className="flex justify-start items-center">
                  <label className="flex gap-0.5">
                    <input
                      type="checkbox"
                      name="is_admin"
                      checked={userData.is_admin === 'N'}
                      onChange={() =>
                        setUserData((prev) => ({
                          ...prev,
                          is_admin: 'N',
                        }))
                      }
                    />
                    Ne
                  </label>
                </div>
                <label className="flex gap-0.5">
                  <input
                    type="checkbox"
                    name="is_admin"
                    checked={userData.is_admin === 'Y'}
                    onChange={() =>
                      setUserData((prev) => ({
                        ...prev,
                        is_admin: 'Y',
                      }))
                    }
                  />
                  Da
                </label>
              </div>
            </div>

            <div className="py-3 bg-sky-600 border border-blue-700 flex justify-center items-center rounded-md text-white text-sm font-medium">
              <button type="submit">Dodaj kupca</button>
            </div>
          </form>
        )}
      </div>
    </div>
  )
}

export default AddCustomerModal
