import { api } from '../api'

export const orderApi = api.injectEndpoints({
  endpoints: (builder) => ({
    addOrderArticle: builder.mutation({
      query: (body) => ({
        url: ` api/v1/order-articles`,
        body,
        method: 'POST',
      }),
      invalidatesTags: ['OrderArticles'],
    }),
    addOrderArticles: builder.mutation({
      query: (body) => ({
        url: `api/v1/order-articles/multiple`,
        body,
        method: 'POST',
      }),
      invalidatesTags: ['OrderArticles'],
    }),
    getOrderArticles: builder.query({
      query: (order_id) => ({
        url: `api/v1/order-articles/get-order-articles/${order_id}`,
      }),
      providesTags: ['OrderArticles'],
    }),
  }),
})

export const {
  useAddOrderArticleMutation,
  useAddOrderArticlesMutation,
  useGetOrderArticlesQuery,
} = orderApi
