import React from 'react'

function NotificationOptions() {
  return (
    <div className="mx-4 h-screen md:mx-12 relative rounded flex flex-col mt-4 md:mt-12 mb-4 md:mb-8 gap-4 items-start justify-start md:items-center md:justify-center">
      <article className="flex flex-col bg-white px-4 md:px-8 shadow-xl border rounded-xl w-full max-w-md py-4 gap-4">
        <div className="flex flex-row rounded border p-2 w-full justify-between">
          <div className="flex flex-col">
            <label htmlFor="">Potvrda narudžbe</label>
            <p className="text-sm text-gray-400">
              Bićete obaviješteni kada Vaša narudžba bude potvrđena
            </p>
          </div>
          <div className="items-center flex mx-2">
            <label className="relative inline-flex items-center cursor-pointer">
              <input type="checkbox" value="" className="sr-only peer" />
              <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-700 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
            </label>
          </div>
        </div>
        <div className="flex flex-row rounded border p-2">
          <div className="flex flex-col">
            <label htmlFor="">Narudžba isporučena</label>
            <p className="text-sm text-gray-400">
              Bićete obaviješteni kada narudžba bude isporučena
            </p>
          </div>
          <div className="items-center flex mx-2">
            <label className="relative inline-flex items-center cursor-pointer">
              <input type="checkbox" value="" className="sr-only peer" />
              <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-700 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
            </label>
          </div>
        </div>
        <div className="flex flex-row rounded border p-2">
          <div className="flex flex-col">
            <label htmlFor="">Artikal na stanju</label>
            <p className="text-sm text-gray-400">
              Bićete obaviješteni kada artikal koji ste označili kao "Omiljeno
              ponovo bude dostupan"
            </p>
          </div>
          <div className="items-center flex mx-2">
            <label className="relative inline-flex items-center cursor-pointer">
              <input type="checkbox" value="" className="sr-only peer" />
              <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-700 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
            </label>
          </div>
        </div>
      </article>
    </div>
  )
}

export default NotificationOptions
