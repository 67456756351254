import React from 'react'
import { useGetOneArticleQuery } from '../../api/services/articleApi'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useGetPerfumeCategoriesQuery } from '../../api/services/perfumeApi'
import { useGetAllArticleGroupsQuery } from '../../api/services/articleGroup'

const AdditionalInformation = () => {
  const { id } = useParams()
  const user = useSelector((state) => state.auth.user)

  const { data } = useGetOneArticleQuery(
    {
      article_id: id,
      user_id: user?.user_id,
    },
    {
      skip: !id,
      refetchOnMountOrArgChange: true,
    },
  )

  const { data: cats } = useGetPerfumeCategoriesQuery()
  const { data: articleGroups } = useGetAllArticleGroupsQuery()

  const articleCategory = cats?.find(
    (item) => item?.perfume_category_id === data?.perfume_category_id,
  )

  const articleGroupName = articleGroups?.find(
    (item) => item?.article_group_id === data?.article_group_id,
  )

  return (
    <div className="py-1">
      <div className="flex flex-col gap-2 text-[#9C9C9C] font-luxorlight md:text-md text-md">
        <div>
          <p>
            Kategorija:{' '}
            <span className="text-black capitalize px-2">
              {articleGroupName?.article_group_name.toLowerCase()}
            </span>
          </p>
        </div>
        <div>
          <p>
            Tip Mirisa:{' '}
            <span className="text-black capitalize px-2">
              {articleCategory?.perfume_category_name.toLowerCase()}
            </span>
          </p>
        </div>
        <div>
          <p>
            Spol:
            <span className="text-black capitalize px-2">
              {data?.perfume_type?.perfume_type_name?.toLowerCase()}
            </span>
          </p>
        </div>
        <div>
          <p>
            Zapremina:{' '}
            <span className="text-black capitalize px-2">
              {data?.volume?.total}
            </span>
          </p>
        </div>
        {data?.notes?.map((item) => {
          return (
            <div key={Math.random()} className="flex  gap-3">
              <p className="capitalize">{item?.note_name?.toLowerCase()}: </p>
              <ul className="flex gap-1 text-black">
                {item?.scents?.map((scent, index) => {
                  return (
                    <li key={Math.random()}>
                      {scent?.scent_name}
                      {index === item?.scents?.length - 1 ? '' : ','}
                    </li>
                  )
                })}
              </ul>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default AdditionalInformation
