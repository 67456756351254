import { api } from '../api'

export const registerApi = api.injectEndpoints({
  endpoints: (builder) => ({
    createUsers: builder.mutation({
      query: (userData) => ({
        url: `api/v1/users`,
        method: 'POST',
        body: userData,
      }),
      invalidatesTags: ['auth'],
    }),
    loginUser: builder.mutation({
      query: (userData) => ({
        url: `api/v1/users/authenticate`,
        method: 'POST',
        body: userData,
      }),
      invalidatesTags: ['auth'],
    }),
  }),
})

export const { useCreateUsersMutation, useLoginUserMutation } = registerApi
