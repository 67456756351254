import { useMemo } from 'react'

const SelectColumnFilterPaginated = ({
  column,
  handleFilterChange,
  providedOptions = [],
  advancedOptions = false,
}) => {
  const { filterValue, setFilter, preFilteredRows, id } = column

  const options = useMemo(() => {
    const options = new Set()
    preFilteredRows?.forEach((row) => {
      options.add(row.values[id])
    })
    return [...options.values()]
  }, [id, preFilteredRows])

  return (
    <div className="p-1">
      <select
        className="select select-xs border border-slate-500 text-main-900 bg-white"
        value={filterValue}
        onChange={(e) => {
          setFilter(e.target.value || undefined)
          handleFilterChange(e.target.value, column.id)
        }}
      >
        <option value="">Sve</option>
        {
          !advancedOptions
            ? providedOptions && providedOptions.length > 0
              ? providedOptions.map((option, i) => (
                  <option key={i} value={option}>
                    {option}
                  </option>
                ))
              : options.map((option, i) => (
                  <option key={i} value={option}>
                    {option}
                  </option>
                ))
            : providedOptions && providedOptions.length > 0
            ? providedOptions.map((option, i) => (
                <option key={i} value={option.value}>
                  {option.label}
                </option>
              ))
            : null // You can render an empty list if advancedOptions is false
        }
      </select>
    </div>
  )
}

export default SelectColumnFilterPaginated
