import React from 'react'
import { useLoginUserMutation } from '../../api/services/authApi'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { setBag, signIn } from '../../api/features/authSlice'
import { updateCart } from '../../api/features/cartSlice'
import Spinner from '../Spinner'
import LoginPicture from '../../img/image 11.png'
import loginIcon from '../../img/Group 1210 (1).png'
import { useGetAllImagePositionsQuery } from '../../api/services/imagePosition'

const SignUp = () => {
  useSelector((state) => state.auth.user)
  const { data: imagePositions } = useGetAllImagePositionsQuery()

  const dispatch = useDispatch()

  const [loginUser, { isLoading }] = useLoginUserMutation()
  const navigate = useNavigate()

  const handleNavigate = () => {
    navigate('/login/register')
  }
  const handleHomepage = () => {
    navigate('/')
  }

  const [userData, setUserData] = useState({
    email: '',
    password: '',
  })
  const handleForgotPassword = () => {
    navigate('forgot-password')
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    loginUser(userData)
      .unwrap()
      .then((res) => {
        localStorage.setItem('token', res.token)
        localStorage.setItem('user', JSON.stringify(res.user))
        localStorage.setItem('bag', JSON.stringify(res.bag))
        localStorage.removeItem('cart', JSON.stringify(res.cart))

        toast.success(res.message)
        dispatch(signIn(res.user))
        dispatch(setBag(res.bag))
        dispatch(updateCart([]))

        navigate('/')
      })
      .catch((err) => toast?.error(err?.data?.message))
  }

  const handleChange = (e) => {
    const { name, value } = e.target
    setUserData((prevData) => ({
      ...prevData,
      [name]: value,
    }))
  }
  return (
    <div className="flex w-full h-full overflow-y-hidden">
      <div className="hidden lg:w-[40%] h-screen bg-white lg:flex justify-end md:justify-start items-center rounded-md">
        <div className="h-full w-full">
          <img
            src={
              Array.isArray(imagePositions) && imagePositions.length > 0
                ? imagePositions[7].isDefault
                  ? LoginPicture
                  : imagePositions[7].image
                : ''
            }
            alt="bannerImg"
            // style={{ maxHeight: '960px', width: '100%', height: 'auto' }}
            className="w-full h-screen"
          />
        </div>
      </div>
      {/* Drugi dio */}
      <div className=" md:px-14  pt-8 bg-white w-full lg:w-[60%] rounded-md flex justify-end md:block h-full">
        <div className="flex flex-col gap-6 md:w-full lg:mr-4 md:mr-0 justify-center items-center mt-20 md:my-16 monitor:mt-36">
          <div className="flex font-bold text-black text-lg">
            <div className="ml-2 monitor:mt-5 hover:cursor-pointer">
              <img
                src={loginIcon}
                alt="logo"
                className=""
                onClick={handleHomepage}
              />
            </div>
          </div>
          {isLoading ? (
            <Spinner />
          ) : (
            <form onSubmit={handleSubmit} className="">
              <div className="flex flex-col font-medium text-base gap-5 font-luxorlight px-10 lg:px-0 pt-6">
                <div className="flex flex-col ">
                  <label htmlFor="email">E-Mail adresa</label>

                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={userData.email}
                    onChange={handleChange}
                    className="border border-[#C2C2C2] h-[50px] md:w-[550px] monitor:w-[660px] px-2 outline-[#DCA950]"
                  />
                </div>
                <div className="flex flex-col w-full">
                  <label htmlFor="password">Lozinka</label>
                  <input
                    name="password"
                    value={userData.password}
                    onChange={handleChange}
                    type="password"
                    className="border border-[#C2C2C2] h-[50px] md:w-[550px] monitor:w-[660px] px-2 outline-[#DCA950]"
                  />
                </div>
                <div className="flex flex-col gap-3 mt-4">
                  <button
                    type="submit"
                    className="bg-[#DCA950] h-[50px] text-white font-medium p-2"
                  >
                    {'Prijavi se >'}
                  </button>
                  <div className="flex justify-between gap-1">
                    <div className="flex lg:flex-row flex-col px-2 gap-1 font-luxor">
                      <h3 className="text-[#9C9C9C] ">
                        Nemate kreiran račun ?
                      </h3>
                      <h3
                        onClick={handleNavigate}
                        className="cursor-pointer hover:underline"
                      >
                        Registruj se
                      </h3>
                    </div>
                    <p
                      className="flex justify-center text-[#9C9C9C] hover:underline hover: cursor-pointer"
                      onClick={handleForgotPassword}
                    >
                      Zaboravljena lozinka?
                    </p>
                  </div>
                </div>
              </div>
            </form>
          )}
        </div>
      </div>
    </div>
  )
}

export default SignUp
