import React, { useEffect, useMemo, useState } from 'react'
import {
  useDeleteVolumeMutation,
  useGetVolumesQuery,
} from '../../api/services/volumesApi'
import { AiOutlinePlusCircle } from 'react-icons/ai'
import TableWrapper from '../../components/TableWrapper'
import MainTable from '../../components/MainTable'
import { HiDotsVertical } from 'react-icons/hi'
import AddVolumeModal from '../../components/volumes/AddVolumeModal'
import VolumesActionModal from '../../components/volumes/VolumesActionModal'
import EditVolumeModal from '../../components/volumes/EditVolumeModal'
import GlobalDelete from '../../components/GlobalDelete'
import { toast } from 'react-toastify'
import MainPaginatedTable from '../../components/MainPaginatedTable'
import { FiEdit } from 'react-icons/fi'
import { FaTrash } from 'react-icons/fa'

const Volumes = () => {
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(25)

  const [filterInputs, setFilterInputs] = useState({})

  const query = useMemo(() => {
    let queryString = ''
    for (const [key, value] of Object.entries(filterInputs)) {
      if (value.trim() !== '') {
        queryString += `${key}=${encodeURIComponent(value)}&`
      }
    }

    return queryString
  }, [filterInputs])

  const { data: allVolumes, isFetching } = useGetVolumesQuery({
    page: page,
    page_size: pageSize,
    query,
  })

  const [expandedRow, setExpandedRow] = useState(null)
  const [selectedVolume, setSelectedVolume] = useState('')
  const [openEdit, setOpenEdit] = useState(false)
  const [openDelete, setOpenDelete] = useState(false)
  const [addVolume, setAddVolume] = useState(false)
  const [deleteVolume] = useDeleteVolumeMutation()

  const data = useMemo(
    () => (Array.isArray(allVolumes?.volumes) ? allVolumes?.volumes : []),
    [allVolumes?.volumes],
  )

  const handleRowClick = (row) => {
    if (expandedRow === row.id) {
      setExpandedRow(null)
    } else {
      setExpandedRow(row.id)
      setSelectedVolume(row.original)
    }
  }

  const handleDelete = async () => {
    try {
      await deleteVolume(selectedVolume?.volume_id)
      setOpenDelete(false)
      toast.success('Uspješno obrisano!')
    } catch (error) {
      toast.error('Došlo je do greške prilikom brisanja volumena')
    }
  }

  const handleCancel = () => {
    setOpenDelete(false)
  }

  const columns = useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'volume_id',
      },
      {
        Header: 'Total',
        accessor: 'total',
      },
      {
        Header: 'Grupe',
        accessor: 'article_group_name',
        Cell: ({ row }) => {
          return (
            <span className="capitalize">{`${row?.original?.article_groups?.map(
              (item) => {
                return item.article_group_name.toLowerCase()
              },
            )}`}</span>
          )
        },
      },
    ],
    [expandedRow],
  )

  const tableHooksArray = [
    {
      label: 'Uredi',
      Icon: FiEdit,
      onClick: (row) => {
        setSelectedVolume(row.original)
        setOpenEdit(true)
      },
      disabled: false,
    },
    {
      label: 'Izbriši',
      Icon: FaTrash,
      onClick: (row) => {
        setOpenDelete(true)
        setSelectedVolume(row.original)
      },
      disabled: false,
    },
  ]

  useEffect(() => {
    if (addVolume || openEdit || openDelete) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'auto'
    }
  }, [addVolume, openEdit, openDelete])

  return (
    <>
      <div className="px-4">
        <div className="bg-white border rounded-lg p-2 flex justify-start items-center">
          <div
            className="p-2 bg-blue-500 border rounded-lg text-white flex items-center justify-between gap-3 cursor-pointer"
            onClick={() => setAddVolume(true)}
          >
            <AiOutlinePlusCircle />
            Dodaj količinu
          </div>
        </div>

        <div className="pt-10">
          <TableWrapper>
            <MainPaginatedTable
              data={data}
              columns={columns}
              actions={true}
              filtering
              // pagination
              pagination
              controlledPageCount={allVolumes?.total_pages || 0}
              setPage={setPage}
              setPageAmount={setPageSize}
              count={allVolumes?.total_count}
              pageManual={page}
              isFetching={isFetching}
              filterInputs={filterInputs}
              setFilterInputs={setFilterInputs}
              hooksArray={tableHooksArray}
            />
          </TableWrapper>
        </div>
      </div>
      {addVolume && <AddVolumeModal setAddVolume={setAddVolume} />}
      {openEdit && (
        <EditVolumeModal
          volume_id={selectedVolume?.volume_id}
          setOpenEdit={setOpenEdit}
        />
      )}
      {openDelete && (
        <GlobalDelete
          userId={selectedVolume?.volume_id}
          setOpenDelete={setOpenDelete}
          handleDelete={handleDelete}
          handleCancel={handleCancel}
        />
      )}
    </>
  )
}

export default Volumes
