import { Menu, Transition } from '@headlessui/react'
import { BsThreeDotsVertical } from 'react-icons/bs'

const TableActions = ({ children }) => {
  return (
    <Menu as="div" className="block">
      <Menu.Button className="hover:cursor-pointer hover:bg-slate-100 p-1 hover:rounded">
        <BsThreeDotsVertical />
      </Menu.Button>
      <Transition
        as="div"
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute overflow-visible right-12 md:right-24 -bottom-2 z-[99999] w-40 mt-4 origin-top-left bg-blue-500  divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="px-1 py-1">{children}</div>
        </Menu.Items>
      </Transition>
    </Menu>
  )
}

export default TableActions
