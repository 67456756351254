import { api } from '../api'

export const notesApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getAllNotes: builder.query({
      query: () => 'api/v1/notes/get-notes',
    }),
    getOneNote: builder.query({
      query: (note_id) => `api/v1/notes/get-note/${note_id}`,
    }),
    deleteNote: builder.mutation({
      query: (note_id) => ({
        url: `api/v1/notes/${note_id}`,
        method: 'DELETE',
      }),
    }),
    updateNote: builder.mutation({
      query: (body) => ({
        url: `api/v1/notes`,
        body,
        method: 'PUT',
      }),
    }),
    addNote: builder.mutation({
      query: (body) => ({
        url: `api/v1/notes`,
        body,
        method: 'POST',
      }),
    }),
    addScentToNote: builder.mutation({
      query: (body) => ({
        url: `api/v1/notes/add-scents-to-note`,
        body,
        method: 'POST',
      }),
      invalidatesTags: ['Notes', 'Articles'],
    }),
    removeScentFromNote: builder.mutation({
      query: (body) => ({
        url: `api/v1/notes/remove-scents-from-note`,
        body,
        method: 'POST',
      }),
    }),
  }),
})

export const {
  useAddNoteMutation,
  useDeleteNoteMutation,
  useGetAllNotesQuery,
  useGetOneNoteQuery,
  useAddScentToNoteMutation,
  useRemoveScentFromNoteMutation,
} = notesApi
