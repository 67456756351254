import React, { useState } from 'react'
import { AiFillStar, AiOutlineStar } from 'react-icons/ai'
import { useSelector } from 'react-redux'
import { useReviewArticlesMutation } from '../../api/services/articleApi'

function CreateReview({
  setRatingMess,
  setRating,
  rating,
  isUserMatch,
  article_id,
}) {
  const [addReview] = useReviewArticlesMutation()
  const user = useSelector((state) => state.auth.user)

  const [message, setMessage] = useState('')

  const handleRatingChange = () => {
    const stars = {
      article_id: article_id,
      user_id: user?.user_id,
      review: rating,
      comment: message || '',
    }
    addReview(stars)
  }
  return (
    <div className="w-full h-full">
      <p className="pl-6 pt-6 font-luxor text-xl">Napiši svoju recenziju</p>
      <div className="text-xl px-4 relative ">
        <div className="flex justify-center w-full py-4 px-2">
          <textarea
            placeholder="Recenzija..."
            className="border w-full h-[130px] outline-none p-2 font-luxorlight"
            name="text"
            onChange={(e) => setMessage(e.target.value)}
            value={message || ''}
          />
        </div>
        <div className="flex md:flex-row flex-col md:justify-between items-center gap-4 p-4  text-lg">
          <div className="flex items-center justify-center ">
            {Array.from({ length: 5 })?.map((_, index) => {
              return (
                <span
                  key={index}
                  className="cursor-pointer"
                  onClick={(e) => {
                    e.preventDefault()
                    setRating(index + 1)
                  }}
                >
                  {index < rating ? (
                    <AiFillStar
                      fill="#DCA950"
                      className="w-7 h-7 md:w-7 md:h-7"
                    />
                  ) : (
                    <AiOutlineStar
                      fill="#DCA950"
                      className="w-7 first-letter: h-7 md:w-7 md:h-7"
                    />
                  )}
                </span>
              )
            })}
          </div>
          <div className="flex items-center gap-2 md:gap-6 mt-9  ">
            <button
              onClick={handleRatingChange}
              className="bg-[#DCA950] text-white text-[0.7rem] w-[185px]  md:text-lg xl:text-xl py-1 px-1 md:px-3 md:py-1 xl:px-5 xl:py-3  flex items-center justify-center gap-2 md:gap-5 font-luxorthin "
            >
              Ocjeni sada{' '}
              <svg
                height="11"
                width="6"
                fill="none"
                viewBox="0 0 6 11"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.447959 9.65454L4.94971 5.68225L0.447959 1.2877"
                  stroke="white"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CreateReview
