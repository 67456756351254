import React from 'react'
import PerfumeTypeTitle from './PerfumeTypeTitle'
import { useNavigate } from 'react-router-dom'
import ArticleCard from './ArticleCard'
import { useGetSuggestedArticlesQuery } from '../../api/services/articleApi'

function ForHomeSection() {
  const navigate = useNavigate()
  const { data: allSuggested } = useGetSuggestedArticlesQuery(
    'AMBIJENTALNI MIRISI',
  )
  return (
    <>
      <div className="monitor:px-[140px] md:px-[100px] px-[35px] md:pt-[100px] pt-[70px]">
        <div className="hidden md:inline-block">
          <PerfumeTypeTitle text={'Odabrano za vas'} fontsize={20} />
        </div>
        <div className=" md:hidden">
          <PerfumeTypeTitle text={'Odabrano za vas'} fontsize={12} />
        </div>
        <div className="flex flex-row gap-3">
          <p className="font-luxor md:text-[45px] text-2xl md:leading-10 leading-[45px]">
            Najbolje za vaš dom
          </p>
          <p
            className="hover:underline text-[#DCA950] md:text-xl text-sm font-luxorlight cursor-pointer pt-3.5"
            onClick={() => {
              navigate('/ambijentalni-mirisi')
            }}
          >
            Prikaži sve
          </p>
        </div>
        <p className="text-[#C2C2C2] md:text-xl text-sm font-luxorthin">
          Lorem Ipsum is simply dummy text of the printing industry and pricing.
        </p>
      </div>
      <div className="md:flex md:flex-row md:justify-between monitor:px-[140px] grid grid-cols-2 gap-x-5 gap-y-[30px] md:px-[100px] px-[35px] md:pt-10 md:py-0 py-10 w-full overflow-hidden">
        {allSuggested?.slice(0, 4).map((item, index) => (
          <ArticleCard
            item={item}
            index={index}
            parfume_type={'ambijentalni mirisi'}
          />
        ))}
      </div>
    </>
  )
}

export default ForHomeSection
