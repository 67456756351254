import React, { useEffect, useState } from 'react'
import { BiSolidDashboard } from 'react-icons/bi'
import { BsArrowLeftSquareFill } from 'react-icons/bs'
import { FaClipboardList, FaUserCircle } from 'react-icons/fa'
import { GiDelicatePerfume } from 'react-icons/gi'
import { IoSettingsSharp } from 'react-icons/io5'
import { LuShoppingBag } from 'react-icons/lu'
import { MdCardGiftcard, MdMessage, MdOutlineDiscount } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'
import logo from '../../../img/icon.jpg'
import { RiInkBottleFill } from 'react-icons/ri'
const pages = [
  { path: '/admin/', label: 'Dashboard', icon: <BiSolidDashboard size={25} /> },
  {
    path: '/admin/orders',
    label: 'Narudžbe',
    icon: <FaClipboardList size={25} />,
  },
  {
    path: '/admin/articles',
    label: 'Proizvodi',
    icon: <LuShoppingBag size={25} />,
  },
  {
    path: '/admin/scentcrud',
    label: 'Vrste nota',
    icon: <GiDelicatePerfume size={25} />,
  },
  {
    path: '/admin/volumes',
    label: 'Zapremine',
    icon: <RiInkBottleFill size={25} />,
  },
  {
    path: '/admin/customers',
    label: 'Kupci',
    icon: <FaUserCircle size={24} />,
  },

  {
    path: '/admin/discount',
    label: 'Popusti',
    icon: <MdOutlineDiscount size={25} />,
  },
  {
    path: '/admin/coupon',
    label: 'Kuponi',
    icon: <MdCardGiftcard size={25} />,
  },
  { path: '/admin/messages', label: 'Poruke', icon: <MdMessage size={25} /> },
  {
    path: '/admin/settings',
    label: 'Podešavanja',
    icon: <IoSettingsSharp size={25} />,
  },
]

function AdminResponsive({ setOpenMenu }) {
  const navigate = useNavigate()
  const [activePage, setActivePage] = useState('/')
  const [menuOpen, setMenuOpen] = useState(false)

  useEffect(() => {
    setMenuOpen(true)
  }, [])

  const handlePageClick = (path) => {
    navigate(path)
    setActivePage(path)
    handleCloseMenu()
  }

  const handleCloseMenu = () => {
    setMenuOpen(false)
    setTimeout(() => {
      setOpenMenu(false)
    }, 300) // Adjust the duration as needed (300 milliseconds in this example)
  }

  return (
    <div className="fixed inset-0 flex items-start justify-start z-10 bg-black bg-opacity-50 overflow-hidden">
      <div
        className={`flex flex-col gap-6 bg-white min-w-[16rem] max-w-screen-sm w-full h-full p-4 transition-transform ${
          menuOpen ? 'transform translate-x-0' : 'transform -translate-x-full'
        }`}
      >
        <div className="w-full flex h-20 justify-center">
          {' '}
          {/* Center the content */}
          <img
            src={logo}
            alt=""
            className="h-full cursor-pointer" // Added cursor-pointer for the logo
            onClick={() => navigate('/')}
          />
        </div>

        {/* Navigation Links */}
        <div className="flex flex-col justify-center space-y-2 px-1 mx-auto w-full">
          {pages.map((page) => (
            <div
              key={page.path}
              className={`flex rounded-md h-8 items-center text-gray-400 space-x-2 px-1 hover:cursor-pointer ${
                activePage === page.path ? 'bg-blue-500 text-white' : ''
              }`}
              onClick={() => {
                handlePageClick(page.path)
                handleCloseMenu(false)
              }}
            >
              {page.icon}
              <label htmlFor="" className="hover:cursor-pointer">
                {page.label}
              </label>
            </div>
          ))}
        </div>
        {/* Close button */}
        <div className="flex items-center justify-center">
          <span className="flex items-center">
            <BsArrowLeftSquareFill
              className="w-8 h-8 cursor-pointer text-gray-400"
              onClick={handleCloseMenu}
            />
          </span>
        </div>
      </div>
      <div className="flex flex-col w-full py-4 px-8"></div>
    </div>
  )
}

export default AdminResponsive
