import React, { useState } from 'react'
import DatePicker from 'react-datepicker'
import { AiOutlinePlus, AiOutlineMail } from 'react-icons/ai'

function UserPayment() {
  const [selectedDate, setSelectedDate] = useState(null)

  const handleDateChange = (date) => {
    setSelectedDate(date)
  }
  return (
    <div className="flex flex-col h-full justify-center items-center px-12">
      <div className="flex flex-col gap-4 bg-white shadow-2xl rounded-lg border border-gray-300 p-4 w-1/2">
        <p className="text-gray-700 font-bold border-b border-black">
          Detalji kartice
        </p>

        <div className="w-full flex justify-center items-start flex-col gap-4">
          <div className="flex justify-between w-full">
            <div className="flex flex-col">
              <label htmlFor="cardName">Ime na kartici</label>
              <input
                type="text"
                name="card_name"
                id="cardName"
                className="border border-gray-600 rounded-md px-1 bg-gray-100"
              />
            </div>
            <div className="flex flex-col">
              <label>Datum isteka</label>
              <DatePicker
                className="border border-gray-600 rounded-md px-1 bg-gray-100"
                selected={selectedDate}
                onChange={handleDateChange}
                dateFormat="MM.yyyy"
                minDate={new Date()}
                showYearDropdown
                scrollableYearDropdown
              />
            </div>
          </div>
          <div className="flex justify-between w-full">
            <div className="flex flex-col">
              <label htmlFor="cardNumber">Broj kartice</label>

              <input
                type="number"
                name="card_number"
                id="cardNumber"
                className="border border-gray-600 rounded-md px-1 bg-gray-100 relative"
              />
            </div>
            <div className="flex flex-col">
              <label>CVV</label>
              <input
                type="number"
                className="border border-gray-600 rounded-md px-1 bg-gray-100 w-1/2"
              />
            </div>
          </div>
          <div className="flex justify-between w-full">
            <div className="flex flex-col relative">
              <label htmlFor="email">Email adresa</label>
              <span className="absolute inset-y-0 left-0 flex items-center pl-2">
                <AiOutlineMail />
              </span>
              <input
                type="email"
                name="email"
                id="email"
                className="border border-gray-600 rounded-md pl-8 pr-1 bg-gray-100"
              />
            </div>

            <div className="flex gap-2 items-center py-6 px-10">
              <span className="flex justify-start items-start">
                <AiOutlinePlus />
              </span>
              <p> Dodaj drugi email</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default UserPayment
