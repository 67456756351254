import React from 'react'
import { AiOutlineCheck } from 'react-icons/ai'
import { useNavigate } from 'react-router-dom'
import { useUpdateUserStatusQuery } from '../api/services/usersApi'
import { useEffect } from 'react'
import { toast } from 'react-toastify'

function Verification() {
  const navigate = useNavigate()
  const handleNavigate = () => {
    navigate('/login')
  }

  const urlParams = new URLSearchParams(window.location.search)
  const param1Value = urlParams.get('q')

  const { data: updateStatus, isError } = useUpdateUserStatusQuery({
    verification_code: param1Value,
  })

  return (
    <div className="flex flex-col justify-center items-center">
      <div className="flex justify-center items-center mb-8 mt-12">
        <div className="flex bg-[#FFDDA0] rounded-full justify-center items-center w-48 h-48">
          <div className=" items-center flex w-44 h-44   rounded-full justify-center">
            <div className="rounded-full bg-[#DCA950] w-40 h-40 text-white p-5 flex justify-center items-center">
              <AiOutlineCheck size={160} />
            </div>
          </div>
        </div>
      </div>
      <label htmlFor="" className="font-bold text-[45px] font-luxor">
        Čestitamo!
      </label>
      <label
        htmlFor=""
        className=" mx-6 font-bold text-center mt-6 font-luxor text-[45px]"
      >
        Uspješno ste verifikovali vaš nalog!
      </label>
      <label className="mx-6 text-2xl text-[#C2C2C2] text-center mt-6 font-luxorthin">
        E-mail sa kupon kodom za 10% POPUSTA vam je poslan!
      </label>
      <button
        className="w-52 bg-[#DCA950] mx-auto font-luxorlight my-auto text-white p-5 text-xl mt-10"
        onClick={handleNavigate}
      >
        Nastavi kupovinu
      </button>
    </div>
  )
}

export default Verification
