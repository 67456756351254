import React, { useState, useEffect } from 'react'
import ReactDatePicker from 'react-datepicker'
import { AiOutlineClose } from 'react-icons/ai'
import Select from 'react-select'
import { useGetAllArticleGroupsQuery } from '../../api/services/articleGroup'
import { toast } from 'react-toastify'
import { useAddDiscountMutation } from '../../api/services/articleApi'
import 'chartjs-adapter-date-fns'
import Spinner from '../Spinner'
import { useGetVolumesByArticleGroupQuery } from '../../api/services/volumesApi'
import { useGetAllPerfumeTypesQuery } from '../../api/services/perfumeTypeApi'

function AddDiscountModal({ onClose }) {
  const { data: articleGroup } = useGetAllArticleGroupsQuery()
  const [articleGroupId, setArticleGroupId] = useState('')
  const { data: perfume_types } = useGetAllPerfumeTypesQuery()
  const { data: volumes, refetch: getVolumes } =
    useGetVolumesByArticleGroupQuery({ article_group_id: articleGroupId })
  const [addDiscount, { isLoading }] = useAddDiscountMutation()

  useEffect(() => {
    if (articleGroup && articleGroup.length > 0) {
      setArticleGroupId(articleGroup[0].article_group_id)
    }
  }, [articleGroup])

  useEffect(() => {
    if (articleGroupId) {
      getVolumes({ article_group_id: articleGroupId })
    }
  }, [articleGroupId, getVolumes])

  const volumeOptions = volumes?.map((volume) => ({
    label: volume.total,
    value: volume?.volume_id,
  }))
  const perfume_type_options = perfume_types?.map((perfume_type) => ({
    label: perfume_type.perfume_type_name,
    value: perfume_type.perfume_type_id,
  }))
  const [discountData, setDiscountData] = useState({
    article_group_id: '',
    from: new Date(),
    to: new Date(),
    discount: '',
    volumes: [],
    perfume_types: [],
  })

  const handleChange = (e) => {
    const { name, value } = e.target
    setDiscountData((prevData) => ({
      ...prevData,
      [name]: value,
    }))
  }
  const totalVolumes = discountData?.volumes.map((volume) => volume.value)
  const perfumeTypeIds = discountData?.perfume_types.map((type) => type.value)

  const handleSubmit = (e) => {
    e.preventDefault()

    addDiscount({
      ...discountData,
      to: discountData?.to.toISOString().substring(0, 10),
      from: discountData?.from.toISOString().substring(0, 10),
      volume_id: totalVolumes,
      perfume_type_id: perfumeTypeIds,
    })
      .unwrap()
      .then((res) => {
        toast.success(res.message)
        onClose()
      })
      .catch((err) => toast.error(err.data.message))
  }
  const perfumeTypeOptions = articleGroup?.map((articleGroup) => ({
    label: articleGroup.article_group_name,
    value: articleGroup.article_group_id,
  }))
  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      {/* Background overlay */}
      <div className="fixed inset-0 bg-black opacity-50"></div>

      {/* Modal */}
      <div className="flex flex-col rounded-lg m-10 w-2/3 md:w-1/3 bg-white z-10 space-y-3 py-4 px-4 gap-3">
        <button className="flex justify-end p-2" onClick={onClose}>
          <AiOutlineClose />
        </button>
        <h1 className="flex justify-center font-bold ">Trajanje akcije</h1>
        {isLoading ? (
          <Spinner />
        ) : (
          <form
            action=""
            className="flex flex-col gap-6"
            onSubmit={handleSubmit}
          >
            <div className="flex flex-col md:flex-row justify-center md:justify-evenly gap-3 md:gap-6">
              <div className=" flex flex-col justify-between">
                <p className="flex justify-center">Od:</p>
                <ReactDatePicker
                  className="border text-center rounded bg-blue-400 text-white py-1 shadow-lg"
                  selected={discountData.from}
                  onChange={(date) =>
                    setDiscountData((prev) => ({ ...prev, from: date }))
                  }
                  minDate={new Date()}
                  dateFormat="dd.MM.yyyy"
                />
              </div>
              <div className="flex flex-col">
                <p className="flex justify-center"> Do:</p>
                <ReactDatePicker
                  className="border text-center rounded bg-blue-400 text-white py-1 shadow-lg"
                  selected={discountData?.to}
                  minDate={discountData?.from}
                  dateFormat="dd.MM.yyyy"
                  onChange={(date) =>
                    setDiscountData((prev) => ({ ...prev, to: date }))
                  }
                />
              </div>
            </div>
            {/* Mid section */}
            <div className="flex md:flex-row flex-col justify-evenly gap-3">
              <Select
                options={perfumeTypeOptions}
                className="w-full md:w-1/2"
                value={articleGroup?.find(
                  (option) => option.value === discountData?.article_group_id,
                )}
                onChange={(selectedOption) => {
                  setArticleGroupId(selectedOption.value)
                  setDiscountData({
                    ...discountData,
                    article_group_id: selectedOption.value,
                  })
                }}
              />
              <div className="flex items-center bg-gray-100 rounded-md border overflow-hidden w-full md:w-2/5">
                <div className="bg-gray-100 rounded-md flex items-center px-3 py-1">
                  <h2 className="text-gray-600 text-sm mr-1 font-semibold">
                    %
                  </h2>
                </div>
                <input
                  type="number"
                  name="discount"
                  className="pl-2 h-9 flex-grow bg-white rounded-md focus:outline-none "
                  placeholder="Procenat popusta"
                  value={discountData?.discount || ''}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="flex flex-row w-full justify-evenly items-center">
              <Select
                isMulti
                name="volumes"
                className="basic-multi-select"
                classNamePrefix="select"
                options={volumeOptions}
                value={discountData.volumes}
                onChange={(selectedOptions) => {
                  const selectedValues = selectedOptions.map(
                    (option) => option.value,
                  )
                  setDiscountData({
                    ...discountData,
                    volumes: selectedOptions,
                  })
                }}
              />
              <Select
                isMulti
                name="perfume_types"
                className="basic-multi-select"
                classNamePrefix="select"
                options={perfume_type_options}
                onChange={(selectedOptions) => {
                  const selectedValues = selectedOptions.map(
                    (option) => option.value,
                  )
                  setDiscountData({
                    ...discountData,
                    perfume_types: selectedOptions,
                  })
                }}
              />
            </div>
            <div className="flex justify-center">
              <button
                className="flex justify-center bg-blue-400 rounded-md text-lg text-white h-8 items-center px-2 text-center w-5/6"
                type="submit"
              >
                Potvrdi
              </button>
            </div>
          </form>
        )}
      </div>
    </div>
  )
}

export default AddDiscountModal
