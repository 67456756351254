// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header::-webkit-scrollbar {
  width: 5px; /* Prilagodite širinu klizača prema želji */
}

.header::-webkit-scrollbar-thumb {
  background-color: rgba(
    128,
    128,
    128,
    0.5
  ); /* Siva boja klizača (promijenite prema želji) */
  border-radius: 10px; /* Zaobljeni rubovi klizača */
}

.header::-webkit-scrollbar-track {
  background-color: rgba(
    192,
    192,
    192,
    0.2
  ); /* Siva boja traga (promijenite prema želji) */
}

/* Za ostale preglednike (Firefox, Edge, itd.) */
.header {
  scrollbar-width: thin; /* Debljina klizača (thin, auto, itd.) */
  scrollbar-color: rgba(128, 128, 128, 0.5) rgba(192, 192, 192, 0.2); /* Boja klizača i traga (promijenite prema želji) */
  border-radius: 10px; /* Zaobljeni rubovi klizača */
}

.scroller::-webkit-scrollbar {
  background-color: #000;
  width: 16px;
}

/* background of the scrollbar except button or resizer */
.scroller::-webkit-scrollbar-track {
  background-color: #fff;
}

/* scrollbar itself */
.scroller::-webkit-scrollbar-thumb {
  margin-top: 5px;
  background-color: #c7c7c7;
  border-radius: 10px;
  border: 4px solid #fff;
}

/* set button(top and bottom of the scrollbar) */
.scroller::-webkit-scrollbar-button {
  display: none;
}
`, "",{"version":3,"sources":["webpack://./src/styles/custom-scrollbar.css"],"names":[],"mappings":"AAAA;EACE,UAAU,EAAE,2CAA2C;AACzD;;AAEA;EACE;;;;;GAKC,EAAE,gDAAgD;EACnD,mBAAmB,EAAE,6BAA6B;AACpD;;AAEA;EACE;;;;;GAKC,EAAE,8CAA8C;AACnD;;AAEA,gDAAgD;AAChD;EACE,qBAAqB,EAAE,wCAAwC;EAC/D,kEAAkE,EAAE,mDAAmD;EACvH,mBAAmB,EAAE,6BAA6B;AACpD;;AAEA;EACE,sBAAsB;EACtB,WAAW;AACb;;AAEA,yDAAyD;AACzD;EACE,sBAAsB;AACxB;;AAEA,qBAAqB;AACrB;EACE,eAAe;EACf,yBAAyB;EACzB,mBAAmB;EACnB,sBAAsB;AACxB;;AAEA,gDAAgD;AAChD;EACE,aAAa;AACf","sourcesContent":[".header::-webkit-scrollbar {\r\n  width: 5px; /* Prilagodite širinu klizača prema želji */\r\n}\r\n\r\n.header::-webkit-scrollbar-thumb {\r\n  background-color: rgba(\r\n    128,\r\n    128,\r\n    128,\r\n    0.5\r\n  ); /* Siva boja klizača (promijenite prema želji) */\r\n  border-radius: 10px; /* Zaobljeni rubovi klizača */\r\n}\r\n\r\n.header::-webkit-scrollbar-track {\r\n  background-color: rgba(\r\n    192,\r\n    192,\r\n    192,\r\n    0.2\r\n  ); /* Siva boja traga (promijenite prema želji) */\r\n}\r\n\r\n/* Za ostale preglednike (Firefox, Edge, itd.) */\r\n.header {\r\n  scrollbar-width: thin; /* Debljina klizača (thin, auto, itd.) */\r\n  scrollbar-color: rgba(128, 128, 128, 0.5) rgba(192, 192, 192, 0.2); /* Boja klizača i traga (promijenite prema želji) */\r\n  border-radius: 10px; /* Zaobljeni rubovi klizača */\r\n}\r\n\r\n.scroller::-webkit-scrollbar {\r\n  background-color: #000;\r\n  width: 16px;\r\n}\r\n\r\n/* background of the scrollbar except button or resizer */\r\n.scroller::-webkit-scrollbar-track {\r\n  background-color: #fff;\r\n}\r\n\r\n/* scrollbar itself */\r\n.scroller::-webkit-scrollbar-thumb {\r\n  margin-top: 5px;\r\n  background-color: #c7c7c7;\r\n  border-radius: 10px;\r\n  border: 4px solid #fff;\r\n}\r\n\r\n/* set button(top and bottom of the scrollbar) */\r\n.scroller::-webkit-scrollbar-button {\r\n  display: none;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
