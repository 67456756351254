import React, { useState } from 'react'
import {
  AiFillCloseCircle,
  AiOutlineLeft,
  AiOutlineRight,
} from 'react-icons/ai'
import { useDeleteImageMutation } from '../api/services/articleImagesApi'
import { IoClose } from 'react-icons/io5'
import '../styles/custom-scrollbar.css'

const SliderImage = ({ data, canDelete }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0)
  const handleNextClick = () => {
    setCurrentImageIndex(
      (prevIndex) => (prevIndex + 1) % data.article_images.length,
    )
  }

  const handlePrevClick = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? data.article_images.length - 1 : prevIndex - 1,
    )
  }
  const [deleteImage] = useDeleteImageMutation()
  const [isModalOpen, setIsModalOpen] = useState(false)

  const handleImageClick = (index) => {
    setCurrentImageIndex(index)
    setIsModalOpen(true)
  }

  const closeModal = () => {
    setIsModalOpen(false)
  }

  return (
    <>
      {' '}
      <div className="flex flex-col-reverse lg:flex-row gap-4 lg:gap-6 w-full pt-4">
        <div className="monitor:flex lg:flex-col justify-between items-start gap-4 cursor-pointer monitor:max-h-[800px] md:max-h-[400px] hidden  overflow-y-scroll scrollbar-hide ">
          {data?.article_images?.map((item, index) => {
            return (
              <img
                key={index}
                src={item?.url}
                alt=""
                className={`w-20 h-20 lg:w-64 lg:h-64 cursor-pointer hidden lg:inline-block ${
                  index === currentImageIndex ? '' : ''
                } ${
                  data?.article_images?.length < 2
                    ? 'monitor:hidden lg:hidden md:hidden hidden'
                    : 'monitor:inline-block  '
                }`}
                onClick={() => setCurrentImageIndex(index)}
              />
            )
          })}
        </div>
        <div className="w-full h-full flex justify-start items-start relative">
          <div className="h-full w-full max-h-[90%] md:max-h-none">
            <div className="relative w-full h-full">
              <img
                src={data?.article_images[currentImageIndex]?.url}
                alt=""
                className="w-full h-full cursor-zoom-in monitor:max-h-[800px] monitor:min-w-[500px]  monitor:min-h-[800px] md:  md:max-h-[500px] md:min-h-[500px] md:min-w-[500px] min-w-[312px]  min-h-[400px] max-h-[400px] overflow-hidden object-cover "
                onClick={() => handleImageClick(currentImageIndex)}
              />
              {isModalOpen && (
                <div className="fixed inset-0 flex items-center justify-center z-50">
                  <div
                    className="absolute inset-0 bg-black opacity-70"
                    onClick={closeModal}
                  ></div>
                  <div className="bg-white p-4  z-50 relative h-fit md:max-h-[800px]">
                    <img
                      src={data?.article_images[currentImageIndex]?.url}
                      alt=""
                      className="h-full w-full"
                    />
                    <button
                      onClick={closeModal}
                      className="absolute top-0 right-0 m-4  text-gray-500 cursor-pointer"
                    >
                      <IoClose className="w-6 h-6" />
                    </button>
                  </div>
                </div>
              )}
              {canDelete ? (
                <button
                  className="text-blue-500 text-xs rounded-lg hover:text-blue-600 cursor-pointer absolute top-0 right-0 p-1"
                  onClick={() => {
                    setCurrentImageIndex(0)
                    deleteImage(
                      data?.article_images[currentImageIndex]?.article_image_id,
                    )
                  }}
                >
                  <AiFillCloseCircle className="h-6 w-6" />
                </button>
              ) : null}
              <div className="flex flex-row justify-between items-start gap-4 cursor-pointer mt-4 md:min-w-[500px] md:max-w-[500px] min-w-[300px] max-w-[300px] min-h-fit  monitor:hidden scroller overflow-x-scroll scroll-smooth   ">
                {data?.article_images?.map((item, index) => {
                  return (
                    <img
                      key={index}
                      src={item?.url}
                      alt=""
                      className={`w-20 h-20 lg:w-64 lg:h-64 cursor-pointer lg:inline-block ${
                        index === currentImageIndex
                          ? ''
                          : // 'border-2 border-[#DCA950]'
                            ''
                      } ${
                        data?.article_images?.length < 2
                          ? 'monitor:hidden lg:hidden hidden '
                          : 'monitor:inline-block inline-block '
                      }`}
                      onClick={() => setCurrentImageIndex(index)}
                    />
                  )
                })}
              </div>

              {data?.article_images.length > 1 ? (
                <div>
                  <div className="absolute monitor:top-1/2 md:top-1/3 top-1/2 left-2 md:left-2 transform -translate-y-1/2 monitor:left-2 cursor-pointer">
                    <div
                      className=" w-10 h-10   rounded-full flex items-center justify-center "
                      onClick={handlePrevClick}
                    >
                      <AiOutlineLeft className="w-8 h-8" />
                    </div>
                  </div>
                  <div className="absolute monitor:top-1/2 md:top-1/3 top-1/2 -right-24 md:-right-16 transform -translate-y-1/2 monitor:right-2 cursor-pointer">
                    <div
                      className=" w-10 h-10 rounded-full flex items-center justify-center  "
                      onClick={handleNextClick}
                    >
                      <AiOutlineRight className="w-8 h-8" />
                    </div>
                  </div>
                </div>
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default SliderImage
