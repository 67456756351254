import React from 'react'
import { FaTrash } from 'react-icons/fa'
import { AiFillEdit } from 'react-icons/ai'

function Actions({ setOpenDelete, setOpenEdit }) {
  const handleDelete = () => {
    setOpenDelete(true)
  }
  const handleEdit = () => {
    setOpenEdit(true)
  }
  return (
    <>
      <div>
        {/* <div className="fixed inset-0 bg-black opacity-50"></div> */}

        {/* Modal */}
        <div className="flex flex-col font-medium rounded-lg  bg-blue-500 text-white  justify-start items-start w-32 h-24 md:w-36 md:h-28 p-2 gap-2 z-[100]">
          <p>Akcije</p>
          <div className="flex flex-col gap-1 w-full">
            <button
              className="flex items-center rounded hover:bg-white hover:text-black p-1"
              onClick={handleEdit}
            >
              <AiFillEdit />
              Uredi
            </button>

            <button
              className="flex items-center rounded hover:bg-white hover:text-black p-1"
              onClick={handleDelete}
            >
              {' '}
              <FaTrash />
              Izbriši
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

export default Actions
