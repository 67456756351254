import React from 'react'
import { useGetOrderArticlesQuery } from '../api/services/orderArticlesApi'

function SubRow({ id }) {
  const { data: orderArticles } = useGetOrderArticlesQuery(id, {
    skip: !id,
  })

  return (
    <div className="py-2 border-b-4 border-[#DCA950] bg-gray-100 pl-4">
      {orderArticles?.map((item) => {
        return (
          <div
            key={Math.random()}
            className="grid grid-cols-4 place-items-center mb-2"
          >
            <div>
              <h3 className="mt-1 font-bold">Naziv artikla:</h3>
              <p className="text-sm">{item?.article_name}</p>
            </div>
            <div>
              <h3 className="mt-1 font-bold">Količina artikla:</h3>
              <p className="text-sm">{item?.amount} </p>
            </div>
            <div>
              <h3 className="mt-1 font-bold">Volumen:</h3>
              <p className="text-sm">{item?.volume} ML</p>
            </div>
            <div>
              <h3 className="mt-1 font-bold">Cijena artikla:</h3>
              <p className="text-sm">{item?.price} KM</p>
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default SubRow
