import React from 'react'
import PerfumeTypeTitle from './PerfumeTypeTitle'
import muski from '../../img/Group 1229.png'
import zenski from '../../img/Group 1230.png'
import { useNavigate } from 'react-router-dom'

function SelectiveCards({ imagePositions }) {
  const navigate = useNavigate()
  return (
    <>
      <div className="monitor:px-[140px] md:px-[100px] px-[35px] md:py-28 py-14">
        <div className="flex md:flex-row flex-col justify-between">
          <div className="flex flex-col gap-2">
            <PerfumeTypeTitle text={'selective'} fontsize={20} />
            <p className="md:text-4xl text-2xl font-luxor font-semibold">
              Selective za svakoga
            </p>
          </div>
          <div>
            <p className="font-luxorthin md:text-xl text-md text-[#C2C2C2] pt-5 md:w-[650px] w-[300px]">
              There are many variations of passages of Lorem Ipsum available and
              text, but the majority have suffered alteration in some form, by
              injected humour.
            </p>
          </div>
        </div>
        <div className="flex md:flex-row flex-col gap-4 md:h-[380px] h-[380px] pt-10 relative ">
          <div
            className="md:w-[50%] w-full overflow-hidden text-white h-fit cursor-pointer"
            onClick={() => {
              navigate('/selective?genders=1')
            }}
          >
            <img
              className="w-full"
              src={
                Array.isArray(imagePositions) && imagePositions.length > 0
                  ? imagePositions[1].isDefault
                    ? muski
                    : imagePositions[1].image
                  : ''
              }
              alt=""
            />{' '}
            <div className="absolute monitor:top-[365px] monitor:left-11 md:top-[215px] md:left-11 bottom-48 left-5 text-white cursor-pointer">
              <p className="md:text-4xl text-2xl font-luxor">Muški Selective</p>
              <p className="md:text-xl  text-lg font-luxorthin hover:text-[#DCA950]">
                Pogledaj sve
              </p>
            </div>
          </div>
          <div
            className="md:w-[50%] w-full overflow-hidden relative text-white h-fit cursor-pointer"
            onClick={() => {
              navigate('/selective?genders=2')
            }}
          >
            <img
              className="w-full"
              src={
                Array.isArray(imagePositions) && imagePositions.length > 0
                  ? imagePositions[2].isDefault
                    ? zenski
                    : imagePositions[2].image
                  : ''
              }
              alt=""
            />{' '}
            <div className="absolute monitor:top-[330px] monitor:left-11 md:top-[180px] md:left-11 bottom-3 left-5 cursor-pointer">
              <p className="md:text-4xl text-2xl font-luxor">
                Ženski Selective
              </p>
              <p className="md:text-xl text-lg font-luxorthin hover:text-[#DCA950]">
                Pogledaj sve
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default SelectiveCards
