import React from 'react'
import { useGetPerfumeCategoriesQuery } from '../../api/services/perfumeApi'
import { useGetAllPerfumeTypesQuery } from '../../api/services/perfumeTypeApi'
import { useGetVolumesByGroupQuery } from '../../api/services/volumesApi'
import { useSearchParams } from 'react-router-dom'

const FilterOptions = ({ article_group_id }) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const { data: perfumeCategories } = useGetPerfumeCategoriesQuery()
  const { data: perfumeType } = useGetAllPerfumeTypesQuery()
  const { data: volumes } = useGetVolumesByGroupQuery({ article_group_id })

  const selectedCategories = searchParams
    .getAll('categories')
    .map((cat) => decodeURIComponent(cat))
  const selectedVolume = searchParams
    .getAll('volumes')
    .map((vol) => decodeURIComponent(vol))
  const selectedGender = searchParams
    .getAll('genders')
    .map((gen) => decodeURIComponent(gen))

  const handleCategoryChange = (category) => {
    const currentGenders = searchParams.getAll('genders')
    const currentVolumes = searchParams.getAll('volumes')
    const currentCategories = searchParams.getAll('categories')
    let updatedCategories

    if (currentCategories.includes(String(category.perfume_category_id))) {
      updatedCategories = currentCategories.filter(
        (cat) => cat !== String(category.perfume_category_id),
      )
    } else {
      updatedCategories = [
        ...currentCategories,
        String(category.perfume_category_id),
      ]
    }

    updateSearchParams({
      genders: currentGenders,
      volumes: currentVolumes,
      categories: updatedCategories,
    })
  }

  const handleVolumeChange = (volume) => {
    const currentGenders = searchParams.getAll('genders')
    const currentVolumes = searchParams.getAll('volumes')
    const currentCategories = searchParams.getAll('categories')
    let updatedVolumes

    if (currentVolumes.includes(String(volume.volume_id))) {
      updatedVolumes = currentVolumes.filter(
        (vol) => vol !== String(volume.volume_id),
      )
    } else {
      updatedVolumes = [...currentVolumes, String(volume.volume_id)]
    }

    updateSearchParams({
      genders: currentGenders,
      volumes: updatedVolumes,
      categories: currentCategories,
    })
  }

  const handleGenderChange = (gender) => {
    const currentGenders = searchParams.getAll('genders')
    const currentVolumes = searchParams.getAll('volumes')
    const currentCategories = searchParams.getAll('categories')
    let updatedGenders

    if (currentGenders.includes(String(gender.perfume_type_id))) {
      updatedGenders = currentGenders.filter(
        (gen) => gen !== String(gender.perfume_type_id),
      )
    } else {
      updatedGenders = [...currentGenders, String(gender.perfume_type_id)]
    }

    updateSearchParams({
      genders: updatedGenders,
      volumes: currentVolumes,
      categories: currentCategories,
    })
  }

  const updateSearchParams = (updates) => {
    setSearchParams((prevParams) => {
      const currentParams = { ...prevParams }

      // Merge updates with existing parameters
      for (const key in updates) {
        if (updates.hasOwnProperty(key)) {
          // If parameter exists, append the new values
          if (Array.isArray(currentParams[key])) {
            currentParams[key] = [...currentParams[key], ...updates[key]]
          } else {
            // If parameter doesn't exist, create a new one
            currentParams[key] = updates[key]
          }
        }
      }

      // Remove empty parameters
      for (const key in currentParams) {
        if (
          Array.isArray(currentParams[key]) &&
          currentParams[key].length === 0
        ) {
          delete currentParams[key]
        }
      }

      return currentParams
    })
  }

  return (
    <div className="bg-[#FEFBF6] flex flex-col  gap-4  monitor:w-[350px] md:w-[200px] w-[130px]">
      <div className="flex flex-col items-start gap-2 border-b monitor:max-w-[295px] md:max-w-[165px] w-[100px] py-3">
        <h2 className="font-luxor md:text-[32px] text-lg text-black">
          Tip mirisa:
        </h2>
        {perfumeCategories?.map((category, index) => {
          const uniqueId = Math.random()
          return (
            <div className="flex gap-2 items-center" key={index}>
              <input
                className="md:w-4 w-3 accent-[#DCA950] bg-white"
                type="checkbox"
                value={category?.perfume_category_id}
                checked={selectedCategories?.find(
                  (item) => +item === category?.perfume_category_id,
                )}
                onChange={() => handleCategoryChange(category)}
                id={uniqueId}
              />
              <label
                className="font-luxorlight md:text-[22px] text-sm text-black"
                htmlFor={uniqueId}
              >
                {category?.perfume_category_name}
              </label>
            </div>
          )
        })}
      </div>
      <div className="flex flex-col items-start gap-2 border-b monitor:max-w-[295px] md:max-w-[165px] w-[100px] py-3">
        <h2 className="font-luxor md:text-[32px] text-lg text-black">ML:</h2>
        {volumes?.map((volume, index) => {
          const uniqueId = Math.random()
          return (
            <div className="flex gap-2 items-center" key={index}>
              <input
                className="md:w-4 w-3 accent-[#DCA950] bg-white"
                type="checkbox"
                value={volume?.volume_id}
                checked={selectedVolume?.find(
                  (item) => +item === volume?.volume_id,
                )}
                onChange={() => handleVolumeChange(volume)}
                id={uniqueId}
              />
              <label
                className="font-luxorlight md:text-[22px] text-sm text-black"
                htmlFor={uniqueId}
              >
                {volume?.total}
              </label>
            </div>
          )
        })}
      </div>
      <div className="flex flex-col items-start justify-start gap-2 border-b monitor:max-w-[295px] md:max-w-[165px] w-[100px] py-3">
        <h2 className="font-luxor md:text-[32px] text-lg text-black">Pol:</h2>
        {perfumeType?.map((gender, index) => {
          const uniqueId = Math.random()
          return (
            <div
              className="flex gap-2 items-center md:py-1 monitor:py-0"
              key={index}
            >
              <input
                className="md:w-4 w-3 accent-[#DCA950] bg-white"
                type="checkbox"
                value={gender?.perfume_type_id}
                checked={selectedGender?.find(
                  (item) => +item === gender?.perfume_type_id,
                )}
                onChange={() => handleGenderChange(gender)}
                id={uniqueId}
              />
              <label
                htmlFor={uniqueId}
                className="font-luxorlight md:text-[22px] text-sm text-black"
              >
                {gender?.perfume_type_name}
              </label>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default FilterOptions
