import React, { useEffect, useMemo, useState } from 'react'
import { AiOutlinePlusCircle } from 'react-icons/ai'
import {
  useDeleteUserMutation,
  useGetAllUsersQuery,
} from '../../api/services/usersApi'
import AddCustomerModal from '../../components/Customers/AddCustomerModal'
import EditModal from '../../components/Customers/EditModal'
import BlockModal from '../../components/Customers/BlockModal'
import TableWrapper from '../../components/TableWrapper'
import { toast } from 'react-toastify'
import GlobalDelete from '../../components/GlobalDelete'
import MainPaginatedTable from '../../components/MainPaginatedTable'
import SelectColumnFilterPaginated from '../../components/SelectColumnFilterPaginated '
import { useAsyncDebounce } from 'react-table'
import { FiEdit } from 'react-icons/fi'
import { FaTrash } from 'react-icons/fa'
import { MdBlock } from 'react-icons/md'
import { PiUserListDuotone } from 'react-icons/pi'
import UserOrderList from '../../components/Customers/UserOrderList'
import { format } from 'date-fns'

const Customers = () => {
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(25)

  const [filterInputs, setFilterInputs] = useState({})

  const query = useMemo(() => {
    let queryString = ''
    for (const [key, value] of Object.entries(filterInputs)) {
      if (value.trim() !== '') {
        queryString += `${key}=${encodeURIComponent(value)}&`
      }
    }

    return queryString
  }, [filterInputs])

  const { data: allUsers, isFetching } = useGetAllUsersQuery({
    page: page,
    page_size: pageSize,
    query,
  })

  const [deleteUser] = useDeleteUserMutation()

  const [expandedRow, setExpandedRow] = useState(null)
  const [selectedUser, setSelectedUser] = useState({})
  const [openEdit, setOpenEdit] = useState(false)
  const [openDelete, setOpenDelete] = useState(false)
  const [openBlock, setOpenBlock] = useState(false)
  const [openOrders, setOpenOrders] = useState(false)
  const handleRowClick = (row) => {
    if (expandedRow === row.id) {
      setExpandedRow(null)
    } else {
      setExpandedRow(row.id)
      setSelectedUser(row.original)
    }
  }
  const handleDelete = async () => {
    try {
      await deleteUser(selectedUser?.user_id)
      setOpenDelete(false)
      toast.success('Korisnik uspješno obrisan!')
    } catch (error) {
      toast.error('Došlo je do greške prilikom brisanja korisnika')
    }
  }

  const handleCancel = () => {
    setOpenDelete(false)
  }
  const handleOpenOrders = () => {
    setOpenOrders((prev) => !prev)
  }

  const handleFilterChange = useAsyncDebounce((value, columnId) => {
    setPage(1)
    setFilterInputs((prev) => {
      const newValue = value.trim() || ' '
      return { ...prev, [columnId]: newValue }
    })
  }, 50)

  const columns = useMemo(
    () => [
      {
        Header: 'Ime ',
        accessor: 'first_name',
        Cell: ({ row }) => {
          return (
            <span className="flex gap-1">
              <img
                src={row?.original?.profile_image || null}
                alt=""
                className="w-10 h-10 flex items-center justify-center rounded shadow space-x-1 bg-gray-200"
              />
              <div className="flex items-center justify-center">
                {`${row?.original?.first_name}`}
              </div>
            </span>
          )
        },
      },
      {
        Header: 'Prezime',
        accessor: 'last_name',
      },
      {
        Header: 'Potrošeno',
        accessor: 'total_spent',
      },
      {
        Header: 'Kreiran',
        accessor: 'created_at',
        Cell: ({ row }) => {
          const date = new Date(row?.original?.created_at?.slice(0, 10))
          return format(date, 'dd.MM.yyyy')
        },
      },
      {
        Header: 'Email',
        accessor: 'email',
      },
      {
        Header: 'Telefon',
        accessor: 'phone_number',
      },
      {
        Header: 'Status',
        accessor: 'user_status.name',
        Filter: ({ column }) => (
          <SelectColumnFilterPaginated
            column={column}
            handleFilterChange={handleFilterChange}
            advancedOptions
            providedOptions={[
              { label: 'AKTIVAN', value: 'AKTIVAN' },
              { label: 'NEAKTIVAN', value: 'NEAKTIVAN' },
              { label: 'BLOKIRAN', value: 'BLOKIRAN' },
            ]}
          />
        ),
        Cell: ({ row }) => {
          return (
            <span
              className={`border p-1 capitalize font-medium flex justify-center ${
                row?.original?.user_status?.name === 'AKTIVAN'
                  ? 'bg-green-200 text-green-500'
                  : ''
              } 
              ${
                row?.original?.user_status?.name === 'NEAKTIVAN'
                  ? 'bg-orange-200 text-orange-500'
                  : ''
              }
              ${
                row?.original?.user_status?.name === 'BLOKIRAN'
                  ? 'bg-red-200 text-red-500'
                  : ''
              } 
              `}
            >
              {row?.original?.user_status?.name?.toLowerCase()}
            </span>
          )
        },
      },
    ],
    [expandedRow],
  )

  const data = useMemo(
    () => (Array.isArray(allUsers?.users) ? allUsers?.users : []),
    [allUsers?.users],
  )

  const tableHooksArray = [
    {
      label: 'Narudžbe',
      Icon: PiUserListDuotone,
      onClick: (row) => {
        setSelectedUser(row.original)
        setOpenOrders(true)
      },
      disabled: false,
    },
    {
      label: 'Uredi',
      Icon: FiEdit,
      onClick: (row) => {
        setSelectedUser(row.original)
        setOpenEdit(true)
      },
      disabled: false,
    },
    {
      label: 'Izbriši',
      Icon: FaTrash,
      onClick: (row) => {
        setSelectedUser(row.original)
        setOpenDelete(true)
      },
      disabled: false,
    },
    {
      label: 'Blokiraj',
      Icon: MdBlock,
      onClick: (row) => {
        setSelectedUser(row.original)
        setOpenBlock(true)
      },
      disabled: false,
    },
  ]
  const [addModal, setAddModal] = useState(false)

  const addCustomer = () => {
    setAddModal(true)
  }

  useEffect(() => {
    if (addModal || openEdit || openDelete || openBlock) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'auto'
    }
  }, [addModal, openEdit, openDelete, openBlock])

  return (
    <>
      <div className="px-4">
        <div className="bg-white border rounded-lg p-2 flex justify-start items-center">
          <div
            className="p-2 bg-blue-500 border rounded-lg text-white flex items-center justify-between gap-3 cursor-pointer"
            onClick={addCustomer}
          >
            <AiOutlinePlusCircle />
            Dodaj kupca
          </div>
        </div>
        {addModal && (
          <AddCustomerModal setAddModal={setAddModal} addModal={addModal} />
        )}

        {/* Tabela */}
        <div className="pt-10">
          <TableWrapper>
            <MainPaginatedTable
              data={data}
              columns={columns}
              actions={true}
              filtering
              // pagination
              pagination
              controlledPageCount={allUsers?.total_pages || 0}
              setPage={setPage}
              setPageAmount={setPageSize}
              count={allUsers?.total_count}
              pageManual={page}
              isFetching={isFetching}
              filterInputs={filterInputs}
              setFilterInputs={setFilterInputs}
              hooksArray={tableHooksArray}
            />
          </TableWrapper>
        </div>
      </div>
      {openEdit && (
        <EditModal user_id={selectedUser?.user_id} setOpenEdit={setOpenEdit} />
      )}
      {openDelete && (
        <GlobalDelete
          userId={selectedUser?.user_id}
          setOpenDelete={setOpenDelete}
          handleDelete={handleDelete}
          handleCancel={handleCancel}
        />
      )}
      {openBlock && (
        <BlockModal user={selectedUser} setOpenBlock={setOpenBlock} />
      )}
      {openOrders && (
        <UserOrderList user={selectedUser} onClose={handleOpenOrders} />
      )}
    </>
  )
}

export default Customers
