import React from 'react'
import { useState } from 'react'
import { BsFillEyeSlashFill, BsFillEyeFill } from 'react-icons/bs'
import { useCreateUsersMutation } from '../../api/services/authApi'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import { setBag, signIn } from '../../api/features/authSlice'
import { useDispatch } from 'react-redux'
import Spinner from '../Spinner'
import ReactDatePicker from 'react-datepicker'
import { useCreateEmailVerificationMutation } from '../../api/services/usersApi'

const RegisterForm = () => {
  const [createUsers, { isLoading }] = useCreateUsersMutation()
  const [createEmailVerification] = useCreateEmailVerificationMutation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const handleNavigate = () => {
    navigate('/login')
  }

  const [showPassword, setShowPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)

  const toggleShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const toggleShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword)
  }

  const [userData, setUserData] = useState({
    first_name: '',
    last_name: '',
    email: '',
    phone_number: '',
    password: '',
    confirmPassword: '',
    gender: '',
    address: '',
    country: '',
    zip_code: '',
    username: '',
    city: '',
    birthday: 0,
  })

  const handleChange = (e) => {
    const { name, value } = e.target
    setUserData((prevData) => ({
      ...prevData,
      [name]: value,
    }))
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    createUsers(userData)
      .unwrap()
      .then((res) => {
        createEmailVerification({ user_id: res.user.user_id })
        localStorage.setItem('token', res.token)
        localStorage.setItem('user', JSON.stringify(res.user))
        localStorage.setItem('bag', JSON.stringify(res.bag))
        toast.success(res.message)
        toast.info('E-mail sa verifikacijom je uspješno poslan!')
        dispatch(signIn(res.user))
        dispatch(setBag(res.bag))
        navigate('/')
      })

      .catch((err) => toast.error(err.data.message))
  }

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <form onSubmit={handleSubmit} className="">
          <div className="flex flex-col gap-4 w-full">
            <div className="grid grid-cols-1 lg:grid-cols-2 font-medium  gap-5 font-luxorlight w-full">
              <div className="flex flex-col w-full">
                <label htmlFor="first_name">Ime</label>

                <input
                  type="text"
                  id="first_name"
                  name="first_name"
                  className="border border-[#C2C2C2] h-[50px] px-2 outline-[#DCA950] w-full"
                  value={userData.first_name}
                  onChange={handleChange}
                />
              </div>
              <div className="flex flex-col">
                <label htmlFor="last_name">Prezime</label>
                <input
                  name="last_name"
                  value={userData.last_name}
                  onChange={handleChange}
                  type="text"
                  className="border border-[#C2C2C2] h-[50px] px-2 outline-[#DCA950]"
                />
              </div>
              <div className="flex flex-col">
                <label htmlFor="email" className="text-[#9C9C9C]">
                  Email adresa
                </label>
                <input
                  type="email"
                  name="email"
                  value={userData.email}
                  onChange={handleChange}
                  id="email"
                  className="border border-[#C2C2C2] h-[50px] px-2 outline-[#DCA950] w-full"
                />
              </div>
              <div className="flex flex-col">
                <label htmlFor="phone_number" className="text-[#9C9C9C]">
                  Broj telefona
                </label>
                <input
                  type="number"
                  id="phone_number"
                  name="phone_number"
                  value={userData.phone_number}
                  onChange={handleChange}
                  className="border border-[#C2C2C2] h-[50px] px-2 outline-[#DCA950] w-full"
                />
              </div>
              <div className="flex flex-col">
                <label htmlFor="birthday" className="text-[#9C9C9C]">
                  Datum rođenja
                </label>
                <ReactDatePicker
                  name="birthday"
                  className="border border-[#C2C2C2] h-[50px] px-2 outline-[#DCA950] w-full"
                  selected={userData.birthday}
                  // minDate={discountData.from}
                  // maxDate={formData.to}
                  dateFormat="dd.MM.yyyy"
                  onChange={(date) =>
                    setUserData((prev) => ({ ...prev, birthday: date }))
                  }
                />
              </div>
              <div className="flex flex-col">
                <label htmlFor="city" className="text-[#9C9C9C]">
                  Grad
                </label>
                <input
                  name="city"
                  value={userData?.city}
                  onChange={handleChange}
                  type="text"
                  id="city"
                  className="border border-[#C2C2C2] h-[50px] px-2 outline-[#DCA950] w-full"
                />
              </div>
              <div className="flex flex-col">
                <label htmlFor="Password" className="text-[#9C9C9C]">
                  Lozinka
                </label>
                <div className="relative">
                  <input
                    type={showPassword ? 'text' : 'password'}
                    id="Password"
                    className="border border-[#C2C2C2] h-[50px] px-2 outline-[#DCA950] w-full"
                    name="password"
                    value={userData.password || ''}
                    onChange={(e) => {
                      setUserData((prev) => ({
                        ...prev,
                        password: e.target.value,
                      }))
                    }}
                  />
                  <span
                    className="absolute top-4 right-3 cursor-pointer"
                    onClick={toggleShowPassword}
                  >
                    {showPassword ? <BsFillEyeFill /> : <BsFillEyeSlashFill />}
                  </span>
                </div>
              </div>
              <div className="flex flex-col">
                <label htmlFor="confirmPassword" className="text-[#9C9C9C]">
                  Potvrdi Lozinku
                </label>
                <div className="relative">
                  <input
                    type={showConfirmPassword ? 'text' : 'password'}
                    id="confirmPassword"
                    className="border border-[#C2C2C2] h-[50px] px-2 outline-[#DCA950] w-full"
                    value={userData.confirmPassword}
                    onChange={(e) => {
                      setUserData((prev) => ({
                        ...prev,
                        confirmPassword: e.target.value,
                      }))
                    }}
                  />
                  <span
                    className="absolute top-4 right-3 cursor-pointer"
                    onClick={toggleShowConfirmPassword}
                  >
                    {userData.password !== userData.confirmPassword ? (
                      <BsFillEyeFill />
                    ) : (
                      <BsFillEyeSlashFill />
                    )}
                  </span>
                </div>
                {userData.password !== userData.confirmPassword && (
                  <p className="text-red-500">Lozinke se ne podudaraju.</p>
                )}
              </div>
            </div>
            <div className="flex flex-col w-full mt-3">
              {' '}
              <button
                type="submit"
                disabled={userData.password !== userData.confirmPassword}
                className="w-full  bg-[#DCA950] h-[50px] border flex justify-center items-center text-white text-md font-medium"
              >
                {'Registruj se >'}
              </button>
              <div className="flex flex-col md:flex-row gap-1 font-luxor mt-3">
                <h3 className="text-[#9C9C9C]">Imaš kreiran račun? </h3>
                <h3
                  onClick={handleNavigate}
                  className="cursor-pointer hover:underline"
                >
                  Prijavi se
                </h3>
              </div>
            </div>
          </div>
        </form>
      )}
    </>
  )
}

export default RegisterForm
