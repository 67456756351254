import React from 'react'
import { useGetShippingCostQuery } from '../api/services/configuratorsApi'
import { IoCloseOutline } from 'react-icons/io5'

function HomepagePopup({ onClose }) {
  const { data: popupImage } = useGetShippingCostQuery()
  return (
    <>
      {/* Transparent overlay */}
      <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
        <div className="flex flex-col  mx-4 sm:mx-10 w-full sm:w-2/3 lg:w-[450px] lg:h-auto bg-white z-10 relative my-10">
          <button className="absolute top-2 right-2 p-2" onClick={onClose}>
            <IoCloseOutline size={25} className="text-white" />
          </button>
          {/* Close button (top right) */}
          <img
            src={popupImage?.pop_up_image || ''}
            alt=""
            className="flex justify-center w-full h-full items-center "
            style={{ width: '100%', height: '100%' }}
          />
        </div>
      </div>
    </>
  )
}

export default HomepagePopup
