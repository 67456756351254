import { toast } from 'react-toastify'
import {
  useEditUserMutation,
  useGetUserStatusQuery,
} from '../../api/services/usersApi'

const BlockModal = ({ setOpenBlock, user }) => {
  const { data } = useGetUserStatusQuery()
  const [editStatus] = useEditUserMutation()

  const onClose = () => {
    setOpenBlock(false)
  }

  const onConfirm = async () => {
    try {
      if (user.user_status_id === 1 || user.user_status_id === 2) {
        await editStatus({
          ...user,
          user_status_id: data[2]?.user_status_id,
        })
      } else {
        await editStatus({
          ...user,
          user_status_id: data[0]?.user_status_id,
        })
      }

      setOpenBlock(false)
      toast.success(
        `Korisnik uspješno ${
          user?.user_status_id === 1 || user?.user_status_id === 2
            ? 'blokiran'
            : 'odblokiran'
        }!`,
      )
    } catch (error) {
      toast.error('Došlo je do greške prilikom uređivanja statusa korisnika')
    }
  }

  return (
    <div className="flex justify-center items-center absolute inset-0 bg-black/60 z-10 p-10 md:p-0">
      <div className="text-xl border border-gray-500 max-w-xl mx-auto bg-white rounded-xl relative">
        <h2 className="border-b-2 border-gray-400 p-2">Korisnik</h2>

        <div className="flex justify-center px-16 py-4">
          <p className="text-gray-600 py-6">
            {` Da li ste sigurni da želite ${
              user?.user_status_id === 1 || user?.user_status_id === 2
                ? 'blokirati'
                : 'odblokirati'
            } korisnika?`}
          </p>
        </div>
        <div className="flex justify-center gap-4 p-4 bg-gray-200 rounded-b-xl text-lg">
          <button
            className="border-black  px-2 py-1 rounded bg-blue-500 text-white hover:bg-blue-600 hover:text-white"
            onClick={onClose}
          >
            Ne
          </button>
          <button
            className="border-black  px-2 py-1 rounded bg-blue-500 text-white hover:bg-blue-600 hover:text-white"
            onClick={onConfirm}
          >
            {`${
              user?.user_status_id === 1 || user?.user_status_id === 2
                ? 'Blokiraj'
                : 'Odblokiraj'
            }`}
          </button>
        </div>
      </div>
    </div>
  )
}

export default BlockModal
