import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import img1 from '../img/mad pics/paypal_2_x42.png'
import img2 from '../img/mad pics/visa_x42.png'
import img3 from '../img/mad pics/mastercard_x42.png'
import DeliveryInformation from '../components/aboutParfum/DeliveryInformation'
import AdditionalInformation from '../components/aboutParfum/AdditionalInformation'
import ParfumValue from '../components/aboutParfum/ParfumValue'
import { AiFillStar, AiOutlineStar } from 'react-icons/ai'
import {
  useGetOneArticleQuery,
  useSameArticleQuery,
} from '../api/services/articleApi'
import { useGetAllArticleGroupsQuery } from '../api/services/articleGroup'
import { useDispatch, useSelector } from 'react-redux'
import { FaHeart, FaRegHeart } from 'react-icons/fa'
import { toast } from 'react-toastify'
import {
  useAddFavoriteArticleMutation,
  useRemoveOneFavoriteArticlesMutation,
} from '../api/services/articleApi'
import {
  useAddArticleToCartMutation,
  useGetBagArticlesQuery,
  useUpdateBagArticleMutation,
} from '../api/services/bagArticleApi'
import { updateCart } from '../api/features/cartSlice'
import SliderImage from '../components/SliderImage'
import SimilarProducts from '../components/aboutParfum/SimilarProducts'

import MessageModal from '../components/selective/MessageModal'
import Reviews from '../components/aboutParfum/Reviews'
import Spinner from '../components/Spinner'
import PerfumeTypeTitle from '../components/homePage/PerfumeTypeTitle'
import SmallCart from '../components/aboutParfum/SmallCart'
import ReviewsOld from '../components/aboutParfum/ReviewsOld'

const AboutParfume = () => {
  const { id } = useParams()
  const bag = useSelector((state) => state.auth.bag)
  const navigate = useNavigate()
  const [newAmount, setNewAmount] = useState(1)

  const user = useSelector((state) => state.auth.user)
  const { data } = useGetOneArticleQuery(
    {
      article_id: id,
      user_id: user?.user_id,
    },
    {
      skip: !id,
      refetchOnMountOrArgChange: true,
    },
  )
  const averageRating = Math.round(data?.averageReview)
  const { data: articleGroups } = useGetAllArticleGroupsQuery()

  const articleGroupName = articleGroups?.find(
    (item) => item?.article_group_id === data?.article_group_id,
  )

  const { data: bagArticles } = useGetBagArticlesQuery(bag?.bag_id)
  const dispatch = useDispatch()

  const [isHeartClicked, setIsHeartClicked] = useState(false)
  const [isCartDisplayed, setIsCartDisplayed] = useState(false)
  const [addFavoriteArticle] = useAddFavoriteArticleMutation()
  const [removeFavoriteArticles] = useRemoveOneFavoriteArticlesMutation()

  const [updateBag] = useUpdateBagArticleMutation()

  const handleHeartClick = () => {
    const articles = {
      article_id: data?.article_id,
      user_id: user?.user_id,
    }

    if (isHeartClicked) {
      removeFavoriteArticles(articles)
      toast.success('Obrisano iz Favorita!')
    } else {
      addFavoriteArticle(articles)
      toast.success('Dodano u Favorite!')
    }
    setIsHeartClicked(!isHeartClicked)
  }

  useEffect(() => {
    if (
      data?.favoritedByUsers?.length &&
      data?.favoritedByUsers[0]?.user_id === user?.user_id
    ) {
      setIsHeartClicked(true)
    } else {
      setIsHeartClicked(false)
    }
  }, [data])

  const [addToCart, { isLoading }] = useAddArticleToCartMutation()
  const handleButtonClick = () => {
    const articles = {
      bag_id: bag?.bag_id,
      article_id: data?.article_id,
      article_name: data?.article_name,
      amount: newAmount,
      price: data?.price,
      volume: data?.volume?.total,
      article_images: data?.article_images,
      volume_id: data?.volume_id,
      discount: data?.discount,
      discount_price: data?.discount_price,
      article_group_name: articleGroupName?.article_group_name,
    }

    if (user?.user_id) {
      const existingBagItemIndex = bagArticles?.findIndex(
        (item) => item?.article_id === articles?.article_id,
      )
      if (existingBagItemIndex === -1) {
        addToCart(articles)
      } else {
        updateBag({
          ...articles,
          amount: bagArticles[existingBagItemIndex]?.amount + newAmount,
          bag_article_id: bagArticles[[existingBagItemIndex]]?.bag_article_id,
        })
      }
    } else {
      const cartData = JSON.parse(localStorage.getItem('cart')) || []

      const existingCartItemIndex = cartData.findIndex(
        (item) => item?.article_id === articles?.article_id,
      )

      if (existingCartItemIndex === -1) {
        cartData.push(articles)

        localStorage.setItem('cart', JSON.stringify(cartData))
        dispatch(updateCart(cartData))
      } else {
        cartData[existingCartItemIndex].amount += newAmount
        dispatch(updateCart(cartData))
        localStorage.setItem('cart', JSON.stringify(cartData))
      }
    }
  }
  const handleBuyNow = () => {
    const articles = {
      bag_id: bag?.bag_id,
      article_id: data?.article_id,
      article_name: data?.article_name,
      amount: 1,
      price: data?.price,
      volume: data?.volume?.total,
      article_images: data?.article_images,
      volume_id: data?.volume_id,
      discount: data?.discount,
      discount_price: data?.discount_price,
    }

    if (user?.user_id) {
      const existingBagItemIndex = bagArticles?.findIndex(
        (item) => item?.article_id === articles?.article_id,
      )
      if (existingBagItemIndex === -1) {
        addToCart(articles)
        toast.success('Dodano u korpu!')
        navigate('/korpa')
      } else {
        updateBag({
          ...articles,
          amount: bagArticles[existingBagItemIndex]?.amount + 1,
          bag_article_id: bagArticles[[existingBagItemIndex]]?.bag_article_id,
        })
        toast.success('Dodano u korpu!')
        navigate('/korpa')
      }
    } else {
      const cartData = JSON.parse(localStorage.getItem('cart')) || []
      const existingCartItemIndex = cartData.findIndex(
        (item) => item?.article_id === articles?.article_id,
      )

      if (existingCartItemIndex === -1) {
        cartData.push(articles)
        localStorage.setItem('cart', JSON.stringify(cartData))
        dispatch(updateCart(cartData))
        toast.success('Dodano u korpu!')
        navigate('/korpa')
      } else {
        cartData[existingCartItemIndex].amount += 1
        dispatch(updateCart(cartData))
        localStorage.setItem('cart', JSON.stringify(cartData))
        toast.success('Dodano u korpu!')
        navigate('/korpa')
      }
    }
  }
  const [rating, setRating] = useState(0)
  const [ratingMess, setRatingMess] = useState(false)

  useEffect(() => {
    if (ratingMess) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'auto'
    }
  }, [ratingMess])

  return (
    <>
      <div className="px-1 relative">
        <div className="flex flex-col lg:flex-row justify-between monitor:px-[140px] md:px-[100px] px-[35px] pt-0  md:py-10 ">
          <div className="flex flex-col w-full justify-start lg:w-1/2  lg:h-2/5 pr-[122px]">
            <SliderImage data={data} />
          </div>

          <div className="w-full lg:w-1/2 py-4 ">
            <div>
              <div className="relative">
                <PerfumeTypeTitle
                  text={articleGroupName?.article_group_name}
                  fontsize={18}
                />
                <p className="md:text-[40px] text-3xl w-[90%] font-luxor leading-10">{`${
                  data?.article_name
                }  ${data?.volume?.total} ${
                  data?.article_group_id !== 5 ? 'ml' : 'gr'
                }
        `}</p>
                {user !== null && (
                  <div className="md:w-[52px] md:h-[52px] w-[36px] h-[36px] cursor-pointer bg-black/95 absolute top-0 right-0 flex justify-center items-center">
                    <button onClick={() => handleHeartClick()}>
                      {isHeartClicked ? (
                        <FaHeart className="w-3.5 h-3.5 md:w-5 md:h-5 text-white" />
                      ) : (
                        <FaRegHeart className="w-3.5 h-3.5 md:w-5 md:h-5 text-white" />
                      )}
                    </button>
                  </div>
                )}
                <div className="flex flex-row gap-6  border-b pb-4 mt-3">
                  <div className="flex items-center justify-center">
                    {Array.from({ length: 5 })?.map((_, index) => (
                      <span
                        key={index}
                        onClick={(e) => {
                          e.preventDefault()
                          setRating(index + 1)
                        }}
                      >
                        {index < averageRating ? (
                          <AiFillStar
                            fill="#DCA950"
                            className="w-5 h-5 md:w-5 md:h-5"
                          />
                        ) : (
                          <AiOutlineStar
                            fill="#DCA950"
                            className="w-5 h-5 md:w-5 md:h-5"
                          />
                        )}
                      </span>
                    ))}
                    <p className="font-luxorthin text-[18px] ml-3">
                      {averageRating.toFixed(1)}
                    </p>
                  </div>
                  <div className="font-luxorthin md:flex flex-row gap-1 hidden ">
                    <p className="text-[#9C9C9C] md:text-[18px] text-md">
                      Šifra proizvoda:
                    </p>
                    <p className="text-[#000000] text-[18px]">
                      {data?.article_id}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex flex-col items-start justify-start border-b  py-4">
              {data?.discount && data?.discount > 0 ? (
                <div>
                  <p className=" md:text-base font-luxor text-[15px] md:text-[15px]   text-gray-400 line-through ">
                    {data?.price} KM
                  </p>
                  <p className=" rounded-xl font-luxor text-[35px] text-black inline-block ">
                    {data?.discount_price} KM
                  </p>
                </div>
              ) : (
                <p className="text-black font-luxor text-[35px]">
                  {data?.price} KM
                </p>
              )}
              <p className="text-[#C2C2C2] font-luxorthin text-[18px] leading-3">
                U cijenu uključen PDV (17%)
              </p>
            </div>

            <DeliveryInformation
              isCartDisplayed={isCartDisplayed}
              newAmount={newAmount}
              setNewAmount={setNewAmount}
              handleButtonClick={handleButtonClick}
              setIsCartDisplayed={setIsCartDisplayed}
            />
          </div>
        </div>
        <div className="flex md:flex-row flex-col w-full monitor:px-[140px] md:px-[100px] px-[35px] mt-[50px] pb-20 ">
          <div className="md:w-[45%] w-full mb-5 md:mb-0">
            <ParfumValue data={data} />
          </div>
          <div className="md:w-[55%] w-full md:pl-20">
            <Reviews
              rating={rating}
              setRating={setRating}
              data={data}
              user_id={user?.user_id}
              setRatingMess={setRatingMess}
            />
          </div>
        </div>
        <div>
          <SimilarProducts
            data={data}
            article_group_id={data?.article_group_id}
            article_id={data?.article_id}
          />
        </div>
        <div className="absolute -top-52 right-0">
          <SmallCart
            isCartDisplayed={isCartDisplayed}
            setIsCartDisplayed={setIsCartDisplayed}
            addedArticle={data}
          />
        </div>
      </div>

      <MessageModal
        isOpen={ratingMess}
        setIsOpen={setRatingMess}
        rating={rating}
        article_id={data?.article_id}
        setRating={setRating}
      />

      {isLoading && <Spinner />}
    </>
  )
}

export default AboutParfume
