import React from 'react'
import logo from '../../img/icon.jpg'
import { BiSolidDashboard } from 'react-icons/bi'
import { FaClipboardList, FaUserCircle } from 'react-icons/fa'
import { LuShoppingBag } from 'react-icons/lu'
import { MdOutlineDiscount } from 'react-icons/md'
import { IoSettingsSharp } from 'react-icons/io5'
import { GiDelicatePerfume } from 'react-icons/gi'
import { useNavigate } from 'react-router-dom'
import { useState } from 'react'
import { RiInkBottleFill } from 'react-icons/ri'
import { MdCardGiftcard } from 'react-icons/md'

const pages = [
  { path: '/admin/', label: 'Dashboard', icon: <BiSolidDashboard size={25} /> },
  {
    path: '/admin/orders',
    label: 'Narudžbe',
    icon: <FaClipboardList size={25} />,
  },
  {
    path: '/admin/articles',
    label: 'Proizvodi',
    icon: <LuShoppingBag size={25} />,
  },
  {
    path: '/admin/scentcrud',
    label: 'Vrste nota',
    icon: <GiDelicatePerfume size={25} />,
  },
  {
    path: '/admin/volumes',
    label: 'Zapremine',
    icon: <RiInkBottleFill size={25} />,
  },
  {
    path: '/admin/customers',
    label: 'Kupci',
    icon: <FaUserCircle size={24} />,
  },

  {
    path: '/admin/discount',
    label: 'Popusti',
    icon: <MdOutlineDiscount size={25} />,
  },
  {
    path: '/admin/coupon',
    label: 'Kuponi',
    icon: <MdCardGiftcard size={25} />,
  },
  // { path: '/admin/messages', label: 'Poruke', icon: <MdMessage size={25} /> },
  {
    path: '/admin/settings',
    label: 'Podešavanja',
    icon: <IoSettingsSharp size={25} />,
  },
]

function AdminSidebar() {
  const navigate = useNavigate()
  const [activePage, setActivePage] = useState('/')

  const handlePageClick = (path) => {
    navigate(path)
    setActivePage(path)
  }

  return (
    <div className="justify-center hidden lg:flex h-full w-full">
      {/* Sidebar */}
      <div className="flex flex-col w-full py-4 px-8">
        <div className="flex justify-center items-center my-4">
          <img
            src={logo}
            alt="Mad logo"
            className="w-40 h-36 hover:cursor-pointer"
            onClick={() => handlePageClick('/')}
          />
        </div>
        {/* Navigation Links */}
        <div className="flex flex-col justify-center space-y-2 px-1 mx-auto w-full">
          {pages.map((page) => (
            <div
              key={page.path}
              className={`flex rounded-md h-8 items-center text-gray-400 space-x-2 px-1 hover:cursor-pointer ${
                activePage === page.path ? 'bg-blue-500 text-white' : ''
              }`}
              onClick={() => handlePageClick(page.path)}
            >
              {page.icon}
              <label htmlFor="" className="hover:cursor-pointer">
                {page.label}
              </label>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default AdminSidebar
