import { useEffect, useMemo } from 'react'
import {
  useAsyncDebounce,
  useFilters,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table'

// import Pagination from './components/Pagination'

import { registerLocale } from 'react-datepicker'
import bs from 'date-fns/locale/bs'
import Spinner from './Spinner'
import PrefetchPaginationUrl from './PrefetchPaginationUrl'
import PrefetchPagination from './PrefetchPagination'
import ColumnFilterPagination from './ColumnFilterPagination'
import { filterByFirstCharacter } from './FilterByFirstCharacter'
import useTableHooks from '../hooks/useTableHooks'

registerLocale('bs', bs)

const MainPaginatedTable = ({
  columns,
  data,
  isLoading,
  isFetching,
  actions,
  hooksArray,
  navbar = true,
  pagination = true,
  paginationUrl,
  wfull = 'w-full',
  filtering,
  filterRows = false,
  setFilteredRows,
  caption = '',

  // Pagination
  controlledPageCount,
  setPage,
  setPageUrl,
  pageManual,
  pageManualUrl,
  setPageAmount,
  setPageAmountUrl,
  pageSizeUrl,
  count,
  filterInputs,
  setFilterInputs,
}) => {
  //FILTERS
  const handleFilterChange = useAsyncDebounce((value, columnId) => {
    if (setPage) {
      setPage(1)
    } else if (setPageUrl) {
      setPageUrl(
        (prev) => {
          prev.set('page', '1')
          return prev
        },
        { replace: true },
      )
    }
    setFilterInputs((prev) => {
      const newValue = value.trim() || ' '
      return { ...prev, [columnId]: newValue }
    })
  }, 600)

  const defaultColumn = useMemo(
    () => ({
      Filter: ({ column }) => (
        <ColumnFilterPagination
          column={column}
          handleFilterChange={handleFilterChange}
          count={count}
        />
      ),
    }),
    [filterInputs, handleFilterChange, count],
  )

  const tableHooks = useTableHooks(hooksArray)

  const tableInstance = useTable(
    {
      columns,
      data,
      manualPagination: true,
      manualFilters: true,
      pageCount: controlledPageCount,
      defaultColumn,
      filterTypes: {
        filterByFirstCharacter: filterByFirstCharacter,
      },
      initialState: { pageSize: 25 },
      filterable: true,
    },
    useFilters,
    useGlobalFilter,
    actions && tableHooks,
    useSortBy,
    usePagination,
  )
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    pageOptions,
    page,
    state: { pageSize },
    setPageSize,
    pageCount,
    filteredRows,
  } = tableInstance

  useEffect(() => {
    if (filterRows) {
      setFilteredRows(filteredRows)
    }
  }, [filterRows, filteredRows])

  return (
    <>
      {navbar && (
        <div className="flex items-end justify-between">
          {pagination && (
            <PrefetchPagination
              pageSize={pageSize}
              setPageSize={setPageSize}
              pageOptions={pageOptions}
              pageCount={pageCount}
              setPage={setPage}
              setPageAmount={setPageAmount}
              pageManual={pageManual}
            />
          )}
          {paginationUrl && (
            <PrefetchPaginationUrl
              pageSize={pageSizeUrl}
              setPageSize={setPageSize}
              pageOptions={pageOptions}
              pageCount={pageCount}
              setPage={setPageUrl}
              setPageAmount={setPageAmountUrl}
              pageManual={pageManualUrl}
            />
          )}
        </div>
      )}
      {/* {isLoading && <Spinner />} */}
      {/* <div className="overflow-auto block max-w-full shadow-lg shadow-slate-400"> */}
      <div className="block max-w-full shadow-lg shadow-slate-400">
        <table
          {...getTableProps()}
          className={`text-base border border-main-400  ${wfull}`}
        >
          <caption className="text-center font-bold uppercase">
            {caption}
          </caption>
          <thead className="p-2">
            {headerGroups.map((headerGroup) => (
              // eslint-disable-next-line react/jsx-key
              <tr
                {...headerGroup.getHeaderGroupProps()}
                className="  bg-blue-500 text-white"
              >
                {headerGroup.headers.map((column) => (
                  // eslint-disable-next-line react/jsx-key
                  <th
                    {...column.getHeaderProps({
                      style: {
                        minWidth: column.minWidth,
                        width: column.width,
                        maxWidth: column.maxWidth,
                        textAlign: 'center',
                        borderBottom: column.borderBottom,
                      },
                    })}
                    className="p-2 border  border-t-main-400  border-b-main-400  border-l-main-300  text-sm font-semibold border-r-main-300 first-of-type:border-l-main-400 last-of-type:border-r-main-400"
                  >
                    <div>
                      <span {...column.getSortByToggleProps()}>
                        {column.render('Header')}
                        {column.isSorted
                          ? column.isSortedDesc
                            ? ' ▼'
                            : ' ▲'
                          : ''}
                      </span>
                    </div>

                    {filtering && (
                      <div className="text-sky-900">
                        {column.canFilter ? column.render('Filter') : null}
                      </div>
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row)
              return (
                // eslint-disable-next-line react/jsx-key
                <tr
                  {...row.getRowProps()}
                  className={`bg-white hover:bg-slate-200 border-collapse border border-b-main-700 text-sm text-center ${
                    isFetching ? 'text-gray-300' : ''
                  } `}
                >
                  {row.cells.map((cell) => {
                    return (
                      // eslint-disable-next-line react/jsx-key
                      <td
                        {...cell.getCellProps({
                          style: {
                            minWidth: cell.column.minWidth,
                            width: cell.column.width,
                          },
                        })}
                        className="p-1"
                      >
                        {cell.render('Cell')}
                      </td>
                    )
                  })}
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
      {isLoading || isFetching ? (
        <div className="flex justify-center items-center mt-5">
          <Spinner />
        </div>
      ) : null}
    </>
  )
}

export default MainPaginatedTable
