import background from '../img/pozadina.png'
import { MdShoppingBasket } from 'react-icons/md'
import React, { useState, useEffect } from 'react'
import background2 from '../img/mad pics/slika 1.png'
import background3 from '../img/mad pics/slika 2.png'
import Background from '../components/homePage/Background'
import SelectiveCollection from '../components/homePage/SelectiveCollection'
import ForYourHome from '../components/homePage/ForYourHome'
import Recommended from '../components/homePage/Recommended'
import HomepagePopup from '../components/HomepagePopup'
import { useGetShippingCostQuery } from '../api/services/configuratorsApi'
import { useNavigate } from 'react-router-dom'
import HeroSection from '../components/homePage/HeroSection'
import Cards from '../components/homePage/Cards'
import SelectiveCards from '../components/homePage/SelectiveCards'
import NicheCollection from '../components/homePage/NicheCollection'
import SmallBanner from '../components/homePage/SmallBanner'
import ForHomeSection from '../components/homePage/ForHomeSection'
import LargeBanner from '../components/homePage/LargeBanner'
import SelectiveSection from '../components/homePage/SelectiveSection'
import { useGetAllImagePositionsQuery } from '../api/services/imagePosition'

const HomePage = () => {
  const [showModal, setShowModal] = useState(false)
  const { data: popupData, isLoading } = useGetShippingCostQuery()
  const { data: imagePositions } = useGetAllImagePositionsQuery()
  const navigate = useNavigate()

  useEffect(() => {
    if (isLoading === false) {
      const previousPopupKey = localStorage.getItem('pop_up_key')
      if (popupData?.pop_up_key !== previousPopupKey) {
        setShowModal(true)
        localStorage.setItem('pop_up_key', popupData?.pop_up_key)
      }
    }
  }, [popupData])

  const handleCloseModal = () => {
    setShowModal(false)
  }

  return (
    <>
      <div className="relative">
        <HeroSection imagePositions={imagePositions} />
      </div>
      <Cards />
      <SelectiveCards imagePositions={imagePositions} />
      <NicheCollection imagePositions={imagePositions} />
      <SmallBanner imagePositions={imagePositions} />
      <ForHomeSection />
      <LargeBanner imagePositions={imagePositions} />
      <SelectiveSection />

      {showModal && <HomepagePopup onClose={handleCloseModal} />}
    </>
  )
}

export default HomePage
