import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { AiFillStar, AiOutlineStar } from 'react-icons/ai'
import { FaRegHeart, FaHeart } from 'react-icons/fa'
import { useSelector } from 'react-redux'
import { useState } from 'react'
import { useRemoveOneFavoriteArticlesMutation } from '../../api/services/articleApi'
import { useAddFavoriteArticleMutation } from '../../api/services/articleApi'
import { toast } from 'react-toastify'

const ProductCard = ({ data, id }) => {
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated)
  const user = useSelector((state) => state.auth.user)

  const avarageRating = Math.round(data.averageReview)

  const [addFavoriteArticle] = useAddFavoriteArticleMutation()
  const [removeFavoriteArticles] = useRemoveOneFavoriteArticlesMutation()

  const [isHeartClicked, setIsHeartClicked] = useState(false)

  const handleHeartClick = async () => {
    try {
      const articles = { article_id: data?.article_id, user_id: user?.user_id }

      if (isHeartClicked) {
        await removeFavoriteArticles(articles)
        toast.success('Obrisano iz Favorita!')
      } else {
        await addFavoriteArticle(articles)
        toast.success('Dodano u Favorite!')
      }
      setIsHeartClicked(!isHeartClicked)
    } catch (error) {
      toast.error('Došlo je do greške prilikom manipulacije sa Favoritima')
    }
  }

  useEffect(() => {
    if (
      data?.favoritedByUsers?.length &&
      data?.favoritedByUsers[0]?.user_id === user?.user_id
    ) {
      setIsHeartClicked(true)
    }
  }, [data, user?.user_id])

  const [rating, setRating] = useState(0)

  useEffect(() => {
    if (data?.reviewedByUsers?.length > 0) {
      const currentRating = data?.reviewedByUsers[0]?.reviews?.review

      setRating(currentRating)
    }
  }, [data])

  const reviewCount = data?.reviewedByUsers?.reduce((total) => {
    return total + 1
  }, 0)

  return (
    <>
      <div className="flex flex-col items-start w-full gap-2 p-2">
        <Link
          to={`/article/${id}`}
          className="w-full h-[78%] overflow-hidden border border-gray-300 rounded-md z-10 "
        >
          <img
            src={data.article_images[0]?.url || ''}
            alt=""
            className="w-full h-full object-cover"
          />
        </Link>
        <div className="flex flex-col pb-2 w-full">
          <div className="flex justify-between py-1 px-0 md:px-4">
            <p className="text-xs md:text-base text-gray-500 font-normal">
              {`${data?.article_name}  ${data?.volume?.total} ${
                data?.article_group_id !== 5 ? 'ML' : 'gr'
              }`}
            </p>
            {isAuthenticated && (
              <button onClick={handleHeartClick}>
                {isHeartClicked ? (
                  <FaHeart className="w-3.5 h-3.5 md:w-5 md:h-5 text-red-600" />
                ) : (
                  <FaRegHeart className="w-3.5 h-3.5 md:w-5 md:h-5" />
                )}
              </button>
            )}
          </div>
          <div className="flex justify-between px-0 md:px-3">
            <div className="flex flex-col">
              {/* <p className="text-xs md:text-base">BAM: </p> */}
              {data?.discount && data.discount > 0 ? (
                <div>
                  <p className="text-[10px] md:text-[12px] text-gray-400 line-through ">
                    {data.price} KM
                  </p>
                  <p className="text-sm text-red-600 font-bold">
                    {data.discount_price} KM
                  </p>
                </div>
              ) : (
                `${data?.price} KM`
              )}
              {/* {data?.discount_price && <p>{data.discount_price}</p>} */}
            </div>
            {isAuthenticated && (
              <div className="flex items-center justify-center">
                {Array.from({ length: 5 })?.map((_, index) => (
                  <span
                    key={index}
                    onClick={(e) => {
                      e.preventDefault()
                      setRating(index + 1)
                    }}
                  >
                    {index < avarageRating ? (
                      <AiFillStar
                        fill="#FFD700"
                        className="w-4 h-4 md:w-4 md:h-4"
                      />
                    ) : (
                      <AiOutlineStar
                        fill="#FFD700"
                        className="w-4 h-4 md:w-4 md:h-4"
                      />
                    )}
                  </span>
                ))}
                <span className="text-xs font-bold text-gray-400">
                  ({reviewCount})
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default ProductCard
