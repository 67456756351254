import React, { useMemo } from 'react'
import { useState } from 'react'
import AddScentModal from '../../components/scentCrud/addScentModal'
import Actions from '../../components/scentCrud/actions'
import {
  useDeleteScentMutation,
  useGetAllScentsQuery,
} from '../../api/services/scentsApi'
import { AiFillEdit, AiOutlineLeft, AiOutlinePlusCircle } from 'react-icons/ai'
import { useNavigate } from 'react-router-dom'
import { HiDotsVertical } from 'react-icons/hi'
import UpdateScent from '../../components/scentCrud/UpdateScent'
import MainTable from '../../components/MainTable'
import ColumnFilters from '../../components/ColumnFilters'
import TableWrapper from '../../components/TableWrapper'
import { toast } from 'react-toastify'
import GlobalDelete from '../../components/GlobalDelete'
import MainPaginatedTable from '../../components/MainPaginatedTable'
import { FaTrash } from 'react-icons/fa'

function ScentCrud() {
  const navigate = useNavigate()
  const [openDelete, setOpenDelete] = useState(false)
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(25)

  const [filterInputs, setFilterInputs] = useState({})

  const query = useMemo(() => {
    let queryString = ''
    for (const [key, value] of Object.entries(filterInputs)) {
      if (value.trim() !== '') {
        queryString += `${key}=${encodeURIComponent(value)}&`
      }
    }

    return queryString
  }, [filterInputs])

  const { data: scents, isFetching } = useGetAllScentsQuery({
    page: page,
    page_size: pageSize,
    query,
  })

  const data = useMemo(
    () => (Array.isArray(scents?.scents) ? scents?.scents : []),
    [scents?.scents],
  )
  const [isAddScentModalVisible, setIsAddScentModalVisible] = useState(false)
  const [isActionsModalVisible, setIsActionsModalVisible] = useState(false)

  const [expandedRow, setExpandedRow] = useState(null)
  const [selectedScent, setSelectedScent] = useState('')
  const [openEdit, setOpenEdit] = useState(false)
  const [deleteScentMutation] = useDeleteScentMutation()

  const handleRowClick = (row) => {
    if (expandedRow === row.id) {
      setExpandedRow(null)
    } else {
      setExpandedRow(row.id)
      setSelectedScent(row.original)
    }
  }

  const handleShowAddScentModal = () => {
    setIsAddScentModalVisible(true)
  }

  const handleCloseAddScentModal = () => {
    setIsAddScentModalVisible(false)
  }

  const handleProdajaClick = () => {
    navigate('/admin/')
  }

  const handleDelete = async () => {
    try {
      const response = await deleteScentMutation({
        scent_id: selectedScent.scent_id,
      })

      if (response.data) {
        toast.success('Nota je uspijesno izbrisana')
        setOpenDelete(false)
      } else {
        toast.error('Error deleting scent:', response.error)
      }
    } catch (error) {
      console.error('Network error:', error)
    }
  }
  const handleCancel = () => {
    setOpenDelete(false)
  }
  const columns = useMemo(
    () => [
      {
        Header: 'Redni broj',
        accessor: 'scent_id',
        Cell: ({ row }) => {
          return <span>{`${row?.original?.scent_id}`}</span>
        },
      },
      {
        Header: 'Naziv note',
        accessor: 'scent_name',
      },
    ],
    [expandedRow],
  )
  const tableHooksArray = [
    {
      label: 'Uredi',
      Icon: AiFillEdit,
      onClick: (row) => {
        setOpenEdit(true)
        setSelectedScent(row.original)
      },
      disabled: false,
    },
    {
      label: 'Izbriši',
      Icon: FaTrash,
      onClick: (row) => {
        setOpenDelete(true)
        setSelectedScent(row.original)
      },
      disabled: false,
    },
  ]

  return (
    <div className="">
      <div className="flex flex-col mx-4 my-5 ">
        <div className="flex flex-row justify-between my-6 mx-4">
          <button
            className="bg-blue-500 rounded-md text-sm text-white h-8 flex items-center px-2"
            onClick={handleProdajaClick}
          >
            <AiOutlineLeft className="flex items-center mx-1 h-5 w-5" />
            <p className="hidden md:block">Vrati se na kreiranje artikala</p>
          </button>
          <button
            className="bg-blue-500 rounded-md text-sm text-white h-8 flex items-center px-2"
            onClick={handleShowAddScentModal}
          >
            <AiOutlinePlusCircle className="flex items center mx-1 h-5 w-5" />
            <p className="hidden md:block">Dodaj miris</p>
          </button>
        </div>
        <TableWrapper>
          <MainPaginatedTable
            data={data}
            columns={columns}
            actions={true}
            filtering
            pagination
            controlledPageCount={scents?.total_pages || 0}
            setPage={setPage}
            setPageAmount={setPageSize}
            count={scents?.total_count}
            pageManual={page}
            isFetching={isFetching}
            filterInputs={filterInputs}
            setFilterInputs={setFilterInputs}
            hooksArray={tableHooksArray}
          />
        </TableWrapper>
      </div>
      {isAddScentModalVisible && (
        <AddScentModal onClose={handleCloseAddScentModal} />
      )}
      {isActionsModalVisible && (
        <Actions
          onClose={() => setIsActionsModalVisible(false)}
          scentId={selectedScent.scent_id}
        />
      )}
      {openEdit && (
        <UpdateScent setOpenEdit={setOpenEdit} scent={selectedScent} />
      )}
      {openDelete && (
        <GlobalDelete
          scentId={selectedScent?.scent_id}
          setOpenDelete={setOpenDelete}
          handleDelete={handleDelete}
          handleCancel={handleCancel}
        />
      )}
    </div>
  )
}

export default ScentCrud
