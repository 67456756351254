import React, { useEffect } from 'react'
import { useState } from 'react'
import {
  useGetOneArticleQuery,
  useOtherVolumesQuery,
} from '../../api/services/articleApi'
import { Link, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useGetVolumesByGroupQuery } from '../../api/services/volumesApi'
import { setVolumeId } from '../../api/features/perfumeSlice'
import ArticleCard from '../homePage/ArticleCard'
import { useGetAllArticleGroupsQuery } from '../../api/services/articleGroup'

const ParfumValue = ({ data }) => {
  const { data: otherArticles } = useOtherVolumesQuery({
    article_name: data?.article_name,
    volume_id: data?.volume_id,
  })

  const { data: articleGroups } = useGetAllArticleGroupsQuery()

  return (
    <>
      {otherArticles?.length > 0 ? (
        <div>
          <div className="w-full h-[77px] flex flex-row justify-start items-center p-6 bg-[#FEFBF6]  mb-[35px]">
            <span className="font-luxor text-2xl">
              RAZLIČITE ZAPREMINE ISTOG PROIZVODA:
            </span>
          </div>
          <div className="flex flex-row gap-4  ">
            {otherArticles?.map((item) => {
              const articleGroupName = articleGroups?.find(
                (gr) => gr?.article_group_id === item?.article_group_id,
              )
              return (
                <ArticleCard
                  item={item}
                  parfume_type={articleGroupName?.article_group_name}
                  volume={true}
                />
              )
            })}
          </div>
        </div>
      ) : (
        ''
      )}
    </>
  )
}

export default ParfumValue
