import React from 'react'
import { AiOutlineLeft, AiOutlineRight } from 'react-icons/ai'

const Pagination = ({
  numbersArray,
  setCurrentPage,
  paginatedArticles,
  currentPage,
}) => {
  const totalPages = paginatedArticles?.total_pages

  // console.log('pagina', paginatedArticles)

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage)
  }

  const renderPageNumbers = () => {
    const maxDisplayedPages = 3
    const currentPageIndex = numbersArray.indexOf(currentPage)
    const startIndex = Math.max(0, currentPageIndex - 2)
    const endIndex = Math.min(
      startIndex + maxDisplayedPages,
      numbersArray.length,
    )

    let displayedPages = numbersArray.slice(startIndex, endIndex)

    if (startIndex > 0) {
      displayedPages = [1, '...', ...displayedPages]
    }
    if (endIndex < numbersArray.length) {
      displayedPages = [...displayedPages, '...', totalPages]
    }

    return displayedPages.map((num) => {
      const isCurrentPage = num === currentPage
      return (
        <div
          key={num}
          className={`px-3 font-luxor py-1 text-xl my-auto text-center cursor-pointer  hover:text-[#DCA950] ${
            isCurrentPage
              ? 'bg-[#DCA950] text-white w-full h-full  hover:text-white'
              : 'text-[#5B5B5B]'
          }`}
          onClick={() => {
            if (num !== '...') setCurrentPage(num)
          }}
        >
          {num}
        </div>
      )
    })
  }

  return (
    <>
      {paginatedArticles?.count !== 0 && (
        <div className="p-4">
          <div className="mt-24 flex items-center justify-center ">
            <button
              disabled={currentPage === 1}
              onClick={() => handlePageChange(currentPage - 1)}
            >
              <AiOutlineLeft className="text-[#5B5B5B]" />
            </button>
            <div className="flex mx-2">{renderPageNumbers()}</div>
            <button
              disabled={currentPage === totalPages}
              onClick={() => handlePageChange(currentPage + 1)}
            >
              <AiOutlineRight className="text-[#5B5B5B]" />
            </button>
          </div>
        </div>
      )}
    </>
  )
}

export default Pagination
