import { AiOutlineClose, AiOutlineCloseCircle } from 'react-icons/ai'
import { useGetPerfumeCategoriesQuery } from '../../api/services/perfumeApi'
import { useGetAllPerfumeTypesQuery } from '../../api/services/perfumeTypeApi'
import { useEffect, useState } from 'react'
import { useGetVolumesByGroupQuery } from '../../api/services/volumesApi'
import { useDispatch } from 'react-redux'
import { reset } from '../../api/features/filterSlice'
import { useSearchParams } from 'react-router-dom'

const FilterModal = ({ setOpenFilterModal, article_group_id }) => {
  const [menuOpen, setMenuOpen] = useState(false)
  const closeModal = () => {
    setMenuOpen(false)
    setTimeout(() => {
      setOpenFilterModal(false)
    }, 300)
  }
  useEffect(() => {
    setMenuOpen(true)
  }, [])

  const { data: perfumeCategories } = useGetPerfumeCategoriesQuery()
  const { data: perfumeType } = useGetAllPerfumeTypesQuery()
  const { data: volumes } = useGetVolumesByGroupQuery({ article_group_id })
  const [searchParams, setSearchParams] = useSearchParams()
  const selectedCategories = searchParams
    .getAll('categories')
    .map((cat) => decodeURIComponent(cat))
  const selectedVolume = searchParams
    .getAll('volumes')
    .map((vol) => decodeURIComponent(vol))
  const selectedGender = searchParams
    .getAll('genders')
    .map((gen) => decodeURIComponent(gen))
  const dispatch = useDispatch()

  const handleCategoryChange = (category) => {
    const currentCategories = searchParams.getAll('categories')
    const newCategories = [...currentCategories, category.perfume_category_id]

    const encodedCategories = newCategories.map((cat) =>
      encodeURIComponent(cat),
    )

    setSearchParams({ categories: encodedCategories })
  }
  const handleVolumeChange = (volume) => {
    const currentVolumes = searchParams.getAll('volumes')
    const newVolumes = [...currentVolumes, volume.volume_id]

    const encodedVolumes = newVolumes.map((vol) => encodeURIComponent(vol))

    setSearchParams({ volumes: encodedVolumes })
  }
  const handleGenderChange = (gender) => {
    const currentGenders = searchParams.getAll('genders')
    const newGenders = [...currentGenders, gender.perfume_type_id]

    const encodedGenders = newGenders.map((gen) => encodeURIComponent(gen))

    setSearchParams({ genders: encodedGenders })
  }

  return (
    <div className="fixed inset-0 flex items-start justify-start z-10 bg-black bg-opacity-50 overflow-hidden">
      <div
        className={`flex  flex-col  gap-6 bg-white w-2/3 h-full p-4 transition-transform ${
          menuOpen ? 'transform translate-x-0' : 'transform -translate-x-full'
        }`}
      >
        <div onClick={closeModal} className="w-full flex justify-between">
          <p>Filter</p>
          <span className="flex items-center">
            <AiOutlineCloseCircle className="w-6 h-6" />
          </span>
        </div>
        <div className="flex flex-col justify-between mt-1">
          <div className="flex flex-col items-start gap-3 justify-between w-full text-base"></div>
        </div>
        <div className="flex flex-col mt-3 gap-8 text-gray-500">
          <div className="flex flex-col gap-2">
            <h2>Tip mirisa:</h2>
            {perfumeCategories?.map((category, index) => {
              const uniqueId = Math.random()
              return (
                <div className="flex items-center gap-2" key={index}>
                  <input
                    type="checkbox"
                    value={category.perfume_category_id}
                    checked={selectedCategories?.find(
                      (item) => +item === category?.perfume_category_id,
                    )}
                    onChange={() => handleCategoryChange(category)}
                    id={uniqueId}
                  />
                  <label htmlFor={uniqueId}>
                    {category.perfume_category_name}
                  </label>
                </div>
              )
            })}
          </div>
          <div className="flex flex-col gap-2">
            <h2>ML:</h2>
            {volumes?.map((volume, index) => {
              const uniqueId = Math.random()
              return (
                <div className="flex items-center gap-2" key={index}>
                  <input
                    type="checkbox"
                    value={volume.volume_id}
                    checked={selectedVolume?.find(
                      (item) => +item === volume?.volume_id,
                    )}
                    onChange={() => handleVolumeChange(volume)}
                    id={uniqueId}
                  />
                  <label htmlFor={uniqueId}>{volume?.total}</label>
                </div>
              )
            })}
          </div>
          <div className="flex flex-col gap-2">
            <h2>Pol:</h2>
            {perfumeType?.map((gender, index) => {
              const uniqueId = Math.random()
              return (
                <div className="flex items-center gap-2" key={index}>
                  <input
                    type="checkbox"
                    value={gender.perfume_type_id}
                    checked={selectedGender?.find(
                      (item) => +item === gender?.perfume_type_id,
                    )}
                    onChange={() => handleGenderChange(gender)}
                    id={uniqueId}
                  />
                  <label htmlFor={uniqueId}>{gender?.perfume_type_name}</label>
                </div>
              )
            })}
          </div>
        </div>
        <div
          onClick={() => dispatch(reset())}
          className="bg-gray-300 p-2 w-full flex items-center justify-between"
        >
          Resetuj filtere{' '}
          <span>
            <AiOutlineClose />
          </span>
        </div>
      </div>
    </div>
  )
}

export default FilterModal
