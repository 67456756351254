import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Layout from './components/Layout'
import HomePage from './pages/HomePage'
import AdminPanel from './pages/adminPages/AdminPanel'
import Checkout from './pages/Checkout'
import Selective from './pages/Selective'
import AboutParfum from './pages/AboutParfum'
import Korpa from './pages/Korpa'
import Favorites from './pages/Favorites'
import ScrollToTop from './components/ScrollToTop'
import Login from './pages/Login'
import Error from './pages/Error'
import AdminDashLayout from './components/Layouts/Admin/AdminDashLayout'
import CheckoutComplete from './pages/checkoutComplete'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import AdminDashboard from './pages/adminPages/AdminDashboard'
import ScentCrud from './pages/adminPages/ScentCrud'
import ProtectedLogin from './components/protectedRoute/ProtectedLogin'
import ArticleCrud from './pages/adminPages/ArticleCrud'
import UpdateArticle from './components/ArticleCrud/UpdateArticle'
import Customers from './pages/adminPages/Customers'
import Orders from './pages/adminPages/Orders'
import Messages from './pages/adminPages/Messages'
import ProtectedAdmin from './components/protectedRoute/ProtectedAdmin'
import Settings from './pages/adminPages/Settings'
import UserLayout from './components/Layouts/User/UserLayout'
import UserProfile from './pages/userPages/UserProfile'
import NotificationOptions from './pages/userPages/NotificationOptions'
import UserPayment from './pages/userPages/UserPayment'
import UserPrivacy from './pages/userPages/UserPrivacy'
import UserOrders from './pages/userPages/UserOrders'
import ProtectedCustomer from './components/protectedRoute/ProtectedCustomer'
import Volumes from './pages/adminPages/Volumes'
import Coupon from './pages/adminPages/Coupon'
import Discount from './pages/adminPages/Discount'
import Verification from './pages/Verification'
import AllArticleShop from './components/homePage/AllArticleShop'
import ForgotPasswordEmail from './components/login/ForgotPasswordEmail'
import ChangePassword from './components/login/ChangePassword'
import Register from './components/login/Register'
import Kontakt from './pages/Kontakt'
import Onama from './pages/Onama'
import Dostava from './pages/Dostava'
import UsloviKoristenja from './pages/UsloviKoristenja'
import PravilaPrivatnosti from './pages/PravilaPrivatnosti'
import './index.css'

function App() {
  return (
    <>
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index path="/" element={<HomePage />} />
            <Route path="/checkout" element={<Checkout />} />
            <Route path="/favorites" element={<Favorites />} />
            <Route path="/shop" element={<AllArticleShop />} />
            <Route path="/:parfume_type" element={<Selective />} />
            <Route path="/article/:id" element={<AboutParfum />} />
            <Route path="/korpa" element={<Korpa />} />
            <Route path="/checkout_complete" element={<CheckoutComplete />} />
            <Route path="/kontakt" element={<Kontakt />} />
            <Route
              path="/politikaprivatnosti"
              element={<PravilaPrivatnosti />}
            />
            <Route path="/onama" element={<Onama />} />
            <Route path="/dostava" element={<Dostava />} />
            <Route path="/uslovikoristenja" element={<UsloviKoristenja />} />
            <Route
              path="/pravilaprivatnosti"
              element={<PravilaPrivatnosti />}
            />
          </Route>
          <Route path="*" element={<Error />} />

          <Route element={<ProtectedAdmin />}>
            <Route path="/admin" element={<AdminDashLayout />}>
              <Route index element={<AdminDashboard />} />
              <Route path="articles" element={<ArticleCrud />} />
              <Route path="add_article" element={<AdminPanel />} />
              <Route path="scentcrud" element={<ScentCrud />} />
              <Route path="customers" element={<Customers />} />
              <Route path="orders" element={<Orders />} />
              <Route path="messages" element={<Messages />} />
              <Route path="settings" element={<Settings />} />
              <Route path="volumes" element={<Volumes />} />
              <Route path="coupon" element={<Coupon />} />
              <Route path="discount" element={<Discount />} />
              <Route
                path="update_article/:article_id"
                element={<UpdateArticle />}
              />
            </Route>
          </Route>

          <Route element={<ProtectedCustomer />}>
            <Route path="/korisnik" element={<UserLayout />}>
              <Route index element={<UserProfile />} />
              <Route path="notifications" element={<NotificationOptions />} />
              <Route path="user_payment" element={<UserPayment />} />
              <Route path="user_privacy" element={<UserPrivacy />} />
              <Route path="orders" element={<UserOrders />} />
            </Route>
          </Route>

          <Route path="/login" element={<ProtectedLogin />}>
            <Route index element={<Login />} />
            <Route path="register" element={<Register />} />
            <Route path="forgot-password" element={<ForgotPasswordEmail />} />
          </Route>
          <Route path="/verification" element={<Verification />} />
          <Route path="/password-change" element={<ChangePassword />} />
        </Routes>
      </BrowserRouter>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </>
  )
}

export default App
