import React, { useState } from 'react'
import { useSendContactUsMailMutation } from '../api/services/usersApi'

function ContactFormInput() {
  const [ime, setIme] = useState('')
  const [mail, setMail] = useState('')
  const [poruka, setPoruka] = useState('')

  const [sendInfoMail] = useSendContactUsMailMutation()

  const handleSubmit = () => {
    sendInfoMail({
      email: mail,
      first_name: ime,
      content: poruka,
    })
      .unwrap()
      .then(() => {
        setIme('')
        setMail('')
        setPoruka('')
      })
  }

  return (
    <div className="flex flex-col">
      <label
        className="block text-gray-700 text-xl font-luxorlight mb-2"
        htmlFor="username"
      >
        Ime i prezime
      </label>
      <input
        onChange={(e) => {
          setIme(e.target.value)
        }}
        value={ime}
        id="username"
        className="border mb-3 h-14 pl-3 outline-[#DCA950] border-[#9C9C9C] "
        type="text"
      />

      <label
        className="block text-gray-700 text-xl font-luxorlight mb-2"
        htmlFor="email"
      >
        Email adresa
      </label>
      <input
        id="email"
        onChange={(e) => {
          setMail(e.target.value)
        }}
        value={mail}
        className="border mb-3 h-14 pl-3 outline-[#DCA950] border-[#9C9C9C] "
        type="email"
      />
      <label
        className="block text-gray-700  mb-2 text-xl font-luxorlight"
        htmlFor="poruka"
      >
        Poruka
      </label>
      <textarea
        id="poruka"
        onChange={(e) => {
          setPoruka(e.target.value)
        }}
        value={poruka}
        className="border mb-1 pt-1 h-24 pl-3 resize-none border-[#9C9C9C] outline-[#DCA950] min-h-[100px]"
      />
      <button
        onClick={handleSubmit}
        className="bg-black p-4 text-xl font-luxorlight text-white mt-5"
      >
        Pošalji!
      </button>
    </div>
  )
}

export default ContactFormInput
