import React, { useMemo } from 'react'
import { useTable } from 'react-table'
import { useFilters, useGlobalFilter } from 'react-table'
import ColumnFilters from './ColumnFilters'
import useTableHooks from '../hooks/useTableHooks'

const MainTable = ({ data, columns, hooksArray, filter, actions }) => {
  const defaultColumn = useMemo(
    () => ({
      Filter: ({ column }) => <ColumnFilters column={column} />,
    }),
    [],
  )

  const tableHooks = useTableHooks(hooksArray)
  const tableInstance = useTable(
    {
      columns,
      defaultColumn,
      data,
    },
    useFilters,
    useGlobalFilter,
    actions && tableHooks,
  )

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    tableInstance

  return (
    <>
      {' '}
      <div className="overflow-x-auto w-full border">
        <table
          {...getTableProps()}
          className="w-full mx-auto overflow-x-auto whitespace-nowrap max-h-[85vh] overflow-y-auto"
        >
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr
                key={Math.random()}
                {...headerGroup.getHeaderGroupProps()}
                className="bg-blue-500 text-white text-sm font-medium"
              >
                {headerGroup.headers.map((column) => (
                  <th
                    key={Math.random()}
                    {...column.getHeaderProps()}
                    className="px-4 py-2"
                  >
                    {column.render('Header')}
                    {filter ? (
                      <div>
                        {column.canFilter ? column.render('Filter') : null}
                      </div>
                    ) : null}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()} className="border">
            {rows.map((row, idx) => {
              prepareRow(row)
              return (
                <React.Fragment key={idx}>
                  <tr
                    {...row.getRowProps()}
                    className="relative hover:bg-gray-100 bg-white border"
                  >
                    {row.cells.map((cell) => {
                      return (
                        <>
                          <td
                            key={Math.random()}
                            {...cell.getCellProps({
                              style: {
                                minWidth: cell.column.minWidth,
                                width: cell.column.width,
                                whiteSpace: 'normal',
                              },
                            })}
                            className="p-1 border-b-main-700 text-sm text-center"
                          >
                            {cell.render('Cell')}
                          </td>
                        </>
                      )
                    })}
                  </tr>
                </React.Fragment>
              )
            })}
          </tbody>
        </table>
      </div>
    </>
  )
}
export default MainTable
