export const countries = [
  {
    name: "Afganistan",
    code: "AF",
  },
  {
    name: "Olandska ostrva",
    code: "AX",
  },
  {
    name: "Albanija",
    code: "AL",
  },
  {
    name: "Alzir",
    code: "DZ",
  },
  {
    name: "Američka Samoa",
    code: "AS",
  },
  {
    name: "Andora",
    code: "AD",
  },
  {
    name: "Angola",
    code: "AO",
  },
  {
    name: "Angvila",
    code: "AI",
  },
  {
    name: "Antarktika",
    code: "AQ",
  },
  {
    name: "Antigva i Barbuda",
    code: "AG",
  },
  {
    name: "Argentina",
    code: "AR",
  },
  {
    name: "Armenija",
    code: "AM",
  },
  {
    name: "Aruba",
    code: "AW",
  },
  {
    name: "Australija",
    code: "AU",
  },
  {
    name: "Austrija",
    code: "AT",
  },
  {
    name: "Azerbejdžan",
    code: "AZ",
  },
  {
    name: "Bahami",
    code: "BS",
  },
  {
    name: "Bahrein",
    code: "BH",
  },
  {
    name: "Bangladeš",
    code: "BD",
  },
  {
    name: "Barbados",
    code: "BB",
  },
  {
    name: "Belorusija",
    code: "BY",
  },
  {
    name: "Belgija",
    code: "BE",
  },
  {
    name: "Beliz",
    code: "BZ",
  },
  {
    name: "Benin",
    code: "BJ",
  },
  {
    name: "Bermuda",
    code: "BM",
  },
  {
    name: "Butan",
    code: "BT",
  },
  {
    name: "Bolivija",
    code: "BO",
  },
  {
    name: "Bosna i Hercegovina",
    code: "BA",
  },
  {
    name: "Bocvana",
    code: "BW",
  },
  {
    name: "Ostrvo Buve",
    code: "BV",
  },
  {
    name: "Brazil",
    code: "BR",
  },
  {
    name: "Britanski Indijski okeanski teritorij",
    code: "IO",
  },
  {
    name: "Brunej",
    code: "BN",
  },
  {
    name: "Bugarska",
    code: "BG",
  },
  {
    name: "Burkina Faso",
    code: "BF",
  },
  {
    name: "Burundi",
    code: "BI",
  },
  {
    name: "Kambodža",
    code: "KH",
  },
  {
    name: "Kamerun",
    code: "CM",
  },
  {
    name: "Kanada",
    code: "CA",
  },
  {
    name: "Zelenortska ostrva",
    code: "CV",
  },
  {
    name: "Kajmanska ostrva",
    code: "KY",
  },
  {
    name: "Centralnoafrička Republika",
    code: "CF",
  },
  {
    name: "Čad",
    code: "TD",
  },
  {
    name: "Čile",
    code: "CL",
  },
  {
    name: "Kina",
    code: "CN",
  },
  {
    name: "Božićno ostrvo",
    code: "CX",
  },
  {
    name: "Kokosova ostrva (Keeling)",
    code: "CC",
  },
  {
    name: "Kolumbija",
    code: "CO",
  },
  {
    name: "Komori",
    code: "KM",
  },
  {
    name: "Kongo",
    code: "CG",
  },
  {
    name: "Kongo, Demokratska Republika",
    code: "CD",
  },
  {
    name: "Kukova ostrva",
    code: "CK",
  },
  {
    name: "Kostarika",
    code: "CR",
  },
  {
    name: "Obala Slonovače",
    code: "CI",
  },
  {
    name: "Hrvatska",
    code: "HR",
  },
  {
    name: "Kuba",
    code: "CU",
  },
  {
    name: "Kipar",
    code: "CY",
  },
  {
    name: "Češka",
    code: "CZ",
  },
  {
    name: "Danska",
    code: "DK",
  },
  {
    name: "Džibuti",
    code: "DJ",
  },
  {
    name: "Dominika",
    code: "DM",
  },
  {
    name: "Dominikanska Republika",
    code: "DO",
  },
  {
    name: "Ekvador",
    code: "EC",
  },
  {
    name: "Egipat",
    code: "EG",
  },
  {
    name: "Salvador",
    code: "SV",
  },
  {
    name: "Ekvatorska Gvineja",
    code: "GQ",
  },
  {
    name: "Eritreja",
    code: "ER",
  },
  {
    name: "Estonija",
    code: "EE",
  },
  {
    name: "Esvatini",
    code: "SZ",
  },
  {
    name: "Farska ostrva",
    code: "FO",
  },
  {
    name: "Fidži",
    code: "FJ",
  },
  {
    name: "Finska",
    code: "FI",
  },
  {
    name: "Francuska",
    code: "FR",
  },
  {
    name: "Francuska Gvajana",
    code: "GF",
  },
  {
    name: "Francuska Polinezija",
    code: "PF",
  },
  {
    name: "Francuski južni teritoriji",
    code: "TF",
  },
  {
    name: "Gabon",
    code: "GA",
  },
  {
    name: "Gambija",
    code: "GM",
  },
  {
    name: "Gruzija",
    code: "GE",
  },
  {
    name: "Nemačka",
    code: "DE",
  },
  {
    name: "Gana",
    code: "GH",
  },
  {
    name: "Gibraltar",
    code: "GI",
  },
  {
    name: "Grčka",
    code: "GR",
  },
  {
    name: "Grenland",
    code: "GL",
  },
  {
    name: "Grenada",
    code: "GD",
  },
  {
    name: "Gvadelupe",
    code: "GP",
  },
  {
    name: "Guam",
    code: "GU",
  },
  {
    name: "Gvatemala",
    code: "GT",
  },
  {
    name: "Gernzi",
    code: "GG",
  },
  {
    name: "Gvineja",
    code: "GN",
  },
  {
    name: "Gvineja-Bisau",
    code: "GW",
  },
  {
    name: "Gvajana",
    code: "GY",
  },
  {
    name: "Haiti",
    code: "HT",
  },
  {
    name: "Ostrvo Herd i ostrva Mekdonald",
    code: "HM",
  },
  {
    name: "Sveti Stol (Država Vatikan)",
    code: "VA",
  },
  {
    name: "Honduras",
    code: "HN",
  },
  {
    name: "Hong Kong",
    code: "HK",
  },
  {
    name: "Mađarska",
    code: "HU",
  },
  {
    name: "Island",
    code: "IS",
  },
  {
    name: "Indija",
    code: "IN",
  },
  {
    name: "Indonezija",
    code: "ID",
  },
  {
    name: "Iran, Islamska Republika",
    code: "IR",
  },
  {
    name: "Irak",
    code: "IQ",
  },
  {
    name: "Irska",
    code: "IE",
  },
  {
    name: "Ostrvo Man",
    code: "IM",
  },
  {
    name: "Izrael",
    code: "IL",
  },
  {
    name: "Italija",
    code: "IT",
  },
  {
    name: "Jamajka",
    code: "JM",
  },
  {
    name: "Japan",
    code: "JP",
  },
  {
    name: "Džerzi",
    code: "JE",
  },
  {
    name: "Jordan",
    code: "JO",
  },
  {
    name: "Kazahstan",
    code: "KZ",
  },
  {
    name: "Kenija",
    code: "KE",
  },
  {
    name: "Kiribati",
    code: "KI",
  },
  {
    name: "Koreja, Demokratska Narodna Republika",
    code: "KP",
  },
  {
    name: "Koreja, Republika",
    code: "KR",
  },
  {
    name: "Kuvajt",
    code: "KW",
  },
  {
    name: "Kirgistan",
    code: "KG",
  },
  {
    name: "Laos, Narodna Demokratska Republika",
    code: "LA",
  },
  {
    name: "Letonija",
    code: "LV",
  },
  {
    name: "Liban",
    code: "LB",
  },
  {
    name: "Lesoto",
    code: "LS",
  },
  {
    name: "Liberija",
    code: "LR",
  },
  {
    name: "Libijska Arapska Džamahirija",
    code: "LY",
  },
  {
    name: "Lihtenštajn",
    code: "LI",
  },
  {
    name: "Litvanija",
    code: "LT",
  },
  {
    name: "Luksemburg",
    code: "LU",
  },
  {
    name: "Makao",
    code: "MO",
  },
  {
    name: "Makedonija, Bivša Jugoslovenska Republika",
    code: "MK",
  },
  {
    name: "Madagaskar",
    code: "MG",
  },
  {
    name: "Malavi",
    code: "MW",
  },
  {
    name: "Malezija",
    code: "MY",
  },
  {
    name: "Maldivi",
    code: "MV",
  },
  {
    name: "Mali",
    code: "ML",
  },
  {
    name: "Malta",
    code: "MT",
  },
  {
    name: "Maršalska ostrva",
    code: "MH",
  },
  {
    name: "Martinik",
    code: "MQ",
  },
  {
    name: "Mauritanija",
    code: "MR",
  },
  {
    name: "Mauricijus",
    code: "MU",
  },
  {
    name: "Majote",
    code: "YT",
  },
  {
    name: "Meksiko",
    code: "MX",
  },
  {
    name: "Mikronezija, Federativne Države",
    code: "FM",
  },
  {
    name: "Moldavija, Republika",
    code: "MD",
  },
  {
    name: "Monako",
    code: "MC",
  },
  {
    name: "Mongolija",
    code: "MN",
  },
  {
    name: "Monserat",
    code: "MS",
  },
  {
    name: "Maroko",
    code: "MA",
  },
  {
    name: "Mozambik",
    code: "MZ",
  },
  {
    name: "Mjanmar",
    code: "MM",
  },
  {
    name: "Namibija",
    code: "NA",
  },
  {
    name: "Nauru",
    code: "NR",
  },
  {
    name: "Nepal",
    code: "NP",
  },
  {
    name: "Holandija",
    code: "NL",
  },
  {
    name: "Holandski Antili",
    code: "AN",
  },
  {
    name: "Nova Kaledonija",
    code: "NC",
  },
  {
    name: "Novi Zeland",
    code: "NZ",
  },
  {
    name: "Nikaragva",
    code: "NI",
  },
  {
    name: "Niger",
    code: "NE",
  },
  {
    name: "Nigerija",
    code: "NG",
  },
  {
    name: "Niue",
    code: "NU",
  },
  {
    name: "Ostrvo Norfolk",
    code: "NF",
  },
  {
    name: "Severnomarijanska ostrva",
    code: "MP",
  },
  {
    name: "Norveška",
    code: "NO",
  },
  {
    name: "Oman",
    code: "OM",
  },
  {
    name: "Pakistan",
    code: "PK",
  },
  {
    name: "Palau",
    code: "PW",
  },
  {
    name: "Palestinska Teritorija, Okupirana",
    code: "PS",
  },
  {
    name: "Panama",
    code: "PA",
  },
  {
    name: "Papua Nova Gvineja",
    code: "PG",
  },
  {
    name: "Paragvaj",
    code: "PY",
  },
  {
    name: "Peru",
    code: "PE",
  },
  {
    name: "Filipini",
    code: "PH",
  },
  {
    name: "Pitkern",
    code: "PN",
  },
  {
    name: "Poljska",
    code: "PL",
  },
  {
    name: "Portugal",
    code: "PT",
  },
  {
    name: "Porto Riko",
    code: "PR",
  },
  {
    name: "Katar",
    code: "QA",
  },
  {
    name: "Rejunion",
    code: "RE",
  },
  {
    name: "Rumunija",
    code: "RO",
  },
  {
    name: "Ruska Federacija",
    code: "RU",
  },
  {
    name: "Ruanda",
    code: "RW",
  },
  {
    name: "Sveti Helena",
    code: "SH",
  },
  {
    name: "Sveti Kits i Nevis",
    code: "KN",
  },
  {
    name: "Sveta Lucija",
    code: "LC",
  },
  {
    name: "Sveti Pjer i Mikelon",
    code: "PM",
  },
  {
    name: "Sveti Vinsent i Grenadini",
    code: "VC",
  },
  {
    name: "Samoa",
    code: "WS",
  },
  {
    name: "San Marino",
    code: "SM",
  },
  {
    name: "Sao Tome i Principe",
    code: "ST",
  },
  {
    name: "Saudijska Arabija",
    code: "SA",
  },
  {
    name: "Senegal",
    code: "SN",
  },
  {
    name: "Srbija i Crna Gora",
    code: "CS",
  },
  {
    name: "Sejšeli",
    code: "SC",
  },
  {
    name: "Sijera Leone",
    code: "SL",
  },
  {
    name: "Singapur",
    code: "SG",
  },
  {
    name: "Slovačka",
    code: "SK",
  },
  {
    name: "Slovenija",
    code: "SI",
  },
  {
    name: "Solomonska ostrva",
    code: "SB",
  },
  {
    name: "Somalija",
    code: "SO",
  },
  {
    name: "Južna Afrika",
    code: "ZA",
  },
  {
    name: "Južna Džordžija i Južna Sendvič ostrva",
    code: "GS",
  },
  {
    name: "Španija",
    code: "ES",
  },
  {
    name: "Šri Lanka",
    code: "LK",
  },
  {
    name: "Sudan",
    code: "SD",
  },
  {
    name: "Surinam",
    code: "SR",
  },
  {
    name: "Svalbard i Jan Majen",
    code: "SJ",
  },
  {
    name: "Svazilend",
    code: "SZ",
  },
  {
    name: "Švedska",
    code: "SE",
  },
  {
    name: "Švajcarska",
    code: "CH",
  },
  {
    name: "Sirijska Arapska Republika",
    code: "SY",
  },
  {
    name: "Tajvan, Kineska Provincija",
    code: "TW",
  },
  {
    name: "Tadžikistan",
    code: "TJ",
  },
  {
    name: "Tanzanija, Ujedinjena Republika",
    code: "TZ",
  },
  {
    name: "Tajland",
    code: "TH",
  },
  {
    name: "Timor-Leste",
    code: "TL",
  },
  {
    name: "Togo",
    code: "TG",
  },
  {
    name: "Tokelau",
    code: "TK",
  },
  {
    name: "Tonga",
    code: "TO",
  },
  {
    name: "Trinidad i Tobago",
    code: "TT",
  },
  {
    name: "Tunis",
    code: "TN",
  },
  {
    name: "Turska",
    code: "TR",
  },
  {
    name: "Turkmenistan",
    code: "TM",
  },
  {
    name: "Ostrva Turks i Kaikos",
    code: "TC",
  },
  {
    name: "Tuvalu",
    code: "TV",
  },
  {
    name: "Uganda",
    code: "UG",
  },
  {
    name: "Ukrajina",
    code: "UA",
  },
  {
    name: "Ujedinjeni Arapski Emirati",
    code: "AE",
  },
  {
    name: "Ujedinjeno Kraljevstvo",
    code: "GB",
  },
  {
    name: "Sjedinjene Američke Države",
    code: "US",
  },
  {
    name: "Manja Američka Ostrva",
    code: "UM",
  },
  {
    name: "Urugvaj",
    code: "UY",
  },
  {
    name: "Uzbekistan",
    code: "UZ",
  },
  {
    name: "Vanuatu",
    code: "VU",
  },
  {
    name: "Venecuela",
    code: "VE",
  },
  {
    name: "Vijetnam",
    code: "VN",
  },
  {
    name: "Devičanska ostrva, Britanska",
    code: "VG",
  },
  {
    name: "Devičanska ostrva, Američka",
    code: "VI",
  },
  {
    name: "Valis i Futuna",
    code: "WF",
  },
  {
    name: "Zapadna Sahara",
    code: "EH",
  },
  {
    name: "Jemen",
    code: "YE",
  },
  {
    name: "Zambija",
    code: "ZM",
  },
  {
    name: "Zimbabve",
    code: "ZW",
  },
]
