import React, { useEffect, useState } from 'react'
import { AiFillCloseCircle } from 'react-icons/ai'
import { BiImageAdd } from 'react-icons/bi'
import { toast } from 'react-toastify'
import {
  useUpdatePopupImageMutation,
  useGetShippingCostQuery,
} from '../../api/services/configuratorsApi'

function PopupDiscount() {
  const [selectedImages, setSelectedImages] = useState([])
  const [imagePreviews, setImagePreviews] = useState([])
  const [popupKey, setPopupKey] = useState('')
  const [uploadPopupImage] = useUpdatePopupImageMutation()
  const { data: popupData } = useGetShippingCostQuery()

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files)
    const newSelectedImages = [...selectedImages, ...files]
    setSelectedImages(newSelectedImages)

    const previews = newSelectedImages.map((file) => URL.createObjectURL(file))
    setImagePreviews(previews)
  }

  const clearFileInput = () => {
    const fileInput = document.getElementById('file-input')
    fileInput.value = ''
  }

  const handleAddArticle = async () => {
    try {
      if (!popupKey.trim()) {
        toast.error('Molimo unesite Identifikacijski ključ.')
        return
      }

      const FD = new FormData()

      selectedImages.forEach((image) => {
        FD.append('image', image)
      })
      FD.append('pop_up_key', popupKey)

      await uploadPopupImage(FD)

      toast.success('Artikal uspiješno ažuriran!')

      setImagePreviews([])
      clearFileInput()
      setSelectedImages([])
      setPopupKey('')
    } catch (error) {
      toast.error('Došlo je do greške prilikom dodavanja artikla')
    }
  }

  const handleImageRemove = (indexToRemove) => {
    const updatedSelectedImages = selectedImages.filter(
      (_, index) => index !== indexToRemove,
    )
    setSelectedImages(updatedSelectedImages)

    const updatedPreviews = updatedSelectedImages.map((file) =>
      URL.createObjectURL(file),
    )
    setImagePreviews(updatedPreviews)
  }

  useEffect(() => {
    return () => {
      imagePreviews.forEach((preview) => URL.revokeObjectURL(preview))
    }
  }, [imagePreviews])

  return (
    <div className="flex flex-col pt-4">
      <label htmlFor="inventory" className="text-black font-bold">
        Popup slika
      </label>
      <div className="px-7 py-3 my-3 bg-white rounded-2xl">
        <h6 className="font-semibold">Trenutna popup slika</h6>
        <p>Identifikacijski kljuc: {popupData?.pop_up_key}</p>
        <img src={popupData?.pop_up_image} />
      </div>
      <div className="flex flex-row border rounded-2xl  px-7 py-3 my-3 bg-white">
        <div className="relative">
          <input
            type="file"
            id="file-input"
            accept="image/*"
            className="absolute inset-0 opacity-0 cursor-pointer"
            alt="Image input"
            onChange={handleImageChange}
          />
          <div className="border-spacing-4 border-2 border-dashed border-blue-500 rounded-lg p-4">
            <BiImageAdd className="w-16 h-16 mx-auto text-gray-400" />
            <label htmlFor="file-input" className="block text-center mt-2">
              Klikni za odabir ili prevuci
            </label>
          </div>
        </div>
        <section className="flex flex-wrap gap-4">
          {imagePreviews.map((preview, index) => (
            <div
              key={index}
              className="border border-gray-300 rounded-lg p-4 h-fit"
            >
              <img
                src={preview}
                alt={`Preview ${index}`}
                className="flex max-w-sm max-h-12 mb-2"
              />
              <button
                className="text-blue-500 text-xs rounded-lg hover:text-blue-600 cursor-pointer absolute p-1"
                onClick={() => handleImageRemove(index)}
              >
                <AiFillCloseCircle className="h-5 w-5" />
              </button>
            </div>
          ))}
        </section>
      </div>
      <div className="flex flex-col border rounded-2xl  px-7 py-3 my-3 bg-white">
        <label htmlFor="" className="text-gray-400">
          Identifikacijski kljuc
        </label>
        <input
          type="text"
          className="border rounded-md pl-3 h-10"
          onChange={(e) => setPopupKey(e.target.value)}
          value={popupKey}
          required
        />
      </div>
      <button
        className="bg-gray-700 text-white h-8 m-4 font-bold"
        onClick={handleAddArticle}
      >
        Ažuriraj popup
      </button>
    </div>
  )
}

export default PopupDiscount
