import React from 'react'
import { useGetAllArticleGroupsQuery } from '../../api/services/articleGroup'
import PerfumeTypeTitle from '../homePage/PerfumeTypeTitle'
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io'
import { useSelector } from 'react-redux'

function CartArticle({
  data,
  increaseCount,
  decreaseCount,
  handleDelete,
  bagArticles,
}) {
  const bag = useSelector((state) => state.auth.bag)
  const { data: articleGroups } = useGetAllArticleGroupsQuery()
  const user = useSelector((state) => state.auth.user)

  return (
    <>
      <div className="px-2 md:px-16 lg:px-20 mb-2 mt-5 md:w-[70%] w-full">
        <div className="flex flex-col w-full gap-4">
          {data?.length > 0 &&
            data?.map((item, index) => {
              const articleGroupName = articleGroups?.find(
                (gr) =>
                  gr?.article_group_id === item?.article?.article_group_id,
              )
              const articleGroupNameCart = articleGroups?.find(
                (gr) => gr?.article_group_id === item?.article_group_id,
              )

              return (
                <>
                  <div
                    // key={index}
                    className="hidden border-b-2 md:flex pb-2 gap-2 "
                  >
                    <div className="flex w-full">
                      <div className="flex border m-2">
                        <img
                          src={
                            bagArticles?.length && user
                              ? item.article.article_images[0]?.url || ''
                              : item.article_images[0].url || ''
                          }
                          alt="article"
                          className="w-36 h-32"
                        />
                      </div>

                      <div className="flex flex-col py-2 justify-between w-full">
                        <div className="flex w-full justify-between">
                          <div className="flex flex-col">
                            <PerfumeTypeTitle
                              text={
                                bag && user
                                  ? articleGroupName?.article_group_name +
                                    ' KOLEKCIJA'
                                  : item?.article_group_name + ' KOLEKCIJA'
                              }
                              fontsize={17}
                            />

                            <div className="text-xl">
                              <p>{item.article_name}</p>
                            </div>
                          </div>
                          <div className="flex  w-[100px]">
                            <div className=" text-2xl w-[50%] border-t border-l border-b flex justify-center items-center font-luxor text-[#DCA950] border-[#DCA950] h-[40px]">
                              <p>{item.amount}</p>
                            </div>
                            <div className=" w-[50%] flex flex-col border border-[#DCA950] h-[40px] ">
                              <button
                                className="h-[50%] cursor-pointer flex justify-center items-center"
                                onClick={() => {
                                  increaseCount(item.article_id)
                                }}
                              >
                                <IoIosArrowUp className="text-black" />
                              </button>
                              <button
                                className="border-t h-[50%] cursor-pointer border-[#DCA950] flex justify-center items-center"
                                onClick={() => decreaseCount(item.article_id)}
                              >
                                <IoIosArrowDown className="text-black" />
                              </button>
                            </div>
                          </div>
                        </div>

                        <div className="flex w-full justify-between">
                          <div className="flex gap-8 justify-evenly">
                            <div className="flex flex-col">
                              <div>
                                <p>ŠIFRA ARTIKLA</p>
                              </div>
                              <div>{item.article_id}</div>
                            </div>
                            <div className="flex flex-col">
                              <div>
                                <p>MILITRAŽA</p>
                              </div>
                              <div>{item.volume}</div>
                            </div>
                            <div className="flex flex-col">
                              <div>
                                <p>CIJENA</p>
                              </div>
                              <div>
                                {item.article?.discount &&
                                item.article?.discount > 0 ? (
                                  <span>{item.article?.discount_price}</span>
                                ) : item.discount && item.discount > 0 ? (
                                  <span>{item.discount_price}</span>
                                ) : (
                                  <span className="text-xs md:text-base">
                                    {item.price}
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>

                          <div
                            className="flex bg-black items-center justify-center w-[120px] h-10 my-1 hover:cursor-pointer"
                            onClick={() => handleDelete(item.article_id)}
                          >
                            <button className="text-white">Izbriši</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    // key={index}
                    // RESPONSIVE
                    className="md:hidden border-b-2 flex pb-2 gap-2 "
                  >
                    <div className="flex flex-col w-full">
                      <div className="flex gap-2">
                        <div className="border">
                          <img
                            src={
                              bagArticles?.length && user
                                ? item.article.article_images[0]?.url || ''
                                : item.article_images[0].url || ''
                            }
                            alt="article"
                            className="w-36 h-32"
                          />
                        </div>
                        <div className=" flex flex-col">
                          <div className="flex w-full justify-between">
                            <div className="flex flex-col">
                              <PerfumeTypeTitle
                                text={
                                  bag
                                    ? articleGroupName?.article_group_name +
                                      ' KOLEKCIJA'
                                    : articleGroupNameCart?.article_group_name
                                }
                                fontsize={17}
                              />

                              <div className="text-xl">
                                <p>{item.article_name}</p>
                              </div>
                            </div>
                          </div>
                          <div className="flex gap-8 justify-between text-sm mt-1 text-gray-400">
                            <div className="flex flex-col">
                              <div>
                                <p>MILITRAŽA</p>
                              </div>
                              <div>{item.volume}</div>
                            </div>
                            <div className="flex flex-col">
                              <div>
                                <p>CIJENA</p>
                              </div>
                              <div>
                                {item.article?.discount &&
                                item.article?.discount > 0 ? (
                                  <span>{item.article?.discount_price}</span>
                                ) : item.discount && item.discount > 0 ? (
                                  <span>{item.discount_price}</span>
                                ) : (
                                  <span className="text-xs md:text-base">
                                    {item.price}
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="flex flex-col py-2 justify-between">
                        <div className="flex w-full justify-between">
                          <div className="flex  w-[128px]">
                            <div className=" text-2xl w-[50%] border-t border-l border-b flex justify-center items-center font-luxor text-[#DCA950] border-[#DCA950] h-[40px]">
                              <p>{item.amount}</p>
                            </div>
                            <div className=" w-[50%] flex flex-col border border-[#DCA950] h-[40px] ">
                              <button
                                className="h-[50%] cursor-pointer flex justify-center items-center"
                                onClick={() => {
                                  increaseCount(item.article_id)
                                }}
                              >
                                <IoIosArrowUp className="text-black" />
                              </button>
                              <button
                                className="border-t h-[50%] cursor-pointer border-[#DCA950] flex justify-center items-center"
                                onClick={() => decreaseCount(item.article_id)}
                              >
                                <IoIosArrowDown className="text-black" />
                              </button>
                            </div>
                          </div>
                          <div
                            className="flex bg-black items-center justify-center w-[120px] h-10 hover:cursor-pointer"
                            onClick={() => handleDelete(item.article_id)}
                          >
                            <button className="text-white">Izbriši</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )
            })}
        </div>
      </div>
    </>
  )
}

export default CartArticle
