import { useMemo } from 'react'
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io'
import { RxDoubleArrowLeft, RxDoubleArrowRight } from 'react-icons/rx'

const CustomPrefetchPagination = ({
  pageSize,
  setPageSize,
  pageOptions,
  pageCount,
  setPage,
  setPageAmount,
  pageManual,
}) => {
  const arrayInSelect = useMemo(
    () => [...Array(pageOptions.length).keys()].map((i) => i + 1),
    [pageOptions],
  )

  return (
    <div className="flex lg:flex-row items-center justify-evenly bg-[#000] p-2 mb-2 mt-2 flex-wrap w-full gap-2 font-luxor opacity-90">
      <button
        className="text-white px-2 py-2 disabled:opacity-70  disabled:cursor-not-allowed hover:bg-main-700/95"
        onClick={() => {
          setPage((prev) => prev - 1)
        }}
        disabled={pageManual === 1}
      >
        <IoIosArrowBack size={23} />
      </button>

      <div className="text-white font-semibold">
        {pageManual} / {pageOptions.length}
      </div>

      <button
        className="text-white disabled:opacity-70 px-2 py-2 disabled:bg-main-700/30 disabled:cursor-not-allowed hover:bg-main-700/95"
        onClick={() => setPage((prev) => prev + 1)}
        disabled={pageManual === pageOptions.length || pageOptions.length === 0}
      >
        <IoIosArrowForward size={23} />
      </button>
    </div>
  )
}

export default CustomPrefetchPagination
