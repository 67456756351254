import React, { useEffect, useMemo, useState } from 'react'
import { useGetPaginatedUserOrdersQuery } from '../../api/services/ordersApi'
import { useSelector } from 'react-redux'
import OrdersModal from '../../components/UserPanelFunkc/OrdersModal'
import TableWrapper from '../../components/TableWrapper'
import ModalReport from '../../components/ModalReport'
import generateComplensationSumPDF from '../../pdf/GenerateComplensationSumPDF'
import CustomSubrowTable from '../../components/CustomSubrowTable'
import SubRow from '../../components/SubRow'
import { useAsyncDebounce } from 'react-table'
import { format } from 'date-fns'

const UserOrders = () => {
  const user = useSelector((state) => state.auth.user)

  const [expandedRow, setExpandedRow] = useState(null)
  const [selectedOrder, setSelectedOrder] = useState({})
  const [openModal, setOpenModal] = useState(false)
  const [openPrint, setOpenPrint] = useState(false)
  const [reportURL, setReportURL] = useState(false)
  const [loadingReport, setLoadingReport] = useState(false)
  const [subRow, setSubRow] = useState()
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(25)
  const [filterInputs, setFilterInputs] = useState({})

  const { data: userOrders, isFetching } = useGetPaginatedUserOrdersQuery({
    page: page,
    page_size: pageSize,
    user_id: user?.user_id,
  })
  const handlePrint = async () => {
    setLoadingReport(true)
    setOpenPrint(true)

    const pdfBlob = await generateComplensationSumPDF()

    const linkSource = `data:application/pdf;base64,${pdfBlob.blob}`
    setReportURL(linkSource)
    setLoadingReport(false)
  }

  const handleRowClick = (row) => {
    if (expandedRow === row.id) {
      setExpandedRow(null)
      setSubRow(null)
    } else {
      setExpandedRow(row.id)
      setSubRow(row)
    }
  }

  const handleFilterChange = useAsyncDebounce((value, columnId) => {
    setPage(1)
    setFilterInputs((prev) => {
      const newValue = value.trim() || ' '
      return { ...prev, [columnId]: newValue }
    })
  }, 50)
  const columns = useMemo(
    () => [
      {
        Header: 'Broj narudžbe',
        accessor: 'order_number',
      },
      {
        Header: 'Datum',
        accessor: 'created_at',
        Cell: ({ row }) => {
          const dateObject = new Date(row.original.created_at)
          return format(dateObject, 'dd.MM.yyyy')
        },
      },
      {
        Header: 'Cijena',
        accessor: 'order_total',
      },

      {
        Header: 'Status',
        accessor: 'order_status.name',
        Cell: ({ row }) => {
          return (
            <span
              className={`border p-1 capitalize font-medium flex justify-center ${
                row?.original?.order_status?.name === 'NARUČENO'
                  ? 'bg-[#FFF5E4] text-[#DCA950]'
                  : ''
              } 
              ${
                row?.original?.order_status?.name === 'ISPORUČENO'
                  ? 'bg-[#E2FFE2] text-[#50DC59]'
                  : ''
              }
              ${
                row?.original?.order_status?.name === 'OTKAZANO'
                  ? 'bg-red-100 text-red-400'
                  : ''
              } 
              `}
            >
              {row?.original?.order_status?.name?.toLowerCase()}
            </span>
          )
        },
      },
    ],
    [expandedRow],
  )

  const userOrder = userOrders?.orders?.map((item) => {
    return item
  })
  const data = useMemo(
    () => (Array.isArray(userOrder) ? userOrder : []),
    [userOrder],
  )

  useEffect(() => {
    if (openModal) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'auto'
    }
  }, [openModal])

  return (
    <>
      <div className="px-2 md:pt-14">
        <div className="pt-6 h-screen">
          <TableWrapper>
            <CustomSubrowTable
              data={data}
              columns={columns}
              actions={false}
              SubRow={SubRow}
              handleRowClick={handleRowClick}
              expandedRow={expandedRow}
              //pagination
              pagination
              controlledPageCount={userOrders?.total_pages || 0}
              setPage={setPage}
              setPageAmount={setPageSize}
              count={userOrders?.total_count}
              pageManual={page}
              filterInputs={filterInputs}
              setFilterInputs={setFilterInputs}
            />
          </TableWrapper>
        </div>
      </div>
      {openModal && (
        <OrdersModal
          selectedOrder={selectedOrder}
          setOpenModal={setOpenModal}
          openModal={openModal}
        />
      )}
      <ModalReport
        showModal={openPrint}
        setShowModal={() => setOpenPrint(false)}
        data={reportURL}
        state="Račun"
      />
    </>
  )
}

export default UserOrders
