import React, { useMemo } from 'react'
import { AiOutlineCloseCircle } from 'react-icons/ai'
import MainTable from '../MainTable'
import TableWrapper from '../TableWrapper'

const OrdersModal = ({ selectedOrder, setOpenModal }) => {
  const onClose = () => {
    setOpenModal(false)
  }

  const columns = useMemo(
    () => [
      {
        Header: 'Ime artikla',
        accessor: 'article_name',
      },

      {
        Header: 'Količina',
        accessor: 'amount',
      },
      {
        Header: 'ML',
        accessor: 'volume.total',
      },
      {
        Header: 'Cijena',
        accessor: 'price',
      },
      {
        Header: 'Ukupno',
        accessor: 'total',
        Cell: ({ row }) => {
          return <span>{row.original.price * row.original.amount}</span>
        },
      },
    ],
    [],
  )
  const data = useMemo(
    () =>
      selectedOrder?.order_articles?.map((item) => {
        return {
          article_name: item?.article_name,
          volume: item?.volume,
          amount: item?.amount,
          price: item?.price,
        }
      }),
    [selectedOrder],
  )

  return (
    <>
      <div className="fixed inset-0 bg-black opacity-50"></div>
      <div className="fixed inset-0 flex items-center justify-center z-50 p-6 md:p-0">
        {/* Background overlay */}

        {/* Modal */}
        <div className="flex flex-col gap-2 rounded-lg bg-white z-10 md:w-2/3  border border-black p-2 w-full">
          <div className="flex justify-between border-b-2 border-black">
            <h1>Prikaz narudžbe</h1>
            <span className="cursor-pointer" onClick={onClose}>
              {<AiOutlineCloseCircle />}
            </span>
          </div>
          <TableWrapper>
            <MainTable columns={columns} data={data} />
          </TableWrapper>
        </div>
      </div>
    </>
  )
}

export default OrdersModal
