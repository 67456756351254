import { BsTruck } from 'react-icons/bs'
import { SlRefresh } from 'react-icons/sl'
import { CiMail } from 'react-icons/ci'
import { FaTruckFast } from 'react-icons/fa6'
import { useGetShippingCostQuery } from '../../api/services/configuratorsApi'
import { IoIosArrowUp } from 'react-icons/io'
import { IoIosArrowDown } from 'react-icons/io'

import AdditionalInformation from './AdditionalInformation'

const DeliveryInformation = ({
  isCartDisplayed,
  newAmount,
  setNewAmount,
  handleButtonClick,
  setIsCartDisplayed,
}) => {
  const { data: shippingCost } = useGetShippingCostQuery()

  return (
    <div className=" ">
      <div className="pt-6 text-sm font-medium flex items-center">
        <span className="text-[25px] font-luxor">Dodatne Informacije</span>
      </div>
      <div className="flex items-center gap-4 py-3 border-b">
        <AdditionalInformation />
      </div>
      <div className="w-full  p-4 flex flex-row gap-4 border-b relative">
        <FaTruckFast className="relative w-7 h-7" />

        <span className=" md:text-lg text-lg font-luxorlight  leading-8">
          Besplatna dostava za narudžbe vrijednosti iznad{' '}
          {shippingCost?.free_shipping} KM
        </span>
      </div>
      <div className="mt-6">
        <span className="text-[25px] font-luxor">Količina: </span>
        <div className="flex flex-row gap-5">
          <div className="flex flex-row  w-[110px]">
            <div className=" text-3xl w-[50%] border-t border-l border-b flex justify-center items-center font-luxor text-[#DCA950] border-[#DCA950] h-[60px]">
              {newAmount}
            </div>
            <div className=" w-[50%] flex flex-col border border-[#DCA950]  ">
              <button
                className=" h-[50%] cursor-pointer flex justify-center items-center"
                onClick={() => {
                  const amount = newAmount + 1
                  setNewAmount(amount)
                }}
              >
                <IoIosArrowUp className="text-black" />
              </button>
              <button
                className="border-t cursor-pointer border-[#DCA950] h-[50%] flex justify-center items-center"
                onClick={() => {
                  if (newAmount > 1) {
                    const amount = newAmount - 1
                    setNewAmount(amount)
                  }
                }}
              >
                <IoIosArrowDown className="text-black" />
              </button>
            </div>
          </div>
          <div className="flex flex-row justify-center w-full">
            <button
              disabled={isCartDisplayed}
              onClick={async () => {
                await handleButtonClick()
                {
                  !isCartDisplayed && (await setIsCartDisplayed(true))
                }
              }}
              className={`bg-black text-white font-bold w-full h-[60px]  flex flex-row justify-center items-center gap-5 font-luxorthin text-[22px]`}
            >
              Dodaj u korpu{' '}
              <svg
                height="11"
                width="6"
                fill="none"
                viewBox="0 0 6 11"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.447959 9.65454L4.94971 5.68225L0.447959 1.2877"
                  stroke="white"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DeliveryInformation
