import React, { useState } from 'react'
import { usePasswordChangeUserMutation } from '../../api/services/usersApi'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { logout } from '../../api/features/authSlice'
import { useNavigate } from 'react-router-dom'
import UserDelete from '../../components/UserPanelFunkc/UserDelete'

function UserPrivacy() {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [updateUser] = usePasswordChangeUserMutation()
  const UserData = useSelector((state) => state.auth.user)
  const [formData, setFormData] = useState({
    old_password: '',
    password: '',
  })
  const [showPassword, setShowPassword] = useState(false)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const handleUpdateUser = () => {
    if (formData.password !== formData.confirm_password) {
      toast.error('Nova lozinka i potvrda nove lozinke se ne podudaraju')
      return
    }
    updateUser({
      ...formData,
      user_id: UserData?.user_id,
    })

    toast.success('Vaš profil je uspiješno ažuriran')
    dispatch(logout())
    navigate('/login')

    setFormData({
      old_password: '',
      password: '',
      confirm_password: '',
    })
  }
  const toggleShowPassword = () => {
    setShowPassword(!showPassword)
  }
  return (
    <div className="mx-12 relative flex flex-col mt-12 mb-8 gap-8">
      <div>
        <article className="flex flex-col bg-white px-8  gap-2 shadow-xl">
          <section className="my-2 py-4 font-luxor ">
            <h1 className="text-2xl">Lozinka</h1>
            <p className="text-[#C2C2C2] font-luxorlight">
              Molimo unesite Vašu trenutnu lozinku, kako biste ju promijenili.
            </p>
          </section>

          <div className="lg:border border-[#555555] grid grid-rows-1 grid-cols-1 md:grid-cols-3  gap-3 font-luxorlight">
            <div className="flex flex-col lg:pl-4 pt-2">
              <label htmlFor="" className="text-gray-400">
                Trenutna lozinka:
              </label>
              <input
                type="password"
                className="border border-[#555555] h-12 px-2 outline-[#DCA950]"
                value={formData.old_password || ''}
                onChange={(e) =>
                  setFormData({ ...formData, old_password: e.target.value })
                }
              />
            </div>
            <div className="flex flex-col pt-2">
              <label htmlFor="" className="text-gray-400">
                Nova lozinka:
              </label>
              <input
                type="password"
                className="border border-[#555555] h-12 px-2 outline-[#DCA950]"
                value={formData.password || ''}
                onChange={(e) =>
                  setFormData({ ...formData, password: e.target.value })
                }
              />
            </div>
            <div className="flex flex-col pt-2 lg:pr-3">
              <label htmlFor="" className="text-gray-400">
                Potvrdi novu lozinku:
              </label>
              <input
                type="password"
                className="border border-[#555555] h-12 px-2 outline-[#DCA950]"
                value={formData.confirm_password || ''}
                onChange={(e) =>
                  setFormData({ ...formData, confirm_password: e.target.value })
                }
              />
            </div>
            <div className="lg:col-start-3 lg:col-end-4 px-2">
              <button
                className="flex w-full lg:w-52 items-center justify-center bg-[#DCA950] text-white h-11 mb-4 mt-2 ml-auto mr-5 lg:mr-3"
                onClick={handleUpdateUser}
              >
                Sačuvaj izmjene
              </button>
            </div>
          </div>
        </article>
        <article className="flex flex-col bg-white px-8 gap-2 shadow-xl font-luxorlight">
          <section className="py-4 font-luxor ">
            <h1 className="text-2xl">Brisanje naloga</h1>
            <p className="text-[#C2C2C2] text-md font-luxorlight">
              Brisanje naloga brišete Vaše informacije iz naše baze podataka.
              Ovo se ne može poništiti
            </p>
          </section>
          <div className="lg:border border-[#555555] mb-12 p-2">
            <button
              className="flex w-full items-center justify-center bg-[#B5B5B5] hover:bg-[#DCA950] ease-in text-white h-12 transition duration-150"
              onClick={() => setIsDeleteModalOpen(true)}
            >
              Izbriši nalog
            </button>
          </div>
        </article>
      </div>
      {isDeleteModalOpen && (
        <UserDelete onClose={() => setIsDeleteModalOpen(false)} />
      )}
    </div>
  )
}
export default UserPrivacy
