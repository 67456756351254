import { Document, Page, Text, View, pdf } from '@react-pdf/renderer'
import { Fragment } from 'react'

const generateComplensationSumPDF = async () => {
  const pdfData = (
    <Fragment>
      <Document>
        <Page style={{ padding: 14 }}>
          <View
            style={{
              padding: 10,
              backgroundColor: 'red',
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <View style={{ backgroundColor: 'purple', flex: 1 }}>
              <Text>Racun broj:</Text>
              <Text>Broj narudžbe:</Text>
              <Text>Datum: </Text>
            </View>
            <View
              style={{
                display: 'flex',
                backgroundColor: 'blue',
                flex: 1,
              }}
            >
              <Text>ss</Text>
            </View>
          </View>
          <View>
            <Text>Napomena: Fiskalni racun dolazi uz pošiljku!</Text>
          </View>
        </Page>
      </Document>
    </Fragment>
  )

  const blob = await pdf(pdfData).toBlob()

  // Convert Blob to base64 string
  const base64String = await new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = () => {
      if (typeof reader.result === 'string') {
        resolve(reader.result.split(',')[1])
      } else {
        reject(new Error('Unable to read base64 string.'))
      }
    }
    reader.onerror = (error) => reject(error)
    reader.readAsDataURL(blob)
  })

  return { blob: base64String }
}

export default generateComplensationSumPDF
