import React from 'react'
import { useGetSuggestedArticlesQuery } from '../../api/services/articleApi'
import { Link } from 'react-router-dom'

const SelectiveCollection = () => {
  const { data: suggestedArticles } = useGetSuggestedArticlesQuery('SELECTIVE')
  return (
    <div className="px-2 md:px-14 md:py-10 py-4">
      <div className="flex flex-col justify-center items-center gap-5 py-5">
        <p className="text-base font-normal">ZA NJU I NJEGA</p>
        <p className="text-base md:text-2xl font-semibold">
          ODABRANA SELECTIVE KOLEKCIJA
        </p>
      </div>
      <div className="grid gap-3 grid-cols-2 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-4">
        {suggestedArticles?.map((item) => {
          return (
            <div
              key={Math.random()}
              className="shadow-md transition-transform transform hover:scale-105 duration-100"
            >
              <div className="flex flex-col gap-3">
                <Link to={`/article/${item?.article_id}`} className="border">
                  <img src={item?.article_images[0]?.url || ''} alt="" />
                </Link>
                <div className="flex flex-col md:flex-row justify-between font-normal md:font-semibold items-center md:gap-3 text-xs md:text-base px-2 py-2 md:px-6 md:py-4">
                  <p>{item?.article_name}</p>
                  {item.discount && item.discount > 0 ? (
                    <div>
                      <p className="text-[10px] md:text-base text-gray-400 line-through ">
                        {item.price} KM
                      </p>
                      <p className="bg-red-100 rounded-xl text-red-600 inline-block px-2">
                        {item.discount_price} KM
                      </p>
                    </div>
                  ) : (
                    <p className="bg-blue-100 rounded-xl text-blue-600 inline-block px-2">
                      {item?.price} KM
                    </p>
                  )}
                </div>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default SelectiveCollection
