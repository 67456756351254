import { api } from '../api'

// GET api/v1/articles/get-articles/page/page_size

export const articleApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getAllArticles: builder.query({
      query: ({ page, page_size, query }) =>
        `api/v1/articles/get-articles/${page}/${page_size}/${
          query ? `?${query}` : ''
        }`,
      providesTags: ['Articles'],
    }),
    getOneArticle: builder.query({
      query: ({ article_id, user_id }) =>
        `api/v1/articles/get-article/${article_id}/${
          user_id ? `?user_id=${user_id}` : ''
        }`,
      providesTags: ['Articles'],
    }),
    getArticleByGroup: builder.mutation({
      query: (body) => ({
        url: `api/v1/articles/get-articles-by-article-group`,
        method: 'POST',
        body,
      }),
      providesTags: ['Articles'],
    }),
    getArticleByGroup: builder.query({
      query: ({ article_group_id, page, page_size, sort_by, user_id, query }) =>
        `api/v1/articles/get-articles-by-article-group/${article_group_id}/${page}/${page_size}?user_id=${user_id}&sort_by=${sort_by}${
          query ? `&${query}` : ''
        }`,
      providesTags: ['Articles'],
    }),

    getPaginatedArticles: builder.query({
      query: ({ page, page_size }) =>
        `api/v1/articles/get-articles/home-page/${page}/${page_size}`,
      providesTags: ['Articles'],
    }),
    getTopSelling: builder.query({
      query: () => 'api/v1/articles/top-selling',
      providesTags: ['Articles'],
    }),
    getTopRated: builder.query({
      query: () => 'api/v1/articles/top-rated',
      providesTags: ['Articles'],
    }),
    getTotalSoldArticles: builder.query({
      query: ({ from, to }) => `api/v1/articles/total-bought/${from}/${to}`,
      providesTags: ['Articles'],
    }),
    deleteOneArticle: builder.mutation({
      query: ({ article_id }) => ({
        url: `api/v1/articles/${article_id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Articles'],
    }),
    updateArticle: builder.mutation({
      query: (body) => ({
        url: `api/v1/articles`,
        body,
        method: 'PUT',
      }),
      invalidatesTags: ['Articles'],
    }),
    addArticle: builder.mutation({
      query: (body) => ({
        url: `api/v1/articles`,
        body,
        method: 'POST',
      }),
      invalidatesTags: ['Articles'],
    }),
    addFavoriteArticle: builder.mutation({
      query: (body) => ({
        url: `api/v1/articles/add-article-to-favorites`,
        body,
        method: 'POST',
      }),
      invalidatesTags: ['FavoriteArticles', 'Articles'],
    }),
    getAllFavoriteArticles: builder.query({
      query: (user_id) => `api/v1/articles/get-favorite-articles/${user_id}`,
      providesTags: ['FavoriteArticles'],
    }),
    removeOneFavoriteArticles: builder.mutation({
      query: ({ article_id, user_id }) => ({
        url: `api/v1/articles/remove-article-from-favorites/${user_id}/${article_id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['FavoriteArticles', 'Articles'],
    }),
    removeAllFavoriteArticles: builder.mutation({
      query: ({ user_id }) => ({
        url: `api/v1/articles/remove-all-articles/${user_id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['FavoriteArticles', 'Articles'],
    }),

    getSuggestedArticles: builder.query({
      query: (article_group_name) => ({
        url: `api/v1/articles/get-suggested-articles-by-article-group/${article_group_name}`,
      }),
      providesTags: ['Articles'],
    }),
    editSuggestedArticle: builder.mutation({
      query: (body) => ({
        url: `api/v1/articles`,
        body,
        method: 'PUT',
      }),
      invalidatesTags: ['Articles'],
    }),
    searchArticles: builder.query({
      query: (article_name) => ({
        url: `api/v1/articles/search/${article_name}`,
      }),
      providesTags: ['Articles'],
    }),
    reviewArticles: builder.mutation({
      query: (body) => ({
        url: `api/v1/articles/review`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Articles'],
    }),
    reviewPaginatedArticles: builder.query({
      query: ({ article_id, page, page_size }) => ({
        url: `api/v1/articles/reviews-by-article/${article_id}/${page}/${page_size}`,
      }),
      providesTags: ['Articles'],
    }),
    sameArticle: builder.query({
      query: ({ article_name, volume_id, user_id }) => ({
        url: `api/v1/articles/get-article-by-name-volume/${article_name}/${volume_id}/${
          user_id ? `?user_id=${user_id}` : ''
        }`,
      }),
      providesTags: ['Articles'],
    }),
    otherVolumes: builder.query({
      query: ({ article_name, volume_id }) => ({
        url: `api/v1/articles/get-articles-by-other-volumes/${article_name}/${volume_id}`,
      }),
      providesTags: ['Articles'],
    }),
    addDiscount: builder.mutation({
      query: (body) => ({
        url: 'api/v1/articles/post-discount-articles',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Articles'],
    }),
    getAllDiscounts: builder.query({
      query: (body) => ({
        url: 'api/v1/articles/get-discounted-articles',
        body,
      }),
      providesTags: ['Articles'],
    }),
    deleteDiscount: builder.mutation({
      query: ({ discount_id, discount, article_group_id }) => ({
        url: `api/v1/articles/delete-discounted-articles/${discount_id}/${discount}/${article_group_id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Articles'],
    }),
    updateDiscount: builder.mutation({
      query: (body) => ({
        url: `api/v1/articles/update-articles-without-discount`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['Articles'],
    }),
    getAllArticlesMutation: builder.mutation({
      query: (body) => ({
        url: `api/v1/articles/get-articles-without-article-group`,
        method: 'POST',
        body,
      }),
      providesTags: ['Articles'],
    }),
    getAllArticlesWithoutGroup: builder.query({
      query: ({ page, page_size, sort_by, user_id, query }) =>
        `api/v1/articles/get-articles-without-article-group/${page}/${page_size}?user_id=${user_id}&sort_by=${sort_by}${
          query ? `&${query}` : ''
        }`,
      providesTags: ['Articles'],
    }),

    // sameArticle: builder.query({
    //   query: ({ article_name, user_id, volume_id }) => ({
    //     url: `/api/v1/articles/get-article-by-name-volume/${article_name}/${volume_id}?${user_id}`,
    //   }),
    //   providesTags: ['Articles'],
    // }),
  }),
})

export const {
  useGetAllArticlesQuery,
  useGetOneArticleQuery,
  useUpdateArticleMutation,
  useDeleteOneArticleMutation,
  useAddArticleMutation,
  useGetArticleByGroupMutation,
  useGetArticleByGroupQuery,
  useAddFavoriteArticleMutation,
  useGetAllFavoriteArticlesQuery,
  useRemoveOneFavoriteArticlesMutation,
  useRemoveAllFavoriteArticlesMutation,
  useGetSuggestedArticlesQuery,
  useEditSuggestedArticleMutation,
  useSearchArticlesQuery,
  useLazySearchArticlesQuery,
  useReviewArticlesMutation,
  useGetTopSellingQuery,
  useGetTopRatedQuery,
  useReviewPaginatedArticlesQuery,
  useSameArticleQuery,
  useGetTotalSoldArticlesQuery,
  useAddDiscountMutation,
  useGetAllDiscountsQuery,
  useDeleteDiscountMutation,
  useUpdateDiscountMutation,
  useOtherVolumesQuery,
  useGetAllArticlesMutationMutation,
  useGetAllArticlesWithoutGroupQuery,
} = articleApi
