import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import icon from '../../img/icon.jpg'
import { IoClose } from 'react-icons/io5'
import { BiSearch } from 'react-icons/bi'
import { useLazySearchArticlesQuery } from '../../api/services/articleApi'
import { debounce } from 'lodash'
import { useSelector } from 'react-redux'

const ResponsiveMenu = ({ setOpenMenu, articleGropus }) => {
  const [showResults, setShowResults] = useState(false)
  const [fetch, { data: searchArticles }] = useLazySearchArticlesQuery()
  const [menuOpen, setMenuOpen] = useState(false)
  const user = useSelector((state) => state.auth.user)
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated)
  const navigate = useNavigate()

  const handleCloseMenu = () => {
    setMenuOpen(false)
    setTimeout(() => {
      setOpenMenu(false)
    }, 300) // Adjust the duration as needed (300 milliseconds in this example)
  }

  useEffect(() => {
    setMenuOpen(true)
  }, [])

  const location = useLocation()

  const closeModal = () => {
    setShowResults(false)
  }
  // const handleChange = debounce((e) => {
  //   const value = e.target.value
  //   if (value) fetch(value)
  //   setShowResults(true)
  // }, 200)
  const handleChange = debounce((e) => {
    const value = e.target.value
    if (value?.length < 1) {
      setShowResults(false)
      return
    }
    fetch(value)
    setShowResults(true)
  }, 200)

  const handleUserClick = () => {
    if (user?.is_admin === 'Y') {
      navigate('/admin')
    } else if (user?.is_admin === 'N') {
      navigate('/korisnik')
    } else {
      navigate('/login')
    }
  }

  return (
    <div className="fixed inset-0 flex items-end justify-end z-50 bg-black bg-opacity-50 overflow-hidden font-luxorlight  ">
      <div
        className={`flex flex-col gap-6 bg-white w-2/3 h-full p-4 transition-transform ${
          menuOpen ? 'transform translate-x-0 ' : 'transform translate-x-full '
        }`}
      >
        <div className="w-full flex h-20 justify-between">
          <img src={icon} alt="" className="h-full" />
          <span className="flex items-center">
            <IoClose className="w-7 h-7" onClick={handleCloseMenu} />
          </span>
        </div>
        <div className="border   flex items-center justify-center relative">
          <input
            type="text"
            placeholder="Pretraži..."
            className="flex-1  font-luxorlight  p-1  outline-none"
            onChange={handleChange}
          />
          <button className="text-black absolute right-1">
            <BiSearch className="w-5 h-5" />
          </button>
        </div>{' '}
        {showResults && (
          <div className="absolute min-w-[60%] max-w-[90%] min-h-[50%] max-h-[50%] overflow-y-scroll top-40 bg-white border rounded p-4 z-10 text-base">
            {/* <button className="absolute top-0 right-0" onClick={closeModal}>
              <AiOutlineCloseCircle className="w-5 h-5" />
            </button> */}
            <ul className="text-sm" onClick={() => setShowResults(false)}>
              {searchArticles?.map((article) => (
                <li
                  className="flex gap-3 hover:bg-gray-100 items-center "
                  key={article.id}
                >
                  <img
                    src={article?.article_images[0]?.url}
                    alt=""
                    className="w-10 h-10"
                  />
                  <div className="flex justify-between w-full">
                    <Link
                      to={`/article/${article?.article_id}`}
                      onClick={() => setOpenMenu(false)}
                      className="truncate max-w-[200px] ..."
                    >
                      {article?.article_name}
                    </Link>
                    <p>{article?.price} KM</p>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        )}
        <div className="flex flex-col justify-between mt-1">
          <div className="flex flex-col items-start gap-3 justify-between w-full text-base">
            <Link
              onClick={handleCloseMenu}
              to={'/'}
              className={`flex items-center justify-center border-b-2 border-white ${
                location.pathname === '/' ? 'active:border-[#DCA950]' : ''
              }`}
              style={{
                color: location.pathname === '/' ? '#DCA950' : 'black',
                borderBottom:
                  location.pathname === '/' ? '2px solid #DCA950' : 'none',
              }}
            >
              Početna
            </Link>
            <Link
              onClick={handleCloseMenu}
              to={'/shop'}
              className={`flex items-center justify-center border-b-2 border-white ${
                location.pathname === '/shop' ? 'active:border-[#DCA950]' : ''
              }`}
              style={{
                color: location.pathname === '/shop' ? '#DCA950' : 'black',
                borderBottom:
                  location.pathname === '/shop' ? '2px solid #DCA950' : 'none',
              }}
            >
              Svi artikli
            </Link>
            {articleGropus?.map((item) => {
              const type = item.article_group_name
                .toLowerCase()
                .replace(/\s+/g, '-')

              const decodedPathname = decodeURIComponent(location.pathname)
              return (
                <Link
                  onClick={handleCloseMenu}
                  key={Math.random()}
                  to={`/${type}`}
                  className={`flex items-center justify-center border-b-2 border-white ${
                    decodedPathname.substring(1) === type
                      ? 'active:border-[#DCA950]'
                      : ''
                  }`}
                  style={{
                    color:
                      decodedPathname.substring(1) === type
                        ? '#DCA950'
                        : 'black',
                    borderBottom:
                      decodedPathname.substring(1) === type
                        ? '2px solid #DCA950'
                        : 'none',
                  }}
                >
                  <span className="capitalize">
                    {item.article_group_name.toLowerCase()}
                  </span>
                </Link>
              )
            })}
          </div>
        </div>
        <div className="flex flex-row justify-start w-full gap-10 mt-10">
          <div className="w-[33%]" onClick={handleUserClick}>
            <span className="text-xs w-full flex flex-col justify-center items-center">
              <svg
                height="30"
                width="30"
                fill="none"
                viewBox="0 0 36 36"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M18.2379 3.5083C10.2384 3.5083 3.74609 10.0006 3.74609 18.0001C3.74609 25.9996 10.2384 32.492 18.2379 32.492C26.2374 32.492 32.7298 25.9996 32.7298 18.0001C32.7298 10.0006 26.2374 3.5083 18.2379 3.5083ZM11.0935 27.101C11.7166 25.7967 15.5135 24.5215 18.2379 24.5215C20.9624 24.5215 24.7738 25.7967 25.3824 27.101C23.4115 28.6661 20.9334 29.5936 18.2379 29.5936C15.5425 29.5936 13.0643 28.6661 11.0935 27.101ZM27.4547 24.9997C25.3824 22.4781 20.3537 21.6231 18.2379 21.6231C16.1221 21.6231 11.0935 22.4781 9.02112 24.9997C7.54296 23.0578 6.64446 20.6377 6.64446 18.0001C6.64446 11.6092 11.847 6.40667 18.2379 6.40667C24.6288 6.40667 29.8314 11.6092 29.8314 18.0001C29.8314 20.6377 28.9329 23.0578 27.4547 24.9997ZM18.2379 9.30504C15.4265 9.30504 13.1658 11.5658 13.1658 14.3772C13.1658 17.1886 15.4265 19.4493 18.2379 19.4493C21.0493 19.4493 23.3101 17.1886 23.3101 14.3772C23.3101 11.5658 21.0493 9.30504 18.2379 9.30504ZM18.2379 16.551C17.0351 16.551 16.0642 15.58 16.0642 14.3772C16.0642 13.1744 17.0351 12.2034 18.2379 12.2034C19.4408 12.2034 20.4117 13.1744 20.4117 14.3772C20.4117 15.58 19.4408 16.551 18.2379 16.551Z"
                  fill="black"
                />
              </svg>
              {isAuthenticated ? 'Moj nalog' : 'Prijavi se'}
            </span>
          </div>
          <div className="w-[33%]">
            <Link to={'/korpa'}>
              <span className="text-xs flex flex-col justify-center items-center">
                <svg
                  height="30"
                  width="30"
                  fill="none"
                  viewBox="0 0 33 34"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M21.6354 18.3605C22.6442 18.3605 23.5318 17.809 23.9891 16.9752L28.8042 8.24621C29.3018 7.35853 28.6562 6.25564 27.634 6.25564H7.7283L6.46401 3.56567H2.06592V6.25564H4.75588L9.59782 16.4641L7.78209 19.7458C6.80026 21.5481 8.09144 23.7404 10.1358 23.7404H26.2756V21.0504H10.1358L11.6153 18.3605H21.6354ZM9.00603 8.9456H25.3476L21.6354 15.6705H12.1936L9.00603 8.9456ZM10.1358 25.0854C8.65633 25.0854 7.4593 26.2959 7.4593 27.7754C7.4593 29.2548 8.65633 30.4653 10.1358 30.4653C11.6153 30.4653 12.8258 29.2548 12.8258 27.7754C12.8258 26.2959 11.6153 25.0854 10.1358 25.0854ZM23.5856 25.0854C22.1062 25.0854 20.9091 26.2959 20.9091 27.7754C20.9091 29.2548 22.1062 30.4653 23.5856 30.4653C25.0651 30.4653 26.2756 29.2548 26.2756 27.7754C26.2756 26.2959 25.0651 25.0854 23.5856 25.0854Z"
                    fill="#12131C"
                  />
                  {/* <circle cx="29.20238" cy="4.54125" fill="#FF7777" r="3.7976" /> */}
                </svg>
                Korpa
              </span>
            </Link>
          </div>
          {isAuthenticated && (
            <div className="w-[33%]">
              <Link to={'/favorites'}>
                <span className="text-xs flex flex-col justify-center items-center">
                  <svg
                    height="30"
                    width="28"
                    fill="none"
                    viewBox="0 0 30 28"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M26.2072 4.47168C23.4796 1.74284 19.056 1.74284 16.3285 4.47168L14.9825 5.81763L13.6366 4.47168C10.9087 1.74375 6.4858 1.74375 3.75786 4.47168C1.02993 7.19962 1.02993 11.6225 3.75786 14.3504L5.10381 15.6964L14.9825 25.5751L24.8613 15.6963L26.2072 14.3504C28.9361 11.6228 28.936 7.19924 26.2072 4.47168Z"
                      stroke="#12131C"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="3"
                    />
                  </svg>
                  Favoriti
                </span>
              </Link>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default ResponsiveMenu
