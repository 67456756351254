import { useEffect } from 'react'
import { AiFillCloseCircle, AiOutlineQuestionCircle } from 'react-icons/ai'
import { BiImageAdd } from 'react-icons/bi'
import Select from 'react-select'
import { useAddArticleMutation } from '../../api/services/articleApi'
import { useGetAllArticleGroupsQuery } from '../../api/services/articleGroup'
import { useGetAllPerfumeTypesQuery } from '../../api/services/perfumeTypeApi'
import { useState } from 'react'
import { useGetAllScentQuery } from '../../api/services/scentsApi'
import { useAddScentToNoteMutation } from '../../api/services/notesApi'
import { toast } from 'react-toastify'
import { useGetPerfumeCategoriesQuery } from '../../api/services/perfumeApi'
import {
  useGetVolumesByArticleGroupQuery,
  useGetVolumesByGroupQuery,
} from '../../api/services/volumesApi'
import { useNavigate } from 'react-router-dom'

function AdminPanel() {
  const [formData, setFormData] = useState({
    article_name: '',
    article_code: '',
    volume_id: '',
    article_group_id: '',
    perfume_type_id: '',
    perfume_category_id: '',
    price: '',
    is_manual: 'N',
  })
  const [selectedImages, setSelectedImages] = useState([])
  const [imagePreviews, setImagePreviews] = useState([])
  const [disableInputFields, setDisableInputFields] = useState(false)

  const [gornjaNota, setGornjaNota] = useState([])
  const [srednjaNota, setSrednjaNota] = useState([])
  const [donjaNota, setDonjaNota] = useState([])
  const [gornjaNotaId, setGornjaNotaId] = useState(0)
  const [srednjaNotaId, setSrednjaNotaId] = useState(0)
  const [donjaNotaId, setDonjaNotaId] = useState(0)
  const [showNotesForm, setNotesShowForm] = useState(false)

  const [addArticleMutation] = useAddArticleMutation()
  const [addScentToNote] = useAddScentToNoteMutation()

  const { data: perfumeTypes } = useGetAllPerfumeTypesQuery()
  const { data: articleGroup } = useGetAllArticleGroupsQuery()
  const { data: scents } = useGetAllScentQuery()
  const { data: perfumeCategory } = useGetPerfumeCategoriesQuery()
  const { data: volume } = useGetVolumesByArticleGroupQuery({
    article_group_id: formData?.article_group_id,
  })

  const navigate = useNavigate()
  const scentOptions = scents?.map((scents) => ({
    label: scents.scent_name,
    value: scents.scent_id,
  }))
  const articleGroupOptions = articleGroup?.map((articleGroup) => ({
    label: articleGroup.article_group_name,
    value: articleGroup.article_group_id,
  }))
  const perfumeTypeOptions = perfumeTypes?.map((perfumeType) => ({
    label: perfumeType.perfume_type_name,
    value: perfumeType.perfume_type_id,
  }))
  const perfumeCategoryOptions = perfumeCategory?.map((perfumeCategory) => ({
    label: perfumeCategory.perfume_category_name,
    value: perfumeCategory.perfume_category_id,
  }))
  const volumeOptions = volume?.map((volume) => ({
    label: volume?.total,
    value: volume?.volume_id,
  }))

  const handleGornjaChange = (gornjaNota) => {
    setGornjaNota(gornjaNota)
  }
  const handleSrednjaChange = (srednjaNota) => {
    setSrednjaNota(srednjaNota)
  }
  const handleDonjaChange = (donjaNota) => {
    setDonjaNota(donjaNota)
  }

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files)
    const newSelectedImages = [...selectedImages, ...files]

    setSelectedImages(newSelectedImages)

    const previews = newSelectedImages.map((file) => URL.createObjectURL(file))
    setImagePreviews(previews)
  }
  const handleCancelAdd = () => {
    navigate('/admin/articles')
  }

  const handleAddArticle = () => {
    if (
      formData.article_name === '' ||
      formData.article_code === '' ||
      formData.volume === '' ||
      formData.article_group_id === '' ||
      formData.perfume_type_id === '' ||
      formData.perfume_category_id === '' ||
      formData.volume_id === '' ||
      formData.price === ''
    ) {
      toast.error('Sva polja su obavezna. Molimo vas da popunite sva polja.')
      return
    }
    const AllNotes = gornjaNota.concat(srednjaNota.concat(donjaNota))

    const FD = new FormData()
    for (const key in formData) {
      FD.append(key, formData[key])
    }
    selectedImages.forEach((image) => {
      FD.append('image', image)
    })
    FD.append('notes', AllNotes)

    addArticleMutation(FD)
      .unwrap()
      .then((res) => {
        setNotesShowForm(true)
        const note = res?.notes
        setGornjaNotaId(note[0]?.note_id)
        setSrednjaNotaId(note[1]?.note_id)
        setDonjaNotaId(note[2]?.note_id)
      })
    setDisableInputFields(true)

    toast.success('Artikal uspijesno dodan!')

    setImagePreviews([])
    clearFileInput()
    setSelectedImages([])
    setFormData({
      article_name: '',
      article_code: '',
      volume_id: '',
      article_group_id: '',
      perfume_type_id: '',
      perfume_category_id: '',
      price: '',
    })
  }
  const handleAddScentToNote = async () => {
    try {
      const gornjaNoteId = gornjaNotaId
      const srednjaNoteId = srednjaNotaId
      const donjaNoteId = donjaNotaId

      const gornjaScents = gornjaNota.map((note) => note?.value)

      const srednjaScents = srednjaNota.map((note) => note?.value)
      const donjaScents = donjaNota.map((note) => note?.value)

      await addScentToNote({
        note_id: gornjaNoteId,
        scents: gornjaScents,
      })

      await addScentToNote({
        note_id: srednjaNoteId,
        scents: srednjaScents,
      })

      await addScentToNote({
        note_id: donjaNoteId,
        scents: donjaScents,
      })
      toast.success('Note dodane na artikal!')
      setDisableInputFields(false)
    } catch (error) {
      console.error('Error adding scents to notes:', error)
    }
  }
  //REMOVE image
  const handleImageRemove = (indexToRemove) => {
    const updatedSelectedImages = selectedImages.filter(
      (_, index) => index !== indexToRemove,
    )
    setSelectedImages(updatedSelectedImages)

    const updatedPreviews = updatedSelectedImages.map((file) =>
      URL.createObjectURL(file),
    )
    setImagePreviews(updatedPreviews)
  }
  function clearFileInput() {
    const fileInput = document.getElementById('file-input')
    fileInput.value = ''
  }
  useEffect(() => {
    return () => {
      imagePreviews.forEach((preview) => URL.revokeObjectURL(preview))
    }
  }, [imagePreviews])

  return (
    <div className="flex flex-col mx-4 px-5 p-4 bg-gray-100 min-h-screen">
      <div className="flex my-4">
        <label htmlFor="addArticle" className="font-extrabold mx-2">
          DODAJ NOVI ARTIKAL
        </label>
      </div>

      <div className="flex md:flex-row flex-col md:space-x-8 w-full ">
        <div className="flex flex-col md:w-2/4 w-full">
          <div className="">
            <label htmlFor="basicInfo" className="font-bold ">
              Osnovno
            </label>
            <div className="flex flex-col border rounded-2xl item-center px-7 py-3 my-3 bg-white ">
              <label htmlFor="nazivArtikla" className="text-gray-400 my-2">
                Naziv artikla
              </label>
              <input
                type="text"
                value={formData.article_name}
                onChange={(e) =>
                  setFormData({ ...formData, article_name: e.target.value })
                }
                readOnly={disableInputFields}
                className="border rounded-md pl-3 h-10"
              />
              <label htmlFor="sifraArtikla" className="text-gray-400 my-2 ">
                Šifra artikla
              </label>
              <input
                type="number"
                className="border rounded-md pl-3 h-10"
                value={formData.article_code}
                onChange={(e) =>
                  setFormData({ ...formData, article_code: e.target.value })
                }
                readOnly={disableInputFields}
              />
            </div>
          </div>

          <div className="">
            <label htmlFor="Categories" className="text-black font-bold">
              Kategorija
            </label>
            <div className="flex flex-col border rounded-2xl item-center px-7 py-3 my-3 gap-y-1 bg-white">
              <label htmlFor="articleCategory" className="text-gray-400">
                Kategorija artikla
              </label>
              <Select
                options={articleGroupOptions}
                onChange={(option) => {
                  setFormData((prev) => ({
                    ...prev,
                    article_group_id: option.value,
                  }))
                }}
                value={
                  articleGroupOptions?.find(
                    (item) => item.value === formData.article_group_id,
                  ) || null
                }
                isDisabled={disableInputFields}
              />
              <label htmlFor="sifraArtikla" className="text-gray-400">
                Podkategorija artikla
              </label>
              <Select
                name="subcategory"
                options={perfumeTypeOptions}
                onChange={(option) => {
                  setFormData((prev) => ({
                    ...prev,
                    perfume_type_id: option.value,
                  }))
                }}
                value={
                  perfumeTypeOptions?.find(
                    (item) => item.value === formData?.perfume_type_id,
                  ) || null
                }
                isDisabled={disableInputFields}
                classNamePrefix="select"
              />
              <label htmlFor="" className="text-gray-400">
                Kategorija mirisa parfema
              </label>
              <Select
                name="perfumeCategory"
                options={perfumeCategoryOptions}
                onChange={(option) => {
                  setFormData((prev) => ({
                    ...prev,
                    perfume_category_id: option.value,
                  }))
                }}
                value={
                  perfumeCategoryOptions?.find(
                    (item) => item.value === formData?.perfume_category_id,
                  ) || null
                }
                isDisabled={disableInputFields}
                classNamePrefix="select"
              />
            </div>
          </div>

          <div className="">
            <label htmlFor="inventory" className="text-black font-bold">
              Detalji
            </label>
            <div className="flex flex-col border rounded-2xl item-center px-7 py-3 my-3 bg-white gap-2">
              <label htmlFor="" className="flex text-gray-400 my-2 ">
                ML <AiOutlineQuestionCircle size={18} className="mt-1 ml-2" />
              </label>
              <Select
                name="subcategory"
                options={volumeOptions}
                onChange={(option) => {
                  setFormData((prev) => ({
                    ...prev,
                    volume_id: option.value,
                  }))
                }}
                value={
                  volumeOptions?.find(
                    (item) => item.value === formData?.volume_id,
                  ) || null
                }
                isDisabled={disableInputFields}
              />
              <label htmlFor="amounth" className="text-gray-400">
                Količina
              </label>
              <input
                type="number"
                className="border rounded-md pl-3 h-10"
                onChange={(e) =>
                  setFormData({ ...formData, stock: e.target.value })
                }
                readOnly={disableInputFields}
              />
            </div>
          </div>
        </div>

        <div className="flex flex-col md:w-2/4">
          <div>
            <label htmlFor="inventory" className="text-black font-bold">
              Fotografije artikla
            </label>
            <div className="flex flex-row w-full border rounded-2xl  px-7 py-3 my-3 bg-white">
              <div className="relative">
                <input
                  onChange={handleImageChange}
                  type="file"
                  id="file-input"
                  accept="image/*"
                  className="absolute inset-0 opacity-0 cursor-pointer"
                  alt="Image input"
                  readOnly={disableInputFields}
                />
                <div className="border-spacing-4 border-2 border-dashed border-blue-500 rounded-lg p-4">
                  <BiImageAdd className="w-16 h-16 mx-auto text-gray-400" />
                  <label
                    htmlFor="file-input"
                    className="block text-center mt-2"
                  >
                    Klikni za odabir ili prevuci
                  </label>
                </div>
              </div>
              <section className="flex flex-wrap gap-4">
                {imagePreviews.map((preview, index) => (
                  <div
                    key={index}
                    className="border border-gray-300 rounded-lg p-4 h-fit"
                  >
                    <img
                      src={preview}
                      alt={`Preview ${index}`}
                      className="flex max-w-sm max-h-12 mb-2"
                    />
                    <button
                      className="text-blue-500 text-xs rounded-lg hover:text-blue-600 cursor-pointer absolute p-1"
                      onClick={() => handleImageRemove(index)}
                    >
                      <AiFillCloseCircle className="h-5 w-5" />
                    </button>
                  </div>
                ))}
              </section>
            </div>
          </div>

          <div className="">
            <label htmlFor="price" className="text-black font-bold">
              Cijena artikla
            </label>
            <div className="border rounded-2xl px-7 py-3 my-3 bg-white">
              <label htmlFor="price" className="text-gray-400 block mb-1">
                Cijena
              </label>
              <div className="flex items-center bg-gray-100 rounded-md border overflow-hidden">
                <div className="bg-gray-100 rounded-md flex items-center px-3 py-1">
                  <h2 className="text-gray-600 text-sm mr-1 font-semibold">
                    KM
                  </h2>
                </div>
                <input
                  type="number"
                  className="pl-3 h-10 flex-grow bg-white rounded-md focus:outline-none "
                  placeholder="Cijena artikla"
                  value={formData.price}
                  onChange={(e) =>
                    setFormData({ ...formData, price: e.target.value })
                  }
                  readOnly={disableInputFields}
                />
              </div>
            </div>
          </div>

          {/* Buttoni dodaj i odustani */}
          <div className="flex flex-row justify-evenly my-8 space-x-4">
            <button
              className="border h-10 w-3/6 font-bold bg-gray-300"
              onClick={handleCancelAdd}
            >
              ODUSTANI
            </button>
            <button
              className="border h-10 w-3/6 font-bold bg-slate-800 text-white"
              onClick={handleAddArticle}
              disabled={disableInputFields}
            >
              DODAJ ARTIKAL
            </button>
          </div>

          {showNotesForm && (
            <div className="flex flex-col border rounded-2xl item-center px-7 py-3 my-3 gap-y-1 bg-white">
              <label htmlFor="" className="text-gray-400">
                Gornja nota
              </label>
              <Select
                isMulti
                name="colors"
                options={scentOptions}
                onChange={handleGornjaChange}
                value={gornjaNota}
                className="basic-multi-select"
                classNamePrefix="select"
              />
              <label htmlFor="" className="text-gray-400">
                Srednja nota
              </label>
              <Select
                isMulti
                name="colors"
                options={scentOptions}
                onChange={handleSrednjaChange}
                value={srednjaNota}
                className="basic-multi-select"
                classNamePrefix="select"
              />
              <label htmlFor="" className="text-gray-400">
                Donja nota
              </label>
              <Select
                isMulti
                name="lowNote"
                options={scentOptions}
                onChange={handleDonjaChange}
                value={donjaNota}
                classNamePrefix="select"
              />
              <div>
                <button
                  className="border h-10 w-3/6 font-bold bg-slate-800 text-white"
                  onClick={handleAddScentToNote}
                >
                  Dodaj note
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default AdminPanel
