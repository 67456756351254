import React from 'react'

function ArticleCardSkeleton() {
  return (
    <div className="flex flex-col md:w-[260px] md:h-fit monitor:w-[400px] monitor:h-[500px] w-[150px] ">
      <div className="w-full h-full monitor:min-h-[403px] monitor:max-h-[403px] md:min-h-[283px] md:max-h-[283px] md: relative">
        <div
          // src={item?.article_images[0]?.url || ''}
          // alt=""
          className="w-full h-full monitor:min-h-[403px] monitor:max-h-[403px] md:min-h-[283px] md:max-h-[283px]  border bg-gray-100 object-cover  "
        ></div>
      </div>
      <div className="pt-2">
        <div className="hidden md:inline-block">
          {/* <PerfumeTypeTitle text={parfume_type} fontsize={15} /> */}
        </div>
        <div className="md:hidden ">
          {/* <PerfumeTypeTitle text={parfume_type} fontsize={10} /> */}
        </div>
        <div className="flex md:flex-row flex-col md:gap-3 bg-gray-100 text-gray-100 rounded-3xl gap-2">
          <p className="font-luxor max-h-[24px] md:text-[24px] text-lg leading-6 overflow-hidden">
            ARtikel name
          </p>

          <p className="font-luxor max-h-[24px] md:text-[24px] text-md leading-6 overflow-hidden">
            mili ml
          </p>
        </div>
        <div className="flex flex-row md:gap-2 rounded">
          <p className=" md:text-[32px] text-xl bg-gray-100 text-gray-100  inline-block font-karla md:leading-9 rounded-3xl mt-2">
            cijena
          </p>
        </div>
      </div>
    </div>
  )
}

export default ArticleCardSkeleton
