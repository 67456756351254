import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useGetShippingCostQuery } from '../../api/services/configuratorsApi'

const Payment = ({ total }) => {
  const bag = useSelector((state) => state.auth.bag)
  useSelector((state) => state.auth.user)
  const { data: shippingCost } = useGetShippingCostQuery()

  const navigate = useNavigate()

  const handleNaplataClick = () => {
    // if (bagArticles.length > 0) {
    //   addOrder({
    //     order_number: Math.random(),
    //     first_name: user?.first_name,
    //     last_name: user?.last_name,
    //     email: user?.email,
    //     address: user?.address,
    //     country: user?.country,
    //     zip_code: user?.zip_code,
    //     phone_number: user?.phone_number,
    //     user_id: user?.user_id,
    //     city: user?.city,
    //   })
    //     .unwrap()
    //     .then((res) => {
    //       console.log(res)
    //       localStorage.setItem('order_id', res?.order?.order_id)
    //       addOrderArticles({
    //         order_id: res?.order?.order_id,
    //         order_articles: bagArticles,
    //       })
    //       toast.success('Narudžba uspješno kreirana!')
    //     })
    // }
    navigate('/checkout')
  }

  return (
    <div className=" px-2 py-5 md:w-[25%] w-full">
      {/* {/ {/ RAČUN /} /} */}
      <div className="flex flex-col bg-[#FEFBF6]">
        <div className="mx-3 my-3">
          <div className="space-y-4">
            <h2 className="font-semibold text-lg mb-4">Račun</h2>
          </div>
          {/* {/ {/ Vrijednost robe /} /} */}
          <div className="space-y-3">
            <div className="flex flex-row justify-between">
              <p className="text-gray-400">Vrijednost proizvoda:</p>
              <p> {total?.toFixed(2)} KM</p>
            </div>
            {/* {/ {/ Dostava /} /} */}
            <div className="flex flex-row justify-between">
              <label className="text-gray-400">Troškovi dostave</label>
              <span>
                {' '}
                {total >= shippingCost?.free_shipping
                  ? parseFloat(0).toFixed(2)
                  : parseFloat(shippingCost?.shipping_cost).toFixed(2)}{' '}
                KM
              </span>
            </div>
            {/* {/ {/ Total price /} /} */}
            <div className="flex flex-row w-full justify-between ">
              <label className="text-gray-400">Ukupno</label>
              {total > 0 ? (
                <p>
                  {' '}
                  {total >= shippingCost?.free_shipping
                    ? total.toFixed(2)
                    : (total + parseFloat(shippingCost?.shipping_cost)).toFixed(
                        2,
                      )}{' '}
                  KM
                </p>
              ) : (
                <p>0 KM </p>
              )}
            </div>
            {/* {/ {/ Naplata button /} /} */}
            <div className="flex w-full items-center justify-center my-4 ">
              <button
                className="bg-[#DCA950] text-white w-full py-2 mt-4"
                onClick={handleNaplataClick}
              >
                Naplata računa
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* {/ {/ Metode plaćanja /} /} */}
    </div>
  )
}

export default Payment
