import React from 'react'
import { Link } from 'react-router-dom'

function TopArticles({ data }) {
  return (
    <div className="flex flex-col gap-4">
      {data?.map((item, index) => (
        <div key={index} className="flex border rounded p-2 gap-2">
          <img
            src={item?.article_images[0]?.url} // Set the image source from your data
            alt="ArticleImage"
            className="w-10 h-10 flex items-center justify-center"
          />
          <div className="flex flex-col">
            <Link
              to={`/article/${item.article_id}`}
              className="flex items-center justify-center"
            >
              <h3 className="font-bold">{item?.article_name}</h3>
            </Link>
            <p>{item?.article_group.article_group_name}</p>
          </div>
          <div className="flex-grow"></div>
          <div className="flex self-end font-bold gap-1">
            <p>{item?.price}</p>
            <p>KM</p>
          </div>
        </div>
      ))}
    </div>
  )
}

export default TopArticles
