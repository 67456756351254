import React, { useState } from 'react'
import { useAddScentMutation } from '../../api/services/scentsApi'
import { AiOutlineClose } from 'react-icons/ai'
import { toast } from 'react-toastify'

function AddScentModal({ onClose }) {
  const [addScentMutation, { isLoading }] = useAddScentMutation() // Use the addScentMutation hook

  const [scentName, setScentName] = useState({
    scent_name: '',
  })

  const handleInputScentName = async () => {
    try {
      // Send the scent data to the API
      const response = await addScentMutation(scentName)

      if (response.data) {
        toast.success('Nota uspiješno dodata!')
        // Clear the input field
        setScentName({ scent_name: '' })
        onClose()
      } else {
        console.error('Error adding scent:', response.error)
      }
    } catch (error) {
      console.error('Network error:', error)
    }
  }

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      {/* Background overlay */}
      <div className="fixed inset-0 bg-black opacity-50"></div>

      {/* Modal */}
      <div className="flex flex-col rounded-lg m-10 w-2/3 md:w-1/3 bg-white z-10 space-y-3 py-4">
        <button className="flex justify-end p-2" onClick={onClose}>
          <AiOutlineClose />
        </button>
        <label htmlFor="" className="mx-3 font-bold">
          Naziv mirisa
        </label>
        <input
          type="text"
          value={scentName.scent_name}
          onChange={(e) =>
            setScentName((prev) => ({ ...prev, scent_name: e.target.value }))
          }
          className="mx-3 border-blue-400 border-2 rounded h-12 px-2"
        />
        <button
          className="mx-3 rounded border-black hover: bg-blue-400 h-8 text-white font-bold"
          onClick={handleInputScentName}
        >
          Dodaj
        </button>
      </div>
    </div>
  )
}

export default AddScentModal
