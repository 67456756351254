const TableWrapper = ({ children }) => {
  return (
    <div className="container px-4 mx-auto max-w-[350px] sm:max-w-xl lg:max-w-full overflow-x-auto block  ">
      <div className="flex flex-col items-center justify-center">
        <div className="flex flex-col w-full mb-2 ">{children}</div>
      </div>
    </div>
  )
}

export default TableWrapper
