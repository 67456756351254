import React from 'react'

function PravilaPrivatnosti() {
  return (
    <div className="monitor:px-[140px] md:px-[100px] px-[40px] pt-10">
      <p className="md:text-6xl text-3xl font-luxor">Politika privatnosti</p>
      <p className="md:text-xl text-lg  font-luxorlight pt-5">
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Officiis,
        necessitatibus alias. Nobis, debitis cumque labore, dolorem, reiciendis
        et cum quam eum nam voluptatem rem voluptate repellat. Quasi, quis?
        Ullam, velit!
      </p>
    </div>
  )
}

export default PravilaPrivatnosti
