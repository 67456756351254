import { api } from '../api'

export const bagArticleApi = api.injectEndpoints({
  endpoints: (builder) => ({
    addArticleToCart: builder.mutation({
      query: (body) => ({
        url: `api/v1/bag-articles`,
        body,
        method: 'POST',
      }),
      invalidatesTags: ['BagArticle'],
    }),
    getBagArticles: builder.query({
      query: (bag_id) => ({
        url: `api/v1/bag-articles/get-bag-articles/${bag_id}`,
      }),
      providesTags: ['BagArticle', 'Orders'],
    }),
    deleteOneBagArticle: builder.mutation({
      query: ({ bag_article_id }) => ({
        url: `api/v1/bag-articles/${bag_article_id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['BagArticle'],
    }),
    updateBagArticle: builder.mutation({
      query: (body) => ({
        url: `api/v1/bag-articles`,
        body,
        method: 'PUT',
      }),
      invalidatesTags: ['BagArticle'],
    }),
    updateBagArticleByVoucher: builder.mutation({
      query: ({ bag_id, query }) => ({
        url: `api/v1/bag-articles/update-by-voucher/${bag_id}/${query}`,
        // body,
        method: 'PUT',
      }),
      invalidatesTags: ['BagArticle'],
    }),
    deleteAllBagArticles: builder.mutation({
      query: (bag_id) => ({
        url: `api/v1/bag-articles/from-bag/${bag_id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['BagArticle'],
    }),
  }),
})

export const {
  useAddArticleToCartMutation,
  useGetBagArticlesQuery,
  useDeleteOneBagArticleMutation,
  useUpdateBagArticleMutation,
  useDeleteAllBagArticlesMutation,
  useUpdateBagArticleByVoucherMutation,
} = bagArticleApi

// DELETE api/v1/bag-articles/from-bag/bag_id
