import React from 'react'
import AdminSidebar from '../AdminSidebar'
import { Outlet } from 'react-router-dom'
import AdminHeader from './AdminHeader'

function AdminLayout() {
  return (
    <div className="grid lg:grid-cols-[20%_auto] h-screen w-full font-karla">
      <AdminSidebar />
      <main className="bg-slate-100 h-full ">
        <AdminHeader />
        <Outlet />
      </main>
    </div>
  )
}

export default AdminLayout
