const ColumnFilterPagination = ({ column, handleFilterChange, count }) => {
  const { filterValue, setFilter } = column

  return (
    <div className="p-1 w-full">
      <input
        className="border input input-xs w-full border-slate-500 placeholder:text-center"
        value={filterValue || ''}
        onChange={(e) => {
          setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
          handleFilterChange(e.target.value, column.id)
        }}
        placeholder={`Pretraži zapise...`}
      />
    </div>
  )
}

export default ColumnFilterPagination
