import React from 'react'
import SignUp from '../components/login/SignUp'

const Login = () => {
  return (
    <div className="w-full h-full flex justify-center items-center">
      <div className="flex lg:flex-row w-full">
        <SignUp />
      </div>
    </div>
  )
}
export default Login
