import React from 'react'
import { Outlet } from 'react-router-dom'
import UserSidebar from './UserSidebar'
import UserPageHeader from './UserPageHeader'

function UserLayout() {
  return (
    <div>
      {/* <Header /> */}
      <div className="grid lg:grid-cols-[25%_auto] md:h-screen">
        <UserSidebar />
        <main className="bg-[#F7FAFC] h-sfull max-h-[200%] overflow-auto">
          <UserPageHeader />
          <Outlet />
        </main>
      </div>
    </div>
  )
}

export default UserLayout
