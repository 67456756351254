import Modal from './Modal'

const ModalReport = ({ state, showModal, setShowModal, data }) => {
  return (
    <Modal
      title={state}
      showModal={showModal}
      setShowModal={setShowModal}
      page="report"
    >
      <div className="w-full h-[75vh]">
        {/* <object data={data} type='application/pdf' width='100%' height='100%'>
          <p>
            Link za pdf fajl. <a href={data}>to the PDF!</a>
          </p>
        </object> */}
        <iframe
          src={data}
          frameBorder="0"
          title="test"
          width="100%"
          height="100%"
        ></iframe>
      </div>
    </Modal>
  )
}

export default ModalReport
