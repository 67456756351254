import React, { useMemo, useState } from 'react'
import { AiFillEdit, AiOutlinePlusCircle } from 'react-icons/ai'
import TableWrapper from '../../components/TableWrapper'
import AddDiscountModal from '../../components/Discounts/addDiscountModal'
import { FaTrash } from 'react-icons/fa'

import MainTable from '../../components/MainTable'
import {
  useDeleteDiscountMutation,
  useGetAllDiscountsQuery,
} from '../../api/services/articleApi'
import GlobalDelete from '../../components/GlobalDelete'
import { toast } from 'react-toastify'
import UpdateDiscount from '../../components/Discounts/updateDiscount'
import { format } from 'date-fns'

function Discount() {
  const [openModal, setOpenModal] = useState(false)
  const [openDelete, setOpenDelete] = useState(false)
  const [openUpdate, setOpenUpdate] = useState(false)
  const [selectedDiscount, setSelectedDiscount] = useState()

  const { data: discounts } = useGetAllDiscountsQuery()
  const [deleteDiscount] = useDeleteDiscountMutation()

  const handleCancel = () => {
    setOpenDelete(false)
  }

  const handleDelete = async () => {
    try {
      const response = await deleteDiscount({
        discount_id: selectedDiscount.discount_id,
        discount: selectedDiscount.discount,
        article_group_id: selectedDiscount.article_group_id,
      })

      if (response.data) {
        toast.success('Sniženje je uspiješno izbrisano')
        setOpenDelete(false)
      } else {
        toast.error('Error deleting discount:', response.error)
      }
    } catch (error) {
      console.error('Network error:', error)
    }
  }

  const handleOpenAddDiscount = () => {
    setOpenModal((prev) => !prev)
  }
  const handleOpenEdit = () => {
    setOpenUpdate((prev) => !prev)
  }

  const tableData = useMemo(
    () => (Array.isArray(discounts) ? discounts : []),
    [discounts],
  )

  const columns = useMemo(
    () => [
      {
        Header: 'Kategorija',
        accessor: 'article_group.article_group_name',
      },
      {
        Header: 'Od',
        accessor: 'from',
        Cell: ({ row }) => {
          const date = new Date(row.original.from)
          return format(date, 'dd.MM.yyyy')
        },
      },
      {
        Header: 'Do',
        accessor: 'to',
        Cell: ({ row }) => {
          const date = new Date(row.original.to)
          return format(date, 'dd.MM.yyyy')
        },
      },
      {
        Header: 'Iznos popusta %',
        accessor: 'discount',
      },
      {
        Header: 'Zapremine',
        accessor: 'string_volumes',
      },
      {
        Header: 'Status',
        accessor: 'status',
      },
    ],
    [],
  )
  const tableHooksArray = [
    {
      label: 'Status',
      Icon: AiFillEdit,
      onClick: (row) => {
        setOpenUpdate(true)
        setSelectedDiscount(row.original)
      },
      disabled: false,
    },
    {
      label: 'Izbriši',
      Icon: FaTrash,
      onClick: (row) => {
        setOpenDelete(true)
        setSelectedDiscount(row.original)
      },
      disabled: false,
    },
  ]

  return (
    <>
      <div className="flex flex-col mx-4 my-5">
        <div className="flex flex-row my-6 items-center rounded mx-4">
          <button
            className="bg-blue-500 rounded-md text-sm text-white h-8 flex items-center px-2"
            onClick={handleOpenAddDiscount}
          >
            <AiOutlinePlusCircle className="flex items-center" />
            Dodaj akciju
          </button>
        </div>
        <TableWrapper>
          <MainTable
            columns={columns}
            actions={true}
            data={tableData}
            hooksArray={tableHooksArray}
          />
        </TableWrapper>
      </div>
      {openDelete && (
        <GlobalDelete handleCancel={handleCancel} handleDelete={handleDelete} />
      )}
      {openModal && <AddDiscountModal onClose={handleOpenAddDiscount} />}
      {openUpdate && (
        <UpdateDiscount onClose={handleOpenEdit} data={selectedDiscount} />
      )}
    </>
  )
}

export default Discount
