import React, { useEffect, useMemo, useState } from 'react'
import {
  useDeleteOrderMutation,
  useGetAllOrdersQuery,
} from '../../api/services/ordersApi'
import { BsClipboard2DataFill, BsFillEyeFill } from 'react-icons/bs'
import OrderStatusModal from '../../components/adminOrders/OrderStatusModal'
import OrderArticles from '../../components/adminOrders/OrderArticles'
import TableWrapper from '../../components/TableWrapper'
import { toast } from 'react-toastify'
import GlobalDelete from '../../components/GlobalDelete'
import AboutOrder from '../../components/adminOrders/AboutOrder'
import MainPaginatedTable from '../../components/MainPaginatedTable'
import SelectColumnFilterPaginated from '../../components/SelectColumnFilterPaginated '
import { useAsyncDebounce } from 'react-table'
import { FaTrash } from 'react-icons/fa'
import { FiEdit } from 'react-icons/fi'

const Orders = () => {
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(25)

  const [filterInputs, setFilterInputs] = useState({})

  const query = useMemo(() => {
    let queryString = ''
    for (const [key, value] of Object.entries(filterInputs)) {
      if (value.trim() !== '') {
        queryString += `${key}=${encodeURIComponent(value)}&`
      }
    }

    return queryString
  }, [filterInputs])

  const [deleteOrder] = useDeleteOrderMutation()

  const { data: allOrders, isFetching } = useGetAllOrdersQuery({
    page: page,
    page_size: pageSize,
    query,
  })

  const [expandedRow, setExpandedRow] = useState(null)
  const [selectedOrder, setSelectedOrder] = useState({})
  const [deleteModal, setDeleteModal] = useState(false)
  const [orderStatus, setOrderStatus] = useState(false)
  const [editModal, setEditModal] = useState(false)
  const [orderModal, setOrderModal] = useState(false)

  const handleRowClick = (row) => {
    if (expandedRow === row.id) {
      setExpandedRow(null)
    } else {
      setExpandedRow(row.id)
      setSelectedOrder(row.original)
    }
  }

  const handleDelete = async () => {
    try {
      await deleteOrder(selectedOrder?.order_id)
      setDeleteModal(false)
      toast.success('Narudžba uspješno obrisana!')
    } catch (error) {
      toast.error('Došlo je do greške prilikom brisanja narudžbe')
    }
  }

  const handleCancel = () => {
    setDeleteModal(false)
  }

  const handleFilterChange = useAsyncDebounce((value, columnId) => {
    setPage(1)
    setFilterInputs((prev) => {
      const newValue = value.trim() || ' '
      return { ...prev, [columnId]: newValue }
    })
  }, 50)

  const columns = useMemo(
    () => [
      {
        Header: 'Broj Narudzbe',
        accessor: 'order_number',
      },
      {
        Header: 'Ime',
        accessor: 'first_name',
      },
      {
        Header: 'Prezime',
        accessor: 'last_name',
      },
      {
        Header: 'Cijena',
        accessor: 'order_total',
      },
      {
        Header: 'Email',
        accessor: 'email',
      },
      {
        Header: 'Status',
        accessor: 'order_status.name',
        Filter: ({ column }) => (
          <SelectColumnFilterPaginated
            column={column}
            handleFilterChange={handleFilterChange}
            advancedOptions
            providedOptions={[
              { label: 'Isporučeno', value: 'ISPORUČENO' },
              { label: 'Naručeno', value: 'NARUČENO' },
              { label: 'Otkazano', value: 'OTKAZANO' },
            ]}
          />
        ),
        Cell: ({ row }) => {
          return (
            <span
              className={`border p-1 capitalize font-medium flex justify-center ${
                row?.original?.order_status?.name === 'NARUČENO'
                  ? 'bg-orange-200 text-orange-500'
                  : ''
              } 
              ${
                row?.original?.order_status?.name === 'ISPORUČENO'
                  ? 'bg-green-200 text-green-500'
                  : ''
              }
              ${
                row?.original?.order_status?.name === 'OTKAZANO'
                  ? 'bg-red-200 text-red-500'
                  : ''
              } 
              `}
            >
              {row?.original?.order_status?.name?.toLowerCase()}
            </span>
          )
        },
      },
    ],
    [expandedRow],
  )

  const data = useMemo(
    () => (Array.isArray(allOrders?.orders) ? allOrders?.orders : []),
    [allOrders?.orders],
  )

  const editStatus = (row) => {
    if (
      row.original.order_status.order_status_id === 2 ||
      row.original.order_status.order_status_id === 3
    ) {
      toast.info(
        `Narudžba ${
          row.original.order_status.order_status_id === 2
            ? 'isporučena!'
            : 'otkazana!'
        } Status se ne može mijenjati!`,
      )
    } else {
      setOrderStatus(true)
    }
  }

  const tableHooksArray = [
    {
      label: 'Uredi',
      Icon: FiEdit,
      onClick: (row) => {
        setSelectedOrder(row.original)
        editStatus(row)
      },
      disabled: false,
    },
    {
      label: 'Izbriši',
      Icon: FaTrash,
      onClick: (row) => {
        setDeleteModal(true)
        setSelectedOrder(row.original)
      },
      disabled: false,
    },
    {
      label: 'Podaci',
      Icon: BsClipboard2DataFill,
      onClick: (row) => {
        setOrderModal(true)
        setSelectedOrder(row.original)
      },
      disabled: false,
    },
    {
      label: 'Narudžba',
      Icon: BsFillEyeFill,
      onClick: (row) => {
        setEditModal(true)
        setSelectedOrder(row.original)
      },
      disabled: false,
    },
  ]

  useEffect(() => {
    if (deleteModal || orderStatus || editModal || orderModal) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'auto'
    }
  }, [deleteModal, orderStatus, editModal, orderModal])

  return (
    <>
      <div className="px-4">
        <div className="w-full">
          <TableWrapper>
            <MainPaginatedTable
              data={data}
              columns={columns}
              actions={true}
              filtering
              pagination
              controlledPageCount={allOrders?.total_pages || 0}
              setPage={setPage}
              setPageAmount={setPageSize}
              count={allOrders?.total_count}
              pageManual={page}
              isFetching={isFetching}
              filterInputs={filterInputs}
              setFilterInputs={setFilterInputs}
              hooksArray={tableHooksArray}
            />
          </TableWrapper>
        </div>
      </div>
      {deleteModal && (
        <GlobalDelete
          setDeleteModal={setDeleteModal}
          orderId={selectedOrder?.order_id}
          handleDelete={handleDelete}
          handleCancel={handleCancel}
        />
      )}
      {orderStatus && (
        <OrderStatusModal
          setOrderStatus={setOrderStatus}
          orderId={selectedOrder?.order_id}
          selectedOrder={selectedOrder}
        />
      )}
      {editModal && (
        <OrderArticles
          setEditModal={setEditModal}
          selectedOrder={selectedOrder}
        />
      )}
      {orderModal && (
        <AboutOrder
          setOrderModal={setOrderModal}
          selectedOrder={selectedOrder}
        />
      )}
    </>
  )
}

export default Orders
