import { api } from '../api'

export const couponApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getAllCouponsToPrint: builder.query({
      query: ({ voucher_batch_key }) => ({
        url: `api/v1/voucher/get-vouchers-by-key/${voucher_batch_key}`,
      }),
      providesTags: ['Coupon'],
    }),
    getAllCoupons: builder.query({
      query: ({ page, page_size }) => ({
        url: `api/v1/voucher/get-vouchers/${page}/${page_size}`,
      }),
      providesTags: ['Coupon'],
    }),
    createCoupon: builder.mutation({
      query: (body) => ({
        url: `api/v1/voucher/`,
        body,
        method: 'POST',
      }),
      invalidatesTags: ['Coupon'],
    }),
    getCouponById: builder.query({
      query: ({ voucher_id }) => ({
        url: `api/v1/voucher/get-voucher/${voucher_id}`,
      }),
      providesTags: ['Coupon'],
    }),
    deleteCoupon: builder.mutation({
      query: ({ voucher_id }) => ({
        url: `api/v1/voucher/${voucher_id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Coupon'],
    }),
    // {percentage, from, toString, coupon_used, code, number_of_created_vouchers}
    createAutoCoupon: builder.mutation({
      query: (body) => ({
        url: `api/v1/voucher/full-voucher-endpoint`,
        body,
        method: 'POST',
      }),
      invalidatesTags: ['Coupon'],
    }),
    updateUsedCoupon: builder.mutation({
      query: ({ coupon_id }) => ({
        url: `api/v1/voucher/updateing-if-used/${coupon_id}`,
        method: 'POST',
      }),
      invalidatesTags: ['Coupon'],
    }),
    getAllCouponsToUse: builder.query({
      query: () => ({
        url: `api/v1/voucher/vouchers-without-pagination`,
      }),
      providesTags: ['Coupon'],
    }),

    // getAllCouponsByLot: builder.query({
    //   query: ({ voucher_batch_key }) => ({
    //     url: `api/v1/voucher/get-vouchers-by-key/${voucher_batch_key}`,
    //   }),
    //   providesTags: ['Coupon'],
    // }),
    // getCouponToPrint: builder.query({
    //   query: ({ voucher_batch_key }) => ({
    //     url: `api/v1/voucher/get-vouchers-by-key/${voucher_batch_key}`,
    //   }),
    //   providesTags: ['Coupon'],
    // }),
  }),
})
export const {
  useCreateCouponMutation,
  useDeleteCouponMutation,
  useGetAllCouponsQuery,
  useGetCouponByIdQuery,
  useCreateAutoCouponMutation,
  useUpdateUsedCouponMutation,
  useGetAllCouponsToUseQuery,
  useLazyGetAllCouponsToPrintQuery,
} = couponApi
