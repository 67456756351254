import React, { useState } from 'react'
import { useLocation } from 'react-router'
import ResponsiveUserMenu from './ResponsiveUserMenu'
import { AiOutlineMenu } from 'react-icons/ai'

function UserPageHeader() {
  const { pathname } = useLocation()
  const [openMenu, setOpenMenu] = useState(false)

  const getLabel = () => {
    switch (pathname) {
      case '/korisnik/':
        return 'Korisnički račun'
      case '/korisnik/user_privacy':
        return 'Privatnost računa'
      case '/korisnik/orders':
        return 'Narudžbe'
      default:
        return 'Korisnički račun'
    }
  }
  const getDescription = () => {
    switch (pathname) {
      case '/korisnik/':
        return 'Ovdje možete promijeniti podatke Vašeg korisničkog računa'
      case '/korisnik/user_privacy':
        return 'Ovdje možete promijeniti postavke privatnosti'
      case '/korisnik/orders':
        return 'Ovdje možete pogledati sve Vaše dosadašnje narudžbe'
      default:
        return 'Korisnički račun'
    }
  }
  const handleOpenMenu = () => {
    setOpenMenu(!openMenu)
  }

  return (
    <>
      <div className="flex lg:flex-col h-[152px] bg-white w-full font-luxor px-9 py-7 gap-4">
        <div className=" flex lg:hidden items-center pb-[42px]">
          <button onClick={handleOpenMenu}>
            <AiOutlineMenu />
          </button>
        </div>
        <div>
          <h1 className="text-[35px] sm:text-[40px]">{getLabel()}</h1>
          <p className="font-luxorlight text-[#C2C2C2]">{getDescription()}</p>
        </div>
      </div>
      {openMenu && (
        <ResponsiveUserMenu openMenu={openMenu} setOpenMenu={setOpenMenu} />
      )}
    </>
  )
}

export default UserPageHeader
