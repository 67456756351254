import React from 'react'
import { useGetSuggestedArticlesQuery } from '../../api/services/articleApi'
import { Link, useNavigate } from 'react-router-dom'

const ForYourHome = ({ img }) => {
  const { data: candles } = useGetSuggestedArticlesQuery('SVIJEĆE')
  const { data: ambiental } = useGetSuggestedArticlesQuery(
    'AMBIJENTALNI MIRISI',
  )

  const navigate = useNavigate()

  const allSuggested = candles?.concat(ambiental)
  return (
    <div className="flex flex-col justify-center items-center px-2 md:px-14 md:py-12 gap-4 pt-4">
      <p className="text-base font-normal">ODABRANO ZA VAS</p>
      <p className="text-2xl font-semibold">NAJBOLJE ZA VAŠ DOM</p>
      <div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-4 md:gap-10 md:p-8 p-2 gap-4">
        {allSuggested?.map((item, index) => (
          <div
            key={index}
            className={`border-2 rounded-lg p-2 flex flex-col justify-between shadow-md transition-transform transform hover:scale-105 duration-300 ${
              index === 2
                ? 'col-span-2 row-span-2 md:col-span-2 md:row-span-2 flex justify-between'
                : ''
            }`}
          >
            <Link to={`/article/${item?.article_id}`} className="h-full w-full">
              <img
                src={item?.article_images[0]?.url}
                alt=""
                className="h-full w-full object-contain"
              />
            </Link>
            <div className="flex justify-between items-center p-1 flex-wrap font-medium md:font-semibold text-xs md:text-base">
              <p className="text-black">{item?.article_name}</p>
              <p className="text-sm text-orange-500 border rounded-xl bg-white p-1">
                {item?.price} KM
              </p>
            </div>
          </div>
        ))}
      </div>

      <div className="py-4">
        <button
          onClick={() => navigate('/ambijentalni-mirisi')}
          className="border border-black p-1 px-3 md:p-3 md:px-6 rounded-lg transition duration-300 ease-in-out hover:bg-gray-200 text-xs md:text-base font-semibold text-gray-500"
        >
          Pogledaj sve
        </button>
      </div>
    </div>
  )
}

export default ForYourHome
