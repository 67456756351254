import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import PerfumeTypeTitle from './PerfumeTypeTitle'
import { FaHeart, FaRegHeart } from 'react-icons/fa'
import {
  useAddFavoriteArticleMutation,
  useRemoveOneFavoriteArticlesMutation,
} from '../../api/services/articleApi'
import { useSelector } from 'react-redux'

function ArticleCard({ item, index, parfume_type, volume = false }) {
  const [isHeartClicked, setIsHeartClicked] = useState(false)
  const [addFavoriteArticle] = useAddFavoriteArticleMutation()
  const [removeFavoriteArticles] = useRemoveOneFavoriteArticlesMutation()
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated)
  const user = useSelector((state) => state.auth.user)

  const handleHeartClick = () => {
    const articles = { article_id: item?.article_id, user_id: user?.user_id }

    if (isHeartClicked) {
      removeFavoriteArticles(articles)
    } else {
      addFavoriteArticle(articles)
    }
    setIsHeartClicked(!isHeartClicked)
  }

  useEffect(() => {
    if (
      item?.favoritedByUsers?.length &&
      item?.favoritedByUsers[0]?.user_id === user?.user_id
    ) {
      setIsHeartClicked(true)
    }
  }, [item, user?.user_id])

  return (
    <div
      key={index}
      className="flex flex-col md:w-[260px] md:h-fit monitor:w-[400px] monitor:h-[500px] w-[150px] "
    >
      <div className="w-full h-full monitor:min-h-[403px] monitor:max-h-[403px] md:min-h-[283px] md:max-h-[283px] md: relative">
        <Link to={`/article/${item.article_id}`}>
          <img
            src={item?.article_images[0]?.url || ''}
            alt=""
            className="w-full h-full monitor:min-h-[403px] monitor:max-h-[403px] md:min-h-[283px] md:max-h-[283px]  border object-cover  "
          />
        </Link>
        {isAuthenticated && (
          <div
            className="md:w-[52px] md:h-[52px] w-[25px] h-[25px] cursor-pointer bg-black/95 absolute bottom-0 right-0 flex justify-center items-center"
            onClick={handleHeartClick}
          >
            {isHeartClicked ? (
              <FaHeart className="w-3.5 h-3.5 md:w-5 md:h-5 text-white" />
            ) : (
              <FaRegHeart className="w-3.5 h-3.5 md:w-5 md:h-5 text-white" />
            )}
          </div>
        )}
      </div>
      <div className="pt-2">
        <div className="hidden md:inline-block">
          <PerfumeTypeTitle text={parfume_type} fontsize={15} />
        </div>
        <div className="md:hidden ">
          <PerfumeTypeTitle text={parfume_type} fontsize={10} />
        </div>
        <div className="flex md:flex-row flex-col md:gap-3">
          <p className="font-luxor max-h-[24px] md:text-[24px] text-lg leading-6 overflow-hidden">
            {item?.article_name}
          </p>
          {volume === true ? (
            <p className="font-luxor max-h-[24px] md:text-[24px] text-md leading-6 overflow-hidden">
              {item?.volume?.total} ml
            </p>
          ) : (
            ''
          )}
        </div>
        {item?.discount && item?.discount > 0 ? (
          <div className="flex flex-row md:gap-2">
            <p className=" md:text-[32px] text-xl  inline-block font-karla md:leading-9 ">
              {item.discount_price} KM
            </p>
            <p className="text-[10px] md:text-[15px] text-gray-400 line-through font-karla ">
              {item.price} KM
            </p>
          </div>
        ) : (
          <p className=" md:text-[32px] text-2xl font-karla inline-block leading-8 ">
            {item?.price} KM
          </p>
        )}
      </div>
    </div>
  )
}

export default ArticleCard
