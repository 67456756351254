import React, { useState, useEffect } from 'react'
import { AiOutlineCloseCircle } from 'react-icons/ai'
import UpdateForm from '../Customers/UpdateForm'
import { useGetOneUserQuery } from '../../api/services/usersApi'

const EditModal = ({ user_id, setOpenEdit }) => {
  const { data: user } = useGetOneUserQuery(user_id)

  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    email: '',
    phone_number: '',
    address: '',
    country: '',
    zip_code: '',
    user_id: '',
    is_admin: '',
    city: '',
  })

  useEffect(() => {
    if (user) {
      setFormData({
        first_name: user?.first_name,
        last_name: user?.last_name,
        email: user?.email,
        phone_number: user?.phone_number,
        address: user?.address,
        country: user?.country,
        zip_code: user?.zip_code,
        user_id: user?.user_id,
        is_admin: user?.is_admin,
        city: user?.city,
      })
    }
  }, [user])

  const onClose = () => {
    setOpenEdit(false)
  }
  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      {/* Background overlay */}
      <div className="fixed inset-0 bg-black opacity-50"></div>

      {/* Modal */}
      <div className="flex flex-col rounded-lg bg-white z-10 justify-center items-center w-full md:w-1/3 h-1/2  md:h-1/2">
        <div className="flex justify-between px-2 py-2 w-full border-b-2">
          <p className="text-gray-600 font-medium text-lg">Uredi Kupca</p>
          <button onClick={onClose}>
            <AiOutlineCloseCircle className="w-5 h-5 text-gray-500" />
          </button>
        </div>
        <UpdateForm
          formData={formData}
          setFormData={setFormData}
          setOpenEdit={setOpenEdit}
        />
      </div>
    </div>
  )
}

export default EditModal
