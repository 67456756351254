import { api } from '../api'

export const orderStasuses = api.injectEndpoints({
  endpoints: (builder) => ({
    getOrderStatus: builder.query({
      query: () => 'api/v1/order-statuses',
      providesTags: ['OrderStatus'],
    }),
    getOrderStatusNumber: builder.query({
      query: () => 'api/v1/order-statuses/number',
      providesTags: ['OrderStatus'],
    }),
  }),
})
export const { useGetOrderStatusQuery, useGetOrderStatusNumberQuery } =
  orderStasuses
